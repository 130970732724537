import { Heading } from 'components/v2/Typography';
import styled from 'styled-components';

export const QuestionText = styled(Heading).attrs({ tag: 'h2', styledAs: 'h2' })`
  justify-content: center;
`;

export const QuestionContainer = styled.div`
  text-align: center;
  margin: 3em 0;
  background: white;
`;

export const BackButtonContainer = styled.div`
  margin-top: 85px;
  display: flex;
  justify-content: center;
  width: 100%;

  > button {
    max-width: 400px;
  }
`;
