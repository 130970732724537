import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import AuthFlowLayout from 'components/AuthFlowLayout';
import { Loader } from 'components/Loader/Loader';
import { StepCountHeading } from 'components/StepCountHeading/StepCountHeading';
import { Button } from 'components/v2/Buttons/Button';
import { PartnerClinicDetails } from 'kb-shared';
import { useBookingState } from 'kb-shared/booking/hooks/useBookingState';
import { StepType } from 'kb-shared/booking/types/types';
import { bookingAnalytics } from 'kb-shared/booking/utils/bookingAnalytics';
import { BookingSearchParams } from 'kb-shared/booking/utils/bookingSearchParams';
import { getAppointmentType } from 'kb-shared/booking/utils/getters';
import { toIntOrNull } from 'kb-shared/booking/utils/validation';
import { BackButtonContainer, Container, Content } from 'screens/Book/Book.styled';
import { pageUrl } from 'utilities/pageUrl';

import { getPageTitle, getSteps } from './BookV2.utils';
import { ConfirmStep } from './steps/Confirm';
import { InsuranceStep } from './steps/Insurance/Insurance';
import { LocationStep } from './steps/Location';
import { SuccessStep } from './steps/Success';
import { TimeStep } from './steps/Time';

export const BookV2 = (): React.ReactElement | null => {
  const history = useHistory();
  const location = useLocation();
  const {
    loading,
    error,
    entities,
    insuranceStepCompleted,
    bookingParams,
    stepSupported,
    calculatedStep,
    completedSteps
  } = useBookingState();
  const [partnerClinic, setPartnerClinic] = useState<PartnerClinicDetails | null>(null);
  const stepsMeta = getSteps({
    currentStep: (calculatedStep ?? 'location') as Exclude<StepType, 'success'>,
    completedSteps
  });
  const pageTitle = getPageTitle({
    partnerClinicSearch: bookingParams.get('partner_clinic_search') ?? '',
    selectedProduct: entities.selectedProduct || null,
    stepType: calculatedStep as StepType,
    partnerClinicSelected: partnerClinic
  });

  const appointmentType = getAppointmentType(entities.selectedProduct);
  const {
    selectedClinic,
    selectedLab,
    selectedProduct,
    selectedTimeSlot,
    purchasedProduct,
    allAppointmentTypes
  } = entities;

  const renderLocationStep = (mobile: boolean) => (
    <LocationStep
      appointmentType={appointmentType || undefined}
      selectedAppointmentTypes={allAppointmentTypes ?? []}
      partnerClinicSearch={bookingParams.get('partner_clinic_search') ?? ''}
      mobile={mobile}
      partnerClinicSelected={partnerClinic}
      selectedClinic={selectedClinic}
      selectedProduct={selectedProduct}
      // @ts-ignore-next-line
      selectedLab={selectedLab}
      onSelectClinic={(clinic, inPersonAppointmentType) => {
        const params = new BookingSearchParams(location.search);
        params.merge({
          clinic_id: clinic?.id.toString() ?? '',
          step: 'time',
          appointment_id: inPersonAppointmentType?.id || bookingParams.get('appointment_id') || ''
        });
        params.remove('week_string');
        bookingAnalytics.track.clinicSelected(clinic);
        history.push(`/Book?${params}`);
      }}
      onSelectLab={lab => {
        const params = new BookingSearchParams(location.search);
        // remain on the same step to allow user to select a clinic
        params.set('lab_id', lab?.id ?? '');
        bookingAnalytics.track.labSelected(lab?.id);
        history.push(`/Book?${params}`);
      }}
      onSelectVirtual={appointmentType => {
        if (!appointmentType) {
          return;
        }

        const params = new BookingSearchParams(location.search);
        params.remove('clinic_id');
        params.merge({
          step: 'time',
          appointment_id: appointmentType?.id ?? ''
        });
        bookingAnalytics.track.virtualClinicSelected();
        history.push(`/Book?${params}`);
      }}
      onContactUsClick={() => {
        const params = new BookingSearchParams(location.search);
        params.set('partner_clinic_search', 'search');
        bookingAnalytics.track.partnerClinicSearchStarted();
        history.push(`/Book?${params}`);
      }}
      onPartnerClinicSelected={clinic => {
        setPartnerClinic(clinic);
      }}
      onAfterSend={() => {
        const params = new BookingSearchParams(location.search);
        params.set('step', 'success');
        history.push(`/Book?${params}`);
      }}
    />
  );

  const renderTimeStep = () => (
    <TimeStep
      date={bookingParams.get('date') ?? undefined}
      providerId={toIntOrNull(bookingParams.get('provider_id')) ?? undefined}
      appointmentType={appointmentType || undefined}
      selectedClinic={selectedClinic}
      selectedLab={selectedLab}
      selectedTimeSlot={selectedTimeSlot}
      onTimeSlotSelect={timeSlot => {
        const params = new BookingSearchParams(location.search);
        params.merge({
          time_slot_id: timeSlot.id,
          step: insuranceStepCompleted ? 'confirm' : 'insurance',
          appointment_id: bookingParams.get('appointment_id') ?? ''
        });
        bookingAnalytics.track.timeSlotSelected(timeSlot);
        if (!insuranceStepCompleted) {
          bookingAnalytics.page.enterInsuranceInformation();
        }
        history.push(`/Book?${params}`);
      }}
    />
  );

  const renderInsuranceStep = () => (
    <InsuranceStep
      onNextStep={() => {
        const params = new BookingSearchParams(location.search);
        params.set('step', 'confirm');
        bookingAnalytics.page.confirmPurchase();
        history.push(`/Book?${params}`);
      }}
    />
  );

  const renderConfirmStep = () => (
    <ConfirmStep
      selectedClinic={selectedClinic}
      selectedTimeSlot={selectedTimeSlot}
      selectedProduct={selectedProduct}
      onBookAppointment={appointment => {
        const params = new BookingSearchParams(location.search);
        params.merge({
          step: 'success',
          confirmed_appointment_id: appointment.id
        });
        bookingAnalytics.track.appointmentBookingSucceeded(appointment);
        history.replace(`/Book?${params}`);
      }}
    />
  );

  const renderSuccessStep = () => (
    <SuccessStep
      partnerClinicSelected={partnerClinic}
      purchasedProduct={purchasedProduct}
      selectedTimeSlot={selectedTimeSlot}
      selectedAppointmentTypes={allAppointmentTypes ?? []}
      selectedProduct={selectedProduct}
      appointmentType={appointmentType || undefined}
    />
  );

  if (loading || error) {
    return <Loader container />;
  }

  // redirect to booking-quiz if we don't know what to show
  if (!bookingParams.containsBookingDataParams() || !stepSupported) {
    return <Redirect to={pageUrl.booking.bookingQuiz()} />;
  }

  return (
    <AuthFlowLayout
      title={pageTitle}
      renderAboveTitleContent={() =>
        calculatedStep === 'success' ? null : (
          <StepCountHeading
            currentStep={stepsMeta.currentStepNumber}
            totalSteps={stepsMeta.totalSteps}
          />
        )
      }
      renderMainContent={(mobile: boolean) => (
        <Container>
          <Content>
            {
              <>
                {calculatedStep === 'location' && renderLocationStep(mobile)}
                {calculatedStep === 'time' && renderTimeStep()}
                {calculatedStep === 'insurance' && renderInsuranceStep()}
                {calculatedStep === 'confirm' && renderConfirmStep()}
                {calculatedStep === 'success' && renderSuccessStep()}
              </>
            }
          </Content>
          {calculatedStep && calculatedStep !== 'success' && (
            <BackButtonContainer>
              <Button
                label="Back"
                fullWidth
                size="lg"
                category="secondary"
                onClick={() => {
                  setPartnerClinic(null);
                  history.goBack();
                }}
              />
            </BackButtonContainer>
          )}
        </Container>
      )}
    />
  );
};
