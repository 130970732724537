import { ApolloError } from '@apollo/client';
import React from 'react';

import { Heading } from 'components/v2/Typography';
import { Text } from 'components/v2/Typography/Text';
import { useBreakpoints } from 'utilities/useBreakpoints';

import Pagination from '../../components/Pagination';
import { InvoicesItemContainer, InvoicesTitleContainer } from '../../Invoices.styled';
import Invoice from '../../types/invoice';
import { InvoicesDisplayed } from '../InvoicesDisplayed/InvoicesDisplayed';
import { InvoicesError } from '../InvoicesError/InvoicesError';

export function PageableInvoices({
  invoices,
  isViosPatient,
  onInvoiceSelected,
  onViewInvoice,
  onPayWithCredit,
  totalBalance,
  currentPage,
  onNextPage,
  onPreviousPage,
  error
}: {
  invoices: Invoice[];
  isViosPatient: boolean;
  onInvoiceSelected: (invoice: Invoice) => void;
  onViewInvoice: (invoice: Invoice) => void;
  onPayWithCredit: (invoice: Invoice) => void;
  totalBalance: number;
  currentPage: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  error: ApolloError | undefined;
}) {
  const { isMobile, isTablet } = useBreakpoints();

  return (
    <InvoicesItemContainer>
      <InvoicesTitleContainer>
        <Heading tag="h2">Invoices</Heading>
      </InvoicesTitleContainer>
      {!error && (
        <Text tag="p">
          Review and pay statements for each date of service. Statements with a balance are listed
          at the top. You will receive a payment receipt via email from Kindbody’s credit card
          processing partner. All invoices may not yet be displayed in the portal. Please check back
          or send a secure message to the billing team.
        </Text>
      )}
      {error && <InvoicesError />}
      {!error && (
        <InvoicesDisplayed
          invoices={invoices}
          displayAsTable={!(isMobile || isTablet)}
          isViosPatient={Boolean(isViosPatient)}
          totalBalance={totalBalance}
          onInvoiceSelected={onInvoiceSelected}
          onViewInvoice={onViewInvoice}
          onPayWithCredit={onPayWithCredit}
        />
      )}
      {invoices.length > 0 && !error && (
        <Pagination
          currentPage={currentPage}
          onNextClick={onNextPage}
          onPreviousClick={onPreviousPage}
        />
      )}
    </InvoicesItemContainer>
  );
}
