import { Ellipsis } from 'assets/icons/Icons';
import styled, { css } from 'styled-components';

import { DropdownItemProps, MenuProps } from './Dropdown.types';

export const Container = styled.div`
  display: inline-block;
  position: relative;
`;

export const Icon = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
`;

export const Menu = styled.div.attrs<MenuProps>(({ ...props }) => ({
  ...props
}))<MenuProps>`
  display: ${props => (props.showMenu ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  margin-top: 8px;
  z-index: 1;

  background-color: white;
  border: 1px solid ${props => props.theme.colors.neutral.lighterNavy};

  ${props =>
    props.hasSections
      ? css`
          min-width: 285px;
          padding: 12px;
          border-radius: 8px;

          @media (max-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
            right: 0;
          }
        `
      : css`
          min-width: 185px;
          padding: 8px;
          right: 0;
          border-radius: 4px;
        `}
`;

export const DropdownItemLabel = styled.span<DropdownItemProps>`
  ${props => props.theme.fonts.Gotham};
  letter-spacing: 0px;
  color: ${props => props.theme.colors.neutral.primaryNavy};

  ${props =>
    props.type === 'primary' &&
    css`
      color: ${props.theme.colors.yellow.primary};
    `}

  ${props =>
    props.type === 'secondary' &&
    css`
      color: ${props.theme.colors.neutral.darkestNavy};
    `}

  ${props =>
    props.type === 'success' &&
    css`
      color: ${props.theme.colors.green.lightGreen};
    `}

  ${props =>
    props.type === 'warning' &&
    css`
      color: ${props.theme.colors.semantic.darkRed};
    `}

  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.neutral.lighterNavy};
    `}

  ${props =>
    props.type === 'section'
      ? css`
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        `
      : css`
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        `}
`;

export const DropdownItem = styled.button`
  width: 100%;
  border-radius: 4px;
  padding: 12px 16px;
  text-align: left;

  ${props =>
    props.itemType === 'section'
      ? css`
          padding: 0;
          border-radius: 0;
          height: auto;
        `
      : css`
          padding: 12px 16px;
          border-radius: 4px;
        `}

  ${props =>
    props.onClick
      ? css`
          &:hover {
            background-color: ${props => props.theme.colors.neutral.lavenderLight};
          }
          &:active:focus {
            background-color: ${props => props.theme.colors.yellow.lighterYellow};
          }
        `
      : css`
          cursor: default;
        `}

  ${props =>
    props.disabled
      ? css`
          border-radius: 4px;
          color: ${props => props.theme.colors.neutral.lighterNavy};
          background-color: ${props => props.theme.colors.neutral.lavenderLight};
          margin-top: 2px;
        `
      : css``}
`;

export const DropdownIcon = styled(Ellipsis)`
  color: ${({ theme }) => theme.colors.neutral.lighterNavy};
  font-size: 16px;
  font-weight: 900;
`;
