import { pageUrl } from 'utilities/pageUrl';

import { Data, URLParams } from './BookingStateManager.types';

export function generateRescheduleLink(appointmentId: string) {
  return pageUrl.booking.rescheduleAppointment(appointmentId);
}

export function generateConfirmationLink(data: Data): string | null {
  const { purchasedProduct, selectedProduct } = data;

  if (!purchasedProduct) {
    return null;
  }

  switch (purchasedProduct.type) {
    case 'appointment':
      return pageUrl.booking.confirmation({
        type: 'appointment',
        purchasedProductId: purchasedProduct.data.id
      });
    case 'membership':
      // Use the actual id of the membership product, not the id of the membership record for this user.
      const membershipProductId =
        // @ts-ignore
        (selectedProduct && selectedProduct.data.id) || 0;
      return pageUrl.booking.confirmation({
        type: 'membership',
        confirmedMembershipId: `${membershipProductId}`
      });

    case 'enterprise_membership':
      return pageUrl.booking.confirmation({ type: 'enterprise_membership' });
    default:
      return null;
  }
}

export function urlParamsFromData(data: Data): URLParams {
  const {
    selectedProduct,
    selectedAppointmentTypes,
    selectedLab,
    selectedClinic,
    selectedWeekString,
    selectedTimeSlot,
    purchasedProduct,
    partnerClinicSearch
  } = data;

  const appointmentId =
    (selectedProduct && selectedProduct.type === 'appointment' && selectedProduct.data?.id) || null;

  const membershipId =
    (selectedProduct && selectedProduct.type === 'membership' && selectedProduct.data?.id) || null;

  const rescheduleAppointmentId =
    (selectedProduct &&
      selectedProduct.type === 'reschedule_appointment' &&
      selectedProduct.data?.id) ||
    null;

  const confirmedAppointmentId =
    (purchasedProduct && purchasedProduct.type === 'appointment' && purchasedProduct.data?.id) ||
    null;

  const confirmedMembershipId =
    (purchasedProduct && purchasedProduct.type === 'membership' && purchasedProduct.data?.id) ||
    null;

  const enterpriseMembership =
    (selectedProduct && selectedProduct.type === 'enterprise_membership') || null;

  return {
    step: data.selectedStep,
    appointment_types:
      selectedAppointmentTypes && selectedAppointmentTypes.map(apptType => apptType.id).join(','),
    appointment_id: appointmentId,
    membership_id: membershipId,
    lab_id: selectedLab && selectedLab.id,
    // @ts-ignore
    clinic_id: selectedClinic && selectedClinic.id,
    week_string: selectedWeekString,
    time_slot_id: selectedTimeSlot && selectedTimeSlot.id,
    reschedule_appointment_id: rescheduleAppointmentId,
    partner_clinic_search: partnerClinicSearch,
    confirmed_appointment_id: confirmedAppointmentId,
    confirmed_membership_id: confirmedMembershipId,
    enterprise_membership: enterpriseMembership,
    date: data.date
  };
}
