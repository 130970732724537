import { ApolloError } from '@apollo/client';
import React from 'react';

import { Heading } from 'components/v2/Typography';
import { Text } from 'components/v2/Typography/Text';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { InvoicesItemContainer, InvoicesTitleContainer } from '../../Invoices.styled';
import { Payment } from '../../types/payment';
import { UnappliedPaymentsDisplayed } from '../UnappliedPaymentsDisplayed/UnappliedPaymentsDisplayed';
import { UnappliedPaymentsError } from '../UnappliedPaymentsError/UnappliedPaymentsError';

export function UnappliedPayments({
  unappliedPayments,
  error
}: {
  unappliedPayments: Payment[];
  error: ApolloError | undefined;
}) {
  const { isMinTablet } = useBreakpoints();

  return (
    <InvoicesItemContainer>
      <InvoicesTitleContainer>
        <Heading tag="h2">Unapplied Payments</Heading>
      </InvoicesTitleContainer>
      <Text tag="p">
        Unapplied payments are payments you have made that our billing team has not yet associated
        with a specific date of service or encounter. For individual dates of service, it typically
        takes up to one business week for payments to be applied. For cycle payments, the payment is
        applied after egg retrieval.
      </Text>
      {error && <UnappliedPaymentsError />}
      {!error && (
        <UnappliedPaymentsDisplayed displayAsTable={isMinTablet} payments={unappliedPayments} />
      )}
    </InvoicesItemContainer>
  );
}
