import React from 'react';
import { Link } from 'react-router-dom';

import { ActionsContainer, BookButton, DisclaimerButton, OkButton } from './ActionButtons.styled';
import { ActionButtonsProps } from './ActionButtons.types';

export const ActionButtons = ({ onCancelClick, bookVisitURL }: ActionButtonsProps) => {
  return (
    <ActionsContainer>
      {bookVisitURL ? (
        <>
          <BookButton as={Link} to={bookVisitURL}>
            Book
          </BookButton>

          <DisclaimerButton onClick={onCancelClick}>I already made my appointment</DisclaimerButton>
        </>
      ) : (
        <>
          <OkButton onClick={onCancelClick}>OK</OkButton>
        </>
      )}
    </ActionsContainer>
  );
};
