import React from 'react';

import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';

export const SmsConsentLabel: React.FC = () => {
  return (
    <>
      I agree to Kindbody’s{' '}
      <LinkButton
        text="SMS Terms"
        onClick={() => window.open('https://kindbody.com/sms-terms-2024/', '_blank')}
      />{' '}
      and consent to receive phone calls and SMS messages from Kindbody to provide updates and
      information regarding my business with Kindbody. Message frequency may vary. Message & data
      rates may apply. Reply STOP to opt-out of further messaging.
    </>
  );
};
