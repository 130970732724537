import React from 'react';

import { LoadedEntities } from 'kb-shared/booking/types/types';
import {
  isInPersonAppointmentType,
  isVirtualAppointmentType
} from 'kb-shared/booking/utils/validation';
import { AppointmentType, Clinic, PartnerClinicDetails } from 'kb-shared/types';
import { PartnerLocationRequest, PartnerLocationSearch } from 'screens/Book/steps/PartnerLocation';
import { SelectLocationVirtual } from 'screens/Book/steps/SelectLocationVirtual/SelectLocationVirtual';
import { Lab } from 'screens/Book/steps/SelectLocationVirtual/SelectLocationVirtual.types';

export const LocationStep = ({
  appointmentType,
  selectedAppointmentTypes,
  selectedClinic,
  selectedProduct,
  selectedLab,
  partnerClinicSearch,
  mobile,
  partnerClinicSelected,
  onSelectClinic,
  onSelectLab,
  onSelectVirtual,
  onContactUsClick,
  onPartnerClinicSelected,
  onAfterSend
}: {
  appointmentType?: AppointmentType;
  selectedAppointmentTypes: Array<AppointmentType>;
  selectedClinic?: Clinic;
  selectedLab?: Lab;
  selectedProduct?: LoadedEntities['selectedProduct'];
  partnerClinicSearch: string;
  mobile: boolean;
  partnerClinicSelected?: PartnerClinicDetails | null;
  onSelectClinic: (clinic: Clinic | null, appointmentType?: AppointmentType) => void;
  onSelectLab: (lab: Lab | null) => void;
  onSelectVirtual: (appointmentType?: AppointmentType) => void;
  onContactUsClick: () => void;
  onPartnerClinicSelected: (partnerClinic: PartnerClinicDetails) => void;
  onAfterSend: () => void;
}): React.ReactElement => {
  const virtualAppointmentType = selectedAppointmentTypes.find(isVirtualAppointmentType);
  const inPersonAppointmentType = selectedAppointmentTypes.find(isInPersonAppointmentType);

  let directToPartnerClinicSearch = false;
  if (
    (selectedProduct?.type === 'appointment' || selectedProduct?.type === 'package_appointment') &&
    selectedProduct.data &&
    selectedProduct.data.category
  ) {
    directToPartnerClinicSearch = ['Fertility', 'Non-discount code applicable Fertility'].includes(
      selectedProduct.data.category.name
    );
  } else if (inPersonAppointmentType && inPersonAppointmentType.category) {
    directToPartnerClinicSearch = ['Fertility', 'Non-discount code applicable Fertility'].includes(
      inPersonAppointmentType.category.name
    );
  } else if (virtualAppointmentType && virtualAppointmentType.category) {
    directToPartnerClinicSearch = ['Fertility', 'Non-discount code applicable Fertility'].includes(
      virtualAppointmentType.category.name
    );
  }

  const appointmentName = selectedProduct?.data?.name || inPersonAppointmentType?.name;

  if (partnerClinicSearch === 'search' && !partnerClinicSelected) {
    return (
      <PartnerLocationSearch
        onSelected={(partnerClinic: PartnerClinicDetails) => onPartnerClinicSelected(partnerClinic)}
      />
    );
  } else if (partnerClinicSelected) {
    return (
      <PartnerLocationRequest
        partnerClinicSelected={partnerClinicSelected}
        mobileLayout={mobile}
        appointmentName={appointmentName}
        afterSend={onAfterSend}
      />
    );
  }
  return (
    <SelectLocationVirtual
      appointmentType={appointmentType || inPersonAppointmentType}
      selectedLab={selectedLab ?? null}
      onSelectLab={(lab: Lab | null) => onSelectLab(lab)}
      selectedClinic={selectedClinic ?? null}
      onSelectClinic={(clinic: Clinic | null) => onSelectClinic(clinic, inPersonAppointmentType)}
      showVirtual={!!virtualAppointmentType}
      onSelectVirtual={() => onSelectVirtual(virtualAppointmentType)}
      directToPartnerClinicSearch={directToPartnerClinicSearch}
      onContactUsClick={() => onContactUsClick()}
    />
  );
};
