import { TextInputStyled } from 'components/TextInput/NewTextInput.styled';
import styled from 'styled-components';

export const WalmartForm = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 60px;
  gap: 16px;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    flex-direction: row;
  }
`;

export const TabItem = styled.div<{ active: boolean }>`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  flex: 1;
  text-align: center;
  border-bottom: 1px solid;
  padding: 6px;
  cursor: pointer;
  color: ${({ active }) => (active ? '#353148' : '#ABAEBA')};
  border-color: ${({ active }) => (active ? '#F1CB0A' : 'transparent')};
  border-width: 1px;
  box-shadow: 0 1px 0 0 ${({ active }) => (active ? '#F1CB0A' : '#E3E4E8')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  width: 100%;

  &:before {
    content: '';
    background: ${({ active }) => (active ? '#f1cb0a' : 'transparent')};
    border-radius: 50%;
    width: 8px;
    height: 8px;
    display: inline-block;
    top: 1px;
    margin: 0 16px 0 0;
    position: relative;
  }
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0;

  span {
    display: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    flex-direction: row;

    span {
      display: inline-block;
    }
  }
`;

export const WalmartTextInput = styled(TextInputStyled)`
  height: auto;
  max-height: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    height: 58px;
    max-height: 58px;
  }
`;

export const HelperText = styled.p`
  padding: 8px 0;
`;

export const Description = styled.div`
  ${props => props.theme.fonts.FoundersGrotesk};
  font-size: 14px;
`;

export const TitleContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 4px;
  flex-direction: row;
`;

export const Title = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  ${props => props.theme.fonts.FoundersGrotesk};
  font-weight: 600;
`;
