import React, { MouseEvent } from 'react';

import {
  AnswerBox,
  AnswerText,
  ButtonContainer,
  Container,
  LongOptionButtonContainer,
  LongOptionDescriptionText,
  OptionAndDescriptionContainer,
  OptionContainer,
  OptionTitleText,
  SubtitleText,
  TextContainer,
  TitleText,
  getOptionBackgroundColor,
  getOptionTextColor
} from './ComprehensionQuestionBox.styled';
import { Props, State } from './ComprehensionQuestionBox.types';

export default class ComprehensionQuestionBox extends React.Component<Props, State> {
  state = {
    selectedOption: null
  };

  renderOption(option: string, index: number, useLabel: boolean) {
    const text = useLabel ? `Option ${index + 1}` : option;
    const { showCorrectAnswer, question } = this.props;
    const { correctResponse } = question;

    const isCorrectResponse = correctResponse === option;
    const selected = this.state.selectedOption === option;

    return (
      <Option
        key={option}
        isCorrectResponse={isCorrectResponse}
        text={text}
        showCorrectAnswer={showCorrectAnswer}
        selected={selected}
        onClick={() => {
          if (showCorrectAnswer) return null;
          this.setState({ selectedOption: option });
          this.props.onSelect(option);
        }}
      />
    );
  }

  getQuestionDescription() {
    const { showCorrectAnswer, question } = this.props;
    const { options, correctResponse } = question;
    const option = this.state.selectedOption;

    const isCorrectResponse = correctResponse === option;

    if (showCorrectAnswer) {
      return isCorrectResponse ? 'Your answer is correct!' : 'Your answer is incorrect.';
    }

    if (options.length === 2 && options.includes('True') && options.includes('False')) {
      return 'Is this statement true or false?';
    }

    return 'Select from one of the options below:';
  }

  renderChoices() {
    const { options } = this.props.question;

    // If any of the options are over 40 characters, then display then as "(Option 1) Text"
    let requiresOptionLabels = false;
    options.forEach(element => {
      if (element.length >= 50) {
        requiresOptionLabels = true;
        return;
      }
    });
    if (requiresOptionLabels) {
      return (
        <LongOptionButtonContainer>
          {options.map((option: string, index: number) => {
            return (
              <OptionAndDescriptionContainer key={option}>
                {this.renderOption(option, index, true)}
                <LongOptionDescriptionText>{option}</LongOptionDescriptionText>
              </OptionAndDescriptionContainer>
            );
          })}
        </LongOptionButtonContainer>
      );
    }

    return (
      <ButtonContainer>
        {options.map((option: string, index: number) => {
          return this.renderOption(option, index, false);
        })}
      </ButtonContainer>
    );
  }

  renderAnswer() {
    if (!this.props.showCorrectAnswer) return null;

    return (
      <AnswerBox>
        <AnswerText>{this.props.question.explanation}</AnswerText>
      </AnswerBox>
    );
  }

  render() {
    const { showCorrectAnswer, question } = this.props;
    const { text } = question;

    const description = this.getQuestionDescription();

    return (
      <Container showAnswer={showCorrectAnswer}>
        <TextContainer>
          <TitleText>{text}</TitleText>
          <SubtitleText>{description}</SubtitleText>
        </TextContainer>
        {this.renderChoices()}
        {this.renderAnswer()}
      </Container>
    );
  }
}

const Option = (props: {
  isCorrectResponse: boolean;
  text: string;
  selected: boolean;
  showCorrectAnswer: boolean;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
}) => {
  const { selected, isCorrectResponse, showCorrectAnswer, onClick } = props;
  return (
    <OptionContainer
      showCorrectAnswer={showCorrectAnswer}
      backgroundColor={getOptionBackgroundColor(selected, isCorrectResponse, showCorrectAnswer)}
      onClick={onClick}
    >
      <OptionTitleText color={getOptionTextColor(selected, isCorrectResponse)}>
        {props.text.toUpperCase()}
      </OptionTitleText>
    </OptionContainer>
  );
};
