import styled, { css } from 'styled-components';

import { CollateType } from './Collate.types';

export const CollateContainer = styled.div<{ type: CollateType }>`
  ${props => props.theme.fonts.Gotham};
  padding: 24px 16px;
  border-style: solid;
  ${({ type }) => containerTypeStyle[type]}
  display: flex;
  flex-direction: row;
`;

export const containerTypeStyle = {
  [CollateType.ERROR]: css`
    border-color: ${({ theme }) => theme.colors.semantic.darkRed};
    background-color ${({ theme }) => theme.colors.semantic.lightPink};
  `,
  [CollateType.SUCCESS]: css`
    border-color: ${({ theme }) => theme.colors.green.green};
    background-color ${({ theme }) => theme.colors.green.lightestGreen};
  `
};

export const titleTypeStyle = {
  [CollateType.ERROR]: css`
    color: ${({ theme }) => theme.colors.semantic.darkRed};
  `,
  [CollateType.SUCCESS]: css`
    color: ${({ theme }) => theme.colors.green.darkestGreen};
  `
};

export const CollateTitle = styled.div<{ type: CollateType }>`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  padding-bottom: 8px;
  ${({ type }) => titleTypeStyle[type]}
`;

export const CollateDescription = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
`;

export const CheckMarkContainer = styled.div`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.green.darkestGreen};
  margin-right: 22.5px;
`;

export const XMarkContainer = styled.div`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.semantic.darkRed};
  margin-right: 19.5px;
`;
