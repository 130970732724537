import { themes } from 'kb-shared';
import styled from 'styled-components';

const { breakpoints } = themes;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  padding: 45px 45px 30px 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #ffffff;
  border-left: 8px solid #ffe664;

  @media (min-width: ${breakpoints.tabletLandscape}) {
    padding: 45px 45px 20px 24px;
    min-width: 500px;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const Body = styled.div`
  ${({ theme }) => theme.fonts.Gotham};
  margin: 24px 51px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  font-size: 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 24px;
`;
