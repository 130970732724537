import React, { Component, MouseEvent } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { ReactComponent as SelectionArrow } from '../../../svg/selection_arrow.svg';
import Text from '../../Primitives/FormText';

type Props = {
  title: string;
  isRequired: boolean | null;
  options: Array<string>;
  selectedOptions: Array<string>;
  expanded: boolean;
  onExpandClicked: (event: MouseEvent) => void;
  onSelect: (options: Array<string>) => void;
};

type State = {};

export default class FieldGroupMultiSelection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  selectOption(option: string) {
    const filteredOptions = this.props.selectedOptions.filter((value: string) => {
      return value !== option;
    });
    // If we ended up finding and removing the option, set that as the new value
    if (filteredOptions.length !== this.props.selectedOptions.length) {
      this.props.onSelect(filteredOptions);
    } else {
      // Otherwise we want to add/select the option
      const newOptions = this.props.selectedOptions.concat([option]);
      this.props.onSelect(newOptions);
    }
  }

  renderOptions() {
    if (!this.props.expanded) {
      return null;
    }

    return this.props.options.map((option: string) => {
      const selected = this.props.selectedOptions.find(value => {
        return value === option;
      });
      return (
        <ButtonWrapper
          selected={!!selected}
          key={option}
          onClick={this.selectOption.bind(this, option)}
        >
          <ButtonText>{option}</ButtonText>
        </ButtonWrapper>
      );
    });
  }

  render() {
    return (
      <Container>
        <TitleContainer onClick={this.props.onExpandClicked}>
          <SubText isRequired={!!this.props.isRequired}>{this.props.title}</SubText>
          <ArrowContainer down={this.props.expanded}>
            <SelectionArrow />
          </ArrowContainer>
        </TitleContainer>
        <ButtonsContainer>{this.renderOptions()}</ButtonsContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const TitleContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const SubText = styled(Text)`
  font-size: 24px !important;
  margin: 0px;
`;

interface ArrowContainerProps {
  down: boolean;
}

const ArrowContainer = styled.div`
  margin: 0px 12px 0px 12px;
  transform: ${(props: ArrowContainerProps) => (props.down ? 'rotate(0deg)' : 'rotate(-90deg)')};
  transition: transform 150ms linear;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1 0 50%;
`;

interface ButtonWrapperProps {
  selected: boolean;
}

const ButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1 0 21%;
  min-width: 100px;
  height: 43px;
  background-color: ${(props: ButtonWrapperProps) =>
    props.selected ? themes.colors.yellow.primary : themes.deprecatedColors.beige};
  align-items: center;
  justify-content: center;
  :hover {
    background-color: ${themes.colors.yellow.primary};
  }
`;

const ButtonText = styled.div`
  ${themes.fonts.FoundersGrotesk};
`;
