import React, { useEffect, useState } from 'react';

import { CheckCircle } from 'assets/icons/Icons';
import { Text } from 'components/v2/Typography';
import { useFeatureFlags } from 'hooks';
import { Patient, themes } from 'kb-shared';
import {
  formatToLongWeekdayMonthDay,
  formatToWeekdayMonthDay,
  momentFormatted,
  momentWithParsedTimeZone
} from 'kb-shared/utilities/momentHelpers';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { WidgetTitleContainer } from '../Widgets/Widgets.styled';
import { IntercourseReminder } from './IntercourseReminder/IntercourseReminder';
import { MedicationsAndNotes } from './MedicationsAndNotes/MedicationsAndNotes';
import { medicationInstructionsMock } from './MedicationsWidget.constants';
import { useNextInstructions, useConsentTodos } from './MedicationsWidget.hooks';
import {
  MedicationsWidgetContainer,
  MedicationInstructionsWrapper,
  MedicationsWidgetTitleContainer,
  DaysContainer,
  InstructionDay,
  CollapsibleDayContainer,
  CollapsibleDayContent,
  CollapsibleDayTitleContainer,
  GoUpArrow,
  GoForwardArrow,
  DaysAndLastUpdateContainer,
  LastUpdateContainer,
  LastUpdateTextContainer,
  LastUpdateIconContainer
} from './MedicationsWidget.styled';
import { MedicationInstruction } from './MedicationsWidget.types';
import { formatTitle, areInstructionsMissing } from './MedicationsWidget.utils';
import { MedicationsWidgetBlocker } from './MedicationsWidgetBlocker/MedicationsWidgetBlocker';
import { MedicationsWidgetRetry } from './MedicationsWidgetRetry/MedicationsWidgetRetry';

export const MedicationsWidget = ({ patient }: { patient: Patient }) => {
  const {
    data: instructionsData,
    error: instructionsError,
    loading: instructionsLoading,
    refetch: refetchInstructions
  } = useNextInstructions();
  const { data: consentTodos, loading: consentTodosLoading } = useConsentTodos();
  const { ppMedicationInstructions } = useFeatureFlags();
  const { isMobile } = useBreakpoints();
  const [selectedInstructionsIndexes, setSelectedInstructionsIndexes] = useState<number[]>([]);
  const hasPendingConsentTodos = consentTodos.length > 0;
  const blockMedicationInstructions = Boolean(ppMedicationInstructions && hasPendingConsentTodos);

  useEffect(() => {
    if (!instructionsData || areInstructionsMissing(instructionsData)) return;

    if (!isMobile && selectedInstructionsIndexes.length === 0) setSelectedInstructionsIndexes([0]);
  }, [instructionsData, isMobile, selectedInstructionsIndexes.length]);

  if (instructionsError) {
    return <MedicationsWidgetRetry onRetry={() => refetchInstructions()} />;
  }

  if (
    !instructionsData ||
    areInstructionsMissing(instructionsData) ||
    instructionsLoading ||
    consentTodosLoading
  ) {
    return null;
  }

  const instructions = instructionsData.nextInstructions;
  const items: Array<MedicationInstruction> = instructions.map(instruction => ({
    title: formatTitle(
      `${instruction.dateLabel}`,
      momentWithParsedTimeZone(instruction.date || '')
    ),
    data: instruction
  }));
  // when medication instructions are blocked with overlay, we show mock data so that it's non-obvious how to get or act upon real medication instruction data
  // even if the user tampers with DOM and/or removes elements/styling
  const shownItems: Array<MedicationInstruction> = blockMedicationInstructions
    ? medicationInstructionsMock
    : items;
  const firstDay = instructions[0];
  const hasIntercourseReminder = 'haveIntercourse' in firstDay && firstDay.haveIntercourse;
  const medicationsExist = shownItems.find(item => item.data.drugs?.length > 0) != null;
  const instructionsExist = shownItems.find(item => item.data.instructions) != null;

  const toggleDay = (isDayActive: boolean, index: number) => {
    if (isDayActive)
      setSelectedInstructionsIndexes(
        selectedInstructionsIndexes.filter(instruction => instruction !== index)
      );
    else {
      setSelectedInstructionsIndexes([...selectedInstructionsIndexes, index]);
    }
  };

  return (
    <>
      {hasIntercourseReminder && firstDay && <IntercourseReminder date={firstDay.date} />}
      {(medicationsExist || instructionsExist) && (
        <MedicationsWidgetContainer>
          <MedicationInstructionsWrapper>
            {blockMedicationInstructions && <MedicationsWidgetBlocker />}
            <MedicationsWidgetTitleContainer>
              <WidgetTitleContainer $noMargin>
                <Text>Medications and Instructions</Text>
              </WidgetTitleContainer>
            </MedicationsWidgetTitleContainer>
            {!isMobile && (
              <>
                <DaysAndLastUpdateContainer>
                  <DaysContainer>
                    {shownItems.map((item, index) => {
                      const isActive = selectedInstructionsIndexes.includes(index);
                      const dateFormatted = `(${formatToWeekdayMonthDay(
                        momentWithParsedTimeZone(item.data.date || '')
                      )})`;
                      return (
                        <InstructionDay
                          $active={isActive}
                          onClick={() => setSelectedInstructionsIndexes([index])}
                          key={item.data.id}
                        >
                          <Text fontStyle={isActive ? 'bold' : 'regular'}>
                            {item.data.dateLabel}
                          </Text>
                          <Text color={themes.colors.neutral.lightNavy} size="xs">
                            {dateFormatted}
                          </Text>
                        </InstructionDay>
                      );
                    })}
                  </DaysContainer>
                  {selectedInstructionsIndexes.length !== 0 && (
                    <LastUpdateContainer>
                      <LastUpdateTextContainer>
                        <Text size="sm" fontStyle="medium" color={themes.colors.green.darkestGreen}>
                          Last updated
                        </Text>
                        <Text size="sm" fontStyle="medium" color={themes.colors.green.darkestGreen}>
                          {momentFormatted(
                            instructionsData.nextInstructions[selectedInstructionsIndexes[0]]
                              .updatedAt,
                            'M/D/YY h:mm A zz'
                          )}
                        </Text>
                      </LastUpdateTextContainer>
                      <LastUpdateIconContainer>
                        <CheckCircle type="solid" />
                      </LastUpdateIconContainer>
                    </LastUpdateContainer>
                  )}
                </DaysAndLastUpdateContainer>
                {selectedInstructionsIndexes.length !== 0 && (
                  <MedicationsAndNotes
                    medicationInstruction={
                      instructionsData.nextInstructions[selectedInstructionsIndexes[0]]
                    }
                    patient={patient}
                    hideAcknowledgment={blockMedicationInstructions}
                  />
                )}
              </>
            )}
            {isMobile &&
              shownItems.map((item, index) => {
                const isActive = selectedInstructionsIndexes.includes(index);
                const dateFormatted = `${formatToLongWeekdayMonthDay(
                  momentWithParsedTimeZone(item.data.date || '')
                )}`;
                return (
                  <CollapsibleDayContainer $active={isActive} key={item.data.id}>
                    <CollapsibleDayTitleContainer onClick={() => toggleDay(isActive, index)}>
                      <div>
                        <div>
                          <Text fontStyle="bold">{item.data.dateLabel}</Text>
                        </div>
                        <div>
                          <Text size="xs">{dateFormatted}</Text>
                        </div>
                      </div>
                      {isActive ? <GoUpArrow /> : <GoForwardArrow />}
                    </CollapsibleDayTitleContainer>
                    <CollapsibleDayContent pose={isActive ? 'visible' : 'hidden'}>
                      <LastUpdateContainer>
                        <LastUpdateTextContainer>
                          <Text
                            size="sm"
                            fontStyle="medium"
                            color={themes.colors.green.darkestGreen}
                          >
                            Updated: {momentFormatted(item.data.updatedAt, 'M/D/YY h:mm A zz')}
                          </Text>
                        </LastUpdateTextContainer>
                        <LastUpdateIconContainer>
                          <CheckCircle type="solid" />
                        </LastUpdateIconContainer>
                      </LastUpdateContainer>
                      <MedicationsAndNotes
                        medicationInstruction={item.data}
                        patient={patient}
                        hideAcknowledgment={blockMedicationInstructions}
                      />
                    </CollapsibleDayContent>
                  </CollapsibleDayContainer>
                );
              })}
          </MedicationInstructionsWrapper>
        </MedicationsWidgetContainer>
      )}
    </>
  );
};
