import { IllustratedBackgroundWidget } from 'screens/DashboardV2/Widgets/Widgets.styled';
import styled from 'styled-components';

export const Container = styled(IllustratedBackgroundWidget)`
  box-shadow: none;

  @media ${({ theme }) => theme.queries.tablet} {
    box-shadow: ${({ theme }) => theme.shadows.card.level0};
  }
`;
