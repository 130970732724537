import { Appointment, AppointmentType, EnvironmentType } from '../types';

const Category = {
  FERTILITY: 1,
  GYN: 2,
  COACHING: '3'
};

const AppointmentTypesProduction = {
  AMH: '57',
  ANNUAL_WELL_WOMAN: '14',
  COMPLEX_GYN_CONSULTATION: '145',
  COMPLEX_GYN_VIRTUAL: '146',
  CYCLE_VISIT: '9',
  EGG_FREEZING_ASSESMENT: '58',
  EGG_FREEZING_VIRTUAL: '143',
  EMBRYO_TRANSFER: '33',
  FERTILITY_APPOINTMENT: '3',
  FERTILITY_ASSESSMENT: ['3', '58', '59', '93', '112', '312', '313', '157', '319', '320', '351'],
  FERTILITY_VIRTUAL: '93',
  FOLLOW_UP: '21',
  FOLLOW_UP_PHONE_CALL: '117',
  GYN_CONSULTAION: '44',
  GYN_VIRTUAL: '114',
  INITIAL_CYCLE_VISIT: '35',
  IUI_PROCEDURE: ['34', '77'],
  MALE_FERTULITY_ASSESSMENT_SEMEN_ANALYSIS: '157',
  NEW_OB_VISIT: '189',
  POST_OP: '330',
  POST_OP_PHONE_CALL: '21',
  POST_OP_VIRTUAL: '343',
  PRE_OP: '177',
  PRE_OP_VIRTUAL: '178',
  PREGNANCY: '75',
  PROGESTERONE: '76',
  PULSE_PHONE_CALL: '350',
  PULSE_ULTRASOUND_BLOODWORK: '351',
  RETRIEVAL: '15',
  VIRTUAL_CONSULTATION: ['143', '144'],
  PREGNANCY_CONFIRMATION_VISIT: '135'
};

const AppointmentTypesStaging = {
  AMH: '20',
  ANNUAL_WELL_WOMAN: '14',
  COMPLEX_GYN_CONSULTATION: '145',
  COMPLEX_GYN_VIRTUAL: '146',
  CYCLE_VISIT: '7',
  EGG_FREEZING_ASSESMENT: '58',
  EGG_FREEZING_VIRTUAL: '143',
  EMBRYO_TRANSFER: '27',
  FERTILITY_APPOINTMENT: '3',
  FERTILITY_ASSESSMENT: ['18', '23', '41', '112', '312', '313', '157', '319', '320', '351'],
  FERTILITY_VIRTUAL: '93',
  FOLLOW_UP: '5',
  FOLLOW_UP_PHONE_CALL: '117',
  GYN_CONSULTAION: '44',
  GYN_VIRTUAL: '114',
  INITIAL_CYCLE_VISIT: '4',
  IUI_PROCEDURE: ['40'],
  MALE_FERTULITY_ASSESSMENT_SEMEN_ANALYSIS: '157',
  NEW_OB_VISIT: '189',
  POST_OP: '330',
  POST_OP_PHONE_CALL: '21',
  POST_OP_VIRTUAL: '343',
  PRE_OP: '177',
  PRE_OP_VIRTUAL: '178',
  PREGNANCY: '28',
  PROGESTERONE: '26',
  PULSE_PHONE_CALL: '350',
  PULSE_ULTRASOUND_BLOODWORK: '351',
  RETRIEVAL: '15',
  VIRTUAL_CONSULTATION: '48',
  PREGNANCY_CONFIRMATION_VISIT: '135'
};

const env: EnvironmentType = process.env.REACT_APP_ENV;

export function isGYNAppointment(categoryId: number) {
  return categoryId === Category.GYN;
}

export function isFertility(categoryId: number) {
  return categoryId === Category.FERTILITY;
}

export function appointmentTypes() {
  if (env === 'production' || env === 'uat') {
    return AppointmentTypesProduction;
  }
  return AppointmentTypesStaging;
}

export function isRequiredToBeInTheSameState(appt: AppointmentType) {
  const appointmentTypesByEnv = appointmentTypes();
  const appointmentIds: string[] = [
    appointmentTypesByEnv.FERTILITY_VIRTUAL,
    appointmentTypesByEnv.EGG_FREEZING_VIRTUAL,
    appointmentTypesByEnv.GYN_VIRTUAL,
    appointmentTypesByEnv.COMPLEX_GYN_VIRTUAL
  ];

  return appointmentIds.includes(appt.id);
}

export function shouldDisplayProvider(appt: Appointment) {
  const appointmentTypesByEnv = appointmentTypes();
  const appointmentTypesRequiredProviders = [
    appointmentTypesByEnv.FERTILITY_VIRTUAL,
    appointmentTypesByEnv.FERTILITY_APPOINTMENT,
    appointmentTypesByEnv.EGG_FREEZING_VIRTUAL,
    appointmentTypesByEnv.EGG_FREEZING_ASSESMENT,
    appointmentTypesByEnv.PULSE_PHONE_CALL,
    appointmentTypesByEnv.MALE_FERTULITY_ASSESSMENT_SEMEN_ANALYSIS,
    appointmentTypesByEnv.GYN_CONSULTAION,
    appointmentTypesByEnv.GYN_VIRTUAL,
    appointmentTypesByEnv.ANNUAL_WELL_WOMAN,
    appointmentTypesByEnv.COMPLEX_GYN_VIRTUAL,
    appointmentTypesByEnv.COMPLEX_GYN_CONSULTATION,
    appointmentTypesByEnv.FOLLOW_UP_PHONE_CALL,
    appointmentTypesByEnv.PRE_OP,
    appointmentTypesByEnv.PRE_OP_VIRTUAL,
    appointmentTypesByEnv.POST_OP,
    appointmentTypesByEnv.POST_OP_VIRTUAL,
    appointmentTypesByEnv.POST_OP_PHONE_CALL
  ];

  return appointmentTypesRequiredProviders.includes(appt.appointmentType.id);
}

export function isAssessment(appt: Appointment) {
  const fertilityAssessmentIds: string[] = appointmentTypes().FERTILITY_ASSESSMENT;
  return fertilityAssessmentIds.includes(appt.appointmentType.id);
}

export function isVirtualConsultation(appt: Appointment) {
  const virtualConsultationIds: string | string[] = appointmentTypes().VIRTUAL_CONSULTATION;
  return virtualConsultationIds.includes(appt.appointmentType.id);
}

export function isIUIProcedure(appt: Appointment) {
  const iuiProcedureIds: string[] = appointmentTypes().IUI_PROCEDURE;
  return iuiProcedureIds.includes(appt.appointmentType.id);
}

export function isInitialCycleVisit(appt: Appointment) {
  const initialCycleVisitId: string = appointmentTypes().INITIAL_CYCLE_VISIT;
  return appt.appointmentType.id === initialCycleVisitId;
}

export function isCycleVisit(appt: Appointment) {
  const cycleVisitId: string = appointmentTypes().CYCLE_VISIT;
  return appt.appointmentType.id === cycleVisitId;
}

export function isRetrieval(appt: Appointment) {
  const retrievalId: string = appointmentTypes().RETRIEVAL;
  return appt.appointmentType.id === retrievalId;
}

export function isFollowUp(appt: Appointment) {
  const followUpId: string = appointmentTypes().FOLLOW_UP;
  return appt.appointmentType.id === followUpId;
}

export function isPregnancyTest(appt: Appointment) {
  const pregnancyTestId: string = appointmentTypes().PREGNANCY;
  return appt.appointmentType.id === pregnancyTestId;
}

export function isEmbryoTransfer(appt: Appointment) {
  const embryoTransfer: string = appointmentTypes().EMBRYO_TRANSFER;
  return appt.appointmentType.id === embryoTransfer;
}

export function isPulseUltrasoundBloodwork(appt: Appointment) {
  const pulseUltrasoundBloodwork: string = appointmentTypes().PULSE_ULTRASOUND_BLOODWORK;
  return appt.appointmentType.id === pulseUltrasoundBloodwork;
}

export function isCoachingAppointment(appointment: AppointmentType) {
  return appointment.category?.id === Category.COACHING;
}

export function isAMH(appt: Appointment) {
  return appt.appointmentType.id === appointmentTypes().AMH;
}

export function cycleVisitId() {
  return appointmentTypes().CYCLE_VISIT;
}

export function initialCycleVisitId() {
  return appointmentTypes().INITIAL_CYCLE_VISIT;
}

export const appointmentSupportedInPartnerClinics = (
  appointmentId: string | undefined
): boolean => {
  return appointmentId !== appointmentTypes().PULSE_ULTRASOUND_BLOODWORK;
};

export const VIRTUAL_APPOINTMENT_LICENSING_DISCLAIMER =
  'At your scheduled time, join the consult using the button below. By joining this virtual appointment, you confirm that you are physically present in one of the state(s) where this provider is licensed.';
