import React from 'react';

import { Text } from 'components/v2/Typography';
import { Heading } from 'components/v2/Typography/Heading';
import { sortNullableStrings } from 'utilities/sort';

import { currencyNumberFormat } from '../../../utilities/number-format';
import InvoiceDetails, {
  InvoiceDetailsPayment,
  LineItemNextPayer
} from '../types/invoices-details';
import { formatDate } from '../utils/format-date';
import { BlockText, Table } from './LineItemsTable.styled';
import { getPaymentAmountContent } from './LineItemsTable.utils';

const renderLineItemsRows = (lineItems: InvoiceDetails['lineItems']) =>
  lineItems.map((lineItem, key) => (
    <tr key={key}>
      <td>
        <Text>{lineItem.name}</Text>

        {!!lineItem.description && lineItem.description !== lineItem.name && (
          <Text>{lineItem.description}</Text>
        )}
        {!!lineItem.cptCode && (
          <BlockText fontStyle="regular" size="xs">
            CPT Code: {lineItem.cptCode}
          </BlockText>
        )}
        {!!lineItem.diagnosisCodes && (
          <BlockText>Diagnoses Codes: {lineItem.diagnosisCodes}</BlockText>
        )}
      </td>
      <td>
        <Text fontStyle="regular" size="md">
          {getPaymentAmountContent(lineItem)}
        </Text>
      </td>
    </tr>
  ));

const renderDiscountRow = (discount: NonNullable<InvoiceDetails['discount']>) => (
  <tr>
    <th>
      <Text>Discount for {discount.reason}</Text>
    </th>
    <td>
      <Text fontStyle="regular">(-{currencyNumberFormat(discount.amount)})</Text>
    </td>
  </tr>
);

const renderInsuranceAdjustmentsRow = (amount: number) => (
  <tr>
    <th>
      <Text>Insurance Adjustments</Text>
    </th>
    <td>
      <Text fontStyle="regular">(-{currencyNumberFormat(amount)})</Text>
    </td>
  </tr>
);

const renderAppliedCreditsRow = (appliedCredits: InvoiceDetails['appliedCredits']) =>
  appliedCredits.map((credit, key) => (
    <tr key={key}>
      <th>
        <Text>Applied Credit/Text</Text>
      </th>
      <td>
        <Text fontStyle="regular">-{currencyNumberFormat(credit.amount)}</Text>
      </td>
    </tr>
  ));

const renderPaymentRows = (payments: InvoiceDetailsPayment[]) => {
  payments.sort((prev, next) => sortNullableStrings(prev.date, next.date));
  return payments.map((payment, key) => (
    <tr key={key}>
      <th>
        <Text>
          {payment.reason} {formatPaymentType(payment)}{' '}
          {!!payment.date && <span>({formatDate(payment.date)})</span>}
        </Text>
      </th>
      <td>
        <Text fontStyle="regular">{formatPaymentAmount(payment)}</Text>
      </td>
    </tr>
  ));
};

const formatPaymentType = (payment: InvoiceDetailsPayment) =>
  payment.amount < 0 ? `Reversed ${payment.type}` : payment.type;

const formatPaymentAmount = (payment: InvoiceDetailsPayment) => {
  const amount = payment.amount;
  if (amount < 0) return currencyNumberFormat(Math.abs(amount));
  return `-${currencyNumberFormat(amount)}`;
};

const renderConvertedCreditsRow = (amount: number) => (
  <tr>
    <th>
      <Text>Converted Credit</Text>
    </th>
    <td>
      <Text>{currencyNumberFormat(amount)}</Text>
    </td>
  </tr>
);
interface Props {
  className?: string;
  isAzInvoice: boolean;
  isV2Invoice: boolean;
  lineItems: InvoiceDetails['lineItems'];
  payments: InvoiceDetails['payments'];
  discount: InvoiceDetails['discount'];
  appliedCredits: InvoiceDetails['appliedCredits'];
  lineItemsTotalAmount: number;
  invoiceAdjustmentsAmount: number;
  kindbodyRateAmount: number;
  balanceDueAmount: number;
  nextPayer: LineItemNextPayer;
  convertedCreditsAmount: number;
  insuranceAdjustmentsAmount: number;
}

export const LineItemsTable = ({
  className,
  isAzInvoice,
  isV2Invoice,
  lineItems,
  payments,
  discount,
  lineItemsTotalAmount,
  invoiceAdjustmentsAmount,
  balanceDueAmount,
  kindbodyRateAmount,
  convertedCreditsAmount,
  insuranceAdjustmentsAmount,
  appliedCredits
}: Props) => (
  <Table className={className}>
    <thead>
      <tr>
        <th>
          <Text>Item</Text>
        </th>
        <th>
          <Text>Rate</Text>
        </th>
      </tr>
    </thead>
    <tbody>{renderLineItemsRows(lineItems)}</tbody>
    <tfoot>
      <tr>
        <th>
          <Text>Line Items Total</Text>
        </th>
        <td>
          <Text fontStyle="regular">{currencyNumberFormat(lineItemsTotalAmount)}</Text>
        </td>
      </tr>
      {isV2Invoice && renderInsuranceAdjustmentsRow(insuranceAdjustmentsAmount)}
      {!isAzInvoice && !isV2Invoice && (
        <tr>
          <th>
            <Text>Invoice Adjustments</Text>
          </th>
          <td>
            <Text fontStyle="regular">{currencyNumberFormat(invoiceAdjustmentsAmount)}</Text>
          </td>
        </tr>
      )}
      {!isAzInvoice && (
        <tr>
          <th>
            <Text>Kindbody Rate (Patient Total)</Text>
          </th>
          <td>
            <Text fontStyle="regular">{currencyNumberFormat(kindbodyRateAmount)}</Text>
          </td>
        </tr>
      )}

      {discount && renderDiscountRow(discount)}

      {!isV2Invoice &&
        insuranceAdjustmentsAmount > 0 &&
        renderInsuranceAdjustmentsRow(insuranceAdjustmentsAmount)}

      {renderAppliedCreditsRow(appliedCredits)}

      {renderPaymentRows(payments)}

      {convertedCreditsAmount > 0 && renderConvertedCreditsRow(convertedCreditsAmount)}

      <tr>
        <th>
          <Heading styledAs="h4" tag="div" noMargin>
            Balance Due
          </Heading>
        </th>
        <td>
          <Heading styledAs="h4" tag="div" noMargin>
            {currencyNumberFormat(balanceDueAmount)}
          </Heading>
        </td>
      </tr>
    </tfoot>
  </Table>
);
