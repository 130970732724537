import { themes } from 'kb-shared';
import styled from 'styled-components';

const { breakpoints } = themes;

export const Form = styled.form`
  width: 100%;
  max-width: 560px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const ButtonWrapperPrivacy = styled(ButtonWrapper)`
  padding-bottom: 8px;
`;

export const VMarginWrapper = styled.div`
  margin: 20px 0;
`;

export const TwoFieldWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: ${breakpoints.largePhonePortrait}) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }
`;

export const InputWrapperMargin = styled.div`
  flex: 1;
  :first-child {
    margin-right: 10px;
    max-width: 50%;

    @media (max-width: ${breakpoints.largePhonePortrait}) {
      width: 100%;
      max-width: none;
      margin-right: 0x;
    }
  }
  :last-child {
    margin-left: 10px;
    max-width: 50%;

    @media (max-width: ${breakpoints.largePhonePortrait}) {
      width: 100%;
      max-width: none;
      margin: 20px 0 0 0;
  }

  input[name='phone'][type='default']::placeholder {
    font-size: 0.7em;
  }
`;

export const DisclaimerText = styled.p`
  ${themes.fonts.FoundersGrotesk};
  font-size: 12px;
  font-style: italic;
  background: white;
`;

export const GenderInputWrapper = styled(InputWrapperMargin)`
  min-width: 110px;
`;
