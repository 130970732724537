import { themes } from 'kb-shared';
import styled from 'styled-components';
const { colors, fonts } = themes;

export default styled.button`
  ${fonts.FoundersGrotesk};
  background-color: ${colors.neutral.primaryNavy};
  border-radius: 24px;
  color: ${colors.white};
  cursor: pointer;
  font-size: 14px;
  padding: 15px 24px;
  text-transform: uppercase;
  border: 2px solid transparent;

  :hover,
  :focus {
    background-color: transparent;
    color: ${colors.neutral.primaryNavy};
    border: 2px solid ${colors.neutral.primaryNavy};
    outline: none;
  }
`;
