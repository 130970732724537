import React from 'react';

import { Loader as LoaderBase } from 'components/Loader/Loader';
import styled, { useTheme } from 'styled-components';

import TileWidget from './TileWidget';

const Loading: React.FC = () => {
  const theme = useTheme();

  return (
    <TileWidget>
      <Loader type="spin" color={theme.colors.semantic.pink} height={50} width={50} />
    </TileWidget>
  );
};

const Loader = styled(LoaderBase)`
  margin-top: 40px;
`;

export default Loading;
