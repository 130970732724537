import React, { ReactNode } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { ReactComponent as BloodworkIcon } from '../../../assets/icons/stimulation-bloodwork.svg';
import { ReactComponent as InjectionIcon } from '../../../assets/icons/stimulation-injection.svg';
import { ReactComponent as UltrasoundIcon } from '../../../assets/icons/stimulation-ultrasound.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 6px;
  background-color: ${themes.colors.neutral.primaryNavy};
  border-radius: 10px;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  color: white;
  padding: 8px;
`;

const IconLabel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  display: flex;
`;

type Props = {
  text: string;
  icons: Array<'bloodwork' | 'ultrasound' | 'injection'>;
};

type State = {};

export default class Indicator extends React.Component<Props, State> {
  renderIcons(): ReactNode | null {
    return (
      <IconContainer>
        {this.props.icons.map(icon => {
          switch (icon) {
            case 'bloodwork':
              return <BloodworkIcon key={icon} />;
            case 'injection':
              return <InjectionIcon key={icon} />;
            case 'ultrasound':
              return <UltrasoundIcon key={icon} />;
            default:
              return null;
          }
        })}
      </IconContainer>
    );
  }

  render() {
    return (
      <Container>
        <IconLabel>
          <Label>{this.props.text}</Label>
          {this.renderIcons()}
        </IconLabel>
      </Container>
    );
  }
}
