import moment from 'moment-timezone';

import { todoWidgetElementId } from 'components/ToDoItems/ToDoItems.constants';
import { MedicationsInstructions } from 'kb-shared';
import { formatToWeekdayMonthDay } from 'kb-shared/utilities/momentHelpers';

export const formatTitle = (title: string, date: moment.Moment | null) => {
  return `${title}\n${formatToWeekdayMonthDay(date)}`;
};

export const areInstructionsMissing = (data: MedicationsInstructions | undefined) => {
  return !data?.nextInstructions?.length;
};

export const scrollToTodoWidget = () => {
  const todoWidget = document.getElementById(todoWidgetElementId);

  if (todoWidget) {
    todoWidget.scrollIntoView({ behavior: 'smooth' });
  }
};
