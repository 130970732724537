import styled from 'styled-components';

export const EmployerBenefitText = styled.div`
  ${({ theme }) => theme.fonts.FoundersGrotesk}
  max-width: 280px;
  position: absolute;
  right: 1.5em;
  text-align: center;
  top: 1.5em;
  background: white;
`;

export const Link = styled.a`
  font-weight: bold;
  text-decoration: underline;
`;
