import React from 'react';

import { EmployerBenefits } from 'assets/icons/Icons';
import { Text } from 'components/v2/Typography';
import { theme, themes } from 'kb-shared';
import {
  IconContainer,
  IllustratedBackgroundWidget,
  WidgetTitleContainer
} from 'screens/DashboardV2/Widgets/Widgets.styled';

export const BenefitsWidget = ({
  benefitsDescription,
  hasEmployerBenefitsDescription
}: {
  benefitsDescription?: string;
  hasEmployerBenefitsDescription: boolean;
}) => {
  if (!hasEmployerBenefitsDescription) return null;
  return (
    <IllustratedBackgroundWidget $variant={5} $bgColor={theme.colors.neutral.primaryNavy}>
      <div>
        <IconContainer $iconWidth={36} $iconHeight={48} $color={themes.colors.semantic.pink}>
          <EmployerBenefits type="solid" />
        </IconContainer>
        <WidgetTitleContainer>
          <Text color={themes.colors.semantic.pink}>Your Coverage Includes</Text>
        </WidgetTitleContainer>
        {benefitsDescription ? (
          <Text size="md" fontStyle="regular" color={themes.colors.neutral.lavenderWhite}>
            {benefitsDescription}
          </Text>
        ) : (
          <Text size="md" fontStyle="regular" color={themes.colors.neutral.lavenderWhite}>
            At the moment, we do not have information about the specifics of your coverage.
          </Text>
        )}
      </div>
    </IllustratedBackgroundWidget>
  );
};
