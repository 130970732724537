import { themes } from 'kb-shared';
import styled from 'styled-components';

import { ContainerProps, StyledProps } from './ComprehensionQuestionBox.types';

export function getOptionTextColor(isSelected: boolean, isCorrectResponse: boolean): string {
  const selected = themes.colors.white;
  const defaultColor = themes.colors.neutral.primaryNavy;

  if (isCorrectResponse && isSelected) return defaultColor;

  return isSelected ? selected : defaultColor;
}

export function getOptionBackgroundColor(
  isSelected: boolean,
  isCorrectResponse: boolean,
  showCorrectAnswer: boolean
): string {
  const initial = themes.colors.white;
  const selected = themes.colors.neutral.primaryNavy;
  const disabled = themes.deprecatedColors.beige;
  const error = themes.colors.semantic.darkRed;
  const expected = themes.colors.yellow.primary;
  const correct = themes.colors.green.lightGreen;

  if (!isSelected) {
    if (!isCorrectResponse) {
      return showCorrectAnswer ? disabled : initial;
    } else {
      return showCorrectAnswer ? expected : initial;
    }
  } else {
    if (!isCorrectResponse) {
      return showCorrectAnswer ? error : selected;
    } else {
      return showCorrectAnswer ? correct : selected;
    }
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 16px 28px 16px;
  ${({ showAnswer }: ContainerProps) => `
    background-color: ${showAnswer ? themes.colors.yellow.lightestYellow : 'white'};
    box-shadow: ${showAnswer ? '' : '0 5px 12px 0 rgba(0, 0, 0, 0.2)'};
    :hover {
      box-shadow: ${showAnswer ? '' : '0 5px 12px 0 rgba(0, 0, 0, 0.4)'};
    }
  `}
  transition: all 300ms linear;
  @media screen and (min-width: ${themes.breakpoints.largePhonePortrait}) {
    flex-direction: 'row';
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 28px;
`;

export const TitleText = styled.span`
  ${themes.fonts.DomaineDisplay};
  font-size: 24px;
  color: ${themes.colors.neutral.primaryNavy};
`;

export const SubtitleText = styled.span`
  ${themes.fonts.FoundersGrotesk};
  font-size: 14px;
  color: ${themes.colors.neutral.primaryNavy};
  padding-top: 8px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 14px;
  @media screen and (min-width: ${themes.breakpoints.largePhonePortrait}) {
    padding-top: 0px;
  }
`;

export const LongOptionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 14px;
  @media screen and (min-width: ${themes.breakpoints.largePhonePortrait}) {
    padding-top: 0px;
  }
`;

export const LongOptionDescriptionText = styled.span`
  ${themes.fonts.FoundersGrotesk};
  font-size: 14px;
  color: ${themes.colors.neutral.primaryNavy};
`;

export const OptionAndDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AnswerBox = styled.div`
  background-color: white;
  padding: 16px;
  margin-top: 16px;
`;

export const AnswerText = styled.span`
  ${themes.fonts.FoundersGrotesk};
  font-size: 14px;
  color: ${themes.colors.neutral.primaryNavy};
`;

export const OptionContainer = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 28px;
  border-radius: 14px;
  min-width: 100px;
  margin: 8px;
  padding: 4px 12px;
  cursor: not-allowed;

  ${({ showCorrectAnswer }) => `
    cursor: ${showCorrectAnswer ? 'not-allowed' : 'pointer'};
    ${
      showCorrectAnswer
        ? ''
        : `box-shadow: 0px 0px 0px 1px ${themes.colors.neutral.primaryNavy} inset;
        :hover {
          box-shadow: 0px 0px 0px 2px ${themes.colors.neutral.primaryNavy} inset
        }`
    };
  `}
  ${({ backgroundColor }) => `
     background-color: ${backgroundColor};
  `}
`;

export const OptionTitleText = styled.span<{ color: string }>`
  ${themes.fonts.FoundersGrotesk};
  font-size: 14px;
  color: ${({ color }) => color};
`;
