import styled, { css } from 'styled-components';

import { Category, Gap, Size } from './Button.types';

const gapSize: Record<Gap, string> = {
  xs: '1px',
  sm: '4px',
  md: '8px',
  lg: '11px'
};

export const Button = styled.button<{
  size: Size;
  category: Category;
  fullWidth: boolean;
  gap?: Gap;
  isDisabled?: boolean;
  uppercase?: boolean;
  label?: string;
  noWrap?: boolean;
}>`
  ${({ theme }) => theme.fonts.Gotham};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  font-style: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 0;
  border-radius: 8px;

  ${({ noWrap }) =>
    noWrap &&
    css`
      text-wrap: nowrap;
    `};

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `};

  svg path {
    fill: currentColor;
  }

  ${({ category }) => {
    switch (category) {
      case 'primary':
        return css`
          background-color: ${({ theme }) => theme.colors.yellow.primary};
          color: ${({ theme }) => theme.colors.neutral.primaryNavy};
          box-shadow: ${({ theme }) => theme.shadows.button.default};

          :hover {
            background: linear-gradient(
                0deg,
                rgba(241, 203, 10, 0.32) 0%,
                rgba(241, 203, 10, 0.32) 100%
              ),
              ${({ theme }) => theme.colors.yellow.primary};

            box-shadow: ${({ theme }) => theme.shadows.button.hover};
          }

          :active {
            box-shadow: ${({ theme }) => theme.shadows.button.active};
          }

          :disabled {
            background-color: ${({ theme }) => theme.colors.neutral.lavender};
            box-shadow: none;
            cursor: not-allowed;
          }
        `;
      case 'primary-dark':
        return css`
          background-color: ${({ theme }) => theme.colors.neutral.primaryNavy};
          color: ${({ theme }) => theme.colors.white};
          box-shadow: ${({ theme }) => theme.shadows.button.default};

          :hover {
            background: linear-gradient(0deg, rgba(10, 13, 61, 0.8) 0%, rgba(10, 13, 61, 0.8) 100%),
              ${({ theme }) => theme.colors.neutral.primaryNavy};

            box-shadow: ${({ theme }) => theme.shadows.button.hover};
          }

          :active {
            box-shadow: ${({ theme }) => theme.shadows.button.active};
          }

          :disabled {
            background-color: ${({ theme }) => theme.colors.neutral.lavender};
            color: ${({ theme }) => theme.colors.neutral.lighterNavy};
            box-shadow: none;
            cursor: not-allowed;
          }
        `;
      case 'secondary':
        return css`
          background-color: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.neutral.primaryNavy};
          box-shadow: ${({ theme }) => theme.shadows.button.default};

          :hover {
            background: linear-gradient(
                0deg,
                rgba(247, 247, 253, 0.5) 0%,
                rgba(247, 247, 253, 0.5) 100%
              ),
              ${({ theme }) => theme.colors.white};

            box-shadow: ${({ theme }) => theme.shadows.button.hover};
          }

          :active {
            box-shadow: ${({ theme }) => theme.shadows.button.activeSecondary};
          }

          :disabled {
            background-color: ${({ theme }) => theme.colors.neutral.lavender};
            box-shadow: none;
            cursor: not-allowed;
          }
        `;
      case 'tertiary':
        return css`
          background-color: transparent;
          color: ${({ theme }) => theme.colors.neutral.primaryNavy};
          box-shadow: none;

          :hover {
            background: linear-gradient(
                0deg,
                rgba(158, 163, 229, 0.16) 0%,
                rgba(158, 163, 229, 0.16) 100%
              ),
              ${({ theme }) => theme.colors.neutral.lavenderWhite};

            box-shadow: ${({ theme }) => theme.shadows.button.hover};
          }

          :active {
            box-shadow: ${({ theme }) => theme.shadows.button.activeSecondary};
            background: ${({ theme }) => theme.colors.white};
          }

          :disabled {
            background-color: ${({ theme }) => theme.colors.neutral.lavender};
            box-shadow: none;
            cursor: not-allowed;
          }
        `;
      case 'danger':
        return css`
          background-color: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.semantic.darkRed};
          box-shadow: ${({ theme }) => theme.shadows.button.default};

          :hover {
            background: linear-gradient(
                0deg,
                rgba(240, 200, 191, 0.32) 0%,
                rgba(240, 200, 191, 0.32) 100%
              ),
              ${({ theme }) => theme.colors.white};

            box-shadow: ${({ theme }) => theme.shadows.button.hoverDanger};
          }

          :active {
            box-shadow: ${({ theme }) => theme.shadows.button.activeError};
          }

          :disabled {
            background-color: ${({ theme }) => theme.colors.neutral.lavender};
            box-shadow: none;
            cursor: not-allowed;
          }
        `;
      case 'error':
        return css`
          background-color: ${({ theme }) => theme.colors.semantic.darkRed};
          color: ${({ theme }) => theme.colors.white};
          box-shadow: ${({ theme }) => theme.shadows.button.default};

          :hover {
            background: linear-gradient(
                0deg,
                rgba(39, 42, 94, 0.24) 0%,
                rgba(39, 42, 94, 0.24) 100%
              ),
              ${({ theme }) => theme.colors.semantic.darkRed};
            box-shadow: ${({ theme }) => theme.shadows.button.hover};
          }

          :active {
            box-shadow: ${({ theme }) => theme.shadows.button.activeError};
          }

          :disabled {
            background-color: ${({ theme }) => theme.colors.neutral.lavender};
            box-shadow: none;
            cursor: not-allowed;
          }
        `;
      case 'success':
        return css`
          background-color: ${({ theme }) => theme.colors.green.darkGreen};
          color: ${({ theme }) => theme.colors.white};
          box-shadow: ${({ theme }) => theme.shadows.button.default};

          :hover {
            background: ${({ theme }) => theme.colors.green.darkestGreen};
            box-shadow: ${({ theme }) => theme.shadows.button.hover};
          }

          :active {
            box-shadow: ${({ theme }) => theme.shadows.button.activeSuccess};
          }

          :disabled {
            background-color: ${({ theme }) => theme.colors.neutral.lavender};
            box-shadow: none;
            cursor: not-allowed;
          }
        `;
    }
  }}

  :has(svg) {
    min-width: auto;
  }

  ${({ size, gap, label }) => {
    switch (size) {
      case 'lg':
        return css`
          padding: 20px ${label ? '32' : '20'}px;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.8px;

          min-width: 120px;
          min-height: 64px;
          gap: ${gapSize[gap || 'md']};

          svg {
            width: 24px;
            height: 24px;
          }
        `;

      case 'md':
        return css`
          padding: ${label ? '14px 16px' : '16px'};
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.48px;

          min-width: 110px;
          min-height: 48px;
          gap: ${gapSize[gap || 'md']};

          svg {
            width: 16px;
            height: 16px;
          }
        `;

      case 'sm':
        return css`
          padding: 6px 8px;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;

          min-width: 72px;
          min-height: 32px;
          gap: ${gapSize[gap || 'sm']};

          svg {
            width: 16px;
            height: 16px;
          }
        `;
    }
  }}
`;
