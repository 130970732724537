import { useHomeMarket } from 'hooks';
import { utils } from 'kb-shared';

import { UseRiskAssessmentResponse } from './use-risk-assessment-result.types';

const searchParameters = new URLSearchParams({
  step: 'location',
  appointment_id: utils.appointmentTypes().PREGNANCY_CONFIRMATION_VISIT
});

const useRiskAssessmentResult = (): UseRiskAssessmentResponse => {
  const { id } = useHomeMarket();
  const isDisneyPatient = utils.isDisneyPatient(id ?? '');
  const bookVisitURL = isDisneyPatient ? `/Book?${searchParameters}` : undefined;

  const instructionsCopy =
    'If you have already booked your first pregnancy appointment, there is nothing you need to do. Otherwise, please schedule a pregnancy confirmation visit with your OBGYN provider.';

  return { bookVisitURL, instructionsCopy };
};

export default useRiskAssessmentResult;
