import React, { useEffect, useState } from 'react';

import { useBreakpoints } from 'utilities/useBreakpoints';

import { Heading } from '../Typography';
import { Props, Tab } from './Tab.types';
import {
  Container,
  Content,
  GoBackArrow,
  GoForwardArrow,
  Label,
  MobileGoBack,
  Nav,
  NavItem
} from './Tabs.styled';

export const Tabs = ({ tabs, initialTab, onChangeTab, noMargin, children }: Props) => {
  const { isDesktop } = useBreakpoints();
  const [selectedTab, setSelectedTab] = useState<Tab | undefined>(initialTab);

  useEffect(() => {
    onChangeTab(selectedTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    const firstTab = tabs[0];
    if (isDesktop && !selectedTab) setSelectedTab(firstTab);
  }, [isDesktop, selectedTab, tabs]);

  return (
    <Container>
      <Nav selectedTab={!!selectedTab}>
        {tabs.map(tab => (
          <NavItem
            $active={selectedTab?.id === tab.id}
            key={tab.id}
            onClick={() => setSelectedTab(tab)}
          >
            <Label $active={selectedTab?.id === tab.id} isDesktop={isDesktop}>
              {tab.label}
            </Label>
            <GoForwardArrow />
          </NavItem>
        ))}
      </Nav>

      <MobileGoBack
        selectedTab={!selectedTab}
        onClick={() => setSelectedTab(undefined)}
        noMargin={noMargin}
      >
        <Heading tag="span" styledAs="h4">
          <GoBackArrow />
          {selectedTab?.label}
        </Heading>
      </MobileGoBack>

      {selectedTab && <Content key={selectedTab?.id}>{children || selectedTab?.content}</Content>}
    </Container>
  );
};
