import { useQuery } from '@apollo/client';
import React from 'react';

import { TODOS } from 'kb-shared/graphql/queries';

import IntakeFormCard from '../../../components/IntakeFormCard';
import { MembershipConfirmationCard } from '../../../components/MembershipConfirmationCard';
import { UpcomingAppointment } from '../../../components/UpcomingAppointment';
import { PartnerLocationConfirmationCard } from './PartnerLocation';
import { CardContainer, Container } from './PurchaseConfirmation.styled';
import { Props, TodoResult } from './PurchaseConfirmation.types';
import { hasIntakeTodo } from './PurchaseConfirmation.utils';

const PurchaseConfirmation = (props: Props) => {
  const { purchase, timeSlot, history, partnerClinicDetails, appointmentName } = props;
  const { data } = useQuery(TODOS) as TodoResult;
  const todos = data && data.patientTodos;

  function goToBook() {
    props.onReturnToBookAppointment();
  }

  if (partnerClinicDetails) {
    return (
      <Container id="google-tag-manager-confirmation">
        {partnerClinicDetails && (
          <PartnerLocationConfirmationCard
            partnerClinicDetails={partnerClinicDetails}
            appointmentName={appointmentName}
          />
        )}
      </Container>
    );
  }

  if (purchase?.type === 'appointment') {
    // @ts-ignore
    const appointment = purchase.data.id ? purchase.data : purchase.data.appointment;
    const showIntakeCard = todos && hasIntakeTodo(todos) && appointment.intakeFormRequired;

    return (
      <Container id="google-tag-manager-confirmation">
        {!partnerClinicDetails && (
          <CardContainer>
            <UpcomingAppointment
              appointment={appointment}
              timeSlot={timeSlot}
              type={appointment.appointmentType}
              isConfirmation={true}
              isPartOfPackage={appointment.patientPackageId !== undefined}
              onReschedule={() => window.location.reload()}
              onAppointmentCancelled={() => {
                props.history.push('/');
              }}
            />
          </CardContainer>
        )}
        {!!showIntakeCard && (
          <CardContainer>
            <IntakeFormCard history={history} confirmation />
          </CardContainer>
        )}
      </Container>
    );
  } else if (purchase?.type === 'membership' || purchase?.type === 'enterprise_membership') {
    return <MembershipConfirmationCard onClick={goToBook} id="google-tag-manager-confirmation" />;
  }
  return null;
};

export default PurchaseConfirmation;
