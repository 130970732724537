import { PatientToDo } from 'kb-shared';

export const filterNonConsentToDosWithDuplicateTitle = (todos: PatientToDo[]) => {
  const filteredOrConsentTodos = todos.filter(
    (todo, index, self) =>
      todo.category === 'consent' || index === self.findIndex(sTodo => sTodo.title === todo.title)
  );

  return filteredOrConsentTodos.map(todo => {
    const todosWithTitle = filteredOrConsentTodos.filter(t => t.title === todo.title);
    const numberOfTodos = todosWithTitle.length;
    const todoIndex = todosWithTitle.findIndex(current_todo => current_todo.id === todo.id);
    return {
      ...todo,
      title: numberOfTodos === 1 ? todo.title : `${todo.title} (${todoIndex + 1})`
    };
  });
};
