import { gql } from '@apollo/client';

export const CONVERSATION_QUERY = gql`
  query conversation($id: Int!) {
    conversation(id: $id) {
      id
      category
      subject
      status
      assignedToUserName
      unreadCount
      messages {
        id
        read
        body
        fromPatient
        createdAt
        senderName
        senderId
        attachments {
          fileName
          file
          id
          uuid
        }
      }
    }
  }
`;

export const MARK_CONVERSATION_READ = gql`
  mutation markConversationMessagesRead($id: Int!) {
    markConversationMessagesRead(id: $id) {
      conversation {
        id
        unreadCount
        lastMessageTimestamp
      }
    }
  }
`;
