import React, { useCallback, useEffect } from 'react';

import { Loader } from 'components/Loader/Loader';
import { Tab } from 'components/v2/Tabs/Tab.types';
import { Tabs } from 'components/v2/Tabs/Tabs';
import { Heading } from 'components/v2/Typography';
import { CONTAINER_DEPRECATED } from 'screens/styled_common';
import { analytics } from 'utilities/analytics';

import { ResourcesList } from './components/ResourcesList';
import useResourceLibrary from './hooks/use-resource-library';

export const Resources = () => {
  const { loading, educationalContent, blogsContent, videosContent } = useResourceLibrary();

  const tabs: Tab[] = [
    {
      id: '1',
      label: 'Educational Content',
      content: <ResourcesList resourcesList={educationalContent} />
    },
    {
      id: '2',
      label: 'Blogs',
      content: <ResourcesList resourcesList={blogsContent} />
    },
    {
      id: '3',
      label: 'Videos',
      content: <ResourcesList resourcesList={videosContent} />
    }
  ];

  const onChangeTab = useCallback(() => {}, []);

  useEffect(() => {
    analytics.page(analytics.PAGES.RESOURCES);
  }, []);

  if (loading) return <Loader absoluteCentered />;

  return (
    <CONTAINER_DEPRECATED>
      <Heading tag="div" styledAs="h1">
        Kindbody Library
      </Heading>
      <Tabs tabs={tabs} onChangeTab={onChangeTab} noMargin={true} />
    </CONTAINER_DEPRECATED>
  );
};
