import styled from 'styled-components';

export const ClickableText = styled.span`
  cursor: pointer;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
