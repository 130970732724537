import React from 'react';

import { FilePdf } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';
import { currencyNumberFormat } from 'utilities/number-format';

import Invoice from '../../types/invoice';
import { formatDate } from '../PagableInvoices/PagableInvoices.utils';
import {
  CardContent,
  CardContentAmount,
  CardContentRow,
  CardListItem,
  LabelHolder,
  CardContentRowContainer,
  FullWidth,
  HalfWidth,
  CardContentButtonRowContainer
} from './InvoiceCardListItem.styled';

interface Props {
  invoice: Invoice;
  totalBalance: number;
  isViosPatient: boolean;
  onPayClick: () => void;
  onViewInvoice: () => void;
  onPayWithCredit: () => void;
}

export const InvoiceCardListItem = ({
  invoice,
  totalBalance,
  isViosPatient,
  onPayClick,
  onViewInvoice,
  onPayWithCredit
}: Props) => {
  const { dateOfInvoice, service, balance, id, azEncounterIdentifier, paidViaPpApp } = invoice;

  const invoiceIdentifier = azEncounterIdentifier || id;
  const invoiceBalance = parseFloat(balance || '0');

  const showPayButton = !paidViaPpApp && invoiceBalance > 0;
  const showPayWithCreditButton = showPayButton && totalBalance < 0 && !isViosPatient;
  const showPaidLabel = invoiceBalance === 0;
  const showProcessingLabel = paidViaPpApp && invoiceBalance > 0;

  return (
    <CardListItem>
      <CardContent>
        <CardContentRow>
          <Text tag="span" fontStyle="medium" transform="uppercase">
            service
          </Text>
          <Text tag="span" fontStyle="regular">
            {formatDate(dateOfInvoice)}
          </Text>
        </CardContentRow>

        <CardContentRow>
          <Text tag="span" fontStyle="regular" className="heading">
            {service || '-'}
          </Text>
        </CardContentRow>

        <CardContentRowContainer>
          <FullWidth $hidden={!showPaidLabel && !showProcessingLabel}>
            <Text tag="span" fontStyle="medium" transform="uppercase">
              status
            </Text>
            {showPaidLabel && <LabelHolder $forPaid={true}>Paid</LabelHolder>}
            {showProcessingLabel && <LabelHolder>Processing...</LabelHolder>}
          </FullWidth>

          <HalfWidth>
            <div>
              <Text tag="span" fontStyle="medium" transform="uppercase">
                invoice no.
              </Text>
            </div>
            <CardContentAmount>
              <Text tag="span" fontStyle="regular">
                {invoiceIdentifier}
              </Text>
            </CardContentAmount>
          </HalfWidth>

          <HalfWidth>
            <div>
              <Text tag="span" fontStyle="medium" transform="uppercase">
                balance
              </Text>
            </div>
            <CardContentAmount>
              <Text tag="span" fontStyle="regular">
                {currencyNumberFormat(invoiceBalance)}
              </Text>
            </CardContentAmount>
          </HalfWidth>

          <FullWidth>
            <Button
              label="View"
              category="secondary"
              size="md"
              fullWidth
              rightIcon={<FilePdf type="solid" />}
              onClick={onViewInvoice}
              uppercase
            />
          </FullWidth>
        </CardContentRowContainer>

        <CardContentButtonRowContainer>
          <HalfWidth>
            {showPayButton && (
              <div>
                <Button
                  label="Pay"
                  category="primary"
                  size="md"
                  fullWidth
                  onClick={onPayClick}
                  uppercase
                />
              </div>
            )}
          </HalfWidth>

          <HalfWidth>
            {showPayWithCreditButton && (
              <Button
                label="Pay with credit"
                category="secondary"
                size="md"
                fullWidth
                onClick={onPayWithCredit}
                uppercase
              />
            )}
          </HalfWidth>
        </CardContentButtonRowContainer>
      </CardContent>
    </CardListItem>
  );
};
