import styled from 'styled-components';

import { InfoAreaContainer as InfoAreaContainerBase } from './styled-components';

export const InfoAreaContainer = styled(InfoAreaContainerBase)`
  margin-top: 32px;
  padding: 21px; 26px; 16px; 26px;
`;

export const InfoAreaText = styled.p`
  font-size: 13px;
  text-align: center;
  line-height: 16px;

  margin-top: 24px;
`;

export const LMPTextContainer = styled.p`
  text-align: center;
  margin: auto 20px;
`;

export const LMPStatus = styled.span`
  display: block;
  font-size: 24px;
  ${({ theme }) => theme.fonts.DomaineDisplay};
`;

export const EDDAndGestionalAge = styled.span`
  font-size: 14px;
`;
