import styled from 'styled-components';

import { YellowButton } from '../../components/Buttons';

export const Container = styled.div`
  ${({ theme }) => theme.grid.pageContainer};

  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 600px;
  margin: 100px auto 0;
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Divider = styled.div`
  border: solid 1px black;
  width: 100%;
  margin: 48px 0px 48px 0px;
`;

export const ConsentText = styled.div`
  ${({ theme }) => theme.fonts.DomaineDisplay};
  font-size: 24px;
  text-align: center;
  padding: 0px 24px 48px 24px;
  max-width: 646px;
`;

export { YellowButton };
