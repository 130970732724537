import React from 'react';
import { Link } from 'react-router-dom';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { KB360AppointmentType } from './graphql/queries';
import { filterAppointmentTypes } from './helpers/helpers';
import { KB360ServiceType } from './ServiceTypeIcon';

interface AppointmentTypesResultsSectionProps {
  heading: string;
  appointmentTypes: KB360AppointmentType[];
  selectedCategories?: KB360ServiceType[];
}

export const AppointmentTypesResultsSection = ({
  heading,
  appointmentTypes,
  selectedCategories
}: AppointmentTypesResultsSectionProps) => {
  const selectedAppointmentTypes = filterAppointmentTypes(appointmentTypes, selectedCategories);

  if (!selectedAppointmentTypes.length) return null;

  return (
    <AppointmentTypeResultsSectionWrapper>
      <SectionHeading>{heading}</SectionHeading>
      {selectedAppointmentTypes.map(appointmentType => (
        <AppointmentTypeCard key={appointmentType.name} appointmentType={appointmentType} />
      ))}
    </AppointmentTypeResultsSectionWrapper>
  );
};

interface AppointmentTypeCardProps {
  appointmentType: KB360AppointmentType;
}

const AppointmentTypeCard = ({ appointmentType }: AppointmentTypeCardProps) => (
  <AppointmentTypeCardContainer>
    <AppointmentTypeTitle>{appointmentType.name}</AppointmentTypeTitle>
    <AppointmentTypeDetail>{appointmentType.description}</AppointmentTypeDetail>
    <BookingLink>
      <Link
        to={
          appointmentType.overrideUrl || `/Book?step=location&appointment_id=${appointmentType.id}`
        }
      >
        See availability →
      </Link>
    </BookingLink>
  </AppointmentTypeCardContainer>
);

const BookingLink = styled.div`
  text-align: center;
  text-decoration: underline;
  margin: 20px;
`;

const AppointmentTypeCardContainer = styled.div`
  box-sizing: border-box;
  height: 394px;
  min-width: 340px;
  max-width: 540px;
  width: 100%;
  border: 1px solid #c6c7ce;
  border-radius: 8px;
  background-color: #ffffff;
  float: left;
  margin-right: 19px;
  margin-top: 21px;
  overflow: hidden;

  @media (max-width: ${themes.breakpoints.tabletPortrait}) {
    height: auto;
    overflow: auto;
  }
`;

const AppointmentTypeTitle = styled.div`
  ${themes.fonts.DomaineDisplay};
  color: #353148;
  font-size: 22px;
  font-weight: 500;
  margin: 24px;
  height: 45px;
  letter-spacing: 0;
  line-height: 29px;
  text-align: center;

  @media (max-width: ${themes.breakpoints.tabletPortrait}) {
    height: auto;
    overflow: auto;
  }
`;

const AppointmentTypeDetail = styled.div`
  ${themes.fonts.FoundersGrotesk};
  height: 226px;
  color: #353148;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  overflow: hidden;
  margin: 20px;
  text-overflow: ellipsis;

  @media (max-width: ${themes.breakpoints.tabletPortrait}) {
    height: auto;
    overflow: auto;
  }
`;

const SectionHeading = styled.h2`
  ${themes.fonts.DomaineDisplay};
  clear: both;
  display: block;
  color: #353148;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 42px;
  overflow: hidden;
  padding-top: 32px;
  margin-bottom: 0px;
`;

const AppointmentTypeResultsSectionWrapper = styled.div`
  margin-bottom: 16px;
  overflow: hidden;
`;
