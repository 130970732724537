import styled from 'styled-components';

export const TableComponent = styled.table`
  width: 100%;
  margin-top: 1em;
  border-spacing: 0px;

  th,
  td {
    white-space: nowrap;
  }

  td:nth-child(2) {
    white-space: normal;
  }
`;

export const TableBody = styled.tbody`
  width: 100%;
  line-height: 20px;

  td {
    border: 0.8px solid ${({ theme }) => theme.colors.neutral.lavender};
  }
`;
