import React, { Fragment } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import SubmitOrCancel from '../../components/SubmitOrCancel';

const { colors, fonts } = themes;

const Header = styled.h1`
  ${({ theme }) => theme.typos.pageHeader};
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

const Error = styled.p`
  color: ${colors.semantic.darkRed};
  font-size: 16px;
  height: 30px;
  margin: 0 0 10px 0;
  text-align: center;
  ${fonts.FoundersGrotesk};
`;

type Props = {
  currentFileBlob: string;
  crop: Crop;
  error: string | null;
  loading: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onChange: (crop: Crop, percentCrop: Crop) => void;
};

const ImageCrop = (props: Props) => {
  const { currentFileBlob, crop, onClose, onSubmit, onChange, error, loading } = props;

  if (!currentFileBlob) return null;

  return (
    <Fragment>
      <Header>Adjust your photo as desired to fit.</Header>
      <Error>{error || ''}</Error>
      <ReactCrop
        crop={crop}
        src={currentFileBlob}
        onChange={onChange}
        style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
        keepSelection={true}
        disabled={loading && !error}
      />
      <SubmitOrCancel onSubmit={onSubmit} onCancel={onClose} loading={loading && !error} />
    </Fragment>
  );
};

export default ImageCrop;
