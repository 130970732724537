import React from 'react';
import { useHistory } from 'react-router-dom';

import { LoadedEntities, PurchasedProduct } from 'kb-shared/booking/types/types';
import { AppointmentType, TimeSlot, PartnerClinicDetails } from 'kb-shared/types';
import { DashboardButton } from 'screens/Book/Book.styled';
import PurchaseConfirmation from 'screens/Book/steps/PurchaseConfirmation';
import { pageUrl } from 'utilities/pageUrl';

export const SuccessStep = ({
  appointmentType,
  selectedAppointmentTypes,
  selectedProduct,
  selectedTimeSlot,
  purchasedProduct,
  partnerClinicSelected
}: {
  appointmentType?: AppointmentType;
  selectedAppointmentTypes: Array<AppointmentType>;
  selectedProduct?: LoadedEntities['selectedProduct'];
  selectedTimeSlot?: TimeSlot;
  purchasedProduct?: PurchasedProduct;
  partnerClinicSelected: PartnerClinicDetails | null;
}): JSX.Element => {
  const history = useHistory();
  let appointmentName = '';

  if (selectedProduct && selectedProduct.type === 'appointment') {
    const selectedAppointment = selectedProduct.data;
    appointmentName = selectedAppointment.name;
  }
  if (!appointmentType && selectedAppointmentTypes) {
    appointmentName = selectedAppointmentTypes[0]?.name;
  }
  return (
    <>
      <PurchaseConfirmation
        purchase={purchasedProduct}
        timeSlot={selectedTimeSlot ?? null}
        partnerClinicDetails={partnerClinicSelected}
        appointmentName={appointmentName}
        history={history}
        onReturnToBookAppointment={() => {
          // Since we are already on the book state, pushing or replacing '/book' will have no effect since the internal state will not change.
          // Do a hard reload of the page
          history.push(pageUrl.booking.bookingQuiz());
          window.location.reload();
        }}
      />
      <DashboardButton href="/">Go To Dashboard</DashboardButton>
    </>
  );
};
