import React, { useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Text } from 'components/v2/Typography/Text';
import { useTooltip } from 'hooks';
import theme from 'kb-shared/theme';
import { convertDateForm } from 'kb-shared/utilities/momentHelpers';
import { DEFAULT_CATEGORIES_INFO } from 'screens/Messages/Messages.constants';
import {
  Category,
  Conversation,
  ConversationStatus,
  MessageParams
} from 'screens/Messages/Messages.type';
import { getFirstName, isResolvedConversation } from 'screens/Messages/Messages.utils';
import { pageUrl } from 'utilities/pageUrl';

import { Badge } from '../Badge/Badge';
import { CategoryIcon } from '../CategoryIcon/CategoryIcon';
import { getParticipantsTooltipHTMLStructure } from '../Header/Header.utils';
import {
  BadgeStyled,
  CategoryInfo,
  Category as CategoryStyled,
  Container,
  Icon,
  SubjectPreview,
  UnreadCounter
} from './ConversationItem.styled';

export const ConversationItem = ({
  conversation,
  unreadMessageCount,
  category,
  status
}: {
  conversation: Conversation;
  unreadMessageCount: number;
  category: Category;
  status: ConversationStatus;
}) => {
  const history = useHistory();
  const { conversationId } = useParams<MessageParams>();
  const tooltipTrigger = useRef<HTMLElement>(null);

  const onClick = (conversation: Conversation) => {
    const url = pageUrl.messages({
      categoryName: conversation.category,
      conversationId: conversation.id
    });
    history.push(url);
  };

  const {
    participantsTooltipHTMLStructure,
    additionalParticipants
  } = getParticipantsTooltipHTMLStructure(conversation);

  useTooltip({
    triggerElementRef: tooltipTrigger,
    content: participantsTooltipHTMLStructure,
    visible: true,
    allowHTML: true
  });

  return (
    <Container
      key={conversation.id}
      onClick={() => onClick(conversation)}
      isActive={conversation.id === conversationId}
    >
      <Icon>
        <CategoryIcon size="md" icon={category ? category.icon : DEFAULT_CATEGORIES_INFO.icon} />

        {unreadMessageCount > 0 && <UnreadCounter>{unreadMessageCount}</UnreadCounter>}
      </Icon>

      <CategoryStyled>
        <Text fontStyle="bold" size="md">
          {getFirstName(conversation.assignedToUserName) ?? category?.title}
        </Text>
        {additionalParticipants.length > 0 && (
          <Badge
            ref={tooltipTrigger}
            label={'+' + additionalParticipants.length}
            type="default"
            size="sm"
          />
        )}

        {conversation.category === 'tech' && (
          <BadgeStyled label="Support" type="default" size="sm" />
        )}

        <CategoryInfo>
          {isResolvedConversation(status) ? (
            <Badge label="Resolved" type="success" size="sm" />
          ) : (
            <Text size="xs" tag="time" color={theme.colors.neutral.darkestNavy}>
              {convertDateForm(conversation.lastMessageTimestamp, 'MM/DD/YYYY')}
            </Text>
          )}
        </CategoryInfo>
      </CategoryStyled>
      <SubjectPreview>{conversation.subject}</SubjectPreview>
    </Container>
  );
};
