import React from 'react';

import { useContactInfo } from 'hooks';

import { PanelProps } from './AdditionalInfo.types';
import MarketContactInfo from './MarketContactInfo';

const AdditionalInfoVirtual = (props: PanelProps) => {
  const { rescheduleNumber } = useContactInfo();
  const showRescheduleContact = props.rescheduleModal;

  return (
    <>
      <strong>
        If you would like to book with a specific provider,{' '}
        {showRescheduleContact && rescheduleNumber ? (
          <>
            <MarketContactInfo rescheduleNumber={rescheduleNumber} />.
          </>
        ) : (
          <>
            you can call us at <a href="tel://855-563-2639">855-563-2639</a> and a Patient Care
            Navigator will happily assist you with scheduling.
          </>
        )}
      </strong>
      <br />
      For services that require you to be seen in clinic, such as fertility care, the provider you
      meet with virtually may differ from your treating provider.
      <p>
        In order to provide you with the earliest availability for your appointment, the appointment
        times listed include all providers for this location.
      </p>
    </>
  );
};

export default AdditionalInfoVirtual;
