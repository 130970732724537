import { Data, Step } from './BookingStateManager.types';

export const wizardStep = {
  SERVICES: 'services',
  LOCATION: 'location',
  CREATE_ACCOUNT: 'create_account',
  INSURANCE: 'insurance',
  CONFIRM: 'confirm',
  SUCCESS: 'success'
} as const;

export const createAccountStep: Step = {
  type: wizardStep.CREATE_ACCOUNT,
  name: 'Create Account'
} as const;

const step = {
  SERVICES: {
    type: wizardStep.SERVICES,
    name: 'Services'
  },
  LOCATION: {
    type: wizardStep.LOCATION,
    name: 'Location'
  },
  CREATE_ACCOUNT: createAccountStep,
  INSURANCE: {
    type: wizardStep.INSURANCE,
    name: 'Insurance'
  },
  CONFIRM: {
    type: wizardStep.CONFIRM,
    name: 'Confirm'
  },
  SUCCESS: {
    type: wizardStep.SUCCESS,
    name: ''
  }
} as const;

type StepDescriptor = typeof step[keyof typeof step];

const defaultSteps: Record<string, StepDescriptor> = {
  services: step.SERVICES,
  location: step.LOCATION,
  create_account: step.CREATE_ACCOUNT,
  insurance: step.INSURANCE,
  confirm: step.CONFIRM,
  success: step.SUCCESS
} as const;

export const loggedInSteps: Record<string, StepDescriptor> = {
  services: step.SERVICES,
  location: step.LOCATION,
  insurance: step.INSURANCE,
  confirm: step.CONFIRM,
  success: step.SUCCESS
} as const;

export const initialDataState: Data = {
  steps: defaultSteps,
  selectedStep: wizardStep.SERVICES,
  selectedProduct: null,
  selectedLab: null,
  selectedWeekString: null,
  selectedTimeSlot: null,
  selectedAppointmentTypes: null,
  partnerClinicSearch: null,
  selectedClinic: null,
  purchasedProduct: null,
  date: null,
  target: null
} as const;

export const initialUrlParamsState = {
  step: wizardStep.SERVICES,
  appointment_id: null,
  appointment_types: null,
  membership_id: null,
  lab_id: null,
  clinic_id: null,
  week_string: null,
  time_slot_id: null,
  partner_clinic_search: null,
  reschedule_appointment_id: null,
  confirmed_appointment_id: null,
  confirmed_membership_id: null,
  target: null,
  enterprise_membership: null,
  require_unique_id: null,
  date: null
} as const;
