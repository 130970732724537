import styled from 'styled-components';

export const Title = styled.div`
  ${props => props.theme.fonts.FoundersGrotesk};
  font-size: 18px;
  font-weight: 600;
  background: white;
`;

export const Details = styled.div`
  ${props => props.theme.fonts.FoundersGrotesk};
  font-size: 14px;
  background: white;
`;

export const CancellationPolicy = styled.span`
  ${props => props.theme.fonts.FoundersGrotesk};
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
`;

export const Strong = styled.span`
  font-weight: bold;
`;

export const CancellingInfo = styled.div`
  margin: 10px 0 0 0;
`;

export const CancellingInfoTitle = styled.strong`
  display: block;
`;
