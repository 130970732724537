import { themes } from 'kb-shared';
import styled from 'styled-components';

import { SpaceContentProps } from './PartnerLocationSearch.types';

export const Title = styled.div`
  ${themes.fonts.DomaineDisplay};
  font-size: 24px;
  color: ${themes.colors.neutral.primaryNavy};
`;

export const Text = styled.div`
  z-index: 1;
  ${themes.fonts.FoundersGrotesk};
  font-size: 16px;
  color: ${themes.colors.neutral.primaryNavy};
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  max-width: 100%;
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    width: 100%;
  }
`;

export const ZipCodeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    margin-right: 32px;
  }
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    flex-direction: column;
    width: 100%;
    padding-top: 0px;
    align-items: start;
    margin-left: 32px;

    > *:last-child {
      margin-top: 16px;
    }
  }
`;

export const KindbodyNetworkContainer = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${themes.breakpoints.tabletLandscape}) {
    flex-direction: column;
    width: 100%;
  }
  font-size: 14px;
  color: ${themes.colors.neutral.primaryNavy};
  ${themes.fonts.FoundersGrotesk};
  width: 75%;
  margin-top: 100px;
  div {
    padding: 10px 10px;
  }
`;

export const UnableToFindContainer = styled.div`
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    text-align: center;
  }

  > * {
    padding: 20px 0px;
  }
`;

export const PartnerClinicsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: stretch;
  margin-top: 40px;
  max-width: 1100px;
  ${(props: SpaceContentProps) => `
    justify-content: ${props.centerContent ? 'space-evenly' : 'flex-start'};
  `}
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    flex-direction: column;
  }
  min-height: 100px;
`;

export const SearchIconWrapper = styled.div`
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background-color: ${themes.colors.yellow.primary};
  cursor: pointer;
  svg {
    transform: translate(10px, 10px);
  }
  svg > path {
    fill: ${themes.colors.neutral.primaryNavy};
  }
`;
