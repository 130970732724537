import React from 'react';
import { useHistory } from 'react-router-dom';

import { usePatient } from 'hooks';
import { AppointmentType } from 'kb-shared/types';
import { analytics } from 'utilities/analytics';

import { ReactComponent as AppointmentIcon } from '../../../assets/icons/appointment.svg';
import { Container, Card, Name, Description, ScheduleButton } from './AppointmentTypeCard.styled';

interface Props {
  appointmentType: AppointmentType;
  appointmentTypeIds: Array<number | string>;
}

export const AppointmentTypeCard = ({ appointmentType, appointmentTypeIds }: Props) => {
  const { isLoggedIn } = usePatient();
  const history = useHistory();
  const scheduleUrl =
    appointmentTypeIds.length > 1
      ? `/${
          isLoggedIn ? 'Book' : 'create-account'
        }?step=location&appointment_types=${appointmentTypeIds.join(',')}`
      : `/${isLoggedIn ? 'Book' : 'create-account'}?step=location&appointment_id=${
          appointmentTypeIds[0]
        }`;

  return (
    <Container>
      <Card>
        <AppointmentIcon />
        <Name>{appointmentType.name}</Name>
        <Description>{appointmentType.description}</Description>
      </Card>
      <ScheduleButton
        onClick={() => {
          analytics.track(analytics.EVENTS.APPOINTMENT_SELECTED);
          history.push(scheduleUrl);
        }}
      >
        Schedule Appointment
      </ScheduleButton>
    </Container>
  );
};
