import React, { Component } from 'react';
import { connect } from 'react-redux';

import 'react-image-crop/dist/ReactCrop.css';

import './cropStyles.scss';
import * as redux from 'kb-redux';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { analytics } from 'utilities/analytics';

import {
  uploadFile,
  CATEGORY,
  UploadFileResponse,
  UploadFileException
} from '../../../api/pf2/files';
import PhotoUploadModal from '../components/PhotoUploadModal';
import Success from '../components/Success';
import { getCroppedImg, Crop } from '../lib/imageCropper';
import ImageCrop from './components/ImageCrop';

const {
  patient: { resetProfileImageError }
} = redux;

type Props = {
  currentFileBlob: string;
  onClose: () => void;
  onSuccess: () => void;
  clearImageError: () => void;
};

type State = {
  croppedImage: string | null;
  croppedImageBlob: Blob | null;
  crop: Crop;
  error: string | null;
  loading: boolean;
};

class ProfilePhotoUploadModal extends Component<Props, State> {
  _modalRef = React.createRef<PhotoUploadModal>();

  constructor(props: Props) {
    super(props);

    const image = new Image();
    image.src = props.currentFileBlob;

    this.state = {
      croppedImage: null,
      croppedImageBlob: null,
      crop: {
        aspect: 1 / 1,
        unit: '%',
        x: 25,
        y: 10,
        width: 50,
        height: (image.width / image.height) * 50
      },
      error: null,
      loading: false
    };
  }

  onSubmit = async () => {
    const { currentFileBlob, clearImageError, onSuccess } = this.props;
    const { crop } = this.state;

    this.setState({ loading: true });

    try {
      const croppedImageBlob: Blob = await getCroppedImg(currentFileBlob, crop, 'profile-photo');

      const response = await uploadFile({
        file: croppedImageBlob,
        category: CATEGORY.PROFILE_PHOTO
      });
      if (response?.status === 422 || response?.status === 413) {
        this.setState({
          loading: false,
          error:
            'Failed to upload, file size exceeds the maximum limit, please try again with a smaller file.'
        });
        analytics.track(analytics.EVENTS.PROFILE_IMAGE_FILE_TOO_BIG);
        return;
      }

      const responseJson: UploadFileResponse = await response.json();

      if ('errors' in responseJson && responseJson?.errors) {
        this.setState({ error: responseJson.errors });
        analytics.track(analytics.EVENTS.PROFILE_IMAGE_UPLOAD_FAILED);
        return;
      }

      if (!response.ok) {
        const { status, statusText } = response;

        BugTracker.notify('ProfilePhotoUploadError', {
          message: `${status} ${statusText}: ${responseJson}`
        });
        const error =
          'message' in responseJson
            ? responseJson.message
            : 'Something went wrong, please try again.';

        this.setState({ error });
        analytics.track(analytics.EVENTS.PROFILE_IMAGE_UPLOAD_FAILED);
        return;
      }

      this.setState({
        croppedImageBlob,
        croppedImage: URL.createObjectURL(croppedImageBlob)
      });

      clearImageError();
      onSuccess();
      analytics.track(analytics.EVENTS.PROFILE_IMAGE_UPLOAD_SUCCEEDED);
    } catch (err) {
      BugTracker.notify('ProfilePhotoUploadError', {
        message: JSON.stringify(err)
      });

      const error = err as UploadFileException;
      this.setState({
        error: error?.message || 'Something went wrong, please try again.'
      });
      analytics.track(analytics.EVENTS.PROFILE_IMAGE_UPLOAD_FAILED);
    }
  };

  dismiss = () => {
    const { current } = this._modalRef;
    if (!current || !current.dismiss) return;
    current.dismiss();
  };

  render() {
    const { currentFileBlob, onClose } = this.props;
    const { croppedImage, error, loading, crop } = this.state;

    return (
      <PhotoUploadModal onClose={onClose} ref={this._modalRef}>
        {!croppedImage ? (
          <ImageCrop
            currentFileBlob={currentFileBlob}
            crop={crop}
            onClose={this.dismiss}
            onChange={(_crop, percentCrop) => this.setState({ crop: percentCrop as Crop })}
            onSubmit={this.onSubmit}
            error={error}
            loading={loading}
          />
        ) : (
          <Success
            label="Your photo has been uploaded."
            images={[croppedImage]}
            onClose={this.dismiss}
          />
        )}
      </PhotoUploadModal>
    );
  }
}

export default connect(null, dispatch => ({
  clearImageError: () => dispatch(resetProfileImageError())
}))(ProfilePhotoUploadModal);
