import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Text = styled.p`
  ${({ theme }) => theme.fonts.DomaineDisplay};
  font-size: 18px;
  margin: 0 100px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 700px) {
    margin: 0;
  }
`;

export const Link = styled.a`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  padding: 15px 24px;
  background-color: ${({ theme }) => theme.colors.yellow.primary};
  border: 1px solid ${({ theme }) => theme.colors.yellow.primary};
  border-radius: 24px;
  cursor: pointer;
`;
