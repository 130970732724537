import { themes } from 'kb-shared';
import styled from 'styled-components';

export const MenuUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  ${({ theme }) => theme.fonts.Gotham};
  color: ${themes.colors.neutral.lavenderLight};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin-left: 16px;
`;

export const MenuUserMembership = styled.span`
  background-color: ${themes.colors.neutral.lavender};
  color: ${themes.colors.neutral.primaryNavy};
  display: inline-block;
  padding: 2px 8px;
  margin-top: 8px;
  border-radius: 4px;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;

export const MenuUserNameH2 = styled.h2`
  max-width: 220px;
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  @media screen and (max-width: ${themes.breakpoints.tabletPortrait}) {
    font-size: 18px;
  }
`;
