import React from 'react';

import LeftBracket from 'components/SVG/LeftBracket';
import RightBracket from 'components/SVG/RightBracket';
import { themes } from 'kb-shared';
import styled from 'styled-components';

import { ReactComponent as MembershipCard } from '../../../assets/icons/membership_card.svg';
import Button from '../../../components/Button/NewButton';

const { colors } = themes;

const BenefitTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const BenefitText = styled.span`
  ${themes.fonts.FoundersGrotesk};
  font-size: 14px;
  color: ${themes.colors.neutral.primaryNavy};
`;

const YellowBulletWrapper = styled(BenefitText)`
  font-size: 12px;
  color: ${themes.colors.yellow.primary};
  margin-right: 4px;
  margin-top: 2px;
`;

const YellowBullet = () => {
  return <YellowBulletWrapper>◼</YellowBulletWrapper>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  margin-bottom: 28px;
`;

const Content = styled.div`
  padding: 20px;
`;

const TitleText = styled.div`
  ${themes.fonts.DomaineDisplay};
  font-size: 24px;
  color: ${colors.neutral.primaryNavy};
`;

const SubtitleText = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-size: 16px;
  color: ${colors.neutral.primaryNavy};
  padding-bottom: 24px;
`;

const DetailsText = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-size: 16px;
  color: ${colors.neutral.primaryNavy};
  padding-top: 20px;
`;

const BoldText = styled(DetailsText)`
  font-weight: bold;
  padding-top: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
`;

type Props = {
  showBecomeMemberCTA?: boolean;
  hasEmployer?: boolean;
  onBecomeMember?: () => void;
};

const MembershipDetailBlock = ({ hasEmployer = false, ...props }: Props) => {
  return (
    <Container>
      <Content>
        <MembershipIconGroup />
        <TitleText>Kindbody Membership</TitleText>
        {!hasEmployer && <SubtitleText>$10 per month billed yearly</SubtitleText>}
        <DetailsText>
          <BoldText>As a member, you have access to these benefits:</BoldText>
          {hasEmployer && (
            <BenefitTextContainer>
              <YellowBullet /> <BenefitText>Discounted fertility services. </BenefitText>
            </BenefitTextContainer>
          )}
          <BenefitTextContainer>
            <YellowBullet /> <BenefitText>On-demand chat with your care team</BenefitText>
          </BenefitTextContainer>
          <BenefitTextContainer>
            <YellowBullet />{' '}
            <BenefitText>Discounted wellness services (mental health, nutrition)</BenefitText>
          </BenefitTextContainer>
          <BenefitTextContainer>
            <YellowBullet /> <BenefitText>Discounted supplements</BenefitText>
          </BenefitTextContainer>
          <BenefitTextContainer>
            <YellowBullet /> <BenefitText>Members-only events</BenefitText>
          </BenefitTextContainer>
        </DetailsText>
        {props.showBecomeMemberCTA ? (
          <ButtonWrapper>
            <Button
              text={'BECOME A MEMBER TODAY'}
              color={themes.colors.neutral.primaryNavy}
              textColor={'white'}
              onClick={props.onBecomeMember}
            />
          </ButtonWrapper>
        ) : null}
      </Content>
    </Container>
  );
};

const MembershipIconGroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4px;
`;

const MembershipIconGroup = () => {
  return (
    <MembershipIconGroupContainer>
      <LeftBracket color={colors.yellow.primary} width={13} height={33} />
      <MembershipCard />
      <RightBracket color={colors.yellow.primary} width={13} height={33} />
    </MembershipIconGroupContainer>
  );
};

export default MembershipDetailBlock;
