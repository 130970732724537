import styled from 'styled-components';

export const TableCell = styled.td<{ $alignRight?: boolean }>`
  ${({ $alignRight }) => ($alignRight ? 'text-align: right;' : 'text-align: start;')}
`;

export const LabelHolder = styled.div<{ $forPaid?: boolean }>`
  text-align: center;
  background: ${({ theme, $forPaid }) =>
    $forPaid ? theme.colors.green.lightestGreen : theme.colors.neutral.lavenderLight};
  color: ${({ theme, $forPaid }) =>
    $forPaid ? theme.colors.green.darkestGreen : theme.colors.neutral.primaryNavy};
  padding: 0.4em 0.5em;
  border-radius: 4px;
  width: 106px;
  font-size: 14px;
`;

export const ButtonWrapper = styled.div`
  padding: 0 1em 0 0;

  button:nth-child(2) {
    margin-left: 1em;
  }

  > button {
    width: 50%;
  }
`;

export const LongTextWrapper = styled.div`
  white-space: wrap;
`;

export const FirstRow = styled.tr<{ $alternateBackground?: boolean }>`
  td {
    padding: 1.1em 0.75em;
  }

  background: ${({ theme, $alternateBackground }) =>
    $alternateBackground ? theme.colors.neutral.lavenderWhite : theme.colors.white};
`;

export const SecondRow = styled.tr<{ $alternateBackground?: boolean }>`
  button {
    font-weight: 500;
  }

  td {
    padding: 0.75em 0.75em;
  }

  background: ${({ theme, $alternateBackground }) =>
    $alternateBackground ? theme.colors.neutral.lavenderWhite : theme.colors.white};
`;
