import React from 'react';

import { usePatient } from 'hooks';
import { isDisneyPatient } from 'kb-shared/utilities/lab.helper';

import { CancellationPolicyProps } from './CancellationPolicy.types';
import { DisneyNumber } from './DisneyNumber';
import { NavigatorEmail } from './NavigatorEmail';
import { NavigatorNumber } from './NavigatorNumber';

const getNoCancelNorRescheduleVariant = (emailBody: string) => {
  return (
    <>
      <p>
        <span>
          This appointment cannot be canceled nor rescheduled in the Patient Portal. Please call us
          at{' '}
        </span>
        <NavigatorNumber />
        <span> or email us at </span>
        <NavigatorEmail emailBody={emailBody} />
        <span> to cancel or reschedule your appointment.</span>
      </p>
    </>
  );
};

const getMoreThan24HoursVariant = (emailBody: string) => {
  return (
    <p>
      <span>
        To reschedule or cancel with less than 24 hours notice, or if you have any questions, email
        us at{' '}
      </span>
      <NavigatorEmail emailBody={emailBody} />
      <span> or call us at </span>
      <NavigatorNumber />
      <span>.</span>
    </p>
  );
};

const getDisneyVariant = () => {
  return (
    <p>
      <span>
        If you need help rescheduling your appointment, please send us a secure message or call the
        office at{' '}
      </span>
      <DisneyNumber />
      <span>.</span>
    </p>
  );
};

export const CancellationPolicy = ({
  emailBody,
  noCancelNorRescheduleVariant
}: CancellationPolicyProps): JSX.Element => {
  const { patient } = usePatient();
  const labId = patient?.lab?.id ?? '';
  const disneyPatient = isDisneyPatient(labId);

  if (disneyPatient) {
    return getDisneyVariant();
  } else if (noCancelNorRescheduleVariant) {
    return getNoCancelNorRescheduleVariant(emailBody);
  } else {
    return getMoreThan24HoursVariant(emailBody);
  }
};
