import { OutlineButton, YellowButton } from 'components/Buttons';
import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  padding: 0px 12px;
  flex-grow: 1;
`;

export const BookButton = styled(YellowButton)`
  display: block;
  width: 100%;
  margin: 8px 0px;
`;

export const BookletButton = styled(OutlineButton)`
  display: block;
  width: 100%;
`;

export const CancelButton = styled.button`
  margin-top: 12px;
  background-color: transparent;
  border: none;
  font-weight: 700;
  font-size: 12px;
  text-decoration: underline;
  ${({ theme }) => theme.fonts.FoundersGrotesk};
`;
