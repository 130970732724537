import { themes } from 'kb-shared';
import styled from 'styled-components';
const { colors, fonts } = themes;

export default styled.button`
  ${fonts.FoundersGrotesk};
  text-transform: uppercase;
  font-size: 14px;
  padding: 15px 24px;
  background-color: ${colors.green.lightGreen};
  border: 1px solid ${colors.green.lightGreen};
  border-radius: 24px;
  cursor: pointer;

  :hover,
  :focus {
    outline: 0;
    opacity: 0.8;

    /* When using the button as a 'a' element */
    font-weight: normal;
    text-decoration: none;
  }
`;
