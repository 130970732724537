import { useQuery } from '@apollo/client';
import React from 'react';

import { graphql, PatientLabResponseData } from 'kb-shared';
import { utils } from 'kb-shared';

const labs = utils.labs;

export enum Customization {
  DISNEY = 'disney', // For Disney LabIDs
  NONE = 'none'
}

function getCompany(labId: string): string {
  const LABS = labs();

  if (labId === LABS.DISNEY) return 'disney';

  return 'kindbody';
}

interface Props {
  is: Customization;
  fallback?: React.ReactElement;
}

export const WhenCustomization: React.FC<Props> = props => {
  const { children, is: customization, fallback } = props;
  const { PATIENT_LAB } = graphql.query;
  const { data, loading, error } = useQuery<PatientLabResponseData>(PATIENT_LAB);

  if (loading) return <></>;

  const applicableCustomizations = new Set();

  // Company Customizations
  const labId = data?.patientLab?.lab.id || '';
  if (getCompany(labId.toString()) === 'disney') {
    applicableCustomizations.add(Customization.DISNEY);
  }

  // No Customizations
  if (applicableCustomizations.size === 0) {
    applicableCustomizations.add(Customization.NONE);
  }

  if (error) {
    console.error(error);
    return <></>;
  }

  if (!applicableCustomizations.has(customization)) {
    return fallback || null;
  }

  return <>{children}</>;
};
