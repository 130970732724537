import moment from 'moment';
import React from 'react';

import { NullableString, Patient } from 'kb-shared';

import { SignedConsentMeta } from '../SignedConsent.styled';
import { SignedConsentTitle, SignedConsentHeaderContainer } from './SignedConsentHeader.styled';
import { SignedConsentHeaderProps } from './SignedConsentHeader.types';

export const SignedConsentHeader: React.FC<SignedConsentHeaderProps> = ({
  completedAt,
  patient,
  patientNameFromConsent
}) => {
  const isValidBirthday = () => {
    return moment.tz(patient.birthday, 'UTC').isValid();
  };

  return (
    <SignedConsentHeaderContainer>
      <SignedConsentTitle>Copy of Patient Consent</SignedConsentTitle>
      <SignedConsentMeta>
        {'Patient: '}
        {getPatientName(patient, patientNameFromConsent)}
      </SignedConsentMeta>
      {patient.birthday && (
        <SignedConsentMeta>
          {'DOB: '}
          {isValidBirthday() ? moment.tz(patient.birthday, 'UTC').format('MM/DD/YYYY') : '-'}
        </SignedConsentMeta>
      )}
      {patient.id && (
        <SignedConsentMeta>
          {'MRN: '}
          {patient.id}
        </SignedConsentMeta>
      )}
      <SignedConsentMeta>
        {'Date completed: '}
        {completedAt}
      </SignedConsentMeta>
    </SignedConsentHeaderContainer>
  );
};

const getPatientName = (patient: Patient, patientNameFromConsent: NullableString) => {
  if (patientNameFromConsent) return patientNameFromConsent;
  return `${patient.firstName} ${patient.lastName}`;
};
