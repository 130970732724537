import * as React from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

const { deprecatedColors, colors, breakpoints } = themes;

export const size = 140;
const Button = styled.div`
  flex-shrink: 0;
  width: ${size}px;
  height: ${size}px;
  border: dashed 4px ${deprecatedColors.gray};
  background-color: ${colors.white};

  @media (max-width: ${breakpoints.tabletPortrait}) {
    display: block;
    align-self: center;
    justify-self: center;
  }
`;

const Line = styled.div`
  background-color: ${colors.neutral.primaryNavy};
  position: absolute;
`;

const LineContainer = styled.div`
  position: relative;
`;

const YLine = styled(Line)`
  height: 70px;
  width: 3px;
  transform: translate(68px, 35px);
`;

const XLine = styled(Line)`
  height: 3px;
  width: 70px;
  transform: translate(35px, 68px);
`;

export const WhiteSquare = (): JSX.Element => {
  return (
    <Button>
      <LineContainer>
        <XLine />
      </LineContainer>
      <LineContainer>
        <YLine />
      </LineContainer>
    </Button>
  );
};
