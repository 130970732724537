import React from 'react';

import { Heading } from 'components/v2/Typography';
import { Appointment } from 'kb-shared';

import { List } from '../List/List';
import { Container } from './PastAppointment.styled';
import { getPastAppointments } from './PastAppointments.utils';

export const PastAppointments = ({ appointments }: { appointments?: Appointment[] }) => {
  const pastAppointments = getPastAppointments(appointments);

  return (
    <Container>
      <Heading tag="div" styledAs="h3">
        Past Appointments
      </Heading>
      <List appointments={pastAppointments} />
    </Container>
  );
};
