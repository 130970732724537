import React from 'react';

import { useContactInfo } from 'hooks';

export const DisneyNumber = () => {
  const { rescheduleNumber } = useContactInfo();

  return (
    <a className="link" href={`tel:${rescheduleNumber}`}>
      {rescheduleNumber}
    </a>
  );
};
