import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import { Droplet } from 'assets/icons/Icons';
import { LMP_TRACKER_QUERY } from 'components/Dashboard/PeriodTrackerWidget/graphql/lmpTrackerQuery.graphql';
import { LmpTrackerQueryResponse } from 'components/Dashboard/PeriodTrackerWidget/graphql/lmpTrackerQuery.types';
import { UPDATE_LMP_TRACKER_ENABLED } from 'components/Dashboard/PeriodTrackerWidget/graphql/updateLmpTrackerEnabledMutation.graphql';
import {
  UpdateLmpTrackerEnabledArgs,
  UpdateLmpTrackerEnabledResponse
} from 'components/Dashboard/PeriodTrackerWidget/graphql/updateLmpTrackerEnabledMutation.types';
import { PREGNANCY_CALCULATOR_QUERY } from 'components/Dashboard/PregnancyCalculatorWidget';
import { Loader } from 'components/Loader/Loader';
import { Button } from 'components/v2/Buttons/Button';
import { DateField } from 'components/v2/Form';
import { TextInput } from 'components/v2/Inputs';
import { Heading, Text } from 'components/v2/Typography';
import { theme, themes, Vital } from 'kb-shared';
import { CREATE_PERIOD, UPDATE_CYCLE_LENGTH, UPDATE_PERIOD } from 'kb-shared/graphql/mutations';
import { USER_PERIOD } from 'kb-shared/graphql/queries';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { dateToISOFormat, formatDate } from 'utilities/formatDate';

import { ButtonsContainer, IconContainer, WidgetTitleContainer } from '../Widgets/Widgets.styled';
import { FinishModal } from './components/FinishModal/FinishModal';
import { ValuesSelected } from './components/ValuesSelected/ValuesSelected';
import { Container, PredictedBox, SessionContainer } from './PeriodTracker.styled';
import { State, Step } from './PeriodTracker.types';
import { DEFAULT_USER_VITAL, INITIAL_STATE } from './PeriodTracker.utils';

export const PeriodTracker = ({ patientIsFemale }: { patientIsFemale: boolean }) => {
  const [state, setState] = useState<State>(INITIAL_STATE);
  const { data, loading: isLoadingVital } = useQuery<LmpTrackerQueryResponse>(LMP_TRACKER_QUERY, {
    onError: error => BugTracker.notify(error, 'PeriodTrackerWidget_LMP_TRACKER_QUERY'),
    skip: !patientIsFemale
  });

  const userVital: Vital = data?.lastPeriod || DEFAULT_USER_VITAL;

  const [updatePeriod, { loading: updatePeriodLoading }] = useMutation(UPDATE_PERIOD, {
    variables: {
      id: userVital.id,
      periodDate: state.editedLastPeriodDate
    },
    refetchQueries: [{ query: USER_PERIOD }, { query: PREGNANCY_CALCULATOR_QUERY }],
    onError: error => BugTracker.notify(error, 'PeriodTrackerWidget - updatePeriod'),
    onCompleted: () =>
      setState({
        ...state,
        step: 'cycle_length'
      })
  });

  const [createPeriod, { loading: createPeriodLoading }] = useMutation(CREATE_PERIOD, {
    variables: {
      periodDate: state.editedLastPeriodDate
    },
    refetchQueries: [{ query: USER_PERIOD }, { query: PREGNANCY_CALCULATOR_QUERY }],
    onError: error => BugTracker.notify(error, 'PeriodTrackerWidget - createPeriod'),
    onCompleted: () =>
      setState({
        ...state,
        step: 'cycle_length'
      })
  });

  const [updateCycleLength, { loading: updateCycleLoading }] = useMutation(UPDATE_CYCLE_LENGTH, {
    variables: {
      daysBetweenCycles: state.editedDaysBetweenPeriod
    },
    refetchQueries: [{ query: USER_PERIOD }],
    onError: error => BugTracker.notify(error, 'PeriodTrackerWidget - updateCycleLength'),
    onCompleted: () =>
      setState({
        ...state,
        step: 'prediction'
      })
  });

  const [updateLmpTracker, { loading: updateLmpTrackerLoading }] = useMutation<
    UpdateLmpTrackerEnabledResponse,
    UpdateLmpTrackerEnabledArgs
  >(UPDATE_LMP_TRACKER_ENABLED, {
    variables: {
      isEnabled: false
    },
    refetchQueries: [{ query: LMP_TRACKER_QUERY }],
    onError: error => BugTracker.notify(error, 'Could not update the tracker')
  });

  const lmpTrackerEnabled = data?.patient?.lmpTrackerEnabled ?? false;

  useEffect(() => {
    if (isLoadingVital || !state.isInitialLoad) return;

    if (!lmpTrackerEnabled) {
      return setState({
        ...INITIAL_STATE,
        isInitialLoad: false
      });
    }

    // If we have a last period date AND cycle length AND nextPeriodDate, start user at the 'prediction' step
    //TODO: Do we need to add date logic here to check if we are past the nextPeriodDate or not? Or will the server always keep that up to date?
    const step = calculateNextStep(userVital);

    setState({
      ...state,
      editedDaysBetweenPeriod: userVital.daysBetweenCycles,
      editedLastPeriodDate: userVital.periodDate,
      step,
      isInitialLoad: false
    });
  }, [isLoadingVital, state, userVital, lmpTrackerEnabled]);

  const calculateNextStep = (userVital: Vital) => {
    let step: Step = 'start';
    if (userVital.periodDate) {
      step = 'cycle_length';
      // Check for both daysBetweenCycles& nextPeriodDate since daysBetweenCycle can exist without a nextPeriodDate
      // Unsure if that is a backend bug or old behavior ^

      const hasPrediction =
        userVital.daysBetweenCycles && userVital.daysBetweenCycles > 0 && userVital.nextPeriodDate;

      if (hasPrediction) step = 'prediction';
    }

    return step;
  };

  const isUpdating =
    isLoadingVital ||
    updatePeriodLoading ||
    updateCycleLoading ||
    createPeriodLoading ||
    updateLmpTrackerLoading;

  const startTracking = () => {
    if (!state.editedLastPeriodDate) return;

    if (userVital.id) {
      updatePeriod();
    } else {
      createPeriod();
    }
  };

  const goToLogPeriod = () => {
    setState({
      ...state,
      step: 'actual_start_date'
    });
  };

  const logNewPeriod = () => {
    if (!state.logNewPeriodDate) return;

    createPeriod({
      variables: {
        periodDate: state.logNewPeriodDate
      },
      onCompleted: () =>
        setState({
          ...state,
          editedLastPeriodDate: state.logNewPeriodDate,
          // Also render the modal that prompts them to book a new appointment
          showCompleteModal: true,
          step: 'prediction'
        })
    });
  };

  const getButtonProps = (): {
    title: string;
    action: () => void;
    description?: string;
  } => {
    switch (state.step) {
      case 'start':
        return {
          title: 'Start tracking',
          action: startTracking,
          description: 'Button to start the fertility calculator'
        };
      case 'cycle_length':
        return {
          title: 'Forecast next period',
          action: state.editedDaysBetweenPeriod ? updateCycleLength : () => {}
        };
      case 'prediction':
        return {
          title: 'Log your period',
          action: goToLogPeriod
        };
      case 'actual_start_date':
        return {
          title: 'Submit',
          action: logNewPeriod
        };
      default:
        return {
          title: '',
          action: () => {}
        };
    }
  };

  const renderCurrentState = () => {
    if (isUpdating) return <Loader height={50} width={50} container />;

    switch (state.step) {
      case 'start':
        return (
          <SessionContainer>
            <Text tag="p" size="lg">
              Enter the first date of your last period and your cycle length. Your cycle length is
              the number of days between periods. Your team will use this information to understand
              your menstrual cycles.
            </Text>

            <DateField
              id="period-date"
              label="Last Period Start Date"
              placeholder="Select date..."
              value={state.editedLastPeriodDate ? new Date(state.editedLastPeriodDate) : null}
              labelBg={theme.colors.semantic.lightPink}
              onChange={date => {
                setState({
                  ...state,
                  editedLastPeriodDate: date && dateToISOFormat(date)
                });
              }}
              hideHelperText
              blockFutureDays
            />
          </SessionContainer>
        );
      case 'cycle_length':
        return (
          <SessionContainer>
            <Text tag="p" size="lg">
              Enter your typical cycle length to forecast your next period
            </Text>
            <TextInput
              type="number"
              placeholder="Enter"
              hideArrowsForNumberInput
              value={state.editedDaysBetweenPeriod?.toString()}
              postfix={
                <Text tag="span" size="lg">
                  days
                </Text>
              }
              onChange={e => {
                setState({
                  ...state,
                  editedDaysBetweenPeriod: parseInt(e.currentTarget.value)
                });
              }}
            />
          </SessionContainer>
        );
      case 'prediction':
        return (
          <PredictedBox>
            <Heading tag="div" styledAs="h5" noMargin>
              Predicted Next Period
            </Heading>

            <Heading tag="div" styledAs="h2" noMargin>
              {userVital.nextPeriodDate && formatDate(userVital.nextPeriodDate, 'MMM Do')}
            </Heading>

            <Text size="xs" fontStyle="lightItalic">
              This is intended as an estimate only, and the actual date could be higher or lower.
            </Text>
          </PredictedBox>
        );
      case 'actual_start_date':
        return (
          <SessionContainer>
            <Text tag="p" size="lg">
              Enter the first date of your last period and your cycle length. Your cycle length is
              the number of days between periods. Your team will use this information to understand
              your menstrual cycles.
            </Text>

            <DateField
              id="actual-start-date"
              label="Actual start date"
              placeholder="Select date..."
              value={state.logNewPeriodDate ? new Date(state.logNewPeriodDate) : null}
              labelBg={theme.colors.semantic.lightPink}
              onChange={date => {
                setState({
                  ...state,
                  logNewPeriodDate: date && dateToISOFormat(date)
                });
              }}
              hideHelperText
              blockFutureDays
            />
          </SessionContainer>
        );
      default:
        return null;
    }
  };

  const renderLastPeriod = () => {
    if (!state.editedLastPeriodDate || state.step !== 'prediction') return null;

    return (
      <ValuesSelected
        label="Last Period"
        value={formatDate(state.editedLastPeriodDate, 'MMMM Do')}
        onClick={() => setState({ ...state, step: 'start' })}
      />
    );
  };

  const renderCycleLength = () => {
    if (!state.editedLastPeriodDate || state.step !== 'prediction') return null;

    return (
      <ValuesSelected
        label="Cycle Length"
        value={`${state.editedDaysBetweenPeriod?.toString()} days` || ''}
        onClick={() =>
          setState({
            ...state,
            step: 'cycle_length'
          })
        }
      />
    );
  };

  const buttonProps = getButtonProps();

  if (!patientIsFemale) return null;

  return (
    <Container $variant={10} $bgColor={theme.colors.semantic.lightPink}>
      <IconContainer $iconWidth={48} $iconHeight={48} $color={themes.colors.semantic.red}>
        <Droplet type="solid" />
      </IconContainer>

      <WidgetTitleContainer>
        <Text>
          Period
          <br />
          Tracker
        </Text>
      </WidgetTitleContainer>

      {renderLastPeriod()}
      {renderCycleLength()}
      {renderCurrentState()}
      <ButtonsContainer>
        <Button
          label={buttonProps.title}
          category="primary-dark"
          size="lg"
          onClick={buttonProps.action}
          fullWidth
        />

        <Button
          label="Cancel Tracker"
          category="danger"
          size="lg"
          onClick={() => {
            setState({
              ...INITIAL_STATE,
              isInitialLoad: false
            });
            updateLmpTracker();
          }}
          fullWidth
        />
      </ButtonsContainer>
      <FinishModal
        visible={state.showCompleteModal}
        dismiss={() => {
          setState({
            ...state,
            showCompleteModal: false
          });
        }}
      />
    </Container>
  );
};
