import React from 'react';
import { useHistory } from 'react-router-dom';

import { ProfileImageUploadButton } from 'components/PhotoUpload/ProfilePhoto/ProfileImageUploadButton';
import { Button } from 'components/v2/Buttons/Button';
import { Heading } from 'components/v2/Typography';
import { pageUrl } from 'utilities/pageUrl';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { BookingButton, Container, Greeting, GreetingLine, ProfileInfo } from './Header.styled';
import { Props } from './Header.types';

export const Header = ({ name, hasMembershipDocument, documentUrl }: Props) => {
  const { isMobile, isDesktop } = useBreakpoints();
  const history = useHistory();

  const greetingLines = [];
  if (isMobile) {
    greetingLines.push('Hello,');
    greetingLines.push(name);
  } else {
    greetingLines.push(`Hello, ${name}`);
  }

  return (
    <Container>
      <ProfileInfo>
        <ProfileImageUploadButton
          id="dashboard-profile-image-upload"
          mode="placeholder"
          size={isDesktop ? 64 : 40}
        />
        <Greeting>
          {greetingLines.map((line, index) => {
            return (
              <GreetingLine key={index}>
                <Heading tag="h1" noMargin>
                  {line}
                </Heading>
              </GreetingLine>
            );
          })}
        </Greeting>
      </ProfileInfo>

      <div>
        {hasMembershipDocument && (
          <Button
            category="secondary"
            label="View Member Benefit"
            onClick={() => window.open(documentUrl, '_blank', 'noreferrer noopener')}
            size={isMobile ? 'sm' : 'md'}
          />
        )}
        <BookingButton
          category="primary"
          label="Schedule Appointment"
          onClick={() => history.push(pageUrl.booking.bookingQuiz())}
          size={isMobile ? 'sm' : 'md'}
        />
      </div>
    </Container>
  );
};
