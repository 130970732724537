import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  position: relative;
  width: 280px;
  box-shadow: 0 2px 8px 0 #ababab;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px 20px 20px;
`;

export const IconContainer = styled.button`
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const Title = styled.div`
  ${({ theme }) => theme.fonts.Pitch};
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  text-align: left;
  min-height: 44px;
  max-width: 70%;
`;

export const Text = styled.div`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  font-size: 14px;
  font-weight: lighter;
  line-height: 20px;
  padding: 20px 0px 20px 0px;
`;
