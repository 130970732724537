import React from 'react';

import { Text } from 'components/v2/Typography';
import { TableHead } from 'screens/Invoices/Invoices.styled';
import Invoice from 'screens/Invoices/types/invoice';

import { InvoicesTableRow } from '../InvoicesTableRow/InvoicesTableRow';
import { TableBody, TableComponent } from './InvoicesTable.styled';

const TABLE_HEADERS = [
  { label: 'Date' },
  { label: 'Invoice No.' },
  { label: 'Service' },
  { label: 'Balance', alignRight: true }
];

export const InvoicesTable = ({
  invoices,
  totalBalance,
  isViosPatient,
  onPayInvoice,
  onViewInvoice,
  onPayWithCredit
}: {
  invoices: Invoice[];
  totalBalance: number;
  isViosPatient: boolean;
  onPayInvoice: (invoice: Invoice) => void;
  onViewInvoice: (invoice: Invoice) => void;
  onPayWithCredit: (invoice: Invoice) => void;
}) => {
  return (
    <TableComponent>
      <thead>
        <tr>
          {TABLE_HEADERS.map(header => (
            <TableHead key={header.label} $alignRight={header.alignRight}>
              <Text tag="span" fontStyle="medium">
                {header.label}
              </Text>
            </TableHead>
          ))}
        </tr>
      </thead>

      {
        <TableBody>
          {invoices.map((invoice, index) => (
            <InvoicesTableRow
              key={invoice.id}
              invoice={invoice}
              totalBalance={totalBalance}
              $alternateBackground={index % 2 === 1}
              onPayClick={() => onPayInvoice(invoice)}
              isViosPatient={isViosPatient}
              onViewInvoice={() => onViewInvoice(invoice)}
              onPayWithCredit={() => onPayWithCredit(invoice)}
            />
          ))}
        </TableBody>
      }
    </TableComponent>
  );
};
