import {
  fontCSS,
  filledOutlineShadow,
  defaultState,
  focusedState,
  errorState,
  disabledState,
  readOnlyState
} from 'components/v2/common.styled';
import styled, { css } from 'styled-components';

import { Status } from '../../types';

export const inputBaseCSS = css`
  ${fontCSS}
  outline: none;
  line-height: 24px;
  padding: 20px 24px;
  border: 0;
  color: ${defaultState.textColor};
  width: 100%;
  border-radius: ${defaultState.borderRadius};
  background-color: ${defaultState.backgroundColor};
  border-style: ${defaultState.borderStyle};
  border-color: ${defaultState.borderColor};
  border-width: ${defaultState.borderWidth};
  box-shadow: ${defaultState.boxShadow};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ::placeholder {
    color: ${defaultState.placeholderColor};
  }
`;

export const inputStatusStyle: Record<Status, ReturnType<typeof css>> = {
  default: css`
    &:focus {
      box-shadow: ${focusedState.boxShadow};
    }
  `,
  error: css`
    color: ${errorState.textColor};
    box-shadow: ${errorState.boxShadow};

    ::placeholder {
      color: ${errorState.placeholderColor};
    }
  `,
  disabled: css`
    color: ${disabledState.textColor};
    background: ${disabledState.backgroundColor};

    cursor: ${disabledState.cursor};

    ::placeholder {
      color: ${disabledState.placeholderColor};
    }
  `,
  readOnly: css`
    color: ${readOnlyState.textColor};
    background: ${readOnlyState.backgroundColor};
    border-width: ${readOnlyState.borderWidth};
    border-style: ${readOnlyState.borderStyle};
    border-color: ${readOnlyState.borderColor};
    cursor: ${readOnlyState.cursor};

    ::placeholder {
      color: ${disabledState.placeholderColor};
    }
  `
};

export const TextInputStyled = styled.input<{
  status: Status;
  isFilled: boolean;
  leftIcon?: boolean;
  rightIcon?: boolean;
  backgroundColor?: string;
  secureTextEntry?: boolean;
  hideArrowsForNumberInput?: boolean;
}>`
  ${inputBaseCSS}
  ${({ isFilled }) =>
    isFilled &&
    css`
      box-shadow: ${filledOutlineShadow};
    `}
  ${({ status }) => inputStatusStyle[status]}

  ${({ leftIcon }) =>
    leftIcon &&
    css`
      padding: 20px 16px 20px 46px;
    `}

  ${({ rightIcon }) =>
    rightIcon &&
    css`
      padding: 20px 20px 20px 24px;
    `}

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background: ${backgroundColor};
    `}

  ${({ hideArrowsForNumberInput }) =>
    hideArrowsForNumberInput &&
    css`
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    `}
`;

export const Container = styled.div<{ fullWidth?: boolean }>`
  position: relative;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      flex: 1;
    `}
`;

export const LeftIcon = styled.div`
  position: absolute;
  top: 24px;
  left: 16px;

  svg {
    fill: ${({ theme }) => theme.colors.neutral.primaryNavy};
  }
`;

export const RightIcon = styled.div`
  position: absolute;
  top: 24px;
  right: 16px;
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.colors.neutral.primaryNavy};
  }
`;
