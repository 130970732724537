import React, { useState } from 'react';

import { Answer } from 'kb-shared';

import AnimatedWrapper from '../AnimatedWrapper';
import FormButton from '../FormButton';
import Text from '../Primitives/FormText';
import { Container, ErrorMessage, TextInput, TextWrapper } from './FieldGroupHeightForm.styles';

type Props = {
  title: string;
  onSave: (response: string) => void;
  onContinue?: () => void;
  answer: Answer | null;
  isRequired: boolean | null;
};

const FieldGroupHeightForm = ({ title, answer, isRequired, onSave, onContinue }: Props) => {
  const getFeetFromAnswer = () => {
    if (!answer?.data) {
      return '';
    }

    return parseInt(answer.data.split(',')[0]);
  };

  const getInchesFromAnswer = () => {
    if (!answer?.data) {
      return '';
    }

    return parseInt(answer.data.split(',')[1]);
  };

  const [feet, setFeet] = useState(getFeetFromAnswer());
  const [inches, setInches] = useState(getInchesFromAnswer());
  const [errorMessage, setErrorMessage] = useState('');

  const renderContinueButton = () => {
    if (!onContinue) return null;

    return (
      <AnimatedWrapper>
        <FormButton text="Continue" onClick={() => onContinue && onContinue()} />
      </AnimatedWrapper>
    );
  };

  const onKeyPress = (e: { key: string }) => {
    if (e.key === 'Enter') {
      onSave(`${feet},${inches}`);
      onContinue && onContinue();
    }
  };

  const validate = (): boolean => {
    const MIN_FEET = 3;
    const MAX_FEET = 7;
    const MIN_INCHES = 0;
    const MAX_INCHES = 11;

    const isFeetValid = feet >= MIN_FEET && feet <= MAX_FEET;
    const isInchesValid = inches >= MIN_INCHES && inches <= MAX_INCHES;

    if (!(isFeetValid && isInchesValid)) {
      setErrorMessage('Please provide your height in Feet and Inches');
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const onBlur = () => {
    if (!validate()) {
      return;
    }

    onSave(`${feet},${inches}`);
  };

  return (
    <Container>
      <Text isRequired={isRequired}>{title}</Text>
      <TextWrapper>
        <TextInput
          type="number"
          pattern="[0-9]"
          value={feet}
          placeholder="eg. 4"
          onKeyPress={onKeyPress.bind(this)}
          onChange={e => {
            const feet = parseInt(e.target.value);
            setFeet(feet);
          }}
          onBlur={onBlur.bind(this)}
        />
        <TextInput
          type="number"
          pattern="[0-9]"
          value={inches}
          placeholder="eg. 5"
          onKeyPress={onKeyPress.bind(this)}
          onChange={e => {
            const inches = parseInt(e.target.value);
            setInches(inches);
          }}
          onBlur={onBlur.bind(this)}
        />
      </TextWrapper>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {renderContinueButton()}
    </Container>
  );
};

export default FieldGroupHeightForm;
