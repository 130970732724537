import React from 'react';

import { useContactInfo } from 'hooks';

import { BoldText, Contact } from './AdditionalInfo.styled';
import { PanelProps } from './AdditionalInfo.types';
import MarketContactInfo from './MarketContactInfo';

const AdditionalInfo = (props: PanelProps) => {
  const { rescheduleNumber } = useContactInfo();
  const showRescheduleContact = props.rescheduleModal;

  return (
    <>
      <BoldText>
        Do you need more immediate scheduling or need help booking with a specific provider?
      </BoldText>
      <br />{' '}
      {showRescheduleContact && rescheduleNumber ? (
        <>
          <MarketContactInfo rescheduleNumber={rescheduleNumber} capitalize={true} />.
        </>
      ) : (
        <>
          <Contact href="https://kindbody.com/assessment-booking-request">
            Contact our patient navigators
          </Contact>{' '}
          or call <Contact href={`tel://855-563-2639`}>855-563-2639</Contact>
        </>
      )}
    </>
  );
};

export default AdditionalInfo;
