import React, { ReactNode } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { ReactComponent as ResultsArrowDown } from '../../assets/icons/results_arrow.svg';
import { SectionTitle } from './styled';

const AMHLevelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const AMHLevelValue = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-size: 48px;
  color: white;
`;

const AMHLevelUnits = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-size: 16px;
  color: white;
  padding-bottom: 8px;
  padding-left: 4px;
`;

const AMHExplainerContainer = styled.div`
  background-color: white;
  padding-top: 0px;
  padding-bottom: 28px;
  padding-left: 20px;
  padding-right: 20px;
`;

const AMHExplainerText = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-size: 14px;
  color: ${themes.colors.neutral.primaryNavy};
`;

const AMHDisclaimerText = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-size: 12px;
  color: ${themes.deprecatedColors.gray};
  margin-top: 28px;
  margin-bottom: 8px;
`;

//-1 top to close the small gap between container and bg
const ArrowWrapper = styled.div`
  position: relative;
  top: -1px;
`;

type Props = {
  title: string;
  result: { value: string; units: string };
  detailsText: string;
  disclaimer?: (string | null) | ReactNode;
};

export default class LabResultCard extends React.Component<Props> {
  render() {
    const { title, result, detailsText, disclaimer } = this.props;

    return (
      <div>
        <SectionTitle>{title}</SectionTitle>
        <AMHLevelContainer>
          <React.Fragment>
            <AMHLevelValue>{result.value ? result.value : 'Unknown'}</AMHLevelValue>
            <AMHLevelUnits>{result.units}</AMHLevelUnits>
          </React.Fragment>
        </AMHLevelContainer>
        <AMHExplainerContainer>
          <ArrowWrapper>
            <ResultsArrowDown />
          </ArrowWrapper>
          <AMHExplainerText>{detailsText}</AMHExplainerText>
        </AMHExplainerContainer>
        <AMHDisclaimerText>{disclaimer}</AMHDisclaimerText>
      </div>
    );
  }
}
