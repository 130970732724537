import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Book } from 'screens/Book/Book';

type Props = RouteComponentProps & {
  skipToSignUp?: boolean;
};

export const SignUp = (props: Props) => {
  return <Book {...props} />;
};
