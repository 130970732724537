import { Heading } from 'components/v2/Typography';
import styled from 'styled-components';

// Steps
// Utils
// Types
export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: content-box;
`;

export const DashboardButton = styled.a`
  margin: 36px auto;
  background: white;
  display: flex;
  cursor: pointer;
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  text-decoration: underline;
  justify-content: center;
  align-items: center;
  text-align: center;
  :hover {
    font-weight: bold;
  }
  font-size: 16px;
`;

export const BackButtonContainer = styled.div`
  margin-top: 85px;
  display: flex;
  justify-content: center;
  width: 100%;

  > button {
    max-width: 400px;
  }
`;

export const StepHeadingContainer = styled.div`
  padding: 20px 0px;
`;

export const StepHeading = styled(Heading).attrs({ tag: 'h2', styledAs: 'h2' })`
  justify-content: center;
`;
