import { themes } from 'kb-shared';
import styled from 'styled-components';

export const AdditionalInfoPanel = styled.div`
  max-width: 600px;
  background-color: ${themes.colors.yellow.lightestYellow};

  ${themes.fonts.FoundersGrotesk};
  font-size: 14px;
  color: ${themes.colors.neutral.primaryNavy};
  padding: 10px;

  strong {
    font-size: 16px;
  }

  a {
    text-decoration: underline;
  }

  @media screen and (max-width: ${themes.breakpoints.tabletPortrait}) {
    max-width: 340px;
  }
`;
