import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PrimaryButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const CreditCartNumber = styled.div`
  ${({ theme }) => theme.fonts.Gotham}
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  background: ${({ theme }) => theme.colors.white};
  text-align: left;
  word-spacing: 12px;
`;

const BaseText = styled.span`
  ${({ theme }) => theme.fonts.Gotham}
  font-weight: 400;
`;

export const ValidLabel = styled(BaseText)`
  color: ${({ theme }) => theme.colors.neutral.lightNavy};
  background: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  white-space: pre-line;
`;

export const ValidValue = styled(BaseText)`
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  background: ${({ theme }) => theme.colors.white};
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`;

export const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  gap: 8px;
`;

interface CreditCardBackgroundAndBorderProps {
  isDefault: boolean;
}

export const CreditCardBackgroundAndBorder = styled.div<CreditCardBackgroundAndBorderProps>`
  width: 316px;
  height: 204px;
  flex-direction: column;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  ${({ isDefault, theme }) => {
    if (isDefault) {
      return `
        border: 1px solid ${theme.colors.yellow.primary};
        box-shadow: 0px 0.5px 0.6000000238418579px -0.25px #F1CB0A8F,
                    0px 0px 0px 4px #F1CB0A52;
      `;
    } else {
      return `
        border: 1px solid ${theme.colors.neutral.lavenderWhite};
        box-shadow: 0px 1px 3px 0px #43479229,
                    0px 1px 1px 0px #0a0d3d14;
      `;
    }
  }}
`;
