import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

import { StripeContext } from 'components/CreditCardForm/types/creditCardForm';
import { handlePaymentError } from 'utilities/errors';

import {
  PayInvoiceWithCreditCardMutationVariables,
  PayInvoiceWithStripeIdentifierMutationVariables
} from '../types/invoice';
import { INVOICES_QUERY } from './use-invoices';
import { TOTAL_BALANCE_QUERY } from './use-total-balance';

const PAY_INVOICE_MUTATION = gql`
  mutation payInvoice(
    $invoiceIdentifier: String!
    $stripeTokenId: String
    $kbStripeCardStripeIdentifier: String
  ) {
    payInvoice(
      invoiceIdentifier: $invoiceIdentifier
      stripeTokenId: $stripeTokenId
      kbStripeCardStripeIdentifier: $kbStripeCardStripeIdentifier
    ) {
      succeeded
    }
  }
`;

const createStripeTokenId = async (
  stripe: StripeContext['stripe'],
  cardNumberElement: StripeContext['cardNumberElement']
) => {
  const tokenResponse = await stripe.createToken(cardNumberElement);
  if (tokenResponse.error?.message) {
    throw new Error(tokenResponse.error.message);
  }

  return tokenResponse.token?.id ?? '';
};

const usePayInvoiceWithCreditCard = () => {
  const [payInvoiceMutation] = useMutation<any, PayInvoiceWithCreditCardMutationVariables>(
    PAY_INVOICE_MUTATION,
    {
      refetchQueries: [{ query: INVOICES_QUERY }, { query: TOTAL_BALANCE_QUERY }]
    }
  );

  return async (
    params: {
      stripe: StripeContext['stripe'];
      cardNumberElement: StripeContext['cardNumberElement'];
      invoiceIdentifier: string;
    },
    onSuccess: () => void,
    onError: (errorMessage: string) => void
  ) => {
    try {
      const stripeTokenId = await createStripeTokenId(params.stripe, params.cardNumberElement);

      await payInvoiceMutation({
        variables: { invoiceIdentifier: params.invoiceIdentifier, stripeTokenId }
      });
      onSuccess();
    } catch (error) {
      handlePaymentError(error, onError);
    }
  };
};

const usePayInvoiceWithStripeIdentifier = () => {
  const [payInvoiceMutation] = useMutation<any, PayInvoiceWithStripeIdentifierMutationVariables>(
    PAY_INVOICE_MUTATION,
    {
      refetchQueries: [{ query: INVOICES_QUERY }, { query: TOTAL_BALANCE_QUERY }]
    }
  );

  return async (
    params: {
      invoiceIdentifier: string;
      kbStripeCardStripeIdentifier: string;
    },
    onSuccess: () => void,
    onError: (errorMessage: string) => void
  ) => {
    try {
      await payInvoiceMutation({
        variables: {
          invoiceIdentifier: params.invoiceIdentifier,
          kbStripeCardStripeIdentifier: params.kbStripeCardStripeIdentifier
        }
      });
      onSuccess();
    } catch (error) {
      handlePaymentError(error, onError);
    }
  };
};

const usePayInvoice = (): UsePayInvoiceResponse => {
  const payInvoiceWithCreditCard = usePayInvoiceWithCreditCard();
  const payInvoiceWithStripeIdentifier = usePayInvoiceWithStripeIdentifier();

  return { payInvoiceWithCreditCard, payInvoiceWithStripeIdentifier };
};

export default usePayInvoice;

interface UsePayInvoiceResponse {
  payInvoiceWithCreditCard: (
    params: {
      stripe: StripeContext['stripe'];
      cardNumberElement: StripeContext['cardNumberElement'];
      invoiceIdentifier: string;
    },
    onSuccess: () => void,
    onError: (errorMessage: string) => void
  ) => Promise<void>;
  payInvoiceWithStripeIdentifier: (
    params: {
      kbStripeCardStripeIdentifier: string;
      invoiceIdentifier: string;
    },
    onSuccess: () => void,
    onError: (errorMessage: string) => void
  ) => Promise<void>;
}
