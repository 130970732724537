import moment from 'moment';
import React from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { DailyResult } from '../Results.types';
import CustomSlider from './Slider';

const Container = styled.div`
  background-color: ${themes.colors.neutral.navy};
  padding: 24px;
`;

const Title = styled.div`
  ${themes.fonts.DomaineDisplay};
  font-size: 28px;
  line-height: 1.22;
  letter-spacing: -0.3px;
  color: ${themes.colors.yellow.primary};
`;

const Subtitle = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  color: white;
  padding-right: 40px;
`;

const SubtitleLegendContainer = styled.div`
  display: flex;
  padding: 20px 0px;
`;

type Props = {
  results: Array<DailyResult>;
  // Array of numbers where each number is the index of result that has labs for that day
  resultIndicesWithLabs: Array<number>;
  currentDayIndex: number;
  onValueChange: (value: number) => void;
};

type State = {};

export default class GraphDetails extends React.Component<Props, State> {
  render() {
    const { currentDayIndex, results } = this.props;

    const currentDayDate = moment(results[currentDayIndex].date).format('dddd, MMMM Do');

    return (
      <Container>
        <Title>{currentDayDate}</Title>
        <SubtitleLegendContainer>
          <Subtitle>Adjust the slider left-right to view details for a specific date</Subtitle>
        </SubtitleLegendContainer>
        <CustomSlider
          validSteps={this.props.resultIndicesWithLabs}
          maxValue={results.length - 1}
          onValueChange={this.props.onValueChange}
          currentValue={currentDayIndex}
        />
      </Container>
    );
  }
}
