import React from 'react';

import { Heading } from 'components/v2/Typography';

import { Container } from './UpcomingAppointments.styled';

export const UpcomingAppointments = () => {
  return (
    <Container>
      <Heading tag="div" styledAs="h3">
        Upcoming Appointments
      </Heading>
      {/* <List appointments={futureAppointments} /> */}
    </Container>
  );
};
