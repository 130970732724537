import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import { ReduxState } from 'kb-redux';
import { PATIENT } from 'kb-shared/graphql/queries';

import { PatientResponse } from './usePatient.types';

const usePatient = () => {
  const isLoggedIn = useSelector<ReduxState, boolean | undefined>(
    state => state.patient.isLoggedIn
  );

  const { data, loading, error, refetch } = useQuery<PatientResponse>(PATIENT, {
    skip: !isLoggedIn
  });

  return { patient: data?.patient, loading, error, refetch, isLoggedIn };
};

export { usePatient };
export default usePatient;
