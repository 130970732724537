import styled from 'styled-components';

export const TermsTextContainer = styled.div`
  margin-bottom: 24px;
  margin-left: 48px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

export const TermsText = styled.span`
  ${({ theme }) => theme.fonts.Gotham};
  font-size: 16px;
  color: ${({ theme }) => theme.colors.neutral.darkestNavy};
`;

export const TermsLink = styled.span`
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;
