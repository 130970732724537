import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
`;

export const TitleText = styled.span`
  ${themes.fonts.DomaineDisplay};
  font-size: 28px;
  color: ${themes.colors.neutral.primaryNavy};
  text-align: center;
`;

export const SubtitleText = styled.span`
  ${themes.fonts.FoundersGrotesk};
  font-size: 16px;
  color: ${themes.colors.neutral.primaryNavy};
`;

export const QuestionsContainer = styled.div`
  padding-top: 28px;
`;

export const OptionContainer = styled.div`
  margin: 18px 0px;
`;

export const AllSetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 20px 0 20px;
`;

export const FinishedSubtitle = styled.span`
  ${themes.fonts.FoundersGrotesk}
  font-size: 20px;
  color: ${themes.colors.neutral.primaryNavy};
  text-align: center;
  padding-top: 8px;
  padding-bottom: 28px;
`;

export const Link = styled.a`
  ${themes.fonts.FoundersGrotesk};
  font-size: 20px;
  color: ${themes.colors.neutral.primaryNavy};
  text-align: center;
  font-weight: bold;
`;
