import { escapeRegex } from 'components/v2/Inputs/CountrySelect';

import { CATEGORIES_INFO, DEFAULT_CATEGORIES_INFO } from './Messages.constants';
import { Conversation, ConversationStatus } from './Messages.type';

export const isNewConversation = (status?: ConversationStatus) => status === undefined;
export const isOpenConversation = (status?: ConversationStatus) => status === 'open';
export const isResolvedConversation = (status?: ConversationStatus) => status === 'resolved';
export const isClosedConversation = (status?: ConversationStatus) => status === 'closed';

export const getCategoryInfo = (categoryName: string) =>
  CATEGORIES_INFO.find(item => item.slug === categoryName) ?? DEFAULT_CATEGORIES_INFO;

export const filterConversation = (conversations: Conversation[], term: string) => {
  const escapedSearchTerm = escapeRegex(term);
  const searchTermRegex = new RegExp(`${escapedSearchTerm}`, 'i');

  return conversations.filter(conversation => {
    const category = getCategoryInfo(conversation.category);

    const hasMatchingSenderName = conversation.messages?.some(message => {
      return searchTermRegex.test(message.senderName);
    });

    return (
      searchTermRegex.test(category.title) ||
      searchTermRegex.test(conversation.subject) ||
      searchTermRegex.test(conversation.assignedToUserName || '') ||
      hasMatchingSenderName
    );
  });
};

export const getFirstName = (name?: string): string | null => {
  if (!name) return null;

  const [firstName] = name.trim().split(' ');
  return firstName;
};
