import React from 'react';

import { ReactComponent as VideoSvg } from 'assets/icons/video.svg';
import { Button } from 'components/v2/Buttons/Button';
import { themes } from 'kb-shared';
import styled from 'styled-components';

import { OutlineButton as OutlineButtonBase } from '../../../components/Buttons';
const { fonts, colors } = themes;

export const Title = styled.p`
  ${fonts.DomaineDisplay};
  font-size: 24px;
  margin: 0 0 8px 0;
  display: flex;
  align-items: center;
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    flex-direction: row;
  }
`;

export const Subheader = styled.p`
  margin-bottom: 0;

  :first-of-type {
    margin: 0;
  }
`;

export const Description = styled.p`
  margin: 0;
  margin-bottom: 28px;
`;

export const CheckIcon = styled.img`
  margin-left: 5px;
`;

export const OutlineButton = styled(OutlineButtonBase)`
  white-space: nowrap;

  :hover,
  :focus,
  :hover a,
  :focus a {
    background-color: ${({ theme }) => theme.colors.neutral.primaryNavy};
    color: white;
    path {
      fill: white;
    }
  }
`;

export const Link = styled.a`
  font-weight: bold;
  color: ${colors.neutral.primaryNavy};
`;

export const VideoButton = styled(Button).attrs({
  category: 'secondary',
  size: 'sm',
  leftIcon: <VideoSvg />
})``;

export const ButtonList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 28px;
`;
