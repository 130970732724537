import { LoadedEntities, StepType } from 'kb-shared/booking/types/types';
import { getAppointmentType } from 'kb-shared/booking/utils/getters';
import { AppointmentType, PartnerClinicDetails } from 'kb-shared/types';

import { stepOrder } from './BookV2.constants';

export const hasEmployer = (selectedProduct?: LoadedEntities['selectedProduct']) =>
  // @ts-ignore
  Boolean(selectedProduct?.employer);

export const getSteps = ({
  currentStep,
  completedSteps
}: {
  currentStep: Exclude<StepType, 'success'>;
  completedSteps: Array<StepType>;
}) => {
  const steps = completedSteps.includes('insurance')
    ? stepOrder.filter(step => step !== 'insurance')
    : stepOrder;

  const currentIndex = Math.max(
    // treat "time" as "location" for the purpose of step numbering
    steps.indexOf(currentStep === 'time' ? 'location' : currentStep),
    0
  );

  return {
    // start counting from 1 + 1st step is booking quiz which is already completed
    currentStepNumber: 2 + currentIndex,
    totalSteps: 1 + steps.length
  };
};

const getLocationStepTitle = ({
  appointment,
  partnerClinicSearch,
  partnerClinicSelected
}: {
  appointment: AppointmentType | null;
  partnerClinicSearch: string | null;
  partnerClinicSelected: PartnerClinicDetails | null;
}) => {
  if (partnerClinicSearch === 'search' && !partnerClinicSelected) {
    return 'Find a Partner Clinic';
  } else if (partnerClinicSelected) {
    return '';
  } else if (appointment?.virtual) {
    // this doesn't seem to be used
    // return 'Choose a time for your virtual consult';
    return '';
  } else {
    return 'Great! Where would you like to meet us?';
  }
};

export const getPageTitle = ({
  stepType,
  selectedProduct,
  partnerClinicSearch,
  partnerClinicSelected
}: {
  stepType: StepType;
  selectedProduct: LoadedEntities['selectedProduct'] | null;
  partnerClinicSearch: string | null;
  partnerClinicSelected: PartnerClinicDetails | null;
}) => {
  const appointment = (selectedProduct && getAppointmentType(selectedProduct)) || null;

  switch (stepType) {
    case 'location':
      return getLocationStepTitle({ appointment, partnerClinicSearch, partnerClinicSelected });
    case 'time':
      return '';
    case 'insurance':
      return 'Insurance Details';
    case 'confirm':
      if (selectedProduct && selectedProduct.type === 'enterprise_membership') {
        return 'Activate Membership';
      } else {
        return hasEmployer(selectedProduct ?? undefined)
          ? 'Activate Kindbody Membership'
          : 'Confirmation:';
      }
    case 'success':
      if (partnerClinicSelected) {
        return "We're working to schedule your appointment!";
      } else {
        return "You're all set!";
      }
    default:
      return '';
  }
};
