import InputWithMask from 'components/v2/Inputs/InputWithMask/InputWithMask';
import { themes } from 'kb-shared';
import styled, { css } from 'styled-components';

import { InputCSSProps, LabelProps, StripeContainerProps } from './NewTextInput.types';

export const InputCSS = css`
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  ${themes.fonts.DomaineDisplay};
  width: 100%;
  font-size: 24px;
  color: black;
  height: 40px;
  border: 0px;
  text-align: left;
  background-color: white;
  transition: all 400ms ease 0s;
  border-bottom: solid 1px #000000;
  ::placeholder {
    ${themes.fonts.DomaineDisplay};
    font-size: 24px;
    text-align: left;
    color: #c6c7ce;
  }
  :focus {
    outline: 0;
    border-bottom: solid 1px ${themes.colors.yellow.primary};
  }
  :disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

// On mobile, padding-bottom is magic number to make bottom border line match text input
export const MobileDateInput = styled.input`
  ${InputCSS};
  padding-bottom: 2px;
  font-size: 20px;
`;

export const Input = styled.input`
  ${InputCSS};
  padding-bottom: 2px;
`;

type ContainerInterface = {
  fullMode?: boolean;
};

export const Container = styled.div<ContainerInterface>`
  flex-direction: column;
  align-items: flex-start;
  max-width: ${({ fullMode }) => (fullMode ? '100%' : '340px')};
  height: 58px;
  max-height: 58px;
  padding-bottom: 12px;
  box-sizing: content-box;
`;

export const Label = styled.label`
  ${themes.fonts.FoundersGrotesk};
  background-color: white;
  font-size: 14px;
  transition: all 400ms ease 0s;
  ${({ visible, textTransform }: LabelProps) => `
    color: ${visible ? themes.colors.neutral.primaryNavy : 'transparent'};
    text-transform: ${textTransform ? textTransform : 'none'};
  `}
  @media screen and (max-width: 390px) {
    font-size: 13px; /* fix for 375-390px resolutions */
  }
`;

export const RequiredIndicatorLabel = styled.label`
  ${themes.fonts.FoundersGrotesk};
  font-size: 14px;
  transition: all 400ms ease 0s;
  color: ${themes.colors.semantic.darkRed};
  font-weight: 600;
  margin-left: 2px;
`;

export const StripeChildWrapper = styled.div`
  padding-top: 10px;
`;

export const StyledMaskInput = styled(InputWithMask)`
  ${InputCSS};
`;

export const StyledCalculatorInput = styled(Input)<InputCSSProps>`
  color: ${({ theme }) => theme.colors.white};
  border-bottom: ${({ error, theme }) =>
    `solid 1px ${error ? theme.colors.semantic.darkRed : theme.colors.white}`};
`;

export const StyledZipCodeInput = styled(StyledMaskInput)<InputCSSProps>`
  border-color: ${({ error }) => (error ? '#000000' : 'inherit')};
`;

/**
 * Stripe Specific Input Wrapper
 * On Stripe elements we can't use our TextInput
 * and are limited in what styles we can apply to their elements.
 */
export const StripeContainer = styled(Container)`
  padding-bottom: 5px;
  border-bottom: solid 1px black;
  ${(props: StripeContainerProps) => `width: ${props.width || 0}`};
`;

export const HelperText = styled.span`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #727587;
  padding: 16px 0;
`;
