import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

export const USER_AUTH_CHECK = gql`
  query userAuthCheck {
    patient {
      id
    }
  }
`;

// Tries to fetch patient and fails if patient is not logged in which case
// BE responds with 403(with Unauthorized error message) and we catch that and redirect in graphql client error link
export const useTestPatientAuth = () => useQuery(USER_AUTH_CHECK);
