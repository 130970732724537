import LoaderBase from 'react-loading';

import { YellowButton } from 'components/Buttons';
import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  padding: 40px 20px 0 20px;
  margin-top: 50px;
`;

export const Message = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.DomaineDisplay};
  font-size: 36px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    margin-bottom: 12px;
    font-size: 32px;
  }
`;

export const Button = styled(YellowButton)`
  padding: 8px 24px;
  font-size: 14px;
  text-transform: uppercase;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    height: 50px;
  }
`;

export const Loader = styled(LoaderBase).attrs(({ theme }) => ({
  type: 'spin',
  color: theme.colors.yellow.primary,
  height: 50,
  width: 50
}))`
  margin: 30px auto auto auto;
`;
