import { ApolloError } from '@apollo/client';

import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { getPatientFacingErrorMessageForPaymentError } from 'utilities/errors';

export function generateErrorString(err: ApolloError | null): string {
  if (!err?.message)
    return 'Please verify that you have correctly entered your credit card number, expiration date and security code.';

  const message = err.message.toLowerCase();
  const patientFacingMessage = getPatientFacingErrorMessageForPaymentError(message);

  if (patientFacingMessage) {
    return patientFacingMessage;
  } else {
    BugTracker.notify(err, 'Payment Processing Error');
    return "We're sorry, something went wrong. Please try again.";
  }
}
