import React, { useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';

import { Plus } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';
import { NullableString, themes } from 'kb-shared';
import { Crop } from 'utilities/imageCropper';

import {
  Description,
  RenderedImage,
  UploadImageContainer,
  Container,
  HiddenInput,
  DropContainer,
  ButtonsContainer,
  UploadContainer,
  ClickableUploadContainer
} from './FilePreviewAndUpload.styled';
import { FilePreviewAndUploadProps, SafeRenderImageProps } from './FilePreviewAndUpload.types';
import { getCropSettings } from './FilePreviewAndUpload.utils';
import { PreviewedFileDescription } from './PreviewedFileDescription';

export const FilePreviewAndUpload = (props: FilePreviewAndUploadProps) => {
  const {
    label,
    error,
    image,
    type,
    onFileChange,
    onFileDropped,
    onSubmit,
    onCancel,
    loading,
    acceptedExtensions,
    extensionsText,
    maxSizeText,
    allowCropping,
    onCropSettingsChange
  } = props;
  const [cropSettings, setCropSettings] = useState<Crop>(getCropSettings(image));

  const fileUploadRef = useRef<HTMLInputElement | null>(null);

  // resets file input value when label changes
  useEffect(() => {
    if (fileUploadRef.current) {
      fileUploadRef.current.value = '';
    }
  }, [label]);

  // triggers when file is dropped
  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (allowCropping && !e.dataTransfer.files[0].type?.startsWith('image/')) {
        return;
      }

      onFileDropped?.(e.dataTransfer.files);
    }
  };

  const imageRenderer = () => {
    if (!image) return null;

    if (!allowCropping) return <SafeRenderImage src={image} type={type} />;
    else if (!isImageType(type)) return noFilePreviewMessage;

    return (
      <ReactCrop
        crop={cropSettings}
        src={image}
        onChange={(_crop, percentCrop) => {
          setCropSettings(percentCrop as Crop);
          onCropSettingsChange?.(percentCrop as Crop);
        }}
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: '320px'
        }}
        keepSelection={true}
        disabled={false}
      />
    );
  };

  return (
    <Container>
      <UploadContainer>
        {!image && <Text>{label}</Text>}
        {error && (
          <Text color={themes.colors.semantic.red} fontStyle="bold">
            {error}
          </Text>
        )}
        <UploadImageContainer>
          {image ? (
            imageRenderer()
          ) : (
            <ClickableUploadContainer onClick={() => fileUploadRef.current?.click()}>
              <DropContainer onDrop={handleDrop} onDragOver={e => e.preventDefault()}>
                <Plus type="regular" />
                <Text>Upload Your Photo</Text>
              </DropContainer>
              <HiddenInput
                type="file"
                onChange={onFileChange}
                accept={acceptedExtensions || '.jpeg, .jpg, .png, .pdf'}
                ref={fileUploadRef}
              />
            </ClickableUploadContainer>
          )}
        </UploadImageContainer>
        {!image && <PreviewedFileDescription extensions={extensionsText} maxSize={maxSizeText} />}
      </UploadContainer>
      <ButtonsContainer>
        <Button onClick={onCancel} label="Cancel" category="secondary" fullWidth />
        <Button
          label="Submit"
          onClick={onSubmit}
          isDisabled={loading || !image}
          category="primary"
          fullWidth
        />
      </ButtonsContainer>
    </Container>
  );
};

const SafeRenderImage = ({ src, type }: SafeRenderImageProps) => {
  if (!isImageType(type)) return noFilePreviewMessage;

  return <RenderedImage src={src} />;
};

const isImageType = (type: NullableString) => type?.startsWith('image/');
const noFilePreviewMessage = (
  <Description>File selected. Preview is not available for this file type.</Description>
);
