import * as React from 'react';

import { ReactComponent as SecureLockIcon } from 'assets/icons/secure-lock.svg';
import styled from 'styled-components';

export const MembershipDetails = ({ hasEmployer }: { hasEmployer: boolean }) => {
  if (!hasEmployer) return null;

  return (
    <>
      <Title>
        Enter Your Access Code <SecureLockIcon />
      </Title>
      <Details>
        Your benefits provider has given you an access code in order to unlock your Kindbody
        membership. Please enter it here.
      </Details>
    </>
  );
};

const Title = styled.div`
  ${props => props.theme.fonts.FoundersGrotesk};
  font-size: 18px;
  font-weight: 600;
`;

const Details = styled.div`
  ${props => props.theme.fonts.FoundersGrotesk};
  font-size: 14px;
`;
