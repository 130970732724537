import React from 'react';

import { FallbackMessage } from './AppointmentTimeSlotsSelection.styles';

export const DEFAULT_FALLBACK = (
  <FallbackMessage>
    <h2>There are no available time slots for this appointment.</h2>
    <p>Please reach out for scheduling options.</p>
    <p>
      <a
        href="https://kindbody.com/assessment-booking-request/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Contact our patient navigators
      </a>{' '}
      or call <a href="tel://855-563-2639">855-563-2639</a>
    </p>
  </FallbackMessage>
);

export const DISNEY_FALLBACK = (
  <FallbackMessage>
    <h2>There are no available time slots for this appointment.</h2>
    <p>Please reach out for scheduling options.</p>
    <p>
      <b>Contact our patient navigator at</b> <a href="tel://407-934-4100">407-934-4100</a>
    </p>
  </FallbackMessage>
);
