import * as React from 'react';
import posed from 'react-pose';

import { themes } from 'kb-shared';
import { AbsoluteFill, FlexCenteredFill } from 'styled';
import styled from 'styled-components';

const { colors } = themes;

// Note: Make sure we're always on top and able
// to control the z index of content
const overlayZIndex = 1000;

const animationDuration = 600;

const Container = styled(AbsoluteFill)`
  z-index: ${overlayZIndex};
`;

const AnimatedOverlay = posed(AbsoluteFill)({
  show: { opacity: 0.6, transition: { duration: animationDuration } },
  close: { opacity: 0, transition: { duration: animationDuration } }
});

const Overlay = styled(AnimatedOverlay)`
  background-color: ${colors.yellow.lightestYellow};
`;

const spring = {
  type: 'spring',
  stiffness: 500,
  damping: 25,
  restDelta: 0.5,
  restSpeed: 10
};

const AnimatedContentContainer = posed.div({
  show: {
    y: '0%',
    transition: {
      duration: animationDuration,
      ...spring
    }
  },
  close: { y: '-1000px', transition: { duration: animationDuration } }
});

const ContentContainer = styled(AnimatedContentContainer)`
  align-items: center;
  display: flex;
  background-color: ${colors.white};
  box-shadow: 0 3px 10px 0 #d8d8d8;
  flex-direction: column;
  padding: 30px;
  max-width: 320px;
  z-index: ${overlayZIndex + 1};
`;

type Props = {
  children: React.ReactNode;
  onClose?: () => void;
};

type State = {
  show: string;
};

export default class PhotoUploadModal extends React.Component<Props, State> {
  state = { show: 'close' };

  componentDidMount() {
    this.setState({ show: 'show' });
    window.scrollTo(0, 0);
  }

  /*
    You'll want to create a React ref with this component to be able
    to dismiss it on some type of success criteria that you define at the
    point of use.
  */
  dismiss = () => {
    this.setState({ show: 'close' }, () => {
      const { onClose } = this.props;

      if (!onClose) return;
      setTimeout(onClose, animationDuration);
    });
  };

  render() {
    const { children } = this.props;
    const { show } = this.state;
    return (
      <Container>
        <Overlay onClick={this.dismiss} pose={show} />
        <FlexCenteredFill>
          <ContentContainer pose={show}>{children}</ContentContainer>
        </FlexCenteredFill>
      </Container>
    );
  }
}
