import { gql } from '@apollo/client';

import {
  Appointment,
  AppointmentType,
  Clinic,
  Lab,
  Membership,
  TimeSlot,
  graphql
} from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

const isFalsyNonNumber = (value: unknown) => !value && typeof value !== 'number';

const QUERY_APPOINTMENT_TYPE = gql`
  query appointmentType($appointmentId: Int!) {
    appointmentType(appointmentTypeId: $appointmentId) {
      id
      name
      virtual
      virtualFert
      description
      videoLink
      rateCents
      shouldChargeAtBooking
      channel
      minuteDuration
      hourDuration
      validateProviderLicense
      category {
        id
        name
      }
    }
  }
`;

export const loadAppointmentType = async (appointmentId?: number) => {
  if (isFalsyNonNumber(appointmentId)) {
    return;
  }

  try {
    const response = await graphql.client.query<{ appointmentType: AppointmentType }>({
      query: QUERY_APPOINTMENT_TYPE,
      variables: { appointmentId: appointmentId }
    });

    return response.data.appointmentType;
  } catch (error) {
    BugTracker.notify(error, 'Failed to load appointment type');
  }
};

const QUERY_APPOINTMENT_TYPES = gql`
  query appointmentTypes($appointmentTypeIds: [Int!]) {
    appointmentTypes(ids: $appointmentTypeIds) {
      id
      name
      virtual
      virtualFert
      description
      videoLink
      rateCents
      channel
      shouldChargeAtBooking
      category {
        id
        name
      }
    }
  }
`;

export const loadAppointmentTypes = async (appointmentTypeIds?: Array<number>) => {
  if (!appointmentTypeIds) {
    return;
  }

  try {
    const response = await graphql.client.query<{ appointmentTypes: Array<AppointmentType> }>({
      query: QUERY_APPOINTMENT_TYPES,
      variables: { appointmentTypeIds: appointmentTypeIds }
    });
    return response.data.appointmentTypes;
  } catch (error) {
    BugTracker.notify(error, 'Failed to load appointment types');
  }
};

const QUERY_LAB = gql`
  query lab($labId: Int!) {
    lab(labId: $labId) {
      id
      name
    }
  }
`;

export const loadLab = async (labId?: number) => {
  if (isFalsyNonNumber(labId)) {
    return;
  }

  try {
    const response = await graphql.client.query<{ lab: Lab }>({
      query: QUERY_LAB,
      variables: { labId: labId }
    });
    return response.data.lab;
  } catch (error) {
    BugTracker.notify(error, 'Failed to load lab');
  }
};

const QUERY_LABS = gql`
  query labs {
    labs {
      id
      name
    }
  }
`;

export const loadDefaultLab = async () => {
  try {
    const response = await graphql.client.query<{ labs: Array<Lab> }>({ query: QUERY_LABS });

    if (response.data.labs.length === 1) {
      return response.data.labs[0];
    }
  } catch (error) {
    BugTracker.notify(error, 'Failed to load labs');
  }
};

const QUERY_LOCATION = gql`
  query location($locationId: Int!) {
    location(locationId: $locationId) {
      id
      name
      address
      timeZone
      imageUrl
      patientFacing
    }
  }
`;

export const loadLocation = async (locationId?: number) => {
  if (isFalsyNonNumber(locationId)) {
    return;
  }

  try {
    const response = await graphql.client.query<{ location: Clinic }>({
      query: QUERY_LOCATION,
      variables: { locationId: locationId }
    });
    return response.data.location;
  } catch (error) {
    BugTracker.notify(error, 'Failed to load location');
  }
};

const QUERY_CLINICS = gql`
  query clinics($appointmentTypeId: Int!, $labId: Int!, $locationId: Int) {
    clinics: locations(
      appointmentTypeId: $appointmentTypeId
      labId: $labId
      locationId: $locationId
    ) {
      id
      name
      address
      timeZone
      imageUrl
      patientFacing
    }
  }
`;

export const loadClinics = async (appointmentTypeId: number, labId: number, locationId: number) => {
  const response = await graphql.client.query<{ clinics: Array<Clinic> }>({
    query: QUERY_CLINICS,
    variables: {
      labId: labId,
      appointmentTypeId: appointmentTypeId,
      locationId: locationId
    }
  });

  return response.data;
};

const QUERY_TIME_SLOT = gql`
  query timeSlot($timeSlotId: Int!) {
    timeslot(timeslotId: $timeSlotId) {
      id
      videoLink
      location {
        id
        name
        address
        timeZone
      }
      lab {
        id
      }
      startTime
      endTime
      timeZone
      licensedProviderName
    }
  }
`;

export const loadTimeSlot = async (timeSlotId?: number) => {
  if (isFalsyNonNumber(timeSlotId)) {
    return;
  }

  try {
    const response = await graphql.client.query<{ timeslot: TimeSlot }>({
      query: QUERY_TIME_SLOT,
      variables: { timeSlotId: timeSlotId }
    });
    return response.data.timeslot;
  } catch (error) {
    BugTracker.notify(error, 'Failed to load time slot');
  }
};

const QUERY_MEMBERSHIP = gql`
  query membership($membershipId: Int!) {
    membership(membershipId: $membershipId) {
      description
      id
      name
      employer
      rateCents
    }
  }
`;

export const loadMembership = async (membershipId?: number) => {
  if (isFalsyNonNumber(membershipId)) {
    return;
  }

  try {
    const response = await graphql.client.query<{ membership: Membership }>({
      query: QUERY_MEMBERSHIP,
      variables: { membershipId: membershipId }
    });
    return response.data.membership;
  } catch (error) {
    BugTracker.notify(error, 'Failed to load membership');
  }
};

const QUERY_BOOKED_APPOINTMENT = gql`
  query appointment($appointmentId: Int!) {
    appointment(appointmentId: $appointmentId) {
      id
      videoLink
      location {
        id
        address
        name
        patientFacing
        timeZone
      }
      startTime
      endTime
      timeZone
      cancelable
      reschedulable
      providerName
      intakeFormRequired
      appointmentType {
        id
        virtual
        virtualFert
        name
        description
        videoLink
        rateCents
        channel
        category {
          id
          name
        }
      }
    }
  }
`;

export const loadAppointment = async (appointmentId?: number) => {
  if (isFalsyNonNumber(appointmentId)) {
    return;
  }

  try {
    const response = await graphql.client.query<{ appointment: Appointment }>({
      query: QUERY_BOOKED_APPOINTMENT,
      variables: { appointmentId: appointmentId }
    });
    return response.data.appointment;
  } catch (error) {
    BugTracker.notify(error, 'Failed to load appointment');
  }
};
