import React, { Fragment } from 'react';

import styled from 'styled-components';

import Button from '../../Button/Button';
import { ModalLabel } from '../lib/styled';

interface CroppedImageProps {
  height?: number;
  width?: number;
  rounded?: boolean;
  group?: boolean;
}

const CroppedImage = styled.img`
  margin-bottom: 0;
  padding: 30px;
  object-fit: fill;
  ${(props: CroppedImageProps) => {
    const { height, width, rounded, group } = props;
    return `
      border-radius: ${rounded ? '50%' : 'none'};
      width: ${width}px;
      height: ${height}px;
      ${group &&
        `
        padding: 5px 30px;
        :first-of-type {
          padding-top: 30px;
        }
        :last-of-type {
          padding-bottom: 39px;
        }
      `}`;
  }}
`;

const SuccessHeader = styled.h2`
  ${({ theme }) => theme.typos.sectionHeader};
  font-size: 48px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  margin: 30px 0 0 0;
`;

type Props = {
  onClose: () => void;
  height?: number;
  images?: string[];
  label: string;
  rounded: boolean;
  width?: number;
};

const Success = (props: Props) => {
  const { label, images, onClose, height, width } = props;
  return (
    <Fragment>
      <SuccessHeader>Success!</SuccessHeader>
      <ModalLabel>{label}</ModalLabel>
      {images &&
        images.map(image => (
          <CroppedImage
            src={image}
            key={`success-image-${image}`}
            height={height}
            width={width}
            group={images.length > 1}
          />
        ))}
      <ButtonContainer>
        <Button onClick={onClose} label="Done" />
      </ButtonContainer>
    </Fragment>
  );
};

Success.defaultProps = {
  height: 200,
  rounded: true,
  width: 200
};

export default Success;
