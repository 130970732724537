import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as IntercourseHeartsIcon } from 'assets/icons/intercourse-hearts.svg';
import { Button } from 'components/v2/Buttons/Button';
import { Heading, Text } from 'components/v2/Typography';
import { formatDate } from 'utilities/formatDate';
import { pageUrl } from 'utilities/pageUrl';

import {
  Container,
  FlexContainer,
  HeartsIconContainer,
  IntercourseReminderContainer,
  SimpleFlex
} from './IntercourseReminder.styled';

export const IntercourseReminder = ({ date }: { date?: string }) => {
  const history = useHistory();

  return (
    <Container>
      <HeartsIconContainer>
        <IntercourseHeartsIcon />
      </HeartsIconContainer>
      <IntercourseReminderContainer>
        <FlexContainer>
          <SimpleFlex>
            {date && (
              <Text tag="p" size="sm">
                {formatDate(date, 'dddd, MMM Do')}
              </Text>
            )}
            <Heading tag="h2" noMargin>
              Intercourse reminder!
            </Heading>
            <Text tag="p">
              Today&apos;s the day! Make sure to have sex with your partner today.
            </Text>
          </SimpleFlex>
          <Button
            category="primary"
            label="My Journey"
            onClick={() => history.push(pageUrl.myJourney())}
            uppercase
          />
        </FlexContainer>
      </IntercourseReminderContainer>
    </Container>
  );
};
