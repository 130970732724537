import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 320px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 28px 0px 28px 0px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  ${themes.fonts.DomaineDisplay};
  font-size: 48px;
  padding: 12px 0px 12px 0px;
`;

export const Text = styled.div`
  ${themes.fonts.FoundersGrotesk};
  color: ${themes.colors.neutral.primaryNavy};
  font-size: 20px;
  text-align: center;
  padding: 0px 35px 28px 35px;
`;

export const BottomLink = styled.a`
  ${themes.fonts.Pitch};
  color: ${themes.colors.neutral.primaryNavy};
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
  padding: 20px 0px 0px 0px;
  :hover {
    font-weight: bold;
  }
`;
