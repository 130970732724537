import React from 'react';

import styled from 'styled-components';

interface SearchResultCountProps {
  total: number;
  matches: number;
}

export const SearchResultsCount = ({ matches, total }: SearchResultCountProps) => (
  <>
    <MatchesText>
      Displaying {matches} of {total} available services
    </MatchesText>
    <MatchesSeparator />
  </>
);

const MatchesText = styled.div`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  clear: both;
  height: 18px;
  margin-top: 18px;
  color: #353148;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`;

const MatchesSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.deprecatedColors.gray};
`;
