import _ from 'lodash';
import React from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { leftArrow, rightArrow } from '../../assets/icons';

const ArrowIcon = styled.img`
  width: 24px;
  height: 20px;
`;
interface PaginationProps {
  setPage: (newPage: number) => void;
  currentPage: number;
  numberOfPages: number;
}

interface ContainerProps {
  disabled?: boolean;
  color?: string;
  arrowType: 'prev' | 'next';
}

interface PageSelectionProps {
  selected?: boolean;
}

const LocationPaginationContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;

  & > * {
    margin-left: 3px;
    margin-right: 3px;
  }
`;

const PageSelection = styled.div`
  height: 30px;
  width: 20px;
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  font-weight: bold;
  text-align: center;
  margin-top: 5px;

  ${({ selected }: PageSelectionProps) => {
    if (selected)
      return `
        text-decoration:underline;
      `;
    else {
      return `
        cursor: pointer;
      `;
    }
  }}
`;

const ButtonContainer = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 35px;
  border-width: 0px;
  opacity: 1;
  transition: background-color 500ms;
  transition: border-color 500ms;
  :hover {
    opacity: 0.8;
  }
  :focus {
    outline: 0;
  }
  text-transform: uppercase;

  ${({ arrowType }: ContainerProps) => {
    if (arrowType === 'prev') {
      return `
        border-top-left-radius: 17.5px;
        border-bottom-left-radius: 17.5px;
      `;
    } else if (arrowType === 'next') {
      return `
        border-top-right-radius: 17.5px;
        border-bottom-right-radius: 17.5px;
      `;
    }
  }}

  ${({ disabled, color }: ContainerProps) => {
    if (disabled) {
      return `
        background-color:${themes.deprecatedColors.gray};
        &:hover {
          cursor: not-allowed;
          opacity: 1;
        }
      `;
    } else {
      return `
        background-color: ${color ? color : themes.colors.yellow.primary};
      `;
    }
  }}
`;

const Pagination = (props: PaginationProps) => {
  const { currentPage, numberOfPages, setPage } = props;

  return (
    <LocationPaginationContainer>
      <ButtonContainer
        disabled={currentPage === 0}
        arrowType="prev"
        onClick={() => {
          setPage(currentPage - 1);
        }}
      >
        <ArrowIcon src={leftArrow} />
      </ButtonContainer>
      {_.times(numberOfPages, idx => (
        <PageSelection
          key={idx}
          onClick={() => idx !== currentPage && setPage(idx)}
          selected={idx === currentPage}
        >
          {idx + 1}
        </PageSelection>
      ))}
      <ButtonContainer
        disabled={currentPage === numberOfPages - 1}
        arrowType="next"
        onClick={() => {
          setPage(currentPage + 1);
        }}
      >
        <ArrowIcon src={rightArrow} />
      </ButtonContainer>
    </LocationPaginationContainer>
  );
};

export default Pagination;
