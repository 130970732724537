import React from 'react';

import styled from 'styled-components';

interface Props {
  text: string;
  linkText: string;
  onClick: () => void;
}

const HelpTextButton = ({ text, linkText, onClick }: Props) => {
  return (
    <Main>
      {`${text} `}
      <Link onClick={onClick}>{linkText}</Link>
    </Main>
  );
};

export default HelpTextButton;

const Main = styled.p`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  text-align: center;
  margin-top: 5em;
`;

const Link = styled.span`
  font-weight: bold;
  text-decoration: underline;
  white-space: nowrap;
  cursor: pointer;
`;
