import { themes } from 'kb-shared';
import styled from 'styled-components';

interface Props {
  isRequired?: boolean | null;
}

const Text = styled.div<Props>`
  max-width: 700px;
  ${themes.fonts.DomaineDisplay};
  font-size: 28px;
  text-align: left;
  margin: 0px 0px 24px 0px;

  @media screen and (min-width: ${themes.breakpoints.largePhonePortrait}) {
    font-size: 48px;
  }

  &::after {
    content: "${props => (!!props.isRequired ? '*' : ' ')}";
    color: red;
  }
`;

export default Text;
