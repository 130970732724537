import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
`;

export const Button = styled.button`
  font-size: 14px;
  line-height: 20px;
  font-family: FoundersGroteskText;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const ListHolder = styled.div`
  margin: auto;
  padding-left: 21px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    width: 90%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    width: 70%;
  }
`;

export const List = styled.ol`
  text-align: left;
  list-style: decimal;
`;

export const Link = styled.a`
  text-decoration: underline;
`;
