import Honeybadger from '@honeybadger-io/js';
import jwt from 'jsonwebtoken';
import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';

import { ReduxState } from 'kb-redux';
import { AppleAuthError } from 'kb-shared';
import client, { isTokenExpired } from 'kb-shared/graphql/client';
import {
  SIGNUP_PATIENT,
  REGISTER_PATIENT_TO_EVENT,
  AUTHENTICATE_PATIENT,
  VERIFY_PATIENT_EMAIL,
  UPDATE_PATIENT_LAST_SIGN_IN,
  RESEND_EMAIL_VERIFICATION_CODE,
  INVALIDATE_PATIENT_AUTH_SESSION
} from 'kb-shared/graphql/mutations';
import { PATIENT } from 'kb-shared/graphql/queries';
import {
  Patient,
  LoginError,
  SignUpError,
  GoogleAuthError,
  ConfirmationError,
  ProfileImageError,
  InsuranceCardError,
  InsuranceCardSide,
  PatientState,
  SignUpProps,
  IDSide,
  IDError,
  AuthenticatePatientResponse,
  AuthenticatePatientRequestVariables
} from 'kb-shared/types';
import {
  waitUntilEpicSsoIdTokenIsSet,
  waitUntilMedtronicSsoIdTokenIsSet
} from 'kb-shared/utilities/api';
import { getIdToken, logout as apiLogout } from 'kb-shared/utilities/api';
import { signGoogleUserOut } from 'kb-shared/utilities/auth.google';
import { sanitizeUsername } from 'kb-shared/utilities/auth.helper';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { clearPwLocalStorage } from 'kb-shared/utilities/clear_pw_storage';
import { analytics } from 'utilities/analytics';
import { getGraphQLErrorsMessages, isPatientUnauthorizedError } from 'utilities/errors';
import { scrubPhoneNumber } from 'utilities/phoneNumber';
import { SIGNUP_TYPE } from 'utilities/userUtil.types';

import * as actions from './actions';

/* ---------- ACTIONS  ---------- */
const APP_LOADED = 'kindbody/user/APP_LOADED';
const SIGNUP = 'kindbody/user/SIGNUP';
const CLEAR_GOOGLE_AUTH_ERROR = 'kindbody/user/CLEAR_GOOGLE_AUTH_ERROR';
const SIGNUP_GOOGLE_USER = 'kindbody/user/SIGNUP_GOOGLE_USER';
const SIGNUP_SUCCESS = 'kindbody/user/SIGNUP_SUCCESS';
const SIGNUP_FAILED = 'kindbody/user/SIGNUP_FAILED';
const CONFIRM_USER = 'kindbody/user/CONFIRM_USER';
const CONFIRM_USER_SUCCESS = 'kindbody/user/CONFIRM_USER_SUCCESS';
const CONFIRM_USER_FAILED = 'kindbody/user/CONFIRM_USER_FAILED';
const SET_CONFIRMATION_STATE = 'kindbody/user/SET_CONFIRMATION_STATE';
const RESET_CONFIRMATION_STATE = 'kindbody/user/RESET_CONFIRMATION_STATE';
const RESEND_CONFIRMATION_CODE = 'kindbody/user/RESEND_CONFIRMATION_CODE';
const RESEND_CONFIRMATION_CODE_SUCCESS = 'kindbody/user/RESEND_CONFIRMATION_CODE_SUCCESS';
const RESEND_CONFIRMATION_CODE_FAILURE = 'kindbody/user/RESEND_CONFIRMATION_CODE_FAILURE';
const AUTH_WITH_GOOGLE = 'kindbody/user/AUTH_WITH_GOOGLE';
const AUTH_WITH_GOOGLE_FAILED = 'kindbody/user/AUTH_WITH_GOOGLE_FAILED';
const SIGNIN_APPLE_USER = 'kindbody/user/SIGNIN_APPLE_USER';
const SET_APPLE_AUTH_ERROR = 'kindbody/user/APPLE_AUTH_ERROR';
const LOGIN = 'kindbody/user/LOGIN';
const LOGIN_FAILED = 'kindbody/user/LOGIN_FAILED';
const LOGIN_SUCCESS = 'kindbody/user/LOGIN_SUCCESS';
const LOGIN_ERROR_HANDLED = 'kindbody/user/LOGIN_ERROR_HANDLED';
export const LOG_OUT = 'kindbody/user/LOGOUT';
const UPDATE_PROFILE_IMAGE = 'kindbody/user/UPDATE_PROFILE_IMAGE';
const PROFILE_IMAGE_ERROR = 'kindbody/user/PROFILE_IMAGE_ERROR';
const RESET_PROFILE_IMAGE_ERROR = 'kindbody/user/RESET_PROFILE_IMAGE_ERROR';
const UPDATE_SUBMITTED_INSURANCE_CARD = 'kindbody/user/UPDATE_SUBMITTED_INSURANCE_CARD';
const INSURANCE_CARD_ERROR = 'kindbody/user/INSURANCE_CARD_ERROR';
const UPDATE_USER_NAME = 'kindbody/user/UPDATE_NAME';
const RESET_INSURANCE_CARD_ERROR = 'kindbody/user/RESET_INSURANCE_CARD_ERROR';
const RESET_ID_ERROR = 'kindbody/user/RESET_ID_ERROR';
const UPDATE_SUBMITTED_ID = 'kindbody/user/UPDATE_SUBMITTED_ID';
const ID_ERROR = 'kindbody/user/ID_ERROR';

/* ---------- LOGOUT ---------- */

async function clearTokens(): Promise<any> {
  const logoutsProcesses = [apiLogout(), signGoogleUserOut()];
  return Promise.all(logoutsProcesses);
}

export async function logoutPatient() {
  try {
    if (getIdToken()) {
      await client.mutate({
        mutation: INVALIDATE_PATIENT_AUTH_SESSION
      });
    }
  } catch (error) {
    BugTracker.notify(error, 'UserReduxInvalidateSessionOnLogoutError');
  }

  try {
    await clearTokens();
    await client.clearStore();
  } catch (error) {
    BugTracker.notify(error, 'UserReduxLogoutError');
  }
}

export function logout() {
  return async () => {
    await logoutPatient();
  };
}

export function setHoneybadgerContext(patientId: string) {
  Honeybadger.setContext({
    user_id: patientId
  });
}

function identifyPatient(patient: Patient) {
  if (!patient?.id) return;

  setHoneybadgerContext(patient.id);
  analytics.identify(patient.id, {
    email: patient.email,
    firstname: patient.firstName,
    lastname: patient.lastName,
    phone: patient.phone,
    lab_id: patient.lab?.id,
    account_creation_date: (patient as any).createdAt,
    employer: patient.patientMembership?.employerName,
    employer_membership: patient.patientMembership?.membership?.name
  });
}

function registerPatientToEvent(event: string) {
  return () =>
    client.mutate({
      mutation: REGISTER_PATIENT_TO_EVENT,
      variables: { event }
    });
}

/* ---------- LOGIN ---------- */

function loginFailed(error: LoginError, errorMetaData?: string | null) {
  return {
    type: LOGIN_FAILED,
    error,
    errorMetaData
  };
}

function _loginSuccess(patient?: Patient | null) {
  return {
    type: LOGIN_SUCCESS,
    patient
  };
}

function _getEventParam() {
  const search = window?.location?.search;
  if (!search) return null;
  const urlParams = new URLSearchParams(search);
  return urlParams.get('event');
}

async function updateLastSignIn() {
  try {
    await client.mutate({
      mutation: UPDATE_PATIENT_LAST_SIGN_IN
    });
  } catch (error) {
    BugTracker.notify(error);
  }
}

function loginSuccess(patient?: Patient | null) {
  return async (dispatch: Function) => {
    // We need to get the parameter before _loginSuccess otherwise we lose them
    // when redirecting to the dashboard
    const event = _getEventParam();
    dispatch(_loginSuccess(patient));

    if (event) {
      dispatch(registerPatientToEvent(event));
    }

    analytics.track(analytics.EVENTS.PATIENT_LOGGED_IN);
  };
}

function _login(username: string, password: string) {
  return {
    type: LOGIN,
    username,
    password
  };
}

export function login(username: string, password: string, otp: string | undefined = undefined) {
  return async (dispatch: Function) => {
    // clear any old user info
    const cleanUserName = sanitizeUsername(username);
    await dispatch(_login(cleanUserName, password));

    try {
      const requestVariables: AuthenticatePatientRequestVariables = {
        email: cleanUserName,
        password: password
      };
      if (otp) {
        requestVariables.otp = otp;
      }
      const { data } = await client.mutate<
        AuthenticatePatientResponse,
        AuthenticatePatientRequestVariables
      >({
        mutation: AUTHENTICATE_PATIENT,
        variables: requestVariables
      });
      if (data?.authenticatePatient?.idToken) {
        window.sessionStorage.setItem('cognitoSsoIdToken', data.authenticatePatient.idToken);
        if (data.authenticatePatient.accessToken) {
          window.sessionStorage.setItem('cognitoAccessToken', data.authenticatePatient.accessToken);
        }
        analytics.track(analytics.EVENTS.EMAIL_SIGN_IN_SUCCEEDED);
        await dispatch(loginSuccess());
        updateLastSignIn();

        const { data: patientData } = await client.query({ query: PATIENT });
        const { patient } = patientData;
        identifyPatient(patient);
      } else {
        const errorCode = data?.authenticatePatient?.errorCode;
        const errorMetaData = data?.authenticatePatient?.errorMetaData;
        if (errorCode === 'UserNotConfirmedException') {
          await dispatch(loginFailed({ type: 'Unconfirmed' }));
        } else if (errorCode === 'UserNotConfirmedWithLimitExceededException') {
          await dispatch(loginFailed({ type: 'UnconfirmedWithResendLimitReached' }));
        } else if (errorCode === 'NotAuthorizedException') {
          await dispatch(loginFailed({ type: 'InvalidCredentials' }));
        } else if (errorCode === 'MfaRequired') {
          await dispatch(loginFailed({ type: 'MfaRequired' }, errorMetaData));
        } else if (errorCode === 'WrongMfaCode') {
          await dispatch(loginFailed({ type: 'WrongMfaCode' }));
        } else if (errorCode === 'MfaCodeExpired') {
          await dispatch(loginFailed({ type: 'MfaCodeExpired' }));
        } else if (errorCode === 'MfaBlocked') {
          await dispatch(loginFailed({ type: 'MfaBlocked' }));
        } else {
          BugTracker.notify(errorCode, 'UserReduxLoginError');
          await dispatch(loginFailed({ type: 'Unknown' }));
        }
      }
    } catch (error) {
      const errorMessages = getGraphQLErrorsMessages(error);
      const passwordMissing = errorMessages.includes(
        'Could not authenticate patient: Missing required parameter PASSWORD'
      );
      if (passwordMissing) {
        await dispatch(loginFailed({ type: 'InvalidCredentials' }));
      } else {
        BugTracker.notify(error, 'UserReduxLoginError');
        await dispatch(loginFailed({ type: 'Unknown' }));
      }
    }
  };
}

export function loginErrorHandled() {
  return {
    type: LOGIN_ERROR_HANDLED
  };
}

/* ---------- GOOGLE AUTH ---------- */

function _googleAuthError(error: GoogleAuthError) {
  return {
    type: AUTH_WITH_GOOGLE_FAILED,
    error
  };
}

function _authWithGoogle(
  id: string,
  token: string,
  email: string,
  firstName: string,
  lastName: string
) {
  return {
    type: AUTH_WITH_GOOGLE,
    id,
    token,
    email,
    firstName,
    lastName
  };
}

export function authWithGoogle(
  id: string,
  token: string,
  expiry: number,
  email: string,
  firstName: string,
  lastName: string
) {
  return async (dispatch: Function) => {
    await dispatch(logout());
    await dispatch(_authWithGoogle(id, token, email, firstName, lastName));

    try {
      if (token) window?.sessionStorage?.setItem('@GoogleSSO:idToken', token);

      try {
        // Attempt to query for the current patient's user data.
        // If this succeeds, it means the user is properly logged in
        // AND that they already have a 'patient' record created in our DB
        // If it fails, it is most likely that we have not created a user record for them
        // in our DB since we previously auth'd them above via federatedSignIn
        const { data } = await client.query({
          query: PATIENT
        });
        const { patient } = data;

        if (patient && patient.id) {
          // If so update redux to mark user as Logged in
          await dispatch(loginSuccess());
          updateLastSignIn();
          identifyPatient(patient);
        }
      } catch (error) {
        if (!isPatientUnauthorizedError(error)) {
          BugTracker.notify(error, 'UserReduxAuthWithGoogleError');
        }

        // If querying for the patient fails, no record exists in the db yet.
        // If an email account userName does not exist (see the above check)
        // then that, combined w/ no patient in the DB, means the google user needs to
        // finish signing up, which creates a patient in our DB
        await dispatch(_googleAuthError({ type: 'UserHasNotSignedUp' }));
      }
    } catch (error) {
      await dispatch(logout());
      BugTracker.notify(error, 'GoogleAuthError');
    }
  };
}

/**
 * This is called "automatically" via the listener whenever the user's
 * Google auth status changs. The listening is initialized in auth.google.ts
 * via the below function `loadGoogleAuth`
 */
async function handleGoogleAuthChange(token: string, dispatch: Function, state: ReduxState) {
  const {
    patient: { userRefreshFailed }
  } = state;

  await dispatch(actions.googleAuthInitialized());

  if (userRefreshFailed) {
    await dispatch(logout());
  }

  if (!token) return;

  try {
    // Get the Google user's details
    // @ts-ignore
    const { email, given_name, family_name, sub, exp } = jwt.decode(token);

    await dispatch(authWithGoogle(sub, token, exp, email, given_name, family_name));
  } catch (error) {
    BugTracker.notify(error, 'UserReduxGoogleAuthDetailsError');
  }
}

export function loadGoogleAuth(jwt: string) {
  return (dispatch: Function, getState: () => ReduxState) => {
    handleGoogleAuthChange(jwt, dispatch, getState());
  };
}

export function clearGoogleAuthError() {
  return {
    type: CLEAR_GOOGLE_AUTH_ERROR
  };
}

/* ---------- INITIAL APP LOAD ---------- */

function _appLoaded() {
  return {
    type: APP_LOADED
  };
}

export function appLoaded() {
  return async (dispatch: Function) => {
    await dispatch(_appLoaded());

    if (window.location.pathname === '/logout') {
      // if all loads because we have redirected the patient to logout
      // (e.g. we did automatic logout because we consider auth token expired)
      // there is no purpose in trying to execute things below
      return;
    }

    // applicable only on web app
    if (window.location.href.includes('login-sso/epic-disney'))
      await waitUntilEpicSsoIdTokenIsSet();

    if (window.location.href.includes('login-sso/azure-medtronic'))
      await waitUntilMedtronicSsoIdTokenIsSet();

    // removed the offending code on 9-6-19, however we still need to
    // make sure any passwords that were stored in localStorage are removed
    clearPwLocalStorage();

    try {
      const idToken = getIdToken();
      if (!idToken || isTokenExpired(idToken)) {
        return dispatch(logout());
      }

      // Query for patient data if they are logged in/have a cognito token stored.
      const { data } = await client.query({ query: PATIENT });
      const { patient } = data;
      if (!patient || !patient.id) {
        return dispatch(logout());
      }

      identifyPatient(patient);
      return dispatch(loginSuccess(patient));
    } catch (error) {
      BugTracker.notify(error, 'UserReduxAppLoadedError');
      // OK if this fails, as user might not be logged in
      await dispatch(logout());
    }
  };
}

/* ---------- SIGN UP ---------- */

function signUpFailed(error: SignUpError) {
  return {
    type: SIGNUP_FAILED,
    error
  };
}

function signUpSuccess(patient: Patient, confirmationState: string) {
  return {
    type: SIGNUP_SUCCESS,
    patient,
    confirmationState
  };
}

export function setConfirmationState(confirmationState: string) {
  return {
    type: SET_CONFIRMATION_STATE,
    confirmationState
  };
}

export function resetConfirmationState() {
  return {
    type: RESET_CONFIRMATION_STATE,
    confirmationState: null
  };
}

export function signUpPatient(signUpData: SignUpProps, signUpType: SIGNUP_TYPE) {
  return async (dispatch: Function, getState: () => ReduxState) => {
    const {
      firstName,
      lastName,
      preferredName,
      gender,
      pronoun,
      genderIdentity,
      birthdate,
      phone,
      password,
      labId,
      event,
      address,
      referralSource,
      referralSourceDetails,
      idtoken,
      preferredTimezone,
      sendSmsAppointmentNotification,
      sendMarketingEmail
    } = signUpData;

    const email = sanitizeUsername(signUpData.email);

    const {
      patient: {
        patient: { apple, google }
      }
    } = getState();

    try {
      const scrubbedPhone = scrubPhoneNumber(phone);
      const patientData = {
        birthdate: birthdate,
        email,
        firstName,
        identifier: preferredName,
        gender,
        pronoun,
        genderIdentity,
        labId,
        preferredTimezone,
        lastName,
        phone: scrubbedPhone,
        event,
        address,
        referralSource,
        referralSourceDetails,
        appleToken: apple?.token,
        googleToken: google?.token,
        password,
        sendSmsAppointmentNotification,
        sendMarketingEmail
      };

      const { data } = await client.mutate({
        mutation: SIGNUP_PATIENT,
        variables: patientData
      });
      const patient = data?.signUpPatient?.patient;

      if (signUpType === SIGNUP_TYPE.APPLE && idtoken) {
        window?.sessionStorage?.setItem('@AppleSSO:idToken', idtoken);
      }

      if (patient) {
        if (signUpType === SIGNUP_TYPE.APPLE) {
          await dispatch(_setAppleAuthError(null));
        }

        if (signUpType === SIGNUP_TYPE.GOOGLE || signUpType === SIGNUP_TYPE.APPLE) {
          dispatch(loginSuccess(patient));
        }

        const patientStatus = signUpType !== SIGNUP_TYPE.EMAIL ? 'confirmed' : 'unconfirmed';
        dispatch(signUpSuccess(patient, patientStatus));
        identifyPatient(patient);
      } else {
        throw new Error('CreatePatientError');
      }
    } catch (error) {
      dispatch(logout());
      const errorMessages = getGraphQLErrorsMessages(error);
      if (errorMessages.includes('Patient is unconfirmed')) {
        dispatch(setConfirmationState('unconfirmed'));
      } else if (errorMessages.includes('Invalid attempt to create patient')) {
        if (signUpType === SIGNUP_TYPE.APPLE)
          dispatch(_setAppleAuthError({ type: 'UserAlreadyExistsAsEmail' }));
        else dispatch(signUpFailed({ type: 'UserNameExists' }));
      } else if (
        errorMessages.includes('Could not create patient: Invalid phone number format.') ||
        errorMessages.includes('["Phone is not valid"]')
      ) {
        dispatch(signUpFailed({ type: 'InvalidPhoneNumber' }));
      } else {
        dispatch(signUpFailed({ type: 'Unknown' }));
        BugTracker.notify(error, 'UserSignUpError');
      }
    }
  };
}

/* ------- SIGN IN WITH APPLE ------- */

export function _signInAppleUser(
  token: string,
  email: string,
  firstName: string | undefined,
  lastName: string | undefined
) {
  return {
    type: SIGNIN_APPLE_USER,
    token,
    email,
    firstName,
    lastName
  };
}

export function _setAppleAuthError(error: AppleAuthError | null) {
  return {
    type: SET_APPLE_AUTH_ERROR,
    appleAuthError: error
  };
}

export function signInAppleUser(
  token: string,
  email: string,
  operation: string,
  firstName: string | undefined,
  lastName: string | undefined
) {
  return async (dispatch: Function) => {
    try {
      await dispatch(_setAppleAuthError(null));
      await dispatch(_signInAppleUser(token, email, firstName, lastName));

      const { data } = await client.query({
        query: PATIENT
      });
      const { patient } = data;

      if (patient && patient.id) {
        // If so update redux to mark user as Logged in
        await dispatch(loginSuccess());
        updateLastSignIn();
        identifyPatient(patient);
      }
    } catch (error) {
      if (!isPatientUnauthorizedError(error)) {
        BugTracker.notify(error, 'UserReduxSignInAppleUserError' + operation);
      }

      dispatch(_setAppleAuthError({ type: 'UserHasNotSignedUp', email: email }));
    }
  };
}

/* ---------- CONFIRM USER ---------- */

function confirmUserSuccess() {
  return {
    type: CONFIRM_USER_SUCCESS
  };
}

function confirmUserFailed(error: ConfirmationError) {
  return {
    type: CONFIRM_USER_FAILED,
    error
  };
}

function _confirmUser(code: string, email?: string) {
  return {
    type: CONFIRM_USER,
    code,
    email
  };
}

export function confirmUser(code: string, email?: string) {
  return async (dispatch: Function, getState: () => ReduxState) => {
    dispatch(_confirmUser(code));

    const username = sanitizeUsername(email || getState().patient.patient.email);

    try {
      const { data } = await client.mutate({
        mutation: VERIFY_PATIENT_EMAIL,
        variables: {
          email: username,
          verificationCode: code
        }
      });

      if (data?.verifyPatientEmail?.succeeded) {
        dispatch(confirmUserSuccess());
      } else if (data?.verifyPatientEmail?.errorCode === 'CodeMismatchException') {
        dispatch(confirmUserFailed({ type: 'InvalidCode' }));
      } else if (data?.verifyPatientEmail?.errorCode === 'ExpiredCodeException') {
        dispatch(confirmUserFailed({ type: 'ExpiredCode' }));
      } else {
        BugTracker.notify(data?.verifyPatientEmail?.errorCode, 'UserReduxConfirmUserError');
        dispatch(confirmUserFailed({ type: 'Unknown' }));
      }
    } catch (error) {
      BugTracker.notify(error, 'UserReduxConfirmUserError');
      dispatch(confirmUserFailed({ type: 'Unknown' }));
    }
  };
}

/* ---------- RESEND CONFIRMATION ---------- */

function _resendConfirmationCode() {
  return {
    type: RESEND_CONFIRMATION_CODE
  };
}

function resendConfirmationCodeSuccess() {
  return {
    type: RESEND_CONFIRMATION_CODE_SUCCESS
  };
}

function resendConfirmationCodeFailure(error: string) {
  return {
    type: RESEND_CONFIRMATION_CODE_FAILURE,
    error
  };
}

export function resendConfirmationCode(email: string) {
  return async (dispatch: Function) => {
    try {
      const cleanEmail = sanitizeUsername(email);
      dispatch(_resendConfirmationCode());

      const { data } = await client.mutate({
        mutation: RESEND_EMAIL_VERIFICATION_CODE,
        variables: {
          email: cleanEmail
        }
      });
      if (data?.resendEmailVerificationCode.succeeded) {
        dispatch(resendConfirmationCodeSuccess());
      } else {
        const error = data?.resendEmailVerificationCode?.errorCode;
        BugTracker.notify(error, 'UserReduxResendConfirmationCodeError');
        dispatch(resendConfirmationCodeFailure(error));
      }
    } catch (error) {
      BugTracker.notify(error, 'UserReduxResendConfirmationCodeError');
      // @ts-ignore
      dispatch(resendConfirmationCodeFailure(error));
    }
  };
}

export function updateProfileImage(url?: string) {
  return {
    type: UPDATE_PROFILE_IMAGE,
    url
  };
}

export function profileImageError(error: ProfileImageError) {
  return {
    type: PROFILE_IMAGE_ERROR,
    error
  };
}

export function resetProfileImageError() {
  return {
    type: RESET_PROFILE_IMAGE_ERROR
  };
}

export function updateSubmittedInsuranceCard(submittedInsuranceCard: boolean) {
  return {
    type: UPDATE_SUBMITTED_INSURANCE_CARD,
    submittedInsuranceCard
  };
}

export function insuranceCardError(target: InsuranceCardSide, error: InsuranceCardError) {
  return {
    type: INSURANCE_CARD_ERROR,
    target,
    error
  };
}

export function idError(target: IDSide, error: IDError) {
  return {
    type: ID_ERROR,
    target,
    error
  };
}

export function resetInsuranceCardError(target: InsuranceCardSide) {
  return {
    type: RESET_INSURANCE_CARD_ERROR,
    target
  };
}

export function resetIDError(target: IDSide) {
  return {
    type: RESET_ID_ERROR,
    target
  };
}

export function updateSubmittedID(submittedID: boolean) {
  return {
    type: UPDATE_SUBMITTED_ID,
    submittedID
  };
}

/* ---------- REDUCER ---------- */
const initialState: PatientState = {
  // need this flag to ensure Google users are fully signed out
  userRefreshFailed: false,
  loading: false,
  isLoggedIn: undefined,
  confirmationState: null,
  loginError: null,
  loginErrorMetaData: null,
  signUpError: null,
  confirmationError: null,
  resendConfirmationCode: {
    status: null,
    error: null
  },
  patient: {
    id: '',
    firstName: '',
    lastName: '',
    gender: '',
    birthday: '',
    phone: '',
    email: '',
    submittedInsuranceCard: true,
    confirmed: false,
    submittedID: true,
    authProviders: []
  },
  googleAuthInitialize: false,
  googleAuthError: null,
  appleAuthError: null,
  profileImageError: null,
  insuranceCardErrors: null,
  idErrors: null
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
const handlers = {
  [AUTH_WITH_GOOGLE]: (state: PatientState, action: AnyAction) => ({
    ...state,
    patient: {
      ...state.patient,
      email: action.email,
      firstName: action.firstName,
      lastName: action.lastName,
      google: {
        id: action.id,
        token: action.token
      }
    }
  }),
  [AUTH_WITH_GOOGLE_FAILED]: (state: PatientState, action: AnyAction) => ({
    ...state,
    googleAuthError: action.error,
    loading: false
  }),
  [SIGNIN_APPLE_USER]: (state: PatientState, action: AnyAction) => ({
    ...state,
    loading: true,
    patient: {
      ...state.patient,
      email: action.email,
      firstName: action.firstName,
      lastName: action.lastName,
      apple: {
        token: action.token
      }
    }
  }),
  [SET_APPLE_AUTH_ERROR]: (state: PatientState, action: AnyAction) => ({
    ...state,
    loading: false,
    appleAuthError: action.appleAuthError
  }),
  [CLEAR_GOOGLE_AUTH_ERROR]: (state: PatientState, action: AnyAction) => ({
    ...state,
    googleAuthError: null
  }),
  [LOGIN]: (state: PatientState, action: AnyAction) => {
    return {
      ...state,
      patient: {
        ...state.patient,
        email: action.username
      },
      loading: true,
      loginError: null,
      loginErrorMetaData: null
    };
  },
  [LOGIN_SUCCESS]: (state: PatientState, action: AnyAction) => {
    return {
      ...state,
      isLoggedIn: true,
      loginError: null,
      loginErrorMetaData: null,
      patient: action.patient || state.patient,
      loading: false
    };
  },
  [LOGIN_FAILED]: (state: PatientState, action: AnyAction) => {
    let confirmationState: string | null = state.confirmationState;
    if (action.error.type === 'Unconfirmed') {
      confirmationState = 'unconfirmed';
    }

    return {
      ...state,
      loginError: action.error,
      loginErrorMetaData: action.errorMetaData,
      isLoggedIn: false,
      loading: false,
      confirmationState: confirmationState
    };
  },
  [LOGIN_ERROR_HANDLED]: (state: PatientState, action: AnyAction) => {
    return {
      ...state,
      loginError: null,
      loginErrorMetaData: null
    };
  },
  [LOG_OUT]: () => {
    return {
      ...initialState,
      isLoggedIn: false
    };
  },
  [SIGNUP]: (state: PatientState, action: AnyAction) => {
    return {
      ...state,
      signUpError: null,
      loading: true
    };
  },
  [SIGNUP_GOOGLE_USER]: (state: PatientState, action: AnyAction) => {
    return {
      ...state,
      signUpError: null,
      loading: true
    };
  },
  [SIGNUP_FAILED]: (state: PatientState, action: AnyAction) => {
    return {
      ...state,
      loading: false,
      signUpError: action.error
    };
  },
  [SIGNUP_SUCCESS]: (state: PatientState, action: AnyAction) => {
    return {
      ...state,
      loading: false,
      signUpError: null,
      patient: action.patient,
      confirmationState: action.confirmationState,
      isLoggedIn: action.confirmationState === 'confirmed' // auto login Google sign ups that don't require verification
    };
  },
  [CONFIRM_USER]: (state: PatientState, action: AnyAction) => {
    return {
      ...state,
      confirmationError: null,
      loginError: null,
      loginErrorMetaData: null,
      loading: true
    };
  },
  [CONFIRM_USER_SUCCESS]: (state: PatientState, action: AnyAction) => {
    return {
      ...state,
      confirmationState: 'confirmed',
      loading: false,
      loginError: null,
      loginErrorMetaData: null,
      patient: {
        ...state.patient,
        confirmed: true
      }
    };
  },
  [CONFIRM_USER_FAILED]: (state: PatientState, action: AnyAction) => {
    return {
      ...state,
      confirmationError: action.error,
      loading: false
    };
  },
  [RESET_CONFIRMATION_STATE]: (state: PatientState, action: AnyAction) => {
    return {
      ...state,
      confirmationState: action.confirmationState
    };
  },
  [SET_CONFIRMATION_STATE]: (state: PatientState, action: AnyAction) => {
    return {
      ...state,
      confirmationState: action.confirmationState
    };
  },
  [RESEND_CONFIRMATION_CODE]: (state: PatientState, action: AnyAction) => {
    return {
      ...state,
      confirmationError: null,
      resendConfirmationCode: {
        ...state.resendConfirmationCode,
        status: 'loading'
      }
    };
  },
  [RESEND_CONFIRMATION_CODE_SUCCESS]: (state: PatientState, action: AnyAction) => {
    return {
      ...state,
      resendConfirmationCode: {
        ...state.resendConfirmationCode,
        status: 'success',
        error: null
      }
    };
  },
  [RESEND_CONFIRMATION_CODE_FAILURE]: (state: PatientState, action: AnyAction) => {
    return {
      ...state,
      resendConfirmationCode: {
        ...state.resendConfirmationCode,
        status: 'error',
        error: action.error
      }
    };
  },
  [UPDATE_PROFILE_IMAGE]: (state: PatientState, action: AnyAction) => ({
    ...state,
    profileImage: action.url
  }),
  [PROFILE_IMAGE_ERROR]: (state: PatientState, action: AnyAction) => ({
    ...state,
    profileImageError: action.error
  }),
  [RESET_PROFILE_IMAGE_ERROR]: (state: PatientState, action: AnyAction) => ({
    ...state,
    profileImageError: null
  }),
  [UPDATE_SUBMITTED_INSURANCE_CARD]: (state: PatientState, action: AnyAction) => ({
    ...state,
    patient: {
      ...state.patient,
      submittedInsuranceCard: action.submittedInsuranceCard
    },
    insuranceCardErrors: action.submittedInsuranceCard ? null : state.insuranceCardErrors
  }),
  [INSURANCE_CARD_ERROR]: (state: PatientState, action: AnyAction) => ({
    ...state,
    patient: {
      ...state.patient,
      submittedInsuranceCard: false
    },
    insuranceCardErrors: {
      ...state.insuranceCardErrors,
      [action.target]: action.error
    }
  }),
  [RESET_INSURANCE_CARD_ERROR]: (state: PatientState, action: AnyAction) => ({
    ...state,
    insuranceCardErrors: {
      ...state.insuranceCardErrors,
      [action.target]: undefined
    }
  }),
  [ID_ERROR]: (state: PatientState, action: AnyAction) => ({
    ...state,
    patient: {
      ...state.patient,
      submittedID: false
    },
    idErrors: {
      ...state.idErrors,
      [action.target]: action.error
    }
  }),
  [RESET_ID_ERROR]: (state: PatientState, action: AnyAction) => ({
    ...state,
    idError: {
      ...state.idErrors,
      [action.target]: undefined
    }
  }),
  [UPDATE_SUBMITTED_ID]: (state: PatientState, action: AnyAction) => ({
    ...state,
    patient: {
      ...state.patient,
      submittedID: action.submittedID
    }
  }),
  [UPDATE_USER_NAME]: (state: PatientState, action: AnyAction) => ({
    ...state,
    patient: {
      ...state.patient,
      firstName: action.firstName,
      lastName: action.lastName
    }
  })
};

export default handleActions(handlers, initialState);
