import { ChevronLeft, ChevronRight } from 'assets/icons/Icons';
import { Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const Label = styled(Text)<{ $active: boolean; isDesktop: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ $active, isDesktop, theme }) =>
    isDesktop
      ? $active
        ? theme.colors.neutral.darkestNavy
        : theme.colors.neutral.lightNavy
      : theme.colors.neutral.darkestNavy};
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    grid-template-columns: 320px 1fr;
    gap: 48px;
  }
`;

export const NavItem = styled.span<{ $active: boolean }>`
  display: inline-flex;
  justify-content: space-between;
  padding: 1.5em 1.5em 1.5em 0;
  white-space: normal;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lavender};

  ${Label} {
    font-weight: 700;

    @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
      font-weight: ${({ $active }) => ($active ? '700' : '400')};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    padding: 16px 24px;
    border-radius: 8px;
    white-space: nowrap;
    border-bottom: 1px solid transparent;

    &:hover {
      background-color: ${({ theme }) => theme.colors.white};
    }

    ${({ $active, theme }) =>
      $active &&
      `
      border-bottom: 1px solid ${theme.colors.neutral.lavender};
      background-color: ${theme.colors.yellow.lightestYellow};
      box-shadow: ${theme.shadows.container.glowYellow};
    `}
  }
`;

export const Nav = styled.nav<{ selectedTab: boolean }>`
  display: ${({ selectedTab }) => (selectedTab ? 'none' : 'flex')};
  flex-direction: column;
  gap: 4px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: flex;
    flex-basis: min-content;
    align-items: flex-start;
  }
`;

export const Content = styled.div`
  height: 100%;
  display: block;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: flex;
    flex-basis: auto;
  }
`;

export const MobileGoBack = styled.button<{ selectedTab: boolean; noMargin?: boolean }>`
  display: ${({ selectedTab }) => (selectedTab ? 'none' : 'flex')};
  width: 100%;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '48px')};
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: none;
  }
`;

export const GoBackArrow = styled(ChevronLeft).attrs({ type: 'solid' })`
  color: ${({ theme }) => theme.colors.neutral.lighterNavy};
  margin-right: 0.5em;
  font-size: 0.7em;
`;

export const GoForwardArrow = styled(ChevronRight).attrs({ type: 'solid' })`
  display: inline;
  color: ${({ theme }) => theme.colors.neutral.lighterNavy};

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: none;
  }
`;
