import React from 'react';
import { useHistory } from 'react-router';

import { kb360Icon } from 'assets/icons/kb360';
import { Button } from 'components/v2/Buttons/Button';
import usePatient from 'hooks/usePatient';
import { Patient } from 'kb-shared';
import { isDisneyPatient } from 'kb-shared/utilities/lab.helper';
import { RoutesPath, pageUrl } from 'utilities/pageUrl';

import { appointment } from '../../../assets/icons';
import {
  ActionBlock,
  ActionBlockDescription,
  ActionBlockHeader,
  ActionBlockTitle,
  ActionBlockWrapper
} from './ActionBlock.styled';

interface Action {
  title: string;
  description: string;
  cta: string;
  icon: string;
  path: string;
}

const actions: Action[] = [
  {
    title: 'Book an appointment',
    description: 'Choose from our full spectrum of services',
    cta: 'Book an appointment',
    icon: appointment,
    path: pageUrl.booking.bookingQuiz()
  },
  {
    title: 'Kindbody360',
    description:
      'Whole-body approach and holistic care support during your family planning experience',
    cta: 'Learn More',
    icon: kb360Icon,
    path: RoutesPath.KINDBODY_360
  }
];

function shouldHide(action: Action, patient?: Patient) {
  const marketId = patient?.lab?.id ?? '';
  const disneyPatient = isDisneyPatient(marketId);

  return action.title === 'Kindbody360' && disneyPatient;
}

const ActionBanner = () => {
  const { patient } = usePatient();
  const history = useHistory();

  return (
    <ActionBlockWrapper>
      {actions
        .filter(action => !shouldHide(action, patient))
        .map(action => (
          <ActionBlock key={action.title}>
            <ActionBlockHeader>
              <img src={action.icon} height="45" width="48" alt="" />
              <ActionBlockTitle>{action.title}</ActionBlockTitle>
              <ActionBlockDescription>{action.description}</ActionBlockDescription>
            </ActionBlockHeader>
            <div>
              <Button
                label={action.cta}
                category="primary-dark"
                size="md"
                onClick={() => history.push(action.path)}
              />
            </div>
          </ActionBlock>
        ))}
    </ActionBlockWrapper>
  );
};

export default ActionBanner;
