import styled from 'styled-components';

export const BookingTimeSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TimeZoneText = styled.div`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  font-size: 14px;
  display: flex;
  margin-top: 4px;
  padding: 0 0 20px 20px;
`;
