import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useFeatureFlags, usePatient } from 'hooks';
import { useInterstitialStatus } from 'hooks/useInterstitialStatus';
import { AppointmentType, Membership } from 'kb-shared';
import { isCoachingAppointment } from 'kb-shared/utilities/appointment.helper';

import { State } from './Book.types';
import { BookingStateManager } from './utils/BookingStateManager';
import { initialDataState } from './utils/BookingStateManager.constants';
import { Data, StepType } from './utils/BookingStateManager.types';

const useInsuranceStepCompletedOnInitialLoad = () => {
  const { status } = useInterstitialStatus();
  const history = useHistory();
  const completedStepsSet = useRef(false);
  const [insuranceFinishedOnInitialLoad, setInsuranceFinishedOnInitialLoad] = useState(false);

  useEffect(() => {
    if (completedStepsSet.current === false && status) {
      const completed = Boolean(status.insuranceDataCompleted);
      setInsuranceFinishedOnInitialLoad(completed);
      completedStepsSet.current = true;
    }
  }, [status, setInsuranceFinishedOnInitialLoad, history]);

  return insuranceFinishedOnInitialLoad;
};

export const useBookingState = ({ skipToSignUp }: { skipToSignUp?: boolean }) => {
  const { isLoggedIn } = usePatient();
  const history = useHistory();
  const { status, loading } = useInterstitialStatus();
  const insuranceStepCompletedInitially = useInsuranceStepCompletedOnInitialLoad();
  const insuranceStepCompleted = Boolean(status?.insuranceDataCompleted);
  const interstitial = {
    loading,
    insuranceStepCompleted
  };
  const completedSteps: Array<StepType> = insuranceStepCompletedInitially ? ['insurance'] : [];
  const [state, setState] = useState<State>({
    data: initialDataState,
    createAccountSubstep: 'choose_signup_option',
    partnerClinicSelected: null,
    initialDataDerived: false
  });

  const [manager, setManager] = useState<BookingStateManager>();
  const updateData = (data: Data) => {
    setState({
      ...state,
      data,
      initialDataDerived: true
    });
  };

  useEffect(() => {
    const bookingStateManager = new BookingStateManager(
      history,
      updateData,
      isLoggedIn,
      completedSteps
    );
    setManager(bookingStateManager);

    if (skipToSignUp) {
      bookingStateManager.skipToSignUp();
    }

    return () => bookingStateManager?.removeHistoryListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (manager && completedSteps.length) {
      manager.setCompletedSteps(completedSteps);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manager, completedSteps.length]);

  return {
    completedSteps,
    isLoggedIn,
    interstitial,
    history,
    state,
    setState,
    manager
  };
};

export const useIsPaymentOptional = (product: AppointmentType | Membership) => {
  const { ppCreditCardOptional } = useFeatureFlags();

  // If product is not defined or does not have a category we can't determine if payment is optional
  if (!product || 'category' in product === false) {
    return { isPaymentOptional: false };
  }

  // Only coaching(holistic) appointments are required to submit payment at booking
  const nonHolisticAppointment = isCoachingAppointment(product) === false;
  const isPaymentOptional = Boolean(ppCreditCardOptional && nonHolisticAppointment);

  return { isPaymentOptional };
};
