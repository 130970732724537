import styled from 'styled-components';

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const Background = styled.div`
  cursor: pointer;
  text-indent: -9999px;
  width: 48px;
  min-width: 48px;
  height: 24px;
  background: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  display: block;
  border-radius: 100px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lighterNavy};

  ${Checkbox}:checked ~ & {
    border: 1px solid ${({ theme }) => theme.colors.yellow.darkYellow};
    background: ${({ theme }) => theme.colors.yellow.primary};
    transition: background-color 0.3s ease;
  }
`;

export const Container = styled.label`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

export const Knob = styled.span`
  position: absolute;
  top: 3px;
  left: 3px;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  transition: 0.3s;
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lighterNavy};

  ${Checkbox}:checked + ${Background} & {
    left: calc(100% - 3px);
    transform: translateX(-100%);
    border: 1px solid ${({ theme }) => theme.colors.yellow.darkYellow};
  }
`;

export const ToggleLabel = styled.span`
  padding-left: 10px;
  ${({ theme }) => theme.fonts.Gotham};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
`;
