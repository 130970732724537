import styled from 'styled-components';

export const Title = styled.h3`
  ${({ theme }) => theme.fonts.DomaineDisplay};
  font-size: 36px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  text-align: center;
  margin-bottom: 28px;
`;

export const SectionTitle = styled(Title)`
  margin: 28px 0;
  text-align: left;
`;
