import React from 'react';

import { ReactComponent as BracketLeft } from 'assets/icons/calculator-bracket-left.svg';
import { ReactComponent as BracketRight } from 'assets/icons/calculator-bracket-right.svg';
import styled from 'styled-components';

export const InfoAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 95px;
  margin-top: 40px;
  padding: 42px 34px;
  background-color: white;
  box-shadow: -4px 4px 0 0 ${({ theme }) => theme.colors.neutral.primaryNavy};
  border: solid 2px ${({ theme }) => theme.colors.neutral.primaryNavy};

  ${({ theme }) => theme.fonts.FoundersGrotesk};
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
`;

export const BracketContainer: React.FC<SvgContainerProps & { className?: string }> = ({
  children,
  bracketColor,
  bracketWidth,
  bracketHeight,
  className
}) => (
  <SvgContainer
    bracketColor={bracketColor}
    bracketWidth={bracketWidth}
    bracketHeight={bracketHeight}
    className={className}
  >
    <BracketLeft />
    {children}
    <BracketRight />
  </SvgContainer>
);

interface SvgContainerProps {
  bracketColor?: string;
  bracketWidth?: string;
  bracketHeight?: string;
}

const SvgContainer = styled.div<SvgContainerProps>`
  display: flex;
  align-items: center;

  & > svg:first-of-type,
  & > svg:last-of-type {
    width: ${props => props.bracketWidth || '30px'};
    height: ${props => props.bracketHeight || 'auto'};
    path {
      fill: ${props => props.bracketColor || props.theme.colors.yellow.primary};
    }
  }
`;
