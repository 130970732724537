import { themes } from 'kb-shared';
import styled from 'styled-components';

import HelperModal from '../HelperModal';
import Popover from '../Popover';
import ResultRecommendation from '../ResultRecommendation';
import ResultsContactModal from '../ResultsContactModal';
import { Container } from '../styled';

export const DiagnosticContainer = styled(Container)`
  margin-bottom: 45px;
`;

export const Divider = styled.div`
  margin: 20px;
  border-style: solid;
  border-color: ${themes.colors.neutral.navy};
  border-top-width: 2px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;

  @media screen and (min-width: ${themes.breakpoints.largePhonePortrait}) {
    margin: 0px;
    border-style: none;
    border-top-width: 0px;
  }
`;

export const ColumnContent = styled.div`
  padding: 10px;
`;

export const Column1 = styled.div`
  flex: 1;
  border: 1px;
  border-color: ${themes.colors.neutral.primaryNavy};
  border-style: solid;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 0px;

  @media screen and (min-width: ${themes.breakpoints.largePhonePortrait}) {
    flex: 2;
  }
`;

export const Column2 = styled.div`
  flex: 1;
  border: 1px;
  border-color: ${themes.colors.neutral.primaryNavy};
  border-style: solid;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 0px;
`;

export const Column3 = styled.div`
  flex: 1;
`;

export const ExpandIconWrapper = styled.div`
  margin-top: 10px;
  margin-left: 5px;
`;

export const MatrixContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themes.colors.neutral.navy};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${themes.colors.neutral.navy};
`;

export const EmptyRow = styled(Row)`
  height: 20px;
`;

export const ColumnHeader = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  background-color: ${themes.colors.yellow.primary};
  min-height: 48px;
  ${themes.fonts.DomaineDisplay};
  font-size: 16px;
  padding: 0 5px;

  @media screen and (min-width: ${themes.breakpoints.tabletLandscape}) {
    font-size: 24px;
    padding-left: 18px;
  }
`;

export const CategoryTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CategoryTitle = styled.div`
  ${themes.fonts.FoundersGrotesk};
  color: ${themes.colors.yellow.primary};
  min-height: 20px;
  font-weight: bold;
  font-size: 14px;
`;

export const InfoButtonWrapper = styled.div`
  cursor: pointer;
  margin-left: 10px;
`;

export const LineItemText = styled.div`
  ${themes.fonts.FoundersGrotesk};
  color: white;
  font-size: 14px;
  word-break: break-word;
`;

export const HelperText = styled.p`
  ${themes.fonts.FoundersGrotesk};
  color: white;
  font-size: 16px;
  margin-bottom: 8px;
`;

export const PopoverContainer = styled.div`
  position: absolute;
  right: 20px;
  flex: 1;
  display: flex;
`;

export const Link = styled.a`
  display: inline;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const LinkHTML = styled.div`
  display: inline;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export { Popover, HelperModal, ResultRecommendation, ResultsContactModal };
