import React, { MouseEvent } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

const Container = styled.div`
  border-color: ${themes.colors.semantic.pink};
  border-width: 1px;
`;

const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: solid 1px ${themes.deprecatedColors.gray};
  background-color: white;
`;

const CheckText = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-weight: bolder;
  font-size: 18px;
  color: ${themes.colors.neutral.primaryNavy};
`;

type Props = {
  onSelect: (e: MouseEvent<HTMLDivElement>) => void;
  selected: boolean;
};

const DeprecatedCheckBox = (props: Props) => {
  return (
    <Container onClick={props.onSelect} id="agree">
      <CheckContainer>
        <CheckText>{props.selected ? '✓' : ''}</CheckText>
      </CheckContainer>
    </Container>
  );
};

DeprecatedCheckBox.defaultProps = {
  selected: false
};

export default DeprecatedCheckBox;
