import { utils } from 'kb-shared';

const { KBContacts, labs } = utils;

interface LabContacts {
  nightDirectNumber?: string;
}

export const LABS: Record<string, LabContacts> = Object.freeze({
  '0': {
    nightDirectNumber: KBContacts.easternNightDirectNumber
  },
  [labs().NY]: {
    nightDirectNumber: KBContacts.easternNightDirectNumber
  },
  [labs().SF]: {
    nightDirectNumber: KBContacts.caNightDirectNumber
  },
  [labs().LA]: {
    nightDirectNumber: KBContacts.caNightDirectNumber
  },
  [labs().SILICON_VALLEY]: {
    nightDirectNumber: KBContacts.caNightDirectNumber
  },
  [labs().AUSTIN]: {
    nightDirectNumber: KBContacts.texasNightDirectNumber
  },
  [labs().MINNEAPOLIS]: {
    nightDirectNumber: KBContacts.centralNightDirectNumber
  },
  [labs().ARKANSAS]: {
    nightDirectNumber: KBContacts.centralNightDirectNumber
  },
  [labs().HOUSTON]: {
    nightDirectNumber: KBContacts.texasNightDirectNumber
  },
  [labs().DALLAS]: {
    nightDirectNumber: KBContacts.texasNightDirectNumber
  },
  [labs().DENVER]: {
    nightDirectNumber: KBContacts.centralNightDirectNumber
  },
  [labs().DC]: {
    nightDirectNumber: KBContacts.southernNightDirectNumber
  },
  [labs().ATLANTA]: {
    nightDirectNumber: KBContacts.southernNightDirectNumber
  },
  [labs().PRINCETON]: {
    nightDirectNumber: KBContacts.easternNightDirectNumber
  },
  [labs().MILWAUKEE]: {
    nightDirectNumber: KBContacts.centralNightDirectNumber
  }
});
