import React from 'react';

import { Button } from 'components/v2/Buttons/Button';
import { Modal } from 'components/v2/Modal/Modal';
import { Patient } from 'kb-shared';

import { ButtonContainer } from './IHaveMoreQuestionsModal.styled';
import { LABS } from './IHaveMoreQuestionsModal.utils';
import { ModalContent } from './ModalContent';

export const IHaveMoreQuestionsModal = ({
  visible,
  patient,
  onDismiss
}: {
  visible: boolean;
  patient: Patient;
  onDismiss: () => void;
}) => {
  const marketId = patient?.lab?.id || '0';
  const lab = LABS[marketId] || LABS['0'];

  return (
    <Modal title="Contact Your Nurse Team" open={visible} onCancel={onDismiss}>
      <ModalContent marketId={marketId} lab={lab} />
      <ButtonContainer>
        <Button label="Got it!" uppercase onClick={onDismiss} fullWidth />
      </ButtonContainer>
    </Modal>
  );
};
