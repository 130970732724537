import React from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { ReactComponent as MessagingIcon } from 'assets/icons/messaging.svg';
import { usePatient } from 'hooks';

import { LABS } from '../../utilities/LabContacts';
import Modal from './../Modal';
import {
  Container,
  Content,
  IconContainer,
  Title,
  Body,
  PhoneNumberSmall
} from './ContactModal.styled';

interface Props {
  visible: boolean;
  onDismiss: Function;
}

const ContactModal = ({ visible, onDismiss }: Props) => {
  const { patient } = usePatient();
  const marketId = patient?.lab?.id || '';
  const lab = LABS[marketId] || LABS['0']; // hotfix for princeton & virtual markets;

  return (
    <Modal open={visible} onRequestClose={() => onDismiss()}>
      <Container>
        <Content>
          <IconContainer onClick={() => onDismiss()}>
            <CloseIcon />
          </IconContainer>
          <Title>
            <MessagingIcon /> Contact us
          </Title>
          <Body>
            If this is a medical emergency, please call{' '}
            <PhoneNumberSmall href="tel:911">911</PhoneNumberSmall> <br />
            <br />
            If you need to speak to medical provider urgently please <br />
            call <PhoneNumberSmall href="tel:855-563-2639">(855) 563-2639</PhoneNumberSmall>.
            <br />
            <br />
            If you are calling after the clinic is closed to speak to <br />
            the medical team on call, please call the after-hours line <br />
            at{' '}
            <PhoneNumberSmall href={`tel:${lab.nightDirectNumber}`}>
              {lab.nightDirectNumber}.
            </PhoneNumberSmall>
          </Body>
        </Content>
      </Container>
    </Modal>
  );
};

export default ContactModal;
