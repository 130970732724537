const env = process.env.REACT_APP_ENV;

const LABS_PRODUCTION = {
  NY: '5',
  SF: '8',
  LA: '11',
  SILICON_VALLEY: '21',
  ATLANTA: '16',
  DISNEY: '24',
  PRINCETON: '12',
  AUSTIN: '22',
  DENVER: '25',
  MINNEAPOLIS: '27',
  DC: '28',
  HOUSTON: '30',
  DALLAS: '29',
  ARKANSAS: '41',
  PORTLAND: '38',
  MILWAUKEE: '35',
  CHICAGO: '34'
};

const LABS_STAGING = {
  NY: '5',
  SF: '8',
  LA: '11',
  SILICON_VALLEY: '21',
  ATLANTA: '16',
  DISNEY: '24',
  PRINCETON: '12',
  AUSTIN: '22',
  DENVER: '25',
  MINNEAPOLIS: '27',
  DC: '28',
  HOUSTON: '30',
  DALLAS: '29',
  ARKANSAS: '41',
  PORTLAND: '38',
  MILWAUKEE: '35',
  CHICAGO: '34'
};

export function labs() {
  if (env === 'staging') return LABS_STAGING;
  return LABS_PRODUCTION;
}

export function isDisneyPatient(labId: string): boolean {
  return LABS_PRODUCTION.DISNEY === labId;
}

export function isPortlandPatient(labId: string): boolean {
  return LABS_PRODUCTION.PORTLAND === labId;
}
