import posed from 'react-pose';

import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ContainerPadding = styled(Container)`
  padding: 0px 20px;
`;

export const SectionTitle = styled.div`
  ${themes.fonts.DomaineDisplay};
  font-size: 28px;
  color: ${themes.colors.yellow.primary};
  margin-bottom: 8px;
`;

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${themes.breakpoints.tabletLandscape}) {
    flex-direction: row;
  }
`;

export const SectionContainer = styled.div`
  flex: 1;
  border-style: solid;
  border-color: ${themes.colors.neutral.navy};
  background-color: transparent;
  border-top-width: 2px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;

  @media screen and (min-width: ${themes.breakpoints.tabletPortrait}) {
    padding-left: 16px;
    padding-right: 16px;
    border-width: 2px;
    margin-bottom: 36px;
  }
`;

export const SectionContainerCalculator = styled(SectionContainer)`
  background-color: ${({ theme }) => theme.colors.neutral.navy};
`;

export const Title = styled.h1`
  font-size: 28px;
  color: white;
  margin-bottom: 28px;
  padding: 20px 20px 0px 20px;
`;

export const AnimatedContentContainer = posed.div({
  visible: {
    height: 'auto',
    overflow: 'hidden',
    backgroundColor: themes.colors.neutral.primaryNavy,
    duration: 3000
  },
  hidden: {
    height: 0,
    overflow: 'hidden',
    backgroundColor: themes.colors.neutral.primaryNavy,
    duration: 3000
  }
});

export const ResultsCard = styled.div`
  padding-bottom: 48px;
`;

export const ShadowWrapper = styled.div`
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.15);
`;
