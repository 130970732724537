import styled, { css } from 'styled-components';

import { Decoration, FontStyle, Size, Transform } from './Text.types';

interface Props {
  fontStyle?: FontStyle;
  fontSize?: Size;
  decoration?: Decoration;
  transform?: Transform;
  color?: string;
  bg?: string;
  multilineEllipses?: number;
}

interface CommonCssProps {
  color?: string;
  bg?: string;
}

const baseCss = css<CommonCssProps>`
  ${({ theme }) => theme.fonts.Gotham};
  color: ${({ theme, color }) => (color ? color : theme.colors.neutral.primaryNavy)};
  background-color: ${({ bg }) => (bg ? bg : 'initial')};
`;

const fontWeightCss = css<Props>`
  ${({ fontStyle }) => {
    switch (fontStyle) {
      case 'light':
        return css`
          font-style: normal;
          font-weight: 300;
        `;
      case 'lightItalic':
        return css`
          font-style: italic;
          font-weight: 300;
        `;
      case 'regular':
        return css`
          font-style: normal;
          font-weight: 400;
        `;
      case 'regularItalic':
        return css`
          font-style: italic;
          font-weight: 400;
        `;
      case 'medium':
        return css`
          font-style: normal;
          font-weight: 500;
        `;
      case 'mediumItalic':
        return css`
          font-style: italic;
          font-weight: 500;
        `;
      case 'semibold':
        return css`
          font-style: normal;
          font-weight: 600;
        `;
      case 'semiboldItalic':
        return css`
          font-style: italic;
          font-weight: 600;
        `;
      case 'bold':
        return css`
          font-style: normal;
          font-weight: 700;
        `;
      case 'boldItalic':
        return css`
          font-style: italic;
          font-weight: 700;
        `;
      default:
        return css`
          font-style: inherit;
          font-weight: inherit;
        `;
    }
  }}
`;

const fontSizeCss = css<Props>`
  ${({ fontSize }) => {
    switch (fontSize) {
      case 'md':
        return css`
          font-size: 16px;
          line-height: 1.5;
        `;
      case 'sm':
        return css`
          font-size: 14px;
          line-height: 1.125;
        `;
      case 'xs':
        return css`
          font-size: 12px;
          line-height: 1;
        `;
      case 'lg':
        return css`
          font-size: 16px;
          line-height: 24px;
        `;
      default:
        return css`
          font-size: inherit;
          line-height: inherit;
        `;
    }
  }}
`;

const decorationCss = css<Props>`
  ${({ decoration }) => decoration && `text-decoration: ${decoration};`}
`;

const transformCss = css<Props>`
  ${({ transform }) => transform && `text-transform: ${transform};`}
`;

const colorCss = css<Props>`
  color: ${({ color, theme }) => (color ? color : theme.colors.neutral.primaryNavy)};
`;

const multilineEllipsesCss = css<Props>`
  ${({ multilineEllipses }) =>
    multilineEllipses &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${multilineEllipses};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    `}
`;

export const Span = styled.span<Props>`
  ${baseCss}
  ${fontWeightCss}
  ${fontSizeCss}
  ${decorationCss}
  ${transformCss}
  ${colorCss}
  ${multilineEllipsesCss};
`;

export const Strong = styled.strong<Props>`
  ${baseCss}
  ${fontWeightCss}
  ${fontSizeCss}
  ${decorationCss}
  ${transformCss}
  ${colorCss}
  ${multilineEllipsesCss};
`;

export const Paragraph = styled.p<Props>`
  ${baseCss}
  ${fontWeightCss}
  ${fontSizeCss}
  ${decorationCss}
  ${transformCss}
  ${colorCss}
  ${multilineEllipsesCss};
`;

export const Time = styled.time<Props>`
  ${baseCss}
  ${fontWeightCss}
  ${fontSizeCss}
  ${decorationCss}
  ${transformCss}
  ${colorCss}
  ${multilineEllipsesCss};
`;
