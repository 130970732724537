import React from 'react';

import { Text } from 'components/v2/Typography/Text';
import Invoice from 'screens/Invoices/types/invoice';

import { InvoiceCardList } from '../InvoiceCardList/InvoiceCardList';
import { InvoicesTable } from '../InvoicesTable/InvoicesTable';
import { NoInvoicesContainer } from './InvoicesDisplayed.styled';

export const InvoicesDisplayed = ({
  invoices,
  displayAsTable,
  isViosPatient,
  onInvoiceSelected,
  onViewInvoice,
  onPayWithCredit,
  totalBalance
}: {
  invoices: Invoice[];
  displayAsTable: boolean;
  isViosPatient: boolean;
  onInvoiceSelected: (invoice: Invoice) => void;
  onViewInvoice: (invoice: Invoice) => void;
  onPayWithCredit: (invoice: Invoice) => void;
  totalBalance: number;
}) => {
  if (invoices.length === 0)
    return (
      <NoInvoicesContainer>
        <Text tag="span" fontStyle="medium">
          There are currently no invoices on your account. <br />
          We will send you an email if there is any financial activity or please check back at a
          later time.
        </Text>
      </NoInvoicesContainer>
    );
  return displayAsTable ? (
    <InvoicesTable
      invoices={invoices.filter(invoice => invoice != null)}
      totalBalance={totalBalance}
      onPayInvoice={invoice => onInvoiceSelected(invoice)}
      onViewInvoice={invoice => onViewInvoice(invoice)}
      onPayWithCredit={invoice => onPayWithCredit(invoice)}
      isViosPatient={isViosPatient}
    />
  ) : (
    <InvoiceCardList
      invoices={invoices.filter(invoice => invoice != null)}
      totalBalance={totalBalance}
      onPayInvoice={invoice => onInvoiceSelected(invoice)}
      onViewInvoice={invoice => onViewInvoice(invoice)}
      onPayWithCredit={invoice => onPayWithCredit(invoice)}
      isViosPatient={isViosPatient}
    />
  );
};
