import React from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

const { colors, fonts } = themes;

const UnderlineButton = styled.div`
  color: ${colors.neutral.primaryNavy};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-decoration: underline;
  ${fonts.Pitch};
  cursor: pointer;
`;

type Props = {
  className?: string;
  label: string;
  onClick: () => void;
};

const UnderlineButtonC = (props: Props) => {
  const { className, label, onClick } = props;
  return (
    <UnderlineButton className={className} onClick={onClick}>
      {label}
    </UnderlineButton>
  );
};

export default UnderlineButtonC;
