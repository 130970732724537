import { themes } from 'kb-shared';
import styled from 'styled-components';

import { IndicatorProps, IndicatorFillOverlayProps } from './MobileStepTracker.types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.div`
  text-align: left;
  ${themes.fonts.FoundersGrotesk};
  font-size: 14px;
  color: ${themes.colors.neutral.primaryNavy};
  padding-top: 14px;
`;

export const IndicatorDotsOverlayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
  height: 100%;
  z-index: 1;
`;

// IndicatorLine
const STEPPER_WIDTH = 200;

export const IndicatorLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: ${`${STEPPER_WIDTH}px`};
  height: 6px;
  background-color: ${themes.colors.yellow.lightestYellow};
  ${({ active }: IndicatorProps) => `
    background-color: ${
      active ? themes.colors.yellow.primary : themes.colors.yellow.lightestYellow
    };
    transition: all 400ms ease 0s;
  `}
`;

export const IndicatorFillOverlay = styled.div`
  position: absolute;
  height: 6px;
  background-color: ${themes.colors.yellow.primary};
  width: ${(props: IndicatorFillOverlayProps) => `${props.progress * STEPPER_WIDTH}px`};
  transition: all 600ms ease 0s;
`;

// IndicatorIcon

export const Indicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  ${({ active }: IndicatorProps) => `
    background-color: ${
      active ? themes.colors.yellow.primary : themes.colors.yellow.lightestYellow
    };
    transition: all 800ms ease 200ms;
  `}
`;

export const InnerIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  ${({ active, clickable }: IndicatorProps) => `
    background-color: ${active ? themes.colors.neutral.primaryNavy : 'white'};
    transition: all 800ms ease 200ms;
    cursor: ${clickable ? 'pointer' : 'auto'}
  `}
`;

export const IndicatorContainer = styled.div`
  display: flex;
`;
