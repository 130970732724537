import styled from 'styled-components';

export const ExpandedMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 300;
  background-color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  height: 100%;
  width: 320px;
  align-items: center;
  top: 0;
  left: 0px;
`;

export const MenuContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.fonts.Gotham}
  font-weight: 400;
  padding-left: 16px;
  padding-right: 16px;
`;

export const ButtonItemsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 16px;
`;

export const MenuUserContainer = styled.div`
  display: flex;
  flex-direction: row;

  > label {
    align-self: flex-end;
  }
`;

export const MenuSecondary = styled.div`
  padding: 0 12px;
  margin-bottom: 24px;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const LogoutSpan = styled.span`
  color: ${({ theme }) => theme.colors.yellow.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.8px;
`;

export const Icon = styled.i`
  color: ${({ theme }) => theme.colors.yellow.primary};
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  text-align: center;
  line-height: 24px;
`;
