import { useQuery } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Heading } from 'components/v2/Typography';
import { Appointment } from 'kb-shared';
import { PATIENT_APPOINTMENTS } from 'kb-shared/graphql/queries';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { ResponsiveContainer } from 'screens/styled_common';
import { pageUrl } from 'utilities/pageUrl';

import { BookingButton, Content, HeaderContainer } from './Appointments.styled';
import { PastAppointments } from './components/PastAppointments/PastAppointments';
import { UpcomingAppointments } from './components/UpcomingAppointments/UpcomingAppointments';

export const Appointments: React.FC = () => {
  const history = useHistory();

  const { data } = useQuery<{ appointments: Appointment[] }>(PATIENT_APPOINTMENTS, {
    onError: error => BugTracker.notify(error, 'PatientAppointments-Appointments.tsx')
  });

  return (
    <ResponsiveContainer>
      <HeaderContainer>
        <Heading tag="h1" noMargin>
          My Appointments
        </Heading>
        <BookingButton
          category="primary"
          label="Schedule Appointment"
          onClick={() => history.push(pageUrl.booking.bookingQuiz())}
          size="md"
        />
      </HeaderContainer>
      <Content>
        <UpcomingAppointments />
        <PastAppointments appointments={data?.appointments} />
      </Content>
    </ResponsiveContainer>
  );
};
