import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const TextInput = styled.input`
  font-size: 24px;
  color: black;
  height: 40px;
  width: 85px;
  border: 0px;
  border-bottom: solid 1px black;
  text-align: left;
  background-color: transparent;
  ${themes.fonts.DomaineDisplay};

  ::placeholder {
    ${themes.fonts.DomaineDisplay};
    font-size: 24px;
    text-align: left;
    color: #a9a7b0;
  }

  :focus {
    outline: 0;
    border-bottom: solid 1px ${themes.colors.yellow.primary};
  }

  :last-child {
    margin: 0 0 0 34px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ErrorMessage = styled.div`
  ${({ theme }) => theme.fonts.DomaineDisplay}

  color: ${({ theme }) => theme.colors.semantic.darkRed};
  margin: 12px 0 0 0;
`;
