import { useMutation } from '@apollo/client';
import React, { FormEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from 'components/v2/Buttons/Button';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { LongWordContainer } from 'components/v2/common.styled';
import { TextField } from 'components/v2/Form';
import { Heading } from 'components/v2/Typography';
import { utils } from 'kb-shared';
import { graphql } from 'kb-shared';
import { CONFIRM_PASSWORD_CHANGE, REQUEST_PASSWORD_CHANGE_CODE } from 'kb-shared/graphql/mutations';
import { sanitizeUsername } from 'kb-shared/utilities/auth.helper';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { isEmailCodeValid } from 'kb-shared/utilities/validation';
import { analytics } from 'utilities/analytics';
import { showErrorToast, showSuccessToast } from 'utilities/notificationUtils';

import { CreatePasswordInput } from '../Book/components/CreatePasswordInput';
import { ACTIVATE_PATIENT } from './ForgotPasswordForm.graphql';
import {
  ButtonWrapper,
  FormContent,
  FormContainer,
  Form,
  SubTitleContainer
} from './ForgotPasswordForm.styled';
import {
  ConfirmPasswordChageResponse,
  Props,
  RequestPasswordChageResponse
} from './ForgotPasswordForm.types';

const { isEmailValid, KBContacts } = utils;

export const ForgotPasswordForm = ({
  onSuccessfulReset,
  onGoBack,
  handleUnconfirmed,
  updateFormType,
  formType,
  requestVerificationCodeDescription,
  initialEmail,
  activateAccount
}: Props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [email, setEmail] = useState(decodeURIComponent(queryParams.get('email') || ''));
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordInputValid, setPasswordInputValid] = useState(false);
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const [requestPasswordChangeCode] = useMutation<RequestPasswordChageResponse>(
    REQUEST_PASSWORD_CHANGE_CODE,
    {
      variables: {
        email: sanitizeUsername(email)
      },
      onCompleted: response => {
        setLoading(false);
        if (response?.requestPasswordChangeCode?.succeeded) {
          updateFormType('reset_password');
          analytics.track(analytics.EVENTS.PASSWORD_RESET_VERIFICATION_CODE_REQUEST_SUCCEEDED);
        } else {
          analytics.track(analytics.EVENTS.PASSWORD_RESET_VERIFICATION_CODE_REQUEST_FAILED);
          const errorCode = response?.requestPasswordChangeCode?.errorCode;
          if (errorCode === 'NotVerifiedEmailException') {
            showErrorToast(UNCONFIRMED_CUTOVER_PATIENT_ERROR);
            setTimeout(() => handleUnconfirmed(email), 3000);
            return;
          }

          showErrorForFailedPasswordChange(errorCode || '');
          if (errorCode !== 'NotAuthorizedException')
            BugTracker.notify(errorCode, 'RequestPasswordChangeCode');
        }
      },
      onError: error => {
        setLoading(false);
        BugTracker.notify(error, 'RequestPasswordChangeCode');
        showErrorToast(PASSWORD_CHANGE_GENERAL_ERROR);
        analytics.track(analytics.EVENTS.PASSWORD_RESET_VERIFICATION_CODE_REQUEST_FAILED);
      }
    }
  );

  const [confirmPasswordChange] = useMutation<ConfirmPasswordChageResponse>(
    CONFIRM_PASSWORD_CHANGE,
    {
      variables: {
        email: sanitizeUsername(email),
        code: code,
        newPassword: password
      },
      onCompleted: response => {
        setLoading(false);
        if (response?.confirmPasswordChange?.succeeded) {
          showSuccessToast('Reset password successful, please login.');

          if (activateAccount)
            analytics.track(analytics.EVENTS.ACCOUNT_ACTIVATION_NEW_PASSWORD_SUBMIT_SUCCEEDED);
          else analytics.track(analytics.EVENTS.NEW_PASSWORD_SUBMIT_SUCCEEDED);

          setTimeout(() => {
            onSuccessfulReset();
          }, 3000);
        } else {
          const errorCode = response?.confirmPasswordChange?.errorCode;
          BugTracker.notify(errorCode, 'ConfirmPasswordChange');
          showErrorForFailedNewPasswordVerification(errorCode || '');

          if (activateAccount)
            analytics.track(analytics.EVENTS.ACCOUNT_ACTIVATION_NEW_PASSWORD_SUBMIT_FAILED);
          else analytics.track(analytics.EVENTS.NEW_PASSWORD_SUBMIT_FAILED);
        }
      },
      onError: error => {
        setLoading(false);
        BugTracker.notify(error, 'ConfirmPasswordChange');
        showErrorToast(PASSWORD_VERIFICATION_GENERAL_ERROR);

        if (activateAccount)
          analytics.track(analytics.EVENTS.ACCOUNT_ACTIVATION_NEW_PASSWORD_SUBMIT_FAILED);
        else analytics.track(analytics.EVENTS.NEW_PASSWORD_SUBMIT_FAILED);
      }
    }
  );

  const isInvalidPasswordResetInput =
    !code || !password || password !== confirmPassword || !passwordInputValid;

  useEffect(() => {
    if (email) submitRequestVerificationCode(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitRequestVerificationCode = async (e: FormEvent | undefined) => {
    e?.preventDefault();

    setLoading(true);

    // if we are activating the account, we need to confirm the user first in cognito
    if (activateAccount) {
      analytics.track(analytics.EVENTS.ACCOUNT_ACTIVATION_EMAIL_SUBMITTED);
      try {
        const formattedEmail = email.toLowerCase();
        const { data } = await graphql.client.mutate({
          mutation: ACTIVATE_PATIENT,
          variables: { email: formattedEmail }
        });

        if (!data?.activatePatient?.succeeded) {
          throw new Error('Failed to activate account');
        } else {
          setLoading(false);
          updateFormType('reset_password');
          analytics.track(analytics.EVENTS.ACCOUNT_ACTIVATION_EMAIL_SUBMIT_SUCCEEDED);
          return;
        }
      } catch (error) {
        BugTracker.notify(error as Error, 'ActivatePatient');
        setLoading(false);
        showErrorToast(CUTOVER_ACTIVATION_GENERAL_ERROR);
        analytics.track(analytics.EVENTS.ACCOUNT_ACTIVATION_EMAIL_SUBMIT_FAILED);
        return;
      }
    }

    analytics.track(analytics.EVENTS.PASSWORD_RESET_VERIFICATION_CODE_REQUESTED);
    requestPasswordChangeCode();
  };

  const submitChangePassword = async (e: FormEvent) => {
    e?.preventDefault();

    setLoading(true);
    if (activateAccount)
      analytics.track(analytics.EVENTS.ACCOUNT_ACTIVATION_NEW_PASSWORD_SUBMITTED);
    else analytics.track(analytics.EVENTS.NEW_PASSWORD_SUBMITTED);
    confirmPasswordChange();
  };

  const renderRequestVerificationCode = () => {
    const inputEmail = initialEmail || email;

    return (
      <FormContainer>
        <FormContent>
          <SubTitleContainer>
            <Heading tag="span" styledAs="h5">
              {requestVerificationCodeDescription || defaultRequestVerificationCodeDescription}
            </Heading>
          </SubTitleContainer>

          <Form onSubmit={submitRequestVerificationCode} autoComplete="new-password">
            <TextField
              id="email-input"
              label="EMAIL ADDRESS"
              value={inputEmail}
              status={!!initialEmail ? 'disabled' : 'default'}
              onChange={e => {
                const email = e.currentTarget.value.trim();
                setEmail(email);
              }}
              autoComplete="email"
              placeholder="janedoe@email.com"
              spellCheck={false}
            />
            <ButtonWrapper>
              <Button
                category="primary"
                isDisabled={
                  loading || (!email && !initialEmail) || !isEmailValid(email || initialEmail)
                }
                label="REQUEST VERIFICATION CODE"
                fullWidth={true}
              />
            </ButtonWrapper>
          </Form>
          <LinkButton onClick={onGoBack} text="GO BACK" size="sm" fontStyle="semibold" />
        </FormContent>
      </FormContainer>
    );
  };

  const renderResetPassword = () => {
    const isSubmitDisabled = loading || isInvalidPasswordResetInput || !isEmailCodeValid(code);
    return (
      <FormContainer>
        <FormContent>
          <SubTitleContainer>
            <div>
              <Heading tag="span" styledAs="h5">
                {defaultCheckEmailInstruction}
              </Heading>
            </div>
            <LongWordContainer>
              <Heading tag="span" styledAs="h5">
                {email}
              </Heading>
            </LongWordContainer>
          </SubTitleContainer>

          <Form onSubmit={submitChangePassword} autoComplete="new-password">
            <TextField
              id="input-verification-code"
              label="VERIFICATION CODE"
              value={code}
              onChange={e => {
                const code = e.currentTarget.value;
                setCode(code);
              }}
              spellCheck={false}
              autoComplete="off"
              placeholder="Enter verification code"
              type="number"
              hideArrowsForNumberInput={true}
            />
            <CreatePasswordInput
              value={password}
              onChange={({ currentTarget }) => setPassword(currentTarget.value)}
              confirmationValue={confirmPassword}
              onChangeConfirmation={({ currentTarget }) => setConfirmPassword(currentTarget.value)}
              onInputValidation={valid => setPasswordInputValid(valid)}
            />
            <ButtonWrapper>
              <Button
                category="primary"
                isDisabled={isSubmitDisabled}
                label="CREATE NEW PASSWORD"
                fullWidth={true}
              />
            </ButtonWrapper>
          </Form>
          <LinkButton onClick={onGoBack} text="GO BACK" size="sm" fontStyle="semibold" />
        </FormContent>
      </FormContainer>
    );
  };

  if (formType === 'request_code') {
    return renderRequestVerificationCode();
  } else {
    return renderResetPassword();
  }
};

const defaultCheckEmailInstruction = 'Check your email for your verification code.';
const defaultRequestVerificationCodeDescription =
  'Enter the email address you created your account with to receive a verification code that will enable you to set a new password.';
const LIMIT_REACHED_ERROR = `You have exceeded the limit for requesting a password reset. Please wait then try again, or contact ${KBContacts.navigatorEmail}`;
const VERIFICATION_CODE_DELIVERY_FAILED_ERROR = `We can't deliver verification code needed to reset the password. Please wait then try again, or contact ${KBContacts.navigatorEmail}`;
const PASSWORD_CHANGE_GENERAL_ERROR = `Email doesn't exist or you aren't authorised to reset the password. If you are sure that email is correct, please contact ${KBContacts.navigatorEmail}`;
const WRONG_VERIFICATION_CODE_ERROR = 'Confirmation code incorrect, please check your email.';
const EXPIRED_VERIFICATION_CODE_ERROR =
  'Confirmation code has expired. Please go back and try again.';
const ACCOUNT_UNCONFIRMED_ERROR =
  'Account has not been confirmed, please check your email for a verification code.';
const PASSWORD_VERIFICATION_GENERAL_ERROR = `Account doesn't exist or you aren't authorised to reset the password. If you are sure that email is correct, please contact ${KBContacts.navigatorEmail}`;
const WRONG_PASSWORD_FORMAT =
  'Password format incorrect. Must contain both uppercase and lowercase letters, a special character, and be at least 8 characters long.';
const UNCONFIRMED_CUTOVER_PATIENT_ERROR =
  'You have an account that has not been confirmed, please check your email for a verification code.';
const CUTOVER_ACTIVATION_GENERAL_ERROR = `Your account can't be activated. Please wait then try again, or contact ${KBContacts.navigatorEmail}`;

const showErrorForFailedPasswordChange = (errorCode: string) => {
  if (errorCode === 'LimitExceededException' || errorCode === 'TooManyRequestsException') {
    showErrorToast(LIMIT_REACHED_ERROR);
  } else if (errorCode === 'CodeDeliveryFailureException') {
    showErrorToast(VERIFICATION_CODE_DELIVERY_FAILED_ERROR);
  } else {
    showErrorToast(PASSWORD_CHANGE_GENERAL_ERROR);
  }
};

const showErrorForFailedNewPasswordVerification = (errorCode: string) => {
  if (
    errorCode === 'LimitExceededException' ||
    errorCode === 'TooManyRequestsException' ||
    errorCode === 'TooManyFailedAttemptsException'
  ) {
    showErrorToast(LIMIT_REACHED_ERROR);
  } else if (errorCode === 'CodeMismatchException') {
    showErrorToast(WRONG_VERIFICATION_CODE_ERROR);
  } else if (errorCode === 'ExpiredCodeException') {
    showErrorToast(EXPIRED_VERIFICATION_CODE_ERROR);
  } else if (errorCode === 'InvalidPasswordException') {
    showErrorToast(WRONG_PASSWORD_FORMAT);
  } else if (errorCode === 'UserNotConfirmedException') {
    showErrorToast(ACCOUNT_UNCONFIRMED_ERROR);
  } else {
    showErrorToast(PASSWORD_VERIFICATION_GENERAL_ERROR);
  }
};
