import React from 'react';

import { themes } from 'kb-shared';

import {
  Container,
  Label,
  MobileDateInput,
  StripeChildWrapper,
  StripeContainer,
  StyledMaskInput,
  StyledZipCodeInput,
  StyledCalculatorInput,
  Input,
  HelperText,
  RequiredIndicatorLabel
} from './NewTextInput.styles';
import { Props, StripeLabelProps } from './NewTextInput.types';

function renderInput(props: Props) {
  const {
    label,
    value,
    onChange,
    onBlur,
    onFocus,
    name,
    error,
    inputType,
    placeholder,
    disabled,
    inputId,
    ...rest
  } = props;
  if (inputType === 'date') {
    if (props.mobile) {
      // @ts-ignore
      return (
        <MobileDateInput id={inputId} name={name} onChange={onChange} type="date" onBlur={onBlur} />
      );
    }
    return (
      <StyledMaskInput
        id={inputId}
        // @ts-ignore
        name={name}
        mask="11/11/1111"
        value={value || ''}
        onChange={onChange}
        placeholder={'MM/DD/YYY'}
        placeholderChar={'-'}
        onBlur={onBlur}
        disabled={disabled}
      />
    );
  } else if (inputType === 'phone') {
    return (
      <StyledMaskInput
        id={inputId}
        // @ts-ignore
        name={name}
        mask="111-111-1111"
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={'555-555-5555'}
        placeholderChar={'-'}
        type="tel"
        autoComplete="tel"
        inputMode="tel"
        disabled={disabled}
      />
    );
  } else if (inputType === 'currency') {
    return (
      <Input
        id={label}
        value={value || ''}
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        placeholder={placeholder || '$0.00'}
        inputMode="tel"
        disabled={disabled}
      />
    );
  } else if (inputType === 'calculator') {
    return (
      // @ts-ignore
      <StyledCalculatorInput
        id={label}
        // @ts-ignore
        value={value}
        // @ts-ignore
        onChange={onChange}
        // @ts-ignore
        name={name}
        // @ts-ignore
        error={error}
        // @ts-ignore
        placeholder={placeholder}
        disabled={disabled}
        // @ts-ignore
        {...rest}
      />
    );
  } else if (inputType === 'zip') {
    // US only zip code
    return (
      <StyledZipCodeInput
        id={inputId}
        name={name}
        mask="11111"
        value={value || ''}
        onChange={onChange}
        placeholder={'ZIP'}
        onBlur={onBlur}
        placeholderChar={' '}
        error={error}
        type="text"
        disabled={disabled}
      />
    );
  }
  return (
    // @ts-ignore
    <Input
      id={inputId}
      // @ts-ignore
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      // @ts-ignore
      name={name}
      // @ts-ignore
      error={error}
      // @ts-ignore
      placeholder={placeholder}
      disabled={disabled}
      {...rest}
    />
  );
}

const TextInput = (props: Props) => {
  const {
    label,
    inputId,
    className,
    isRequired,
    helperText,
    helperElement,
    labelTextTransform,
    fullMode
  } = props;

  return (
    <Container className={className} fullMode={fullMode}>
      <Label htmlFor={inputId} visible={true} textTransform={labelTextTransform}>
        {label}
      </Label>
      {isRequired && <RequiredIndicatorLabel>{'*'}</RequiredIndicatorLabel>}
      {renderInput(props)}
      {helperText && <HelperText>{helperText}</HelperText>}
      {helperElement && <HelperText>{helperElement}</HelperText>}
    </Container>
  );
};

TextInput.defaultProps = {
  label: '',
  error: false,
  onChange: null,
  onBlur: null,
  onfocus: null,
  name: '',
  value: '',
  type: 'text',
  mobile: false
};

export const StripeInputWrapper = (props: StripeLabelProps) => {
  const { label, type, children, width } = props;
  const renderLabel = () => (
    // @ts-ignore
    <Label htmlFor={label} visible={true} type={type}>
      {label}
    </Label>
  );
  return (
    <StripeContainer width={width}>
      {renderLabel()}
      <StripeChildWrapper>{children}</StripeChildWrapper>
    </StripeContainer>
  );
};

StripeInputWrapper.defaultProps = {
  type: '',
  label: '',
  width: 'auto',
  children: null
};

export const stripeInputStyle = {
  base: {
    fontFamily: 'Gotham, Helvetica, Arial, sans-serif',
    fontSize: '18px',
    color: 'black',
    textAlign: 'left',
    '::placeholder': {
      fontFamily: 'Gotham, Helvetica, Arial, sans-serif',
      fontSize: '18px',
      textAlign: 'left',
      color: themes.deprecatedColors.gray
    }
  }
};

export default TextInput;
