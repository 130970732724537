import React from 'react';

import { Button } from 'components/v2/Buttons/Button';
import { themes } from 'kb-shared';
import styled from 'styled-components';

type Props = {
  canSchedule?: boolean;
  onScheduleAssessment: () => void;
};

const EmptyState = ({ canSchedule = true, onScheduleAssessment }: Props) => (
  <Container>
    <Title>{"You don't have any results yet."}</Title>
    <ButtonWrapper>
      {canSchedule && <Button label="Schedule an assessment" onClick={onScheduleAssessment} />}
    </ButtonWrapper>
  </Container>
);

export default EmptyState;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${themes.colors.neutral.primaryNavy};
  padding-left: 24px;
  padding-right: 24px;
`;

const Title = styled.p`
  ${themes.fonts.DomaineDisplay};
  font-size: 28px;
  color: white;
  text-align: center;
  margin-bottom: 28px;
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  margin: 20px 0 40px 0;
  display: flex;
  justify-content: center;
`;
