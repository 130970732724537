import styled from 'styled-components';

interface SelectedProps {
  selected?: boolean;
}

export const TimeSlotMessage = styled.div`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  font-size: 14px;
  margin-top: 4px;
  padding: 0 0 10px 10px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
`;

export const BookingTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
`;

export const TimeOfDayText = styled.div`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  font-size: 18px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  padding-bottom: 10px;
  font-weight: bolder;
`;

export const DayContainer = styled.div`
  padding-bottom: 20px;
`;

export const TimeSlotContainer = styled.div.attrs({ 'data-testid': 'time-slot-container' })`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TimeSlotBubble = styled.button.attrs({ type: 'button' })<SelectedProps>`
  cursor: pointer;
  width: 80px;
  height: 28px;
  border-radius: 14px;
  border: solid 1px ${({ theme }) => theme.colors.neutral.primaryNavy};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  :hover {
    border: solid 2px ${({ theme }) => theme.colors.neutral.primaryNavy};
  }
  ${({ theme, selected }) => `
    background-color: ${selected ? theme.colors.neutral.primaryNavy : 'white'};
  `}
  transition: all 400ms ease 0s;
`;

export const TimeSlotText = styled.div<SelectedProps>`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  font-size: 14px;
  ${({ theme, selected }) => `
    color: ${selected ? 'white' : theme.colors.neutral.primaryNavy};
  `}
  transition: all 400ms ease 0s;
`;
