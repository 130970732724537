import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ArrowRightFromBracket } from 'assets/icons/Icons';
import { Loader } from 'components/Loader/Loader';
import { Button } from 'components/v2/Buttons/Button';
import { Tab } from 'components/v2/Tabs/Tab.types';
import { Tabs } from 'components/v2/Tabs/Tabs';
import { getTabRequestedInQueryString } from 'components/v2/Tabs/Tabs.utils';
import { Heading, Text } from 'components/v2/Typography';
import usePatient from 'hooks/usePatient';
import { useQueryParams } from 'hooks/useQueryParams';
import { CONTAINER_DEPRECATED } from 'screens/styled_common';
import { pageUrl } from 'utilities/pageUrl';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { generateTabs } from './MyProfile.constants';
import { Header, HeadingButtonWrapper, TextWrap } from './MyProfile.styled';

export function MyProfile() {
  const history = useHistory();
  const queryString = useQueryParams();
  const { isDesktop } = useBreakpoints();
  const { patient, loading: loadingPatient } = usePatient();
  const { tabs } = generateTabs(patient);

  const [tabSelected, setTabSelected] = useState(false);

  const onChangeTab = useCallback(
    (tab?: Tab) => {
      setTabSelected(!!tab);

      if (tab?.id) return history.push(`?tab=${tab?.id}`);
      history.push();
    },
    [setTabSelected, history]
  );

  function handleLogoutClick() {
    history.push(pageUrl.logout());
  }

  if (loadingPatient || !patient) return <Loader absoluteCentered />;

  return (
    <CONTAINER_DEPRECATED>
      <Header tabSelected={tabSelected}>
        <HeadingButtonWrapper>
          <Heading>My Profile</Heading>
          <Button
            label="Log out"
            uppercase
            category="primary"
            size={isDesktop ? 'md' : 'sm'}
            gap="md"
            leftIcon={<ArrowRightFromBracket type="solid" />}
            onClick={handleLogoutClick}
          />
        </HeadingButtonWrapper>

        <Text tag="span">
          This is the profile information we currently have on file for you.
          <TextWrap>
            <Text tag="strong">All changes are automatically saved.</Text>
          </TextWrap>
        </Text>
      </Header>

      <Tabs
        tabs={tabs}
        initialTab={getTabRequestedInQueryString(queryString, tabs)}
        onChangeTab={onChangeTab}
      />
    </CONTAINER_DEPRECATED>
  );
}
