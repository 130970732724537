import { Button } from 'components/v2/Buttons/Button';
import { themes } from 'kb-shared';
import styled from 'styled-components';

const { breakpoints } = themes;

export const ContentWrapper = styled.div`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  cursor: default;
  display: flex;
  flex-direction: column;
  // margin: 2px 0px 28px 50px;
  // position: relative;

  @media screen and (min-width: ${breakpoints.smallDesktop}) {
    flex-direction: row;
  }
`;

export const RightYellowButton = styled(Button)`
  right: 24px;
  min-width: fit-content;
  height: fit-content;
  width: fit-content;
  white-space: nowrap;
`;
