import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (min-width: ${themes.breakpoints.tabletLandscape}) {
    margin-top: 24px;
  }
`;

export const TitleText = styled.span`
  ${themes.fonts.DomaineDisplay};
  font-size: 28px;
  color: ${themes.colors.neutral.primaryNavy};

  @media screen and (min-width: ${themes.breakpoints.tabletLandscape}) {
    font-size: 36px;
  }
`;

export const SubtitleText = styled.span`
  ${themes.fonts.FoundersGrotesk};
  font-size: 16px;
  color: ${themes.colors.neutral.primaryNavy};
  white-space: pre-wrap;
`;

export const QuestionsContainer = styled.div`
  padding-top: 28px;
`;

export const OptionContainer = styled.div`
  margin: 18px 0px;
`;
