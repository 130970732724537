import styled, { css } from 'styled-components';

export const Button = styled.button`
  width: 100%;
  background: ${({ theme }) => theme.colors.yellow.primary};
  height: 64px;
  margin-top: 24px;
  border-radius: 8px;

  ${({ theme }) => theme.fonts.Gotham}
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};

  ${props =>
    props.disabled &&
    css`
      background: ${({ theme }) => theme.colors.neutral.lighterNavy};
      color: ${({ theme }) => theme.colors.neutral.navy};
      cursor: not-allowed;
    `}
`;
