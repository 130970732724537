import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
`;

export const ResultWrapper = styled.div`
  width: 100%;
  padding: 35px 20px 20px;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0 0 24px 0;

  &:last-child {
    margin: 0;
  }

  p {
    font-size: 14px;
    ${({ theme }) => theme.fonts.FoundersGrotesk};

    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }
`;

export const ResultTitle = styled.h1`
  font-size: 32px;
  margin-bottom: 6px;
`;

export const ResultBox = styled.div`
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 20px;
  display: flex;
  flex-direction: row;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const ToggleResultIcon = styled.span`
  cursor: pointer;
`;

export const HormoneBox = styled.div`
  width: 40%;
  margin: 0 18px 0 0;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const HormoneTitle = styled.h2`
  font-size: 30px;
  margin-bottom: 10px;
  ${({ theme }) => theme.fonts.DomaineDisplay};
`;

export const HormoneDescription = styled.h3`
  ${({ theme }) => theme.fonts.DomaineDisplay};
  font-size: 18px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Recommendation = styled.p`
  white-space: pre-wrap;
`;

export const HelpIcon = styled.button`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${themes.colors.neutral.primaryNavy};
  border-radius: 50%;
  display: block;
  width: 13px;
  height: 13px;
  font-size: 11px;
  text-align: center;
  margin-left: 5px;
`;

export const ResultHeader = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .icon {
    width: 19px;
    height: 13px;
  }
`;

export const HormoneChart = styled.div`
  background-color: ${themes.colors.semantic.lightPink};
  width: 60%;
  height: 156px;
  padding: 10px;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const HormoneChartNumber = styled.span`
  font-size: 36px;
`;

export const HormoneChartUnit = styled.span`
  font-size: 14px;
`;

export const RangeBar = styled.span`
  position: absolute;
  display: flex;
  top: 84px;
  left: 0;
  height: 8px;
  width: 100%;
  background: linear-gradient(90deg, #f8edcd 0%, #f7dd6f 100%);
`;

export const Legend = styled.span`
  margin-top: 14px;
  font-size: 18px;
  width: 33%;
  text-align: center;
  font-size: 14px;
  ${({ theme }) => theme.fonts.FoundersGrotesk};
`;

interface ValueProps {
  position: number;
}

export const Value = styled.span<ValueProps>`
  position: absolute;
  left: ${({ position }) => `${position}%`};
  transform: translateX(-50%);

  margin-top: -8px;

  text-align: center;
  font-size: 14px;
  ${({ theme }) => theme.fonts.FoundersGrotesk};

  &:before {
    content: '';
    display: block;
    height: 40px;
    width: 2px;
    margin: auto;
    border-left: 2px dotted #2c283c;
    margin-bottom: 8px;
  }
`;

interface NormalValueProps {
  position: number;
}

export const NormalValue = styled.div<NormalValueProps>`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  left: ${({ position }) => `${position}%`};
`;

export const NormalValueBar = styled.div`
  width: 5px;
  height: 26px;
  background-color: #2c283c;
  margin: auto;
  border-radius: 4px;
`;
