/**
 * Provides a common interface for web/native bug tracker.
 * On web, we currently use Honeybadger
 *  */
export interface BugTrackerInterface {
  notify(...args: any[]): void;
}

const noopTracker: BugTrackerInterface = {
  notify: () => {}
};

class BugTrackerWrapper implements BugTrackerInterface {
  inner: BugTrackerInterface = noopTracker;

  notify(...args: any[]) {
    this.inner.notify(...args);
  }
}

export const BugTracker = new BugTrackerWrapper();

export function setBugTracker(tracker: BugTrackerInterface) {
  BugTracker.inner = tracker;
}
