import React, { Component } from 'react';

import CalendarIcon from 'components/SVG/Calendar';
import LeftBracket from 'components/SVG/LeftBracket';
import RightBracket from 'components/SVG/RightBracket';
import { themes } from 'kb-shared';
import styled from 'styled-components';

import Modal from '../../Modal';

type Props = {
  visible: boolean;
  dismiss: Function;
};

type State = {};

export default class FinishModal extends Component<Props, State> {
  maybeLater = () => {
    this.props.dismiss();
  };

  render() {
    return (
      <Modal
        onRequestClose={this.maybeLater}
        open={this.props.visible}
        label=" Electronic Communications Consent"
      >
        <Container>
          <Content>
            <IconContainer>
              <LeftBracket />
              <CalendarIcon />
              <RightBracket />
            </IconContainer>
            <Title>Great!</Title>
            <Text>
              Your care team has been notified. If you are starting a treatment cycle this month you
              will hear from your care team about the next steps soon!
            </Text>
            <BottomLink onClick={this.maybeLater}>Okay</BottomLink>
          </Content>
        </Container>
      </Modal>
    );
  }
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 320px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 28px 0px 28px 0px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  ${themes.fonts.DomaineDisplay};
  font-size: 48px;
  padding: 12px 0px 12px 0px;
`;

const Text = styled.div`
  ${themes.fonts.FoundersGrotesk};
  color: ${themes.colors.neutral.primaryNavy};
  font-size: 20px;
  text-align: center;
  padding: 0px 35px 28px 35px;
`;

const BottomLink = styled.a`
  ${themes.fonts.Pitch};
  color: ${themes.colors.neutral.primaryNavy};
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
  padding: 20px 0px 0px 0px;
  :hover {
    font-weight: bold;
  }
`;
