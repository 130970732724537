import React, { useEffect, useState } from 'react';

import { Appointment, AppointmentType, Patient, TimeSlot } from 'kb-shared';
import { ModalCancelResched } from 'screens/ModalCancelResched/ModalCancelResched';
import { analytics } from 'utilities/analytics';

import AppointmentCard from '../../screens/Appointments/components/AppointmentCard';
import './styles.scss';
import { Container } from './UpcomingAppointment.styled';

interface Props {
  timeSlot: TimeSlot | null;
  type: AppointmentType;
  isConfirmation: boolean;
  isPartOfPackage?: boolean;
  appointment: Appointment;
  onAppointmentCancelled?: Function;
  onReturnToDashboard?: Function | null;
  onReschedule?: Function;
  patient?: Patient;
  hasStepper?: boolean;
}

export const UpcomingAppointment = ({
  appointment,
  onReturnToDashboard,
  onAppointmentCancelled,
  onReschedule,
  type,
  isConfirmation,
  isPartOfPackage,
  patient,
  hasStepper
}: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    analytics.page(analytics.PAGES.CONFIRMATION);
    analytics.page(analytics.PAGES.APPOINTMENTS);
  }, []);

  const toggleCancelModal = () => {
    setShowModal(!showModal);
  };

  const returnToDashboard = () => {
    if (onReturnToDashboard) {
      onReturnToDashboard();
    } else {
      toggleCancelModal();
    }
  };

  return (
    <Container>
      <div hidden id="hidden-user-id">
        {patient && patient.id}
      </div>
      {isConfirmation && !isPartOfPackage && (
        <React.Fragment>
          <div hidden className="appt-confirm-page">
            true
          </div>
          <div hidden className="hidden-appt-id">
            {appointment.id}
          </div>
          <div hidden className="hidden-price">
            {type.rateCents / 100}
          </div>
          <div hidden className="hidden-appt-type-id">
            {type.id}
          </div>
        </React.Fragment>
      )}
      <AppointmentCard
        toggleModal={toggleCancelModal}
        type={{ ...appointment, ...type }}
        {...appointment}
        appointment={appointment}
        hasStepper={hasStepper}
        patient={patient}
      />
      <ModalCancelResched
        visible={showModal}
        onClose={toggleCancelModal}
        onReturnToDashboard={returnToDashboard}
        appointment={appointment}
        onAppointmentCancelled={onAppointmentCancelled}
        onReschedule={onReschedule}
      />
    </Container>
  );
};
