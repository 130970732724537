import theme from 'kb-shared/theme';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${theme.colors.white};
  box-shadow: 0px 1px 3px 0px rgba(67, 71, 146, 0.16), 0px 1px 1px 0px rgba(10, 13, 61, 0.08);
  border-left: 8px solid ${theme.colors.yellow.primary};
  border-radius: 8px;
  padding: 12px 24px 12px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  top: 42px;
  position: relative;
  margin: 0 32px;
  z-index: 1;
  max-width: 300px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    top: 110px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    max-width: none;
    top: 100px;
    padding: 16px 32px;
    gap: 12px;
  }
`;

export const EmailText = styled.span`
  word-break: break-word;
`;
