import React from 'react';

import { CalendarHeart } from 'assets/icons/Icons';
import { Text } from 'components/v2/Typography';
import { themes } from 'kb-shared';
import theme from 'kb-shared/theme';

import { IconContainer, WidgetTitleContainer } from '../../Widgets/Widgets.styled';
import { PregnancyCalculatorState } from '../hooks/use-pregnancy-calculator';
import { Container } from './TileWidget.styled';

interface Props {
  state: PregnancyCalculatorState;
}

export const TileWidget: React.FC<Props> = ({ children, state }) => {
  const DEFAULT_VARIANT = 7;
  const LMP_ENTERED_VARIANT = 11;

  const variant = state === 'lmp-entered' ? LMP_ENTERED_VARIANT : DEFAULT_VARIANT;

  return (
    <Container $variant={variant} $bgColor={theme.colors.semantic.lightPink}>
      <IconContainer $iconWidth={42} $iconHeight={48} $color={themes.colors.semantic.red}>
        <CalendarHeart type="solid" />
      </IconContainer>
      <WidgetTitleContainer $noMargin>
        <Text>Pregnancy Calculator</Text>
      </WidgetTitleContainer>
      {children}
    </Container>
  );
};
