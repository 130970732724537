import React from 'react';

import { ReactComponent as PregnancyCalculatorIcon } from 'assets/icons/pregnancy-calculator.svg';
import styled, { useTheme } from 'styled-components';

import { BracketContainer } from './styled-components';

const TileWidget: React.FC = ({ children }) => {
  const theme = useTheme();

  return (
    <WidgetContainer>
      <BracketContainer bracketColor={theme.colors.yellow.lightestYellow}>
        <PregnancyCalculatorIcon />
      </BracketContainer>
      <TitleText>Pregnancy Calculator</TitleText>
      {children}
    </WidgetContainer>
  );
};

export default TileWidget;

const WidgetContainer = styled.div`
  ${({ theme }) => theme.grid.container};
  padding-top: 20px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: ${({ theme }) => theme.deprecatedColors.beige};

  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  ${({ theme }) => theme.fonts.FoundersGrotesk};
`;

const TitleText = styled.div`
  ${({ theme }) => theme.fonts.DomaineDisplay}
  font-size: 24px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  margin: 12px 0px 8px 0px;
`;
