import { utils } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

const { getIdToken } = utils;

const allowedTraitKeys = [
  'account_creation_date',
  'email',
  'employer',
  'employer_membership',
  'firstname',
  'lab_id',
  'lastname',
  'phone'
] as const;

const allowedPropertyKeys = [
  'address',
  'appointment_category',
  'appointment_id',
  'appointment_type_id',
  'appointment_type_ids',
  'appointment_type_name',
  'city',
  'clinic_id',
  'clinic_name',
  'document_type',
  'error',
  'lab_id',
  'name',
  'option_id_selected',
  'option_text_selected',
  'page_number',
  'promo_code',
  'provider',
  'question_id',
  'question_text',
  'radius',
  'state',
  'time_slot_id',
  'time_slot_time',
  'zip_code',
  'enterprise_name'
] as const;

export const EVENTS = {
  EGG_COUNT_CALCULATOR_USED: 'Egg Count Calculator Used',
  PATIENT_PORTAL_BOOKING_APPOINTMENT_PAYMENT_LOADED:
    'Patient Portal - Booking Appointment - Payment Loaded',
  EMAIL_SIGN_UP_STARTED: 'Email Sign Up Started',
  GOOGLE_SIGN_UP_STARTED: 'Google Sign Up Started',
  APPLE_SIGN_UP_STARTED: 'Apple Sign Up Started',
  APPLE_SIGN_IN_STARTED: 'Apple Sign In Started',
  MEDTRONIC_SIGN_UP_STARTED: 'Medtronic Sign Up Started',
  DISNEY_SIGN_UP_STARTED: 'Disney Sign Up Started',
  MFA_CODE_SUBMITTED: 'MFA Code Submitted',
  EMAIL_SIGN_IN_STARTED: 'Email Sign In Started',
  MFA_CODE_REQUESTED: 'MFA Code Requested',
  FORGOT_PASSWORD_CLICKED: 'Forgot Password Clicked',
  PATIENT_PORTAL_PAY_CYCLE_MODAL_PAYMENT_LOADED:
    'Patient Portal - Pay Cycle Modal - Payment Loaded',
  PATIENT_PORTAL_PAY_CYCLE_MODAL_PAYMENT_SUCCESSFUL:
    'Patient Portal - Pay Cycle Modal - Payment Successful',
  PATIENT_PORTAL_INVOICE_PAYMENT_SUCCESSFUL: 'Patient Portal - Invoice - Payment Successful',
  PATIENT_PORTAL_INVOICE_PAYMENT_MODAL_LOADED: 'Patient Portal - Invoice - Payment Modal Loaded',
  PATIENT_CONSENT_SIGN_ATTEMPTED: 'Patient Consent sign attempted',
  PATIENT_CONSENT_SIGNED: 'Patient Consent signed',
  PATIENT_CONSENT_VIEWED: 'Patient Consent viewed',
  SIGNED_CONSENT_VIEWED: 'Signed Consent Viewed',
  SIGNED_TREATMENT_PLAN_VIEWED: 'Signed Treatment Plan Viewed',
  DOCUMENT_TAB_USED: 'Document Tab Used',
  APPOINTMENT_QUIZ_RESPONDED: 'Appointment Quiz Responded',
  APPOINTMENT_SELECTED: 'Appointment Selected',
  APPLE_SIGN_IN_SUCCEEDED: 'Apple Sign In Succeeded',
  GOOGLE_SIGN_IN_SUCCEEDED: 'Google Sign In Succeeded',
  GOOGLE_SIGN_IN_FAILED: 'Google Sign In Failed',
  GOOGLE_SIGN_IN_STARTED: 'Google Sign In Started',
  LIVE_BIRTH_CALCULATOR_USED: 'Live Birth Calculator Used',
  ID_FILE_TOO_BIG: 'ID File Too Big',
  INSURANCE_FILE_TOO_BIG: 'Insurance File Too Big',
  PROFILE_IMAGE_FILE_TOO_BIG: 'Profile Image File Too Big',
  PROFILE_IMAGE_UPLOAD_FAILED: 'Profile Image Upload Failed',
  PROFILE_IMAGE_UPLOAD_SUCCEEDED: 'Profile Image Upload Succeeded',
  ACCOUNT_VERIFICATION_FAILED: 'Account Verification Failed',
  ACCOUNT_VERIFICATION_CODE_SUBMITTED: 'Account Verification Code Submitted',
  ACCOUNT_VERIFICATION_CODE_RESUBMITTED: 'Account Verification Code Resubmitted',
  ACCOUNT_VERIFICATION_SUCCEEDED: 'Account Verification Succeeded',
  ACCOUNT_ACTIVATION_EMAIL_SUBMITTED: 'Account Activation Email Submitted',
  ACCOUNT_ACTIVATION_EMAIL_SUBMIT_SUCCEEDED: 'Account Activation Email Submit Succeeded',
  ACCOUNT_ACTIVATION_EMAIL_SUBMIT_FAILED: 'Account Activation Email Submit Failed',
  PASSWORD_RESET_VERIFICATION_CODE_REQUESTED: 'Password Reset Verification Code Requested',
  PASSWORD_RESET_VERIFICATION_CODE_REQUEST_SUCCEEDED:
    'Password Reset Verification Code Request Succeeded',
  PASSWORD_RESET_VERIFICATION_CODE_REQUEST_FAILED:
    'Password Reset Verification Code Request Failed',
  ACCOUNT_ACTIVATION_NEW_PASSWORD_SUBMITTED: 'Account Activation New Password Submitted',
  NEW_PASSWORD_SUBMITTED: 'New Password Submitted',
  ACCOUNT_ACTIVATION_NEW_PASSWORD_SUBMIT_SUCCEEDED:
    'Account Activation New Password Submit Succeeded',
  NEW_PASSWORD_SUBMIT_SUCCEEDED: 'New Password Submit Succeeded',
  ACCOUNT_ACTIVATION_NEW_PASSWORD_SUBMIT_FAILED: 'Account Activation New Password Submit Failed',
  NEW_PASSWORD_SUBMIT_FAILED: 'New Password Submit Failed',
  PARTNER_CLINIC_SEARCH_STARTED: 'Partner Clinic Search Started',
  MEMBERSHIP_SELECTED: 'Membership Selected',
  LAB_SELECTED: 'Lab Selected',
  CLINIC_SELECTED: 'Clinic Selected',
  TIME_SLOT_SELECTED: 'Time Slot Selected',
  APPOINTMENT_BOOKING_SUCCEEDED: 'Appointment Booking Succeeded',
  SKIP_TO_SIGN_UP: 'Skip to Sign Up',
  PARTNER_CLINIC_SEARCH_SUCCESSFUL: 'Partner Clinic Search Successful',
  PARTNER_CLINIC_SEARCH_ERROR: 'Partner Clinic Search Error',
  PARTNER_CLINIC_SEARCHED: 'Partner Clinic Searched',
  SECURE_MESSAGE_PAGE: 'Secure Message Page',
  PARTNER_CLINIC_SELECTED: 'Partner Clinic Selected',
  APPOINTMENT_BOOKING_FAILED: 'Appointment Booking Failed',
  PATIENT_PORTAL_BOOKING_APPOINTMENT_PAYMENT_SUCCESSFUL:
    'Patient Portal - Booking Appointment - Payment Successful',
  OATH_SIGN_UP_STARTED: 'Oath Sign Up Started',
  PATIENT_HAS_CONSENTS_TO_SIGN: 'Patient has Consents to sign',
  PATIENT_CONSENT_SELECTED: 'Patient Consent selected',
  ACCOUNT_ACTIVATION_STARTED: 'Account Activation Started',
  WALMART_REGISTRATION_SUCCEEDED: 'Walmart Registration Succeeded',
  PROMO_CODE_FAILED: 'Promo Code Failed',
  PROMO_CODE_SUCCEEDED: 'Promo Code Succeeded',
  PATIENT_LOGGED_IN: 'Patient Logged In',
  EMAIL_SIGN_IN_SUCCEEDED: 'Email Sign In Succeeded',
  PARTNER_INVITE_SENT: 'Partner Invite Sent',
  PATIENT_DECLARED_PARTNER_STATUS: 'Patient Declared Partner Status',
  PATIENT_AUTOMATICALLY_LOGGED_OUT: 'Patient Automatically Logged Out',
  APPOINTMENT_CTA_CLICKED: 'Appointment CTA Clicked',
  AHT_RESULTS_VIEWED: 'AHT Results Viewed - TRACK',
  AHT_LABS_VIEWED: 'AHT Labs Viewed',
  EMU_ACCOUNT_ACTIVATED: 'emu account activated',
  BOOKING_NAVIGATION: 'booking-navigation'
} as const;

export const PAGES = {
  MESSAGES: 'Messages',
  APPOINTMENTS: 'Appointments',
  CONFIRMATION: 'Confirmation',
  EMAIL_SIGN_UP: 'Email Sign Up',
  GOOGLE_SIGN_UP: 'Google Sign Up',
  APPLE_SIGN_UP: 'Apple Sign Up',
  MEDTRONIC_SIGN_UP: 'Medtronic Sign Up',
  DISNEY_SIGN_UP: 'Disney Sign Up',
  RESOURCES: 'Resources',
  KIND360: 'Kind360',
  JOURNEY: 'Journey',
  LOG_IN_PAGE: 'Log In Page',
  INVOICES: 'Invoices',
  DOCUMENTS: 'Documents',
  APPOINTMENT_RECOMMENDATION: 'Appointment Recommendation',
  APPOINTMENT_BOOKING_QUIZ: 'Appointment Booking Quiz',
  VERIFICATION: 'Verification',
  ENTER_INSURANCE_INFORMATION: 'Enter insurance information',
  CONFIRM_PURCHASE: 'Confirm Purchase',
  RESULTS: 'Results',
  HOME: 'Home',
  PATIENT_INTAKE: '/patient-intake'
} as const;

type TraitKey = typeof allowedTraitKeys[number];
type PropertyKey = typeof allowedPropertyKeys[number];

export type IdentifyTraits = {
  [key in TraitKey]?: any;
};

export type TrackProperties = {
  [key in PropertyKey]?: any;
};

export type PageProperties = {
  [key in PropertyKey]?: any;
};

export type EventName = typeof EVENTS[keyof typeof EVENTS];
type PageName = typeof PAGES[keyof typeof PAGES];

export type AnalyticsUtil = {
  identify: (userId: string, traits?: IdentifyTraits) => void;
  track: (event: EventName, properties?: TrackProperties) => void;
  page: (name: PageName, properties?: PageProperties) => void;
};

const duplicateSegmentTracking = false;

const eventsToTrackWithBoth = [...Object.values(EVENTS), ...Object.values(PAGES)];

interface TrackData {
  event: string;
  properties?: TrackProperties;
  anonymous_id?: string;
}

interface PageData {
  name: string;
  properties?: PageProperties;
  anonymous_id?: string;
}

async function sendToBackend(endpoint: 'track', data: TrackData): Promise<void>;
async function sendToBackend(endpoint: 'page', data: PageData): Promise<void>;

async function sendToBackend(endpoint: 'track' | 'page', data: TrackData | PageData) {
  const idtoken = getIdToken();

  if (!idtoken) {
    try {
      const anonymousId = getAnonymousId();
      if (anonymousId) {
        (data as any).anonymous_id = anonymousId;
      }
    } catch (error) {
      BugTracker.notify(error, 'anonymous_id does not exist or is invalid');
      return;
    }
  }

  try {
    await postToBackend(endpoint, data, idtoken);
  } catch (error) {
    BugTracker.notify(error, 'Failed to send analytics data to backend');
    return;
  }
}

function getAnonymousId(): string | null {
  const anonymousId = localStorage.getItem('ajs_anonymous_id');
  if (!anonymousId) {
    BugTracker.notify('anonymous_id is null');
    return null;
  }
  return anonymousId.replace(/"/g, '');
}

async function postToBackend(endpoint: string, data: any, idtoken: string | null): Promise<void> {
  await fetch(`${process.env.REACT_APP_GENERATIONS_URL}/patient_portal_analytics/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(idtoken ? { idtoken } : {})
    },
    body: JSON.stringify(data)
  });
}

export const analytics: AnalyticsUtil & { EVENTS: typeof EVENTS; PAGES: typeof PAGES } = {
  EVENTS,
  PAGES,
  identify: (userId, traits = {}) => {
    window.analytics?.identify(userId, traits);
  },
  track: (event, properties = {}) => {
    window.analytics?.track(event, properties);
    if (duplicateSegmentTracking && eventsToTrackWithBoth.includes(event)) {
      sendToBackend('track', { event: `v2 ${event}`, properties });
    }
  },
  page: (name, properties = {}) => {
    window.analytics?.page(name, properties);
    if (duplicateSegmentTracking && eventsToTrackWithBoth.includes(name)) {
      sendToBackend('page', { name: `v2 ${name}`, properties });
    }
  }
};

/*
 * Note: The keys in allowedTraitKeys and allowedPropertyKeys should match
 * the permitted fields in the backend to ensure consistency.
 */
