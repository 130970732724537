import React from 'react';

import { CloseIcon } from 'assets/icons';

import Modal from '../../Modal';
import useRiskAssessmentResult from '../hooks/use-risk-assessment-result';
import { ActionButtons } from './ActionButtons';
import {
  Body,
  Container,
  CloseIconButton,
  Description,
  Header,
  Instructions,
  TopBar
} from './RiskAssessmentResultModal.styled';
import { RiskAssessmentResultModalProps } from './RiskAssessmentResultModal.types';

export const RiskAssessmentResultModal = ({ isOpen, onClose }: RiskAssessmentResultModalProps) => {
  const { bookVisitURL, instructionsCopy } = useRiskAssessmentResult();

  return (
    <Modal onRequestClose={onClose} open={isOpen}>
      <Container>
        <TopBar>
          <CloseIconButton onClick={onClose} aria-label="Close">
            <CloseIcon width={30} height={30} />
          </CloseIconButton>
        </TopBar>
        <Body>
          <Header>Thank you for your submission</Header>

          <Description>
            Your assessment helps us understand when you should come in for your initial pregnancy
            appointment.
          </Description>
          <Instructions>{instructionsCopy}</Instructions>
          <ActionButtons onCancelClick={onClose} bookVisitURL={bookVisitURL} />
        </Body>
      </Container>
    </Modal>
  );
};
