import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { ReactComponent as AlertIcon } from '../../../assets/icons/alert.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/journey-instructions.svg';
import Modal from '../../../components/Modal';

export interface InstructionRow {
  name: string;
  body: ReactNode;
}

interface ModalProps {
  show: boolean;
  hide: () => void;
  title: string;
  alert?: string;
  rows: Array<InstructionRow>;
}

const InstructionsModal = ({ alert, hide, rows, show, title }: ModalProps) => {
  return (
    <Modal onRequestClose={hide} open={show}>
      <Container>
        <Content>
          <Header>
            <LeftHeader>
              <CheckIcon />
              {title}
            </LeftHeader>
            <CloseIconContainer onClick={hide}>
              <CloseIcon />
            </CloseIconContainer>
          </Header>

          {alert && (
            <Alert>
              <AlertIcon />
              <span>{alert}</span>
            </Alert>
          )}

          <InstructionWrapper>
            {rows.map(row => (
              <li key={row.name}>
                <InstructionGroup>
                  <InstructionName>{row.name}</InstructionName>
                  <InstructionBody>{row.body}</InstructionBody>
                </InstructionGroup>
              </li>
            ))}
          </InstructionWrapper>
        </Content>
      </Container>
    </Modal>
  );
};

export default InstructionsModal;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
`;

const Content = styled.div`
  position: relative;
  box-shadow: 0 2px 8px 0 #ababab;
  background-color: ${({ theme }) => theme.colors.yellow.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    width: 610px;
  }
`;

const CloseIconContainer = styled.div`
  padding-right: 20px;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.colors.neutral.lighterNavy};
  }
`;

const Header = styled.h2`
  ${({ theme }) => theme.fonts.DomaineDisplay};
  background-color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  color: white;
  width: 100%;
  display: flex;
  padding: 15px 0;
  font-size: 24px;
  justify-content: space-between;
`;

const LeftHeader = styled.div`
  align-items: center;
  display: flex;

  svg {
    margin: 0 10px;
  }
`;

const InstructionGroup = styled.div`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  font-size: 14px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largePhonePortrait}) {
    display: grid;
    grid-template-columns: 0.5fr 2fr;

    strong {
      border-bottom: 2px solid white;
      margin-top: 1em;
    }

    :last-of-type strong {
      border-bottom: none;
    }
  }
`;

const InstructionBody = styled.div`
  background-color: white;
  border-bottom: 2px solid ${({ theme }) => theme.colors.yellow.primary};
  p {
    margin: 1em;
    margin-top: 0.5em;
    padding-top: 0.5em;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largePhonePortrait}) {
    p {
      margin-top: 1em;
      padding-top: 0;
    }
  }

  ul {
    padding: 0 0 1em 3em;
    list-style-type: square;
  }
`;

const InstructionWrapper = styled.ul`
  padding: 0 5px;
  margin: 5px 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largePhonePortrait}) {
    padding: 0 5px 0 15px;
  }
`;

const InstructionName = styled.strong`
  margin-left: 10px;
  position: relative;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largePhonePortrait}) {
    :after {
      top: 100%;
      left: 10px;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: transparent;
      border-top-color: ${({ theme }) => theme.colors.yellow.primary};
      border-width: 10px;
      margin-left: -10px;
      margin-top: 6px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largePhonePortrait}) {
    margin: 0;
  }
`;

const Alert = styled.div`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  background-color: ${({ theme }) => theme.colors.semantic.pink};
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 10px;
  width: -webkit-fill-available;
  margin: 0 5px;

  span {
    margin-left: 5px;
  }
`;
