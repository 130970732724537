import React from 'react';
import { Redirect } from 'react-router-dom';

import CreateAccount from 'components/auth/CreateAccount/CreateAccount';
import { SubStep as CreateSubStep } from 'components/auth/CreateAccount/CreateAccount.types';
import AuthFlowLayout from 'components/AuthFlowLayout';
import { EmployerBenefitCTA } from 'components/EmployerBenefitCTA/EmployerBenefitCTA';
import { StepCountHeading } from 'components/StepCountHeading/StepCountHeading';
import { Button } from 'components/v2/Buttons/Button';
import { Appointment, Clinic, Lab, PartnerClinicDetails, TimeSlot } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { isDisneyPatient } from 'kb-shared/utilities/lab.helper';
import { RoutesPath, pageUrl } from 'utilities/pageUrl';

import { useBookingState } from './Book.hooks';
import {
  BackButtonContainer,
  Container,
  Content,
  DashboardButton,
  StepHeading,
  StepHeadingContainer
} from './Book.styled';
import { Props } from './Book.types';
import { RedirectIfMember, getStepsMetadata, hasEmployer } from './Book.utils';
import { AppointmentTimeSlotsSelection } from './components/BookingCalendarTime/AppointmentTimeSlotsSelection';
import { BookingCalendarTime } from './components/BookingCalendarTime/BookingCalendarTime';
import {
  DEFAULT_FALLBACK,
  DISNEY_FALLBACK
} from './components/BookingCalendarTime/MissingTimeSlotsFallback';
import { ConfirmOrder } from './steps/ConfirmOrder/ConfirmOrder';
import { Insurance } from './steps/Insurance/Insurance';
import { PartnerLocationRequest, PartnerLocationSearch } from './steps/PartnerLocation';
import PurchaseConfirmation from './steps/PurchaseConfirmation';
import { SelectLocationVirtual } from './steps/SelectLocationVirtual/SelectLocationVirtual';

export const Book = (props: Props) => {
  const {
    isLoggedIn,
    history,
    interstitial,
    state,
    setState,
    manager,
    completedSteps
  } = useBookingState({
    skipToSignUp: props.skipToSignUp
  });

  const previousScreenIsLogin = () => {
    const query = new URLSearchParams(history.location.search);
    return query.get('previous') === 'login';
  };

  const renderCurrentStep = (mobile: boolean) => {
    const {
      selectedClinic,
      selectedLab,
      selectedProduct,
      selectedStep,
      selectedTimeSlot,
      purchasedProduct,
      selectedAppointmentTypes,
      partnerClinicSearch
    } = state.data;

    const appointmentType =
      (selectedProduct && selectedProduct.type === 'appointment' && selectedProduct.data) ||
      (selectedProduct &&
        selectedProduct.type === 'reschedule_appointment' &&
        selectedProduct.data?.appointmentType);

    if (!manager) return null;

    switch (selectedStep) {
      case 'missing_params':
        return isLoggedIn ? (
          <Redirect to={pageUrl.booking.bookingQuiz()} />
        ) : (
          <Redirect to={pageUrl.login()} />
        );
      case 'location':
        const virtualApptType = selectedAppointmentTypes?.find(apptType => apptType.virtual);
        const inPersonApptType = selectedAppointmentTypes?.find(apptType => !apptType.virtual);
        let directToPartnerClinicSearch = false;

        //@ts-ignore
        if (selectedProduct && selectedProduct.data && selectedProduct.data.category) {
          //@ts-ignore
          directToPartnerClinicSearch = [
            'Fertility',
            'Non-discount code applicable Fertility'
            // @ts-ignore
          ].includes(selectedProduct.data.category.name);
          //@ts-ignore
        } else if (inPersonApptType && inPersonApptType.category) {
          //@ts-ignore
          directToPartnerClinicSearch = [
            'Fertility',
            'Non-discount code applicable Fertility'
          ].includes(inPersonApptType.category.name);
          //@ts-ignore
        } else if (virtualApptType && virtualApptType.category) {
          //@ts-ignore
          directToPartnerClinicSearch = [
            'Fertility',
            'Non-discount code applicable Fertility'
          ].includes(virtualApptType.category.name);
        }

        //@ts-ignore
        let appointmentName = selectedProduct?.data?.name;

        if (!appointmentName) {
          appointmentName = inPersonApptType?.name;
        }

        const partnerClinicSelected = state.partnerClinicSelected;

        if (partnerClinicSearch === 'search' && !partnerClinicSelected) {
          return (
            <PartnerLocationSearch
              onSelected={(newPartnerClinicSelected: PartnerClinicDetails) =>
                setState({ ...state, partnerClinicSelected: newPartnerClinicSelected })
              }
            />
          );
        } else if (partnerClinicSelected) {
          return (
            <PartnerLocationRequest
              partnerClinicSelected={partnerClinicSelected}
              mobileLayout={mobile}
              appointmentName={appointmentName}
              afterSend={() => manager.setSuccessPartnerClinic()}
            />
          );
        }

        return (
          <SelectLocationVirtual
            appointmentType={appointmentType || inPersonApptType}
            // @ts-ignore
            selectedLab={selectedLab}
            onSelectLab={(lab: Lab | null) => {
              manager.setLab(lab);
            }}
            selectedClinic={selectedClinic}
            onSelectClinic={(clinic: Clinic | null) => {
              manager.setClinic(clinic, inPersonApptType);
            }}
            showVirtual={!!virtualApptType}
            onSelectVirtual={() => {
              manager.setVirtual(virtualApptType);
            }}
            directToPartnerClinicSearch={directToPartnerClinicSearch}
            onContactUsClick={() => manager.setPartnerClinic()}
          />
        );
      case 'time':
        const query = new URLSearchParams(history.location.search);
        const providerUrlParam = query.get('provider_id');
        const date = query.get('date');
        const providerId = providerUrlParam ? parseInt(providerUrlParam) : undefined;

        const disneyPatient = isDisneyPatient(selectedLab?.id ?? '');
        if (
          !appointmentType ||
          (selectedClinic == null && appointmentType && !appointmentType.virtual)
        ) {
          return disneyPatient ? DISNEY_FALLBACK : DEFAULT_FALLBACK;
        }

        return (
          <>
            <AppointmentTimeSlotsSelection
              labId={selectedLab ? parseInt(selectedLab.id) : null}
              locationId={selectedClinic ? Number(selectedClinic.id) : null}
              appointmentType={appointmentType}
              selectedClinic={selectedClinic}
              providerId={providerId}
              fallback={disneyPatient ? DISNEY_FALLBACK : DEFAULT_FALLBACK}
            >
              <StepHeadingContainer>
                <StepHeading>What date & time works best for you?</StepHeading>
              </StepHeadingContainer>

              {(selectedClinic || appointmentType?.virtual) && appointmentType && (
                <BookingCalendarTime
                  date={date}
                  labId={selectedLab && parseInt(selectedLab.id)}
                  locationId={selectedClinic ? selectedClinic.id : null}
                  appointmentType={appointmentType}
                  selectedTimeSlot={selectedTimeSlot}
                  providerId={providerId}
                  currentDayCanBeSelected={appointmentType.name !== 'AMH Blood Draw'}
                  selectedLabTimeZone={selectedClinic?.timeZone || selectedLab?.timeZone || ''}
                  onSelectTimeSlot={(slot: TimeSlot | null) => {
                    if (slot != null) {
                      manager.setTimeSlot(slot);
                    }
                  }}
                />
              )}
            </AppointmentTimeSlotsSelection>
          </>
        );

      case 'create_account':
        return (
          <CreateAccount
            mobileLayout={mobile}
            lab={selectedLab}
            createAccountSubstep={state.createAccountSubstep}
            onAuthentication={() => {
              manager.setUserDidSignUp();
              const searchParameters = new URLSearchParams(history.location.search);
              // detect booking flow and reload the page to get the updated state
              // this is a fix until we refactor the booking flow
              if (searchParameters.get('appointment_id')) {
                window.location.reload();
              }
            }}
            onChangeSubstep={(step: CreateSubStep) => {
              setState({
                ...state,
                createAccountSubstep: step
              });
            }}
            signInLinkIsVisible={previousScreenIsLogin()}
          />
        );

      case 'insurance':
        if (completedSteps.includes('insurance')) {
          manager
            .deriveDataFromUrl(`${history.location.pathname}?${history.location.search}`, {
              initial: false,
              isLoggedIn
            })
            .then(() => {
              manager.setCompletedSteps(completedSteps);
              manager.updateDataAndUrl(
                { ...manager.data, selectedStep: 'confirm' },
                { replace: true }
              );
            })
            .catch(error => {
              BugTracker.notify(
                error,
                'Failed to derive data from URL on the Booking Insurance step'
              );
            });

          return null;
        }

        return <Insurance onNextStep={() => manager.goToConfirmStep()} />;

      case 'confirm':
        if (selectedProduct?.type === 'enterprise_membership') {
          history.replace(RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE);
          return null;
        } else {
          const query = new URLSearchParams(history.location.search);
          const isOldMembership = query.get('membership_id');

          if (isOldMembership != null) {
            query.delete('membership_id');
            query.append('enterprise_membership', 'true');

            history.replace(`${history.location.pathname}?${query.toString()}`);
            setState({
              ...state,
              data: {
                ...state.data,
                selectedProduct: { type: 'enterprise_membership', data: {} }
              }
            });
            return null;
          } else {
            // wait until the product is loaded
            if (!selectedProduct) return null;

            return (
              <>
                <RedirectIfMember product={selectedProduct} history={history} />
                <ConfirmOrder
                  product={selectedProduct}
                  timeSlot={selectedTimeSlot}
                  clinic={selectedClinic}
                  // TODO: hasEmployer is always false here because the data is not updated, we should update the data in the manager
                  // it is maybe set only when product is membership and can't be used in this case
                  hasEmployer={hasEmployer(state.data)}
                  onBookAppointment={(appointment: Appointment) => {
                    manager.setBookingAppointmentSuccess(appointment);
                  }}
                />
              </>
            );
          }
        }
      case 'success':
        appointmentName = '';
        if (selectedProduct && selectedProduct.type === 'appointment') {
          const selectedAppointment = selectedProduct.data;
          appointmentName = selectedAppointment.name;
        }
        if (!appointmentType && selectedAppointmentTypes) {
          appointmentName = selectedAppointmentTypes[0].name;
        }

        return (
          <>
            <PurchaseConfirmation
              // @ts-ignore
              purchase={purchasedProduct}
              timeSlot={selectedTimeSlot}
              clinic={selectedClinic}
              partnerClinicDetails={state.partnerClinicSelected}
              appointmentName={appointmentName}
              history={history}
              onReturnToBookAppointment={() => {
                // Since we are already on the book state, pushing or replacing '/book' will have no effect since the internal state will not change.
                // Do a hard reload of the page
                history.push(pageUrl.booking.bookingQuiz());
                window.location.reload();
              }}
            />
            <DashboardButton href="/">Go To Dashboard</DashboardButton>
          </>
        );
      default:
        return null;
    }
  };

  const { stepNames, currentStep, pageTitle } = getStepsMetadata(state, completedSteps);
  const isLoadingInterstitialStatus = interstitial.loading;

  let selectedStep = Math.min(currentStep, stepNames.length - 1); // prevent hidden steps from showing as selected
  if (state.data.selectedStep === 'time') {
    selectedStep = 1; // keep Location selected on Time step per designs
  }
  const { target } = state.data;
  const isEnterpriseMembership = target && target.includes('enterprise_membership=true');

  return (
    <AuthFlowLayout
      title={pageTitle}
      renderAboveTitleContent={() => {
        // skip render until we find out the correct number of steps; for logged in users
        if (isLoggedIn && isLoadingInterstitialStatus) {
          return null;
        }

        if (
          state.data.selectedStep === 'success' ||
          stepNames?.length < 2 ||
          !state.data.selectedStep ||
          !state.initialDataDerived
        ) {
          // Don't show the stepper/nav on success
          return null;
        }

        return (
          <>
            {!isLoggedIn && !isEnterpriseMembership && <EmployerBenefitCTA />}

            {/* selectedStep is 0 indexed */}
            <StepCountHeading currentStep={selectedStep + 1} totalSteps={stepNames.length} />
          </>
        );
      }}
      renderMainContent={(mobile: boolean) => {
        return (
          <Container>
            <Content>{renderCurrentStep(mobile)}</Content>
            {state.data.selectedStep &&
              state.data.selectedStep !== 'success' &&
              !previousScreenIsLogin() &&
              state.data.selectedStep !== 'insurance' && (
                <BackButtonContainer>
                  <Button
                    label="Back"
                    fullWidth
                    size="lg"
                    category="secondary"
                    onClick={() => {
                      if (state.createAccountSubstep === 'verification_code') {
                        setState({ ...state, createAccountSubstep: 'choose_signup_option' });
                      } else {
                        setState({ ...state, partnerClinicSelected: null });
                        props.history.goBack();
                      }
                    }}
                  />
                </BackButtonContainer>
              )}
          </Container>
        );
      }}
    />
  );
};
