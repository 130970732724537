import { Appointment, AppointmentProduct, AppointmentType, Membership } from 'kb-shared';
import { parseNumber } from 'utilities/parseNumber';

import { createAccountStep } from './BookingStateManager.constants';
import {
  loadAppointmentType,
  loadAppointmentTypes,
  loadMembership,
  loadLab,
  loadDefaultLab,
  loadLocation,
  loadTimeSlot,
  loadAppointment,
  loadClinics
} from './BookingStateManager.graphql';
import { Data, URLParams } from './BookingStateManager.types';

const isNumber = (value?: string) =>
  typeof value === 'string' && value.length && !isNaN(Number(value));

const toIntOrNull = (value?: string | null) => {
  if (!value || !isNumber(value)) {
    return null;
  }

  const parsedInt = parseNumber(value);

  return parsedInt !== undefined ? parsedInt : null;
};

export async function deriveData(
  params: URLParams,
  options: { initial: boolean; isLoggedIn: boolean | undefined } = {
    initial: false,
    isLoggedIn: false
  },
  data: Data
): Promise<Data> {
  /**
   * Construct data variable that will be resolved/returned
   * selectedStep and selectedWeekString can be pulled directly from url.
   * Other params (if they exist) are used to load data from the server in order
   * to populate the loadedData. Eg if we have an appointment_id, we load the appointment
   * from the server so we have it in our local data.
   */
  const preSelectedStep =
    !options.isLoggedIn && params.step === 'confirm' ? 'create_account' : params.step;
  const loadedData: Data = {
    ...data,
    ...(preSelectedStep ? { selectedStep: preSelectedStep } : null),
    ...(params.week_string ? { selectedWeekString: params.week_string } : null),
    ...(params.target ? { target: params.target } : null),
    partnerClinicSearch: params.partner_clinic_search,
    requireUniqueId: params.require_unique_id,
    date: params.date
  };

  // If this is initial page load and we have specified the create_account step, do not show other steps
  if (options.initial && params.step && params.step === 'create_account') {
    loadedData.steps = { create_account: createAccountStep };
  }

  const variables = {
    appointmentId: toIntOrNull(params.appointment_id),
    appointmentTypeIds:
      params.appointment_types && params.appointment_types.split(',').map(id => parseNumber(id)),
    membershipId: toIntOrNull(params.membership_id),
    labId: toIntOrNull(params.lab_id),
    clinicId: toIntOrNull(params.clinic_id),
    timeSlotId: toIntOrNull(params.time_slot_id),
    rescheduleAppointmentId: toIntOrNull(params.reschedule_appointment_id),
    confirmedAppointmentId: toIntOrNull(params.confirmed_appointment_id),
    confirmedMembershipId: toIntOrNull(params.confirmed_membership_id),
    enterpriseMembership: params.enterprise_membership,
    partnerClinicId: params.partner_clinic_search !== 'search' ? params.partner_clinic_search : null
  };

  // TODO: fix number parsing; make sure we work with numbers in all the following requests
  const appointmentTypeRequest = variables.appointmentId
    ? loadAppointmentType(variables.appointmentId as number)
    : Promise.resolve(undefined);
  const appointmentTypesRequest = variables.appointmentTypeIds
    ? loadAppointmentTypes(variables.appointmentTypeIds as Array<number>)
    : Promise.resolve(undefined);
  const membershipRequest = variables.membershipId
    ? loadMembership(variables.membershipId as number)
    : Promise.resolve(undefined);
  const labRequest = variables.labId ? loadLab(variables.labId as number) : loadDefaultLab();
  const locationRequest = variables.clinicId
    ? loadLocation(variables.clinicId as number)
    : Promise.resolve(undefined);
  const timeSlotRequest = variables.timeSlotId
    ? loadTimeSlot(variables.timeSlotId as number)
    : Promise.resolve(undefined);
  const rescheduledAppointmentRequest = variables.rescheduleAppointmentId
    ? loadAppointment(variables.rescheduleAppointmentId as number)
    : Promise.resolve(undefined);
  const confirmedAppointmentRequest = variables.confirmedAppointmentId
    ? loadAppointment(variables.confirmedAppointmentId as number)
    : Promise.resolve(undefined);
  const confirmedMembershipRequest = variables.confirmedMembershipId
    ? loadMembership(variables.confirmedMembershipId as number)
    : Promise.resolve(undefined);

  if (variables.enterpriseMembership) {
    const enterpriseProduct: AppointmentProduct = {
      type: 'enterprise_membership',
      data: {}
    };
    loadedData.selectedProduct = { ...enterpriseProduct };
    loadedData.purchasedProduct = { ...enterpriseProduct };
  }

  const [
    appointment,
    appointmentTypes,
    membership,
    confirmedMembership,
    lab,
    location,
    timeSlot,
    rescheduledAppointment,
    confirmedAppointment
  ] = await Promise.all([
    appointmentTypeRequest,
    appointmentTypesRequest,
    membershipRequest,
    confirmedMembershipRequest,
    labRequest,
    locationRequest,
    timeSlotRequest,
    rescheduledAppointmentRequest,
    confirmedAppointmentRequest
  ]);

  if (appointment) {
    loadedData.selectedProduct = {
      type: 'appointment',
      // TODO: handle undefined value; currently ignored case
      data: appointment as AppointmentType
    };
  }
  if (appointmentTypes) {
    loadedData.selectedAppointmentTypes = appointmentTypes ?? null;
  }
  if (membership) {
    loadedData.selectedProduct = {
      type: 'membership',
      // TODO: handle undefined value; currently ignored case
      data: membership as Membership
    };
  }
  if (confirmedMembership) {
    loadedData.purchasedProduct = {
      type: 'membership',
      // TODO: handle undefined value; currently ignored case
      data: confirmedMembership as Membership
    };
  }
  if (lab) {
    loadedData.selectedLab = lab ?? null;
  }
  if (location) {
    loadedData.selectedClinic = location ?? null;
  }
  if (timeSlot) {
    loadedData.selectedTimeSlot = timeSlot ?? null;
  }
  if (rescheduledAppointment) {
    loadedData.selectedProduct = {
      type: 'reschedule_appointment',
      // TODO: handle undefined value; currently ignored case
      data: rescheduledAppointment as Appointment
    };
  }
  if (confirmedAppointment) {
    loadedData.purchasedProduct = {
      type: 'appointment',
      // TODO: handle undefined value; currently ignored case
      data: confirmedAppointment as Appointment
    };
  }

  const aptTypes = (loadedData.selectedAppointmentTypes || []).concat(appointment || []);
  const virtualApptType = aptTypes.find(apptType => apptType.virtual);
  const inPersonApptType = aptTypes.find(apptType => !apptType.virtual);
  const isOnlyVirtual = virtualApptType && !inPersonApptType;

  if (isOnlyVirtual) {
    loadedData.selectedProduct = loadedData.selectedProduct ?? {
      type: 'appointment',
      data: virtualApptType as AppointmentType
    };
  }

  // we only pre-select a clinic if we have a selected lab (aka market), appointment
  // and there is no virtual options to select
  if (
    loadedData.selectedLab &&
    loadedData.selectedProduct?.type === 'appointment' &&
    !isOnlyVirtual
  ) {
    // TODO: make sure ids are numbers for clinics request
    const appointmentTypeId = parseNumber(loadedData.selectedProduct.data.id) as number;
    const labId = parseNumber(loadedData.selectedLab.id) as number;
    const response = await loadClinics(appointmentTypeId, labId, variables.clinicId as number);

    if (response?.clinics.length === 1) {
      loadedData.selectedClinic = response.clinics[0];
    }
  }

  // we only skip the location step if there is only virtual appointments
  // or the clinic (aka location) is already selected
  if (
    loadedData.selectedStep === 'location' &&
    (isOnlyVirtual || loadedData.selectedClinic) &&
    !(loadedData.partnerClinicSearch === 'search')
  ) {
    loadedData.selectedStep = 'time';
  }

  if (!data.selectedStep) {
    data.selectedStep = 'missing_params';
  }

  return loadedData;
}
