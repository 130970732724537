import React, { useState } from 'react';

import ContactModal from 'components/Footer/ContactModal';
import { useContactInfo } from 'hooks';

import { INTRODUCTION } from '../../InterstitialModal.constants';
import { OpenModalLink, Paragraph } from '../Shared.styled';

export const Contacts = () => {
  const [visible, setVisible] = useState(false);
  const { email, numberLabel, number, nightNumberAvailable } = useContactInfo();

  return (
    <>
      <Paragraph>
        {INTRODUCTION} If you need help or have any questions, please contact us via email at{' '}
        <a href={`mailto:${email}`}>{email}</a> or phone at{' '}
        <a href={`tel:${number}`}>
          <b>{numberLabel}</b>
        </a>
        .{' '}
        {nightNumberAvailable && (
          <OpenModalLink
            onClick={() => setVisible(true)}
            text="For urgent medical emergencies, click here."
            fontStyle="bold"
          />
        )}
      </Paragraph>
      {nightNumberAvailable && (
        <ContactModal visible={visible} onDismiss={() => setVisible(false)} />
      )}
    </>
  );
};

export default Contacts;
