import React from 'react';

import { FilePdf } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';
import Invoice from 'screens/Invoices/types/invoice';
import { currencyNumberFormat } from 'utilities/number-format';

import { formatDate } from '../PagableInvoices/PagableInvoices.utils';
import {
  ButtonWrapper,
  LabelHolder,
  LongTextWrapper,
  FirstRow,
  SecondRow,
  TableCell
} from './InvoicesTableRow.styled';

export const InvoicesTableRow = ({
  invoice,
  totalBalance,
  isViosPatient,
  $alternateBackground,
  onPayClick,
  onViewInvoice,
  onPayWithCredit
}: {
  invoice: Invoice;
  totalBalance: number;
  isViosPatient: boolean;
  $alternateBackground: boolean;
  onPayClick?: () => void;
  onViewInvoice: () => void;
  onPayWithCredit: () => void;
}) => {
  const { dateOfInvoice, service, balance, id, azEncounterIdentifier, paidViaPpApp } = invoice;

  const invoiceIdentifier = azEncounterIdentifier || id;
  const invoiceBalance = parseFloat(balance || '0');

  const showPayButton = !paidViaPpApp && invoiceBalance > 0;
  const showPayWithCreditButton = showPayButton && totalBalance < 0 && !isViosPatient;
  const showPaidLabel = invoiceBalance === 0;
  const showProcessingLabel = paidViaPpApp && invoiceBalance > 0;

  return (
    <>
      <FirstRow $alternateBackground={$alternateBackground}>
        <TableCell>
          <Text tag="span" fontStyle="regular">
            {formatDate(dateOfInvoice)}
          </Text>
        </TableCell>
        <TableCell>
          <LongTextWrapper>
            <Text tag="span" fontStyle="regular">
              {invoiceIdentifier}
            </Text>
          </LongTextWrapper>
        </TableCell>
        <TableCell>
          <LongTextWrapper>
            <Text tag="span" fontStyle="regular">
              {service}
            </Text>
          </LongTextWrapper>
        </TableCell>
        <TableCell $alignRight={true}>
          <Text tag="span" fontStyle="regular">
            {currencyNumberFormat(invoiceBalance)}
          </Text>
        </TableCell>
      </FirstRow>
      <SecondRow $alternateBackground={$alternateBackground}>
        <TableCell colSpan={3}>
          <ButtonWrapper>
            {showPayButton && (
              <Button
                label="Pay"
                category="primary"
                size="sm"
                fullWidth
                onClick={onPayClick}
                uppercase
              />
            )}
            {showPayWithCreditButton && (
              <Button
                label="Pay with credit"
                category="primary-dark"
                size="sm"
                fullWidth
                onClick={onPayWithCredit}
                uppercase
              />
            )}
            {showPaidLabel && (
              <LabelHolder $forPaid={true}>
                <Text tag="span" fontStyle="regular">
                  Paid
                </Text>
              </LabelHolder>
            )}
            {showProcessingLabel && (
              <LabelHolder>
                <Text tag="span" fontStyle="regular">
                  Processing...
                </Text>
              </LabelHolder>
            )}
          </ButtonWrapper>
        </TableCell>
        <TableCell colSpan={1} $alignRight={true}>
          <Button
            label="View"
            category="secondary"
            size="sm"
            fullWidth
            rightIcon={<FilePdf type="solid" />}
            onClick={onViewInvoice}
            uppercase
          />
        </TableCell>
      </SecondRow>
    </>
  );
};
