import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader/Loader';
import { Tab } from 'components/v2/Tabs/Tab.types';
import { Tabs } from 'components/v2/Tabs/Tabs';
import { getTabRequestedInQueryString } from 'components/v2/Tabs/Tabs.utils';
import { Heading } from 'components/v2/Typography';
import { usePatient } from 'hooks';
import { useQueryParams } from 'hooks/useQueryParams';
import { momentInTimeZoneAndFormat } from 'kb-shared/utilities/momentHelpers';
import SignedCyclePlan from 'screens/Documents/components/SignedCyclePlan/SignedCyclePlan';
import { PatientDocument } from 'screens/Documents/Documents.types';
import { PatientConsent } from 'screens/Documents/graphql/kindbodyDocumentsQuery';
import SignedConsent from 'screens/SignedConsent/SignedConsent';
import { analytics } from 'utilities/analytics';

import { useDocuments } from './Documents.hook';
import { Container, Content, Description, Header } from './Documents.styled';

export const Documents = () => {
  const history = useHistory();
  const queryString = useQueryParams();

  const [tabSelected, setTabSelected] = useState(false);
  const [consentToRenderInline, setConsentToRenderInline] = useState<PatientConsent | undefined>(
    undefined
  );
  const [treatmentPlanToRender, setTreatmentPlanToRender] = useState<PatientDocument | undefined>(
    undefined
  );

  const onYourConsentsView = (consent?: PatientConsent) => {
    setConsentToRenderInline(consent);
    scrollToTop();
    analytics.track(analytics.EVENTS.SIGNED_CONSENT_VIEWED);
  };

  const onTreatmentPlansView = (treatmentPlan?: PatientDocument) => {
    setTreatmentPlanToRender(treatmentPlan);
    scrollToTop();
    analytics.track(analytics.EVENTS.SIGNED_TREATMENT_PLAN_VIEWED);
  };

  const { loading, tabs } = useDocuments(onYourConsentsView, onTreatmentPlansView);
  const { patient, loading: loadingPatient } = usePatient();

  const onChangeTab = useCallback(
    (tab?: Tab) => {
      setTabSelected(!!tab);

      if (tab?.id) {
        analytics.track(analytics.EVENTS.DOCUMENT_TAB_USED, { document_type: tab.id });
        history.push(`?tab=${tab?.id}`);
        return;
      }

      history.push();
    },
    [setTabSelected, history]
  );

  if (loading || loadingPatient || !patient) return <Loader absoluteCentered />;

  const visibleTabs = tabs
    .filter(tab => tab.isVisible)
    .map(({ id, label, content }) => ({ id, label, content }));

  const onBackFromConsent = () => {
    setConsentToRenderInline(undefined);
    scrollToTop();
  };

  const onBackFromCyclePlanAcknowledge = () => {
    setTreatmentPlanToRender(undefined);
    scrollToTop();
  };

  if (consentToRenderInline) {
    return (
      <SignedConsent
        consentRawHtml={consentToRenderInline.patientPortalHtml}
        completedAt={
          consentToRenderInline.completedAt
            ? momentInTimeZoneAndFormat(
                consentToRenderInline.completedAt,
                patient?.lab?.timeZone || 'UTC',
                'MM/DD/YYYY'
              )
            : ''
        }
        onBack={onBackFromConsent}
        consentDecisions={consentToRenderInline.decisions}
        patient={patient}
        patientNameFromConsent={consentToRenderInline.patientName}
      />
    );
  }

  if (treatmentPlanToRender && treatmentPlanToRender.jsonData?.length > 0) {
    return (
      <SignedCyclePlan
        cyclePlans={treatmentPlanToRender.jsonData}
        patient={patient}
        onBack={onBackFromCyclePlanAcknowledge}
      />
    );
  }

  return (
    <Container>
      <Header $tabSelected={tabSelected}>
        <Heading noMargin>Documents</Heading>
        <Description tag="p" size="md">
          Upload recent medical reports to your Kindbody patient record for your care team to
          review. Common examples include pap smears, mammograms, prior fertility treatments, STD
          screening, recent bloodwork, etc.
        </Description>
      </Header>
      <Content>
        <Tabs
          tabs={visibleTabs}
          initialTab={getTabRequestedInQueryString(queryString, visibleTabs)}
          onChangeTab={onChangeTab}
          noMargin
        />
      </Content>
    </Container>
  );
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};
