import styled from 'styled-components';

export const Main = styled.p`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  text-align: center;
  margin-top: 5em;
`;

export const Link = styled.span`
  font-weight: bold;
  text-decoration: underline;
  white-space: nowrap;
  cursor: pointer;
`;
