import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as UnreadMessagesIcon } from 'assets/icons/unread-messages.svg';
import { Button } from 'components/v2/Buttons/Button';
import { Heading } from 'components/v2/Typography';
import { pageUrl } from 'utilities/pageUrl';

import { UNREAD_MESSAGES_COUNT } from './UnreadMessages.graphql';
import {
  Container,
  UnreadMessagesContainer,
  UnreadMessagesIconContainer
} from './UnreadMessages.styled';

export const UnreadMessages = () => {
  const history = useHistory();
  const { data, startPolling, stopPolling } = useQuery(UNREAD_MESSAGES_COUNT);

  useEffect(() => {
    startPolling(60000);

    return () => stopPolling();
  }, [startPolling, stopPolling]);

  if (!data?.unreadMessagesCount) {
    return null;
  }

  const count = data.unreadMessagesCount;

  return (
    <Container>
      <UnreadMessagesIconContainer>
        <UnreadMessagesIcon />
      </UnreadMessagesIconContainer>
      <UnreadMessagesContainer>
        <Heading tag="h2" noMargin>
          You have {count} unread message{count > 1 ? 's' : ''}!
        </Heading>
        <Button
          category="primary-dark"
          label="View Messages"
          onClick={() => history.push(pageUrl.messages())}
        />
      </UnreadMessagesContainer>
    </Container>
  );
};
