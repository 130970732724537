import styled from 'styled-components';

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NumberContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
`;

export const WhatsappNumberContainer = styled(NumberContainer)`
  > svg {
    width: 36px;
    height: 36px;
    margin-right: 8px;
  }
`;

export const SmsNumberContainer = styled(NumberContainer)`
  > svg {
    width: 36px;
    height: 36px;
    margin-right: 8px;
    margin-top: 4px;
  }
`;

export const PhoneNumberContainer = styled(NumberContainer)`
  > svg {
    width: 36px;
    height: 36px;
    margin-right: 8px;
    margin-top: 3px;
  }
`;
