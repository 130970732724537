import React from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import { ReactComponent as Messaging } from '../../assets/icons/results-messaging.svg';
import Modal from '../../components/Modal';
import ContactDiv from './ContactDiv';

interface Props {
  visible: boolean;
  onDismiss: () => void;
}

const ResultsContactModal = ({ visible, onDismiss }: Props) => (
  <Modal onRequestClose={onDismiss} open={visible}>
    <Container>
      <Content>
        <IconContainer onClick={() => onDismiss()}>
          <CloseIcon />
        </IconContainer>
        <Title>
          <Messaging />
          Talk to Us
        </Title>
        <ContactDiv className="results-contact-modal" />
      </Content>
    </Container>
  </Modal>
);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  position: relative;
  min-width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: ${themes.colors.white};
  border-left: 8px solid ${themes.colors.yellow.primary};
  padding: 24px;

  @media screen and (min-width: ${themes.breakpoints.raw.md}px) {
    min-width: 400px;
  }

  & .results-contact-modal {
    padding: 0;
    border-left: 0;
    box-shadow: none;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const Title = styled.h1`
  ${themes.fonts.DomaineDisplay};
  font-size: 28px;
  color: ${themes.colors.neutral.primaryNavy};
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > svg {
    transform: scale(1.5);
    margin-right: 16px;
  }
`;

export default ResultsContactModal;
