import {
  getMoment,
  getMomentAndStripTime,
  inFuture,
  momentWithParsedTimeZone
} from 'kb-shared/utilities/momentHelpers';
import { CycleVisit } from 'screens/Dashboard/Dashboard.types';

const isScheduledDayAfterPlannedDay = (
  scheduledCycleVisit: CycleVisit,
  plannedCycleVisit: string
) => {
  return getMomentAndStripTime(scheduledCycleVisit.startTime).isAfter(getMoment(plannedCycleVisit));
};

export const calculateNextCycleVisitDate = (
  cycleVisits: string[] | null = [],
  cycleVisitsScheduled: CycleVisit[] | null = []
): string | undefined => {
  const firstFuturePlannedCycleVisit = cycleVisits?.find(cycleVisit => inFuture(cycleVisit));
  const firstFutureScheduledCycleVisit = cycleVisitsScheduled?.find(scheduledCycleVisit =>
    inFuture(momentWithParsedTimeZone(scheduledCycleVisit.startTime))
  );

  if (firstFuturePlannedCycleVisit == null) return undefined;
  if (firstFutureScheduledCycleVisit == null) return firstFuturePlannedCycleVisit;

  if (isScheduledDayAfterPlannedDay(firstFutureScheduledCycleVisit, firstFuturePlannedCycleVisit))
    return firstFuturePlannedCycleVisit;

  return undefined;
};
