import styled from 'styled-components';

export const TermsTextContainer = styled.div`
  margin-bottom: 24px;
  margin-left: 15px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

export const TermsText = styled.span`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  font-size: 14px;
  color: white;
`;

export const TermsLink = styled.a`
  font-size: 14px;
  color: white;
  font-weight: bold;
  text-decoration: underline;
`;

export const TermsButton = styled.button`
  font-size: 14px;
  color: white;
  font-weight: bold;
  text-decoration: underline;
`;
