import { History } from 'history';

import { Appointment, AppointmentType, Clinic, Lab, TimeSlot, utils } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { analytics } from 'utilities/analytics';

import {
  createAccountStep,
  loggedInSteps,
  initialDataState,
  initialUrlParamsState
} from './BookingStateManager.constants';
import { Data, URLParams, Membership, Step, StepType } from './BookingStateManager.types';
import { generateConfirmationLink, urlParamsFromData } from './BookingStateManager.utils';
import { deriveData } from './deriveData';

const { momentInTimeZone } = utils;

export class BookingStateManager {
  _userInitiallyLoggedIn: boolean | undefined;
  history: History;
  onDataChange: (data: Data) => void;
  completedSteps: StepType[] = [];

  urlParams: URLParams = initialUrlParamsState;
  data = initialDataState;
  removeHistoryListener: () => void = () => {};

  constructor(
    history: History,
    onDataChange: (data: Data) => void,
    isLoggedIn: boolean | undefined,
    completedSteps?: StepType[]
  ) {
    if (isLoggedIn) {
      this.data = {
        ...this.data,
        steps: loggedInSteps
      };
    }

    this.completedSteps = completedSteps || [];
    this._userInitiallyLoggedIn = isLoggedIn;
    this.onDataChange = onDataChange;
    this.history = history;

    // Initialize and check for existing URL params
    const searchQuery = history.location.search;
    if (searchQuery) {
      this.deriveDataFromUrl(searchQuery, { initial: true, isLoggedIn })
        .then(data => {
          this.data = data;
          this.onDataChange(this.data);
        })
        .catch(error => BugTracker.notify(error));
    } else {
      this.data.selectedStep = 'missing_params';
    }

    // library has a wrong type definition for history.listen so we need to ignore it
    // @ts-ignore
    this.removeHistoryListener = history.listen((location, action) => {
      if (action === 'POP') {
        this.deriveDataFromUrl(location.search, { initial: false, isLoggedIn })
          .then(data => {
            this.data = data;
            this.onDataChange(this.data);
          })
          .catch(error => BugTracker.notify(error));
      }
    });
  }

  resetState() {
    this.data = initialDataState;
    this.onDataChange(this.data);
  }

  deriveDataFromUrl(
    url: string,
    options: { initial: boolean; isLoggedIn: boolean | undefined } = {
      initial: false,
      isLoggedIn: false
    }
  ): Promise<Data> {
    const urlInstance = new URL(url, window.location.origin);
    const urlParams = new URLSearchParams(urlInstance.search);
    // TODO: fix URLParams type
    const params = (Object.fromEntries(urlParams.entries()) as unknown) as URLParams;

    return deriveData(params, options, this.data);
  }

  getUrlParamsString(addQueryPrefix: boolean): string {
    const filteredParamEntries = Object.entries(this.urlParams)
      .filter(([_key, value]) => {
        if (value === null || value === undefined) {
          return false;
        }

        return true;
      })
      .map(([key, value]) => [key, value!.toString()]);
    const params = new URLSearchParams(Object.fromEntries(filteredParamEntries));

    return addQueryPrefix ? `?${params}` : `${params}`;
  }

  updateDataAndUrl(data: Data, options: { replace: boolean } = { replace: false }) {
    this.data = data;
    this.urlParams = urlParamsFromData(this.data);
    const paramsString = this.getUrlParamsString(true);

    if (options.replace) {
      this.history.replace(paramsString);
    } else {
      this.history.push(paramsString);
    }

    this.onDataChange(this.data);
  }

  stepAtIndex(index: number): Step {
    const keyForIndex = Object.keys(this.data.steps)[index];
    // @ts-ignore
    return this.data.steps[keyForIndex];
  }

  goBacktoStep(step: StepType) {
    /**
     * Depending on the step, clear the data that is in any step after this one.
     * This is to avoid storing bad data that may not match.
     * ie. if a user originally selected a fertility assessment, a location, a time slot, a lab
     * gets all the way to confirmation, but then decides to go back to "Select Appointment"
     * where they choose a video consulatation instead, we need to clear the ttime slot, the lab, the location, etc
     */
    if (
      step === 'location' ||
      step === 'time' ||
      step === 'create_account' ||
      step === 'insurance'
    ) {
      this.updateDataAndUrl({
        ...this.data,
        selectedStep: step
      });

      return;
    }

    // we also want to skip clearing state for 'confirm' step
    return;
  }

  setPartnerClinic() {
    analytics.track(analytics.EVENTS.PARTNER_CLINIC_SEARCH_STARTED);

    this.updateDataAndUrl({
      ...this.data,
      partnerClinicSearch: 'search'
    });
  }

  setSuccessPartnerClinic() {
    this.updateDataAndUrl({
      ...this.data,
      selectedStep: this._userInitiallyLoggedIn ? 'success' : 'create_account'
    });
  }

  addMembershipToCart(membership: Membership) {
    analytics.track(analytics.EVENTS.MEMBERSHIP_SELECTED);

    this.updateDataAndUrl({
      ...this.data,
      selectedProduct: { type: 'membership', data: membership },
      // If user is logged in, go to confirm. Otherwise go to create_account
      selectedStep: this._userInitiallyLoggedIn ? 'confirm' : 'create_account'
    });
  }

  setLab(lab: Lab | null) {
    analytics.track(analytics.EVENTS.LAB_SELECTED, {
      lab_id: lab?.id
    });
    this.updateDataAndUrl(
      {
        ...this.data,
        selectedLab: lab,
        // Clear other fields if lab changes
        selectedClinic: null,
        selectedTimeSlot: null,
        selectedWeekString: null
      },
      { replace: true }
    );
  }

  setVirtual(appointmentType: AppointmentType | undefined) {
    if (!appointmentType) {
      return;
    }
    analytics.track(analytics.EVENTS.CLINIC_SELECTED, {
      clinic_name: 'virtual'
    });
    this.updateDataAndUrl({
      ...this.data,
      selectedClinic: null,
      selectedStep: 'time',
      selectedProduct: { type: 'appointment', data: appointmentType }
    });
  }

  setClinic(clinic: Clinic | null, appointmentType: AppointmentType | undefined = undefined) {
    analytics.track(analytics.EVENTS.CLINIC_SELECTED, {
      clinic_name: clinic?.name,
      clinic_id: clinic?.id
    });
    this.updateDataAndUrl(
      {
        ...this.data,
        selectedClinic: clinic,
        selectedWeekString: null,
        selectedStep: 'time',
        selectedProduct: appointmentType
          ? { type: 'appointment', data: appointmentType }
          : this.data.selectedProduct
      },
      { replace: false }
    );
  }

  setWeekString(weekString: string | null) {
    this.updateDataAndUrl(
      {
        ...this.data,
        selectedWeekString: weekString
      },
      { replace: true }
    );
  }

  setTimeSlot(slot: TimeSlot | null) {
    const formattedTime =
      slot?.startTime &&
      // @ts-ignore
      momentInTimeZone(slot.startTime, slot.timeZone).format('MM-DD-YYYY HH:mm z');
    analytics.track(analytics.EVENTS.TIME_SLOT_SELECTED, {
      time_slot_id: slot?.id,
      time_slot_time: formattedTime || ''
    });

    // const nextStep: StepType = this._userInitiallyLoggedIn ? 'confirm' : 'create_account';

    let nextStep: StepType = 'create_account';
    if (this._userInitiallyLoggedIn) {
      nextStep = 'insurance';
    }
    if (this.completedSteps.includes('insurance')) {
      nextStep = 'confirm';
    }
    // const nextStep: StepType = this._userInitiallyLoggedIn ? 'insurance' : 'create_account';

    this.updateDataAndUrl({
      ...this.data,
      selectedTimeSlot: slot,
      selectedStep: nextStep
    });
  }

  canMoveToStep(step: StepType): boolean {
    // Attempt to find the step inside of data.steps
    return !!this.data.steps[step];
  }

  setUserDidSignUp() {
    if (this.canMoveToStep('insurance')) {
      analytics.page(analytics.PAGES.ENTER_INSURANCE_INFORMATION);

      this.updateDataAndUrl(
        {
          ...this.data,
          selectedStep: this.completedSteps.includes('insurance') ? 'confirm' : 'insurance'
        },
        {
          replace: true
        }
      );
    } else {
      // If only step available is create_account, then user
      // has skipped booking and just signed up. Bring them to login after verification
      const allSteps = Object.keys(this.data.steps);

      if (allSteps.length === 1 && allSteps[0] === 'create_account') {
        let url = '/login';

        if (this.data.target) {
          url = url.concat(`?target=${this.data.target}`);
        }

        this.history.push(url);
      }
    }
  }

  setBookingAppointmentSuccess(appointment: Appointment) {
    analytics.track(analytics.EVENTS.APPOINTMENT_BOOKING_SUCCEEDED, {
      appointment_category: appointment.appointmentType?.category?.name,
      appointment_type_id: appointment.appointmentType?.id,
      appointment_type_name: appointment.appointmentType?.name,
      appointment_id: appointment.id,
      lab_id: appointment.location?.lab?.id,
      clinic_id: appointment.location?.id
    });

    this.updateDataAndUrl({
      ...this.data,
      purchasedProduct: { type: 'appointment', data: appointment },
      selectedStep: 'success'
    });

    const replaceUrl = generateConfirmationLink(this.data);

    if (replaceUrl) {
      this.history.replace(replaceUrl);
    }
  }

  skipToSignUp() {
    analytics.track(analytics.EVENTS.SKIP_TO_SIGN_UP);

    this.updateDataAndUrl({
      ...this.data,
      // @ts-ignore
      steps: { create_account: createAccountStep },
      selectedStep: 'create_account'
    });
  }

  goToConfirmStep() {
    if (this.canMoveToStep('confirm')) {
      analytics.page(analytics.PAGES.CONFIRM_PURCHASE);
    }

    this.updateDataAndUrl({
      ...this.data,
      selectedStep: 'confirm'
    });
  }

  setCompletedSteps(steps: Array<StepType>) {
    this.completedSteps = steps;
  }
}
