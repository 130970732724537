import React, { useEffect, useState } from 'react';

import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { TextInput } from 'components/v2/Inputs';
import { Heading, Text } from 'components/v2/Typography';
import usePatient from 'hooks/usePatient';
import { calculateEggCountRange } from 'kb-shared/utilities/eggCount';
import { diffYear } from 'kb-shared/utilities/momentHelpers';

import { EggCounter, Form, ResultBox } from './EggCount.styled';

export const EggCount = () => {
  const { patient } = usePatient();
  const [yourAge, setYourAge] = useState(diffYear(patient?.birthday).toString());
  const [amhLevel, setAmhLevel] = useState('');
  const [afc, setAfc] = useState('');
  const [eggCount, setEggCount] = useState('');

  useEffect(() => {
    if (!(yourAge && amhLevel && afc)) return setEggCount('');

    const eggCountResult = calculateEggCountRange(
      parseInt(yourAge),
      parseFloat(amhLevel),
      parseInt(afc)
    );

    eggCountResult && setEggCount(eggCountResult);
  }, [yourAge, amhLevel, afc]);

  return (
    <>
      <Form>
        <TextInput
          id="your-age-input"
          type="text"
          placeholder="Enter your age..."
          value={yourAge}
          onChange={event => setYourAge(event.currentTarget.value)}
        />
        <TextInput
          id="amh-level-input"
          type="text"
          placeholder="Enter AMH..."
          value={amhLevel}
          onChange={event => setAmhLevel(event.currentTarget.value)}
        />
        <TextInput
          id="agc-input"
          type="text"
          placeholder="Enter AFC..."
          value={afc}
          onChange={event => setAfc(event.currentTarget.value)}
        />
      </Form>
      <ResultBox>
        <EggCounter>
          <Heading tag="h6" styledAs="h2" noMargin>
            {eggCount}*
          </Heading>
          <Heading tag="h6" styledAs="h3" noMargin>
            eggs
          </Heading>
        </EggCounter>
        <Heading tag="h5" noMargin>
          Likely Count of Eggs Retrieved
        </Heading>
        <Text fontStyle="lightItalic" size="sm">
          This is intended as an estimate only, and your actual number could be higher or lower.
          It's based on this{' '}
          <LinkButton
            text="research paper"
            size="sm"
            fontStyle="lightItalic"
            onClick={() =>
              window.open(
                'https://s3.us-east-2.amazonaws.com/kindbody-portal-static-files/egg-predictor.pdf',
                '_blank'
              )
            }
          />
        </Text>
      </ResultBox>
    </>
  );
};
