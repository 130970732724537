import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { OutlineButton } from 'components/Buttons';
import { StripeContext } from 'components/CreditCardForm/types/creditCardForm';
import Modal from 'components/Modal';
import usePayInvoice from 'screens/Invoices/hooks/use-pay-invoice';
import { analytics } from 'utilities/analytics';
import { showErrorToast, showSuccessToast } from 'utilities/notificationUtils';
import { currencyNumberFormat } from 'utilities/number-format';
import { useBreakpoints } from 'utilities/useBreakpoints';

import Invoice from '../../types/invoice';
import * as Styled from './InvoicePayModal.styled';
import { PaymentDetails } from './PaymentDetails';

export const InvoicePayModal = ({
  open,
  invoice,
  onClose,
  onPaid
}: {
  invoice: Invoice;
  open: boolean;
  onClose: () => void;
  onPaid: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const { isMobile } = useBreakpoints();
  const [stripeContext, setStripeContext] = useState<StripeContext | undefined>(undefined);
  const { payInvoiceWithCreditCard, payInvoiceWithStripeIdentifier } = usePayInvoice();
  const [kbStripeCardStripeIdentifierSelected, setKbStripeCardStripeIdentifier] = useState('');
  const [inputValid, setInputValid] = useState(false);

  const onSuccess = () => {
    showSuccessToast("You've successfully made your payment!");
    onPaid();
  };

  const onError = (errorMessage: string) => {
    showErrorToast(errorMessage || DEFAULT_ERROR_MESSAGE);
    setLoading(false);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const invoiceIdentifier = invoice.azEncounterIdentifier || invoice.id;

    if (stripeContext) {
      const { stripe, cardNumberElement } = stripeContext;
      const params = {
        stripe,
        cardNumberElement,
        invoiceIdentifier
      };

      await payInvoiceWithCreditCard(params, onSuccess, onError);
    }

    if (kbStripeCardStripeIdentifierSelected) {
      const params = {
        invoiceIdentifier,
        kbStripeCardStripeIdentifier: kbStripeCardStripeIdentifierSelected
      };

      await payInvoiceWithStripeIdentifier(params, onSuccess, onError);
    }

    analytics.track(analytics.EVENTS.PATIENT_PORTAL_INVOICE_PAYMENT_SUCCESSFUL);

    setLoading(false);
  };

  const payButtonDisabled =
    loading || !inputValid || (!stripeContext && !kbStripeCardStripeIdentifierSelected);

  useEffect(() => {
    if (open) {
      analytics.track(analytics.EVENTS.PATIENT_PORTAL_INVOICE_PAYMENT_MODAL_LOADED);
    }
  }, [open]);

  return (
    <Modal label="Make a payment" open={open} onRequestClose={onClose} mobileFullscreen>
      <Styled.Form onSubmit={onSubmit}>
        {isMobile ? (
          <Styled.CloseArrowButton type="button" onClick={onClose}>
            Your invoices
          </Styled.CloseArrowButton>
        ) : (
          <Styled.CloseIconButton type="button" onClick={onClose} aria-label="Close modal">
            <CloseIcon />
          </Styled.CloseIconButton>
        )}

        <Styled.Header>Make a payment</Styled.Header>

        <Styled.FlexContainer>
          <Styled.InvoiceField>
            <Styled.Label>Appointment</Styled.Label>
            <Styled.Value>{invoice?.service}</Styled.Value>
          </Styled.InvoiceField>

          <Styled.InvoiceField>
            <Styled.Label>Date of service</Styled.Label>
            <Styled.Value>{moment(invoice?.dateOfInvoice).format('MM/DD/YYYY')}</Styled.Value>
          </Styled.InvoiceField>

          <Styled.InvoiceField>
            <Styled.Label>Invoice number</Styled.Label>
            <Styled.Value>{invoice?.azEncounterIdentifier || invoice?.id}</Styled.Value>
          </Styled.InvoiceField>

          <Styled.InvoiceField>
            <Styled.Label>Amount</Styled.Label>
            <Styled.Value>{currencyNumberFormat(invoice?.balance ?? 0)}</Styled.Value>
          </Styled.InvoiceField>
        </Styled.FlexContainer>

        <Styled.PaymentSection>
          <PaymentDetails
            onSelectCreditCard={data => {
              setKbStripeCardStripeIdentifier(data);
            }}
            onStripeCardElementInitialized={(stripe, cardNumberElement) => {
              setStripeContext({
                stripe,
                cardNumberElement
              });
            }}
            onValidation={valid => setInputValid(valid)}
          />
        </Styled.PaymentSection>

        <Styled.FormActions>
          <Styled.PayButton
            type="submit"
            loading={loading}
            disabled={payButtonDisabled}
            text="PAY"
          />
          <OutlineButton type="button" onClick={onClose}>
            Cancel
          </OutlineButton>
        </Styled.FormActions>
      </Styled.Form>
    </Modal>
  );
};

const DEFAULT_ERROR_MESSAGE =
  "We're sorry but we could not process your payment. Please try again.";
