import { themes } from 'kb-shared';
import styled from 'styled-components';

const { breakpoints } = themes;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  padding: 45px 45px 30px 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #ffffff;
  border-left: 8px solid #ffe664;

  @media (min-width: ${breakpoints.tabletLandscape}) {
    padding: 45px 45px 20px 24px;
    min-width: 500px;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const Title = styled.h1`
  ${themes.fonts.DomaineDisplay}
  font-size: 28px;
  color: ${themes.colors.neutral.primaryNavy};
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: 35px auto;
  grid-gap: 16px;
  align-items: center;

  & > svg {
    transform: scale(1.5);
    justify-self: flex-end;
  }
`;

export const Body = styled.div`
  margin-left: 51px;
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  font-size: 16px;
`;

export const PhoneNumber = styled.div`
  ${({ theme }) => theme.fonts.DomaineDisplay};
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
`;

export const PhoneNumberSmall = styled.a`
  color: ${({ theme }) => theme.colors.blue.darkBlue};
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
`;
