import { ApolloError } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Query } from '@apollo/client/react/components';
import { Loader } from 'components/Loader/Loader';
import TabNavigator from 'components/TabNavigator';
import { Heading } from 'components/v2/Typography';
import { usePatient } from 'hooks';
import { useHomeMarket } from 'hooks/useHomeMarket';
import { themes } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { CONTAINER_DEPRECATED } from 'screens/styled_common';
import { analytics } from 'utilities/analytics';
import { pageUrl } from 'utilities/pageUrl';

import Assessment from './Assessment';
import { AtHomeTesting } from './AtHomeTesting/AtHomeTesting';
import { EggRetrieval } from './EggRetrieval/EggRetrieval';
import EmptyState from './EmptyState';
import { RESULTS_QUERY } from './graphql/resultsQuery';
import { DailyResult, ResponseData, ResultsQueryProps, Tab, TabTypes } from './Results.types';
import Stimulation from './Stimulation';
import { ResultsCard, ShadowWrapper } from './styled';

export const Results = () => {
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [currentTab, setCurrentTab] = useState<TabTypes | null>(null);
  const { patient } = usePatient();
  const { vios: isViosPatient } = useHomeMarket();
  const history = useHistory();

  const tabNavigatorThemeConfig = {
    tab: {
      selectedBG: themes.colors.neutral.primaryNavy,
      deselectedBG: themes.colors.neutral.lavender,
      selectedTitle: themes.colors.white,
      deselectedTitle: themes.colors.neutral.navy,
      fullWidthTabs: true
    },
    content: {
      selectedBG:
        currentTab === TabTypes.EGG_RETIEVAL
          ? themes.colors.yellow.lightestYellow //TODO: temp solution until whole Results screen isn't redesigned
          : themes.colors.neutral.primaryNavy,
      deselectedBG: themes.colors.neutral.lavender
    }
  };
  const indexOfCurrentTab = tabs.findIndex(value => value.type === currentTab);

  useEffect(() => {
    analytics.page(analytics.PAGES.RESULTS);
  }, []);

  const onScheduleAssessment = () => {
    analytics.track(analytics.EVENTS.APPOINTMENT_CTA_CLICKED);
    history.push(pageUrl.booking.bookingQuiz());
  };

  const selectCard = (index: number) => {
    const selectedTab = tabs[index];
    setCurrentTab(selectedTab.type);

    if (selectedTab.type === 'at-home') analytics.track(analytics.EVENTS.AHT_RESULTS_VIEWED);
  };

  const getTabs = (data: ResponseData): Tab[] => {
    const allResults: Tab[] = [];
    if (!data) return [];

    const { results, dailyResults: maybeDailyResults, cultureResult } = data;

    const labResults = results.labs.concat(results.spermAnalyses).length > 0;
    const afcResults = results.afc.length > 0;
    if (labResults || afcResults) {
      allResults.push({
        type: TabTypes.FERTILITY,
        title: 'Assessment'
      });
    }

    const dailyResults: DailyResult[] = maybeDailyResults || [];
    if (dailyResults.length > 0) {
      // Don't render stimulation tab if there are no labs present on any of the days.
      // There are user's who have dailResults available but no labs yet, so no valuable data to show.
      const hasLabs = dailyResults.find((value: DailyResult) => {
        return value.labs.length > 0;
      });
      if (hasLabs) {
        allResults.push({
          type: TabTypes.STIMULATION,
          title: 'Stimulation'
        });
      }
    }

    if (cultureResult) {
      allResults.push({
        type: TabTypes.EGG_RETIEVAL,
        title: 'Egg Retrieval'
      });
    }

    const { homeTestingResults } = results;
    if (homeTestingResults.length) {
      allResults.push({
        type: TabTypes.AT_HOME,
        title: 'At-Home Test Results'
      });
    }

    return allResults;
  };

  const setupTabs = (data: ResponseData) => {
    if (currentTab !== null && !data) return;

    const availableTabs = getTabs(data);
    setTabs(availableTabs);

    const hasTabSelected = hasQueryString('activeTab');
    const hasAtHomeTab = hasTab(availableTabs, TabTypes.AT_HOME);
    const hasStimTab = hasTab(availableTabs, TabTypes.STIMULATION);
    const hasAssessmentTab = hasTab(availableTabs, TabTypes.FERTILITY);
    const hasRetrievalTab = hasTab(availableTabs, TabTypes.EGG_RETIEVAL);
    if (hasTabSelected && hasAtHomeTab) {
      setCurrentTab(TabTypes.AT_HOME);
    } else if (hasStimTab) {
      setCurrentTab(TabTypes.STIMULATION);
    } else if (hasAssessmentTab) {
      setCurrentTab(TabTypes.FERTILITY);
    } else if (hasRetrievalTab) {
      setCurrentTab(TabTypes.EGG_RETIEVAL);
    }
  };

  const renderContent = (item: Tab, data?: ResponseData) => {
    if (!data) return null;
    if (!patient) return null;
    const { results, dailyResults, cultureResult } = data;

    switch (item.type) {
      case TabTypes.FERTILITY:
        return (
          <Assessment
            results={results}
            onScheduleAssessment={onScheduleAssessment}
            patient={patient}
          />
        );
      case TabTypes.STIMULATION:
        return <Stimulation dailyResults={dailyResults} />;
      case TabTypes.EGG_RETIEVAL:
        return <EggRetrieval cultureResult={cultureResult || {}} />;
      case TabTypes.AT_HOME:
        return <AtHomeTesting patient={patient} results={results.homeTestingResults} />;
      default:
        return null;
    }
  };

  const hasResult = (data: ResponseData) => {
    const hasLabs = data?.results.labs.length;
    const hasAFC = data?.results.afc.length;
    const hasSpermAnalysis = data?.results.spermAnalyses.length;
    return hasLabs || hasAFC || hasSpermAnalysis || data?.cultureResult;
  };

  return (
    <Query
      query={RESULTS_QUERY}
      onCompleted={(data: ResponseData) => setupTabs(data)}
      onError={(error: ApolloError) => BugTracker.notify(error, 'Failed to fetch Results')}
    >
      {({ data, loading, error }: ResultsQueryProps) => {
        if (error) return null;

        if (loading) {
          return <Loader absoluteCentered />;
        }

        return (
          <CONTAINER_DEPRECATED>
            <Heading>Your Results</Heading>

            <ResultsCard>
              <ShadowWrapper>
                {data && hasResult(data) ? (
                  <TabNavigator
                    items={tabs}
                    currentIndex={indexOfCurrentTab}
                    getSectionTitle={(index: number, item: Tab) => item.title}
                    getUniqueKey={(index: number, item: Tab) => item.type}
                    renderContent={(index: number, item: Tab) => renderContent(item, data)}
                    onSelect={selectCard}
                    theme={tabNavigatorThemeConfig}
                  />
                ) : (
                  <EmptyState
                    canSchedule={!isViosPatient}
                    onScheduleAssessment={onScheduleAssessment}
                  />
                )}
              </ShadowWrapper>
            </ResultsCard>
          </CONTAINER_DEPRECATED>
        );
      }}
    </Query>
  );
};

const hasTab = (tabs: Tab[], tabName: TabTypes): boolean => {
  return !!tabs.find(val => val.type === tabName);
};

const hasQueryString = (queryString: string): boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(queryString);
};
