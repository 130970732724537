import React from 'react';

import { useContactInfo } from 'hooks';

import { BoldText, Contact } from './AdditionalInfo.styled';
import { PanelProps } from './AdditionalInfo.types';
import MarketContactInfo from './MarketContactInfo';

const AdditionalInfoProviderSearch = (props: PanelProps) => {
  const { rescheduleNumber } = useContactInfo();
  const showRescheduleContact = props.rescheduleModal;

  return (
    <>
      <BoldText>
        For booking assistance or to book an in person appointment{' '}
        {showRescheduleContact && rescheduleNumber ? (
          <MarketContactInfo rescheduleNumber={rescheduleNumber} />
        ) : (
          <>
            please{' '}
            <a
              href="https://kindbody.com/assessment-booking-request/"
              target="_blank"
              rel="noreferrer noopener"
            >
              email
            </a>{' '}
            or call us at <a href="tel://855-563-2639">855-563-2639</a>.
          </>
        )}
      </BoldText>
      <br />
      If you would like to book with a specific doctor, please visit our{' '}
      <Contact
        href="https://kindbody.com/kindbody-doctors/"
        target="_blank"
        rel="noreferrer noopener"
      >
        doctor pages
      </Contact>
      .
    </>
  );
};

export default AdditionalInfoProviderSearch;
