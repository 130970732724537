import React from 'react';

import { Contact } from './AdditionalInfo.styled';

type Props = {
  rescheduleNumber: string;
  capitalize?: boolean;
};

const MarketContactInfo = (props: Props) => {
  const { rescheduleNumber, capitalize } = props;
  return (
    <>
      {capitalize ? 'P' : 'p'}lease send a secure message or call us at{' '}
      <Contact href={`tel:${rescheduleNumber}`}>{rescheduleNumber}</Contact>
    </>
  );
};

export default MarketContactInfo;
