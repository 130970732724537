import React, { useRef } from 'react';

import { useDropdownMenu } from 'hooks/useDropdownMenu';
import { useRelativeClickTracker } from 'hooks/useRelativeClickTracker';

import { LinkButton } from '../Buttons/LinkButton/LinkButton';
import { Container, DropdownIcon, Menu } from './Dropdown.styled';
import { DropdownProps, GenericDropdownProps } from './Dropdown.types';
import { DropdownItem } from './DropdownItem';

export function Dropdown({ items }: DropdownProps) {
  return (
    <GenericDropdown items={items}>
      <LinkButton leftIcon={<DropdownIcon />} size="sm" onClick={() => {}} />
    </GenericDropdown>
  );
}

export function GenericDropdown({ items, children }: GenericDropdownProps) {
  const { showMenu, toggleMenu } = useDropdownMenu();
  const hasSections = items.some(item => item.type === 'section');
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (isInside: boolean) => {
    if (isInside) {
      toggleMenu();
    } else {
      toggleMenu(false);
    }
  };

  useRelativeClickTracker(wrapperRef, handleOutsideClick);

  return (
    <Container ref={wrapperRef}>
      {children}
      <Menu showMenu={showMenu} hasSections={hasSections}>
        {items.map((item, index) => (
          <DropdownItem key={index} {...item} />
        ))}
      </Menu>
    </Container>
  );
}
