import styled from 'styled-components';

import { YellowButton } from '../../Buttons';

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  padding: 0px 12px;
  flex-grow: 1;
`;

export const BookButton = styled(YellowButton)`
  align-self: center;
`;

export const DisclaimerButton = styled.button`
  align-self: center;
  text-decoration: underline;
  padding-top: 15px;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;

  ${({ theme }) => theme.fonts.FoundersGrotesk};
`;

export const OkButton = styled(YellowButton)`
  align-self: center;
`;
