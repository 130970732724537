import React from 'react';
import { Link } from 'react-router-dom';

import { ArrowRightFromBracket } from 'assets/icons/Icons';
import { pageUrl } from 'utilities/pageUrl';

import { MenuIcon } from '../MenuIcon/MenuIcon';
import { NavigationItems } from '../NavigationItems/NavigationItems';
import User from '../User/User';
import { UserProfileButton } from '../UserProfileButton/UserProfileButton';
import {
  ButtonItemsContainer,
  ExpandedMenuContainer,
  MenuContent,
  MenuUserContainer,
  MenuSecondary,
  LogoutSpan,
  Icon
} from './ExpandedNavigationItems.styled';
import { ExpandedNavigationItemsProps } from './ExpandedNavigationItems.types';

export const ExpandedNavigationItems = ({
  toggleMenu,
  currentPathName,
  numberOfUnreadMessages,
  items,
  patient
}: ExpandedNavigationItemsProps) => {
  return (
    <ExpandedMenuContainer>
      <MenuContent>
        <MenuIcon toggleMenu={toggleMenu} isOpen={true} />
        <NavigationItems
          items={items}
          currentPathName={currentPathName}
          labelVisible={true}
          numberOfUnreadMessages={numberOfUnreadMessages}
        />
        <ButtonItemsContainer>
          <MenuSecondary>
            <Link to={pageUrl.logout()}>
              <Icon>
                <ArrowRightFromBracket type="solid" />
              </Icon>
              <LogoutSpan>Log out</LogoutSpan>
            </Link>
          </MenuSecondary>
          <MenuUserContainer>
            <UserProfileButton size={48} />
            <User patient={patient} />
          </MenuUserContainer>
        </ButtonItemsContainer>
      </MenuContent>
    </ExpandedMenuContainer>
  );
};
