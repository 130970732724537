import React, { useState } from 'react';

import RiskAssessmentFormModal from 'components/PregnancyRiskAssessmentForm/components/RiskAssessmentFormModal';
import { useRiskAssessmentForm } from 'components/PregnancyRiskAssessmentForm/hooks/use-risk-assessment-form';
import { RiskAssessmentResultModal } from 'components/RiskAssessmentResult/components/RiskAssessmentResultModal';
import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';
import { usePatient } from 'hooks/usePatient';
import { isDisneyPatient } from 'kb-shared/utilities/lab.helper';
import { GAEA_MATERNITY_BOOKLET_URL, MATERNITY_BOOKLET_URL } from 'utilities/documents';
import { showErrorToast } from 'utilities/notificationUtils';

import { ActionsContainer, BottonContainer } from './PregnancyCalculatorActionButtons.styled';

export const PregnancyCalculatorActionButtons = ({
  onCancelClick
}: {
  onCancelClick?: () => void;
}): JSX.Element => {
  const { patient } = usePatient();
  const labId = patient?.lab?.id;
  const [step, setStep] = useState<RiskAssessmentStep>('initialStep');
  const { form, saveAnswer } = useRiskAssessmentForm();

  const onSubmit = () => {
    const isIncomplete = form?.fields.some(field => field.answer === undefined) ?? false;
    if (isIncomplete) {
      showErrorToast('Please complete the assessment questions.');
    } else {
      setStep('resultOpen');
    }
  };

  return (
    <ActionsContainer>
      <Text fontStyle="regular" size="lg">
        If you have not gone to your first obstetrics (OB) appointment, please take the pregnancy
        assessment.
      </Text>

      <BottonContainer>
        <Button
          onClick={() => setStep('formOpen')}
          label="Take the Assessment"
          size="lg"
          fullWidth
        />
        {labId && (
          <Button
            onClick={() =>
              window.open(
                isDisneyPatient(labId) ? GAEA_MATERNITY_BOOKLET_URL : MATERNITY_BOOKLET_URL,
                '_blank'
              )
            }
            label="Read Maternity Booklet"
            category="secondary"
            size="lg"
            fullWidth
          />
        )}

        {onCancelClick && (
          <Button
            label="Cancel Tracker"
            onClick={onCancelClick}
            category="danger"
            size="lg"
            fullWidth
          />
        )}
      </BottonContainer>

      <RiskAssessmentFormModal
        isOpen={step === 'formOpen'}
        form={form}
        onClose={() => setStep('initialStep')}
        onSaveAnswer={saveAnswer}
        onSubmit={onSubmit}
      />
      <RiskAssessmentResultModal
        isOpen={step === 'resultOpen'}
        onClose={() => setStep('initialStep')}
      />
    </ActionsContainer>
  );
};

type RiskAssessmentStep = 'initialStep' | 'formOpen' | 'resultOpen';
