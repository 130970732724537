import React, { useState } from 'react';

import ContactModal from 'components/Footer/ContactModal';
import { useContactInfo } from 'hooks';

import { Label, Text, ContactText, Container, ModalButton } from './ContactDiv.styled';

type Props = {
  className?: string;
};

const ContactDiv = (props: Props) => {
  const { className } = props;
  const [modal, setModal] = useState(false);
  const { email, numberLabel, number, nightNumberAvailable } = useContactInfo();

  return (
    <Container className={className}>
      <Label>Email</Label>
      <Text>
        <a href={`mailto:${email}`}>{email}</a>
      </Text>

      <Label>Phone</Label>
      <Text>
        <a href={`tel:${number}`}>{numberLabel}</a>
      </Text>
      {nightNumberAvailable ? (
        <>
          <ContactText>
            For urgent medical emergencies,{' '}
            <ModalButton
              // eslint-disable-next-line
              aria-description="Button to open a modal with the medical contact"
              onClick={() => setModal(true)}
            >
              click here
            </ModalButton>
          </ContactText>
          <ContactModal visible={modal} onDismiss={() => setModal(false)} />
        </>
      ) : null}
    </Container>
  );
};

export default ContactDiv;
