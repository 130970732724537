import * as React from 'react';

import styled from 'styled-components';

import Button from '../../Button/Button';
import UnderlineButton from '../../UnderlineButton';

const ButtonContainer = styled.div`
  margin: 30px 0 0 0;
`;

const SubmitButtonContainer = styled(ButtonContainer)`
  margin: 30px 0 0 0;
`;

type Props = {
  onSubmit: () => void;
  onCancel: () => void;
  showSubmit?: boolean;
  loading: boolean;
  loadingPercentage?: number | null;
};

const SubmitOrCancel = (props: Props) => {
  const { onSubmit, onCancel, loading, loadingPercentage } = props;

  const showSubmit = props.showSubmit ?? true;

  return (
    <React.Fragment>
      <SubmitButtonContainer>
        {showSubmit && (
          <Button
            label="Submit"
            onClick={onSubmit}
            loading={loading}
            disabled={loading}
            loadingPercentage={loadingPercentage}
          />
        )}
      </SubmitButtonContainer>
      <ButtonContainer>
        <UnderlineButton onClick={onCancel} label="Cancel" />
      </ButtonContainer>
    </React.Fragment>
  );
};

export default SubmitOrCancel;
