import { useQuery } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ClipBoardMedical } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { Heading, Text } from 'components/v2/Typography';
import theme from 'kb-shared/theme';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { pageUrl } from 'utilities/pageUrl';

import {
  IconContainer,
  IllustratedBackgroundWidget,
  WidgetTitleContainer
} from '../Widgets/Widgets.styled';
import { ResultsQuery } from './Results.graphql';
import { Header, Result, ResultContainer, ResultValue } from './Results.styled';
import { DerivedState, LoadedResults, Props } from './Results.types';

export const Results: React.FC<Props> = props => {
  const history = useHistory();
  const { error, loading, data } = useQuery<{
    results: LoadedResults;
  }>(ResultsQuery, { onError: error => BugTracker.notify(error, 'ResultDashboardFetch') });

  const getButtonProps = (state: DerivedState): { title: string; url: string } | null => {
    switch (state.type) {
      case 'AllResults':
      case 'AMHProspect':
        return { title: 'Learn more', url: pageUrl.results() };
      case 'EmptyAMH':
      default:
        return null;
    }
  };

  const getDerivedState = (results: LoadedResults): DerivedState => {
    const { hasScheduledAMH } = props;
    const amh = results.labs.filter(result => result.type === 'amh')[0];

    if (amh && results.afc.length > 0) {
      const latestAFC = results.afc[0];
      return { type: 'AllResults', amh, afc: latestAFC };
    } else if (hasScheduledAMH && !amh) {
      return { type: 'EmptyAMH' };
    } else if (amh && results.afc.length === 0) {
      return { type: 'AMHProspect', amh };
    }
    return { type: 'none' };
  };

  const getTitleAttributes = (state: DerivedState): { title: string; description: string } => {
    switch (state.type) {
      case 'EmptyAMH':
      case 'AMHProspect':
        return {
          title: 'Your AMH Report:',
          description: 'Below are results from your test:'
        };
      case 'AllResults':
        return {
          title: 'My Results',
          description: 'Below are results from your assessment:'
        };
      default:
        return {
          title: '',
          description: ''
        };
    }
  };

  const renderCurrentState = (state: DerivedState) => {
    switch (state.type) {
      case 'EmptyAMH':
        return (
          <ResultContainer>
            <Result>
              <ResultValue>
                <Heading tag="div" styledAs="h2" color={theme.colors.neutral.lavender} noMargin>
                  Results Pending
                </Heading>
              </ResultValue>
            </Result>

            <Text tag="p" size="lg" color={theme.colors.neutral.lavender}>
              AMH is one of many factors in assessing your fertility to help you plan for the
              future.
            </Text>
            <Text tag="p" size="lg" color={theme.colors.neutral.lavender}>
              Schedule an assessment with a fertility specialist to start the conversation today.
            </Text>
          </ResultContainer>
        );
      case 'AMHProspect':
        return (
          <ResultContainer>
            <Result>
              <ResultValue>
                <Heading tag="div" styledAs="h1" color={theme.colors.neutral.lavender} noMargin>
                  {state.amh.value}
                </Heading>
                <Heading tag="div" styledAs="h3" color={theme.colors.neutral.lavender} noMargin>
                  {state.amh.units}
                </Heading>
              </ResultValue>

              <Heading tag="div" styledAs="h5" color={theme.colors.neutral.lavender} noMargin>
                AMH level
              </Heading>
            </Result>

            <Text tag="p" size="lg" color={theme.colors.neutral.lavender}>
              AMH is one of many factors in assessing your fertility to help you plan for the
              future.
            </Text>
            <Text tag="p" size="lg" color={theme.colors.neutral.lavender}>
              Schedule an assessment with a fertility specialist to start the conversation today.
            </Text>
          </ResultContainer>
        );
      case 'AllResults':
        const amh = state.amh;
        const afc = state.afc;
        return (
          <ResultContainer>
            <Result>
              <ResultValue>
                <Heading tag="div" styledAs="h1" color={theme.colors.neutral.lavender} noMargin>
                  {amh.value}
                </Heading>
                {amh.units && (
                  <Heading tag="div" styledAs="h3" color={theme.colors.neutral.lavender} noMargin>
                    {amh.units}
                  </Heading>
                )}
              </ResultValue>
              <Heading tag="div" styledAs="h5" color={theme.colors.neutral.lavender} noMargin>
                AMH level
              </Heading>
            </Result>

            <Result>
              <ResultValue>
                <Heading tag="div" styledAs="h1" color={theme.colors.neutral.lavender} noMargin>
                  {afc.value ? afc.value : 'Unknown'}
                </Heading>
                {amh.units && (
                  <Heading tag="div" styledAs="h3" color={theme.colors.neutral.lavender} noMargin>
                    {afc.units}
                  </Heading>
                )}
              </ResultValue>

              <Heading tag="div" styledAs="h5" color={theme.colors.neutral.lavender} noMargin>
                Antral follicle count
              </Heading>
            </Result>
          </ResultContainer>
        );
      default:
        return null;
    }
  };

  const renderButton = (derivedState: DerivedState) => {
    const buttonProps = getButtonProps(derivedState);
    if (buttonProps) {
      return (
        <Button
          label={buttonProps.title}
          category="primary"
          size="lg"
          onClick={() => history.push(buttonProps.url)}
          fullWidth
          uppercase
        />
      );
    }
  };

  if (error || loading || !data || !data.results) return null;

  const results: LoadedResults = data.results;
  const derivedState = getDerivedState(results);

  if (derivedState.type === 'none') return null;

  const textProps = getTitleAttributes(derivedState);

  return (
    <IllustratedBackgroundWidget $variant={5} $bgColor={theme.colors.neutral.primaryNavy}>
      <Header>
        <IconContainer $iconWidth={36} $iconHeight={48} $color={theme.colors.semantic.pink}>
          <ClipBoardMedical type="solid" />
        </IconContainer>
        <WidgetTitleContainer $noMargin>
          <Text color={theme.colors.semantic.pink}>{textProps.title}</Text>
        </WidgetTitleContainer>
      </Header>

      <Text size="lg" color={theme.colors.neutral.lavender}>
        {textProps.description}
      </Text>

      {renderCurrentState(derivedState)}

      {renderButton(derivedState)}
    </IllustratedBackgroundWidget>
  );
};
