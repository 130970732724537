import React from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

const FIXED_WIDTH_RAW = 175;
const FIXED_WIDTH = `${FIXED_WIDTH_RAW}px`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${FIXED_WIDTH};
  min-height: 82px;
  background-color: ${themes.colors.neutral.navy};
  border-radius: 8px;
  overflow: hidden;
`;

const TitleContainer = styled.div`
  width: '100%';
  height: 20px;
  background-color: ${themes.colors.yellow.primary};
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  ${themes.fonts.FoundersGrotesk};
  color: ${themes.colors.neutral.primaryNavy};
  font-size: 14px;
  font-weight: 300;
  text-align: center;
`;

const LevelContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const ValueText = styled.div`
  ${themes.fonts.FoundersGrotesk};
  color: white;
  font-size: 48px;
  font-weight: 300;
  text-align: center;
`;

const UnitsText = styled.div`
  ${themes.fonts.FoundersGrotesk};
  color: ${themes.deprecatedColors.grayLight};
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 9px;
`;

type Props = {
  currentDay: number;
  level: number | null;
  units: string;
};

type State = {};

export default class GraphToolTip extends React.Component<Props, State> {
  static FIXED_WIDTH = FIXED_WIDTH_RAW;

  getLevel() {
    const { level } = this.props;
    if (level) {
      return level;
    }
    return 'N/A';
  }

  getUnits() {
    // Only display units if level is available
    const { level, units } = this.props;
    if (level) {
      return units;
    }
    return null;
  }

  render() {
    const { currentDay } = this.props;
    return (
      <Container>
        <TitleContainer>
          <Title>{`Estradiol Level on Day ${currentDay + 1}`}</Title>
        </TitleContainer>
        <LevelContainer>
          <ValueText>{this.getLevel()}</ValueText>
          <UnitsText>{this.getUnits()}</UnitsText>
        </LevelContainer>
      </Container>
    );
  }
}
