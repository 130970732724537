import styled from 'styled-components';

import { InfoAreaContainer } from './styled-components';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const GestionalAgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px auto 24px; auto;
  text-align: center;
  font-size: 14px;
  ${({ theme }) => theme.fonts.FoundersGrotesk};
`;

export const StyledInfoAreaContainer = styled(InfoAreaContainer)`
  margin-top: 0px;
  margin-bottom: 12px;
  padding: 20px 42px;
`;

export const EstimatedDueDateContainer = styled.div`
  margin: 0 22px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;

  span {
    display: block;
    font-size: 24px;
    line-height: 37px;
    ${({ theme }) => theme.fonts.DomaineDisplay};
  }
`;
