import * as React from 'react';
import { SizeMe } from 'react-sizeme';
import { AreaChart, Area, ReferenceLine, ResponsiveContainer } from 'recharts';

import { themes, utils, NormalizedFollicleData } from 'kb-shared';
import styled from 'styled-components';

import { DailyResult, Follicle } from '../Results.types';
import FollicleIcon from './FollicleIcon';
import GraphToolTip from './GraphTooltip';

const { getNormalizedFollicleData, getSizeRange, getEstradiolLevel } = utils;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopContent = styled.div`
  padding: 20px 20px 24px 20px;
`;

const Title = styled.div`
  ${themes.fonts.DomaineDisplay};
  font-size: 24px;
  color: ${themes.colors.yellow.primary};
  padding: 0px 0px 12px 0px;
`;

const FollicleAndSizeContainer = styled.div`
  min-height: 90px;
`;

const SizeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SizeValueText = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-size: 48px;
  font-weight: 300;
  line-height: 1;
  color: white;
`;

const SizeUnitsText = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  color: white;
  padding-left: 10px;
`;

const ToolTipContainer = styled.div`
  width: 100%;
  margin-bottom: -8px;
  overflow: hidden;
  flex: 1;
`;

interface ProgressProps {
  progress: number;
}

const ToolTipPosition = styled.div`
  display: flex;
  position: relative;
  left: ${(props: ProgressProps) => {
    return `${props.progress}px`;
  }};
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FollicleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const FollicleWrapper = styled.div`
  padding: 1px;
`;

type Props = {
  results: Array<DailyResult>;
  currentResultIndex: number;
};

type State = {};

export default class Graph extends React.Component<Props, State> {
  _getNormalizedFollicleData(): NormalizedFollicleData[] {
    const resultsCurrentDay: DailyResult | null = this.props.results[this.props.currentResultIndex];
    const follicles: Array<Follicle> | null = resultsCurrentDay && resultsCurrentDay.follicles;
    // If user has no follicle results for this day, skip
    if (!follicles || follicles.length === 0) {
      return [];
    }
    return getNormalizedFollicleData(follicles);
  }

  _getSizeRange(normalizedfollicleData: NormalizedFollicleData[]): string | null {
    return getSizeRange(normalizedfollicleData);
  }

  renderFollicles(normalizedfollicleData: NormalizedFollicleData[]) {
    return (
      <FollicleContainer>
        {normalizedfollicleData.map((follicle, index: number) => {
          const key = `${follicle.date}-${follicle.value}-${index}`;
          const normalizedSize = follicle.normalizedValue;
          return (
            <FollicleWrapper key={key}>
              <FollicleIcon size={normalizedSize} />
            </FollicleWrapper>
          );
        })}
      </FollicleContainer>
    );
  }

  getEstradiolLevels(): Array<{ value: number | null; units: string | null }> {
    return this.props.results.map((result: DailyResult) => {
      return getEstradiolLevel(result);
    });
  }

  render() {
    const { currentResultIndex, results } = this.props;
    const currentResult: DailyResult | null = results[currentResultIndex];
    const normalizedfollicleData = this._getNormalizedFollicleData();
    const sizeRange = this._getSizeRange(normalizedfollicleData);
    const sizeUnits = sizeRange ? 'mm in diameter' : null;
    const currentEstradiolLevel = getEstradiolLevel(currentResult);
    const estradioLevels = this.getEstradiolLevels();
    const numberOfFollicles = normalizedfollicleData?.length ?? 0;
    return (
      <Container>
        <TopContent>
          <Title>Follicles on Day {currentResultIndex + 1}</Title>
          <FollicleAndSizeContainer>
            {this.renderFollicles(normalizedfollicleData)}
            {numberOfFollicles > 0 && (
              <SizeContainer>
                <SizeValueText>{normalizedfollicleData.length}</SizeValueText>
                <SizeUnitsText>{numberOfFollicles === 1 ? 'follicle' : 'follicles'}</SizeUnitsText>
              </SizeContainer>
            )}
            <SizeContainer>
              <SizeValueText>{sizeRange}</SizeValueText>
              <SizeUnitsText>{sizeUnits}</SizeUnitsText>
            </SizeContainer>
          </FollicleAndSizeContainer>
        </TopContent>
        <ChartContainer>
          <SlidingToolTip
            results={results}
            currentResultIndex={currentResultIndex}
            currentEstradiolLevel={currentEstradiolLevel}
          />
          <ResponsiveContainer width={'100%'} height={200}>
            <AreaChart data={estradioLevels}>
              <Area
                type="monotone"
                dataKey="value"
                strokeWidth={14}
                stroke={themes.colors.yellow.primary}
                fill={themes.colors.yellow.primary}
                connectNulls={true}
              />
              <ReferenceLine
                x={this.props.currentResultIndex}
                stroke={themes.colors.neutral.navy}
                strokeWidth={6}
              />
            </AreaChart>
          </ResponsiveContainer>
        </ChartContainer>
      </Container>
    );
  }
}

type SlidingToolTipProps = {
  results: DailyResult[];
  currentResultIndex: number;
  currentEstradiolLevel: {
    value: number | null;
    units: string | null;
  } | null;
};

const SlidingToolTip = (props: SlidingToolTipProps) => {
  const { currentEstradiolLevel, currentResultIndex, results } = props;
  return (
    <SizeMe>
      {({ size: { width } }) => {
        // The Below calculation moves and centers the Tooltip so it moves with the slider/progress
        const percentComplete = currentResultIndex / (results.length - 1);
        // @ts-ignore
        const position = width * percentComplete;
        const minMaxPosition = Math.min(
          // @ts-ignore
          width - GraphToolTip.FIXED_WIDTH,
          Math.max(0, position - GraphToolTip.FIXED_WIDTH / 2)
        );
        return (
          <ToolTipContainer>
            <ToolTipPosition progress={minMaxPosition}>
              <GraphToolTip
                currentDay={currentResultIndex}
                level={currentEstradiolLevel && currentEstradiolLevel.value}
                units={(currentEstradiolLevel && currentEstradiolLevel.units) || ''}
              />
            </ToolTipPosition>
          </ToolTipContainer>
        );
      }}
    </SizeMe>
  );
};
