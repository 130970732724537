import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { AppointmentProduct, AppointmentType } from 'kb-shared';
import { pageUrl } from 'utilities/pageUrl';

import { PATIENT_MEMBERSHIP } from './Book.graphql';
import { State } from './Book.types';
import { Data, StepType } from './utils/BookingStateManager.types';

// If the user lands on confirm order page to buy a membership, but is already a member, redirect them to book.
export const RedirectIfMember = (props: {
  product: AppointmentProduct<AppointmentType> | null;
  history: any;
}) => {
  const { product, history } = props;
  const membership = useQuery(PATIENT_MEMBERSHIP, {
    skip: (product && product.type !== 'membership') || false
  });
  useEffect(() => {
    if (membership.data && membership.data.patient.patientMembership) {
      if (product && product.type === 'membership') {
        history.push(pageUrl.booking.index());
      }
    }
  }, [membership.data, product, history]);
  return null;
};

// @ts-ignore
export const hasEmployer = (data: Data) => Boolean(data.selectedProduct?.data?.employer);

const getAppointment = (product: AppointmentProduct) => {
  if (product.type === 'appointment') {
    return product.data;
  }

  if (product.type === 'reschedule_appointment') {
    return product.data?.appointmentType;
  }

  return null;
};

const getPageTitle = (stepType: StepType, bookingState: State) => {
  const { data, partnerClinicSelected, createAccountSubstep } = bookingState;
  const { selectedProduct, partnerClinicSearch } = data;
  const appointment = (selectedProduct && getAppointment(selectedProduct)) || null;

  switch (stepType) {
    case 'select_appointment':
      return 'Select an option below:';
    case 'location':
      if (partnerClinicSearch === 'search' && !partnerClinicSelected) {
        return 'Find a Partner Clinic';
      } else if (partnerClinicSelected) {
        return '';
      } else if (appointment && appointment.virtual) {
        return 'Choose a time for your virtual consult';
      } else {
        return 'Great! Where would you like to meet us?';
      }
    case 'create_account':
      switch (createAccountSubstep) {
        case 'choose_signup_option':
          return 'Create an Account';
        case 'email':
          return 'Sign up With Email';
        case 'google_attributes':
          return 'Tell us about yourself';
        case 'login':
          return 'Welcome back!';
        case 'forgot_password':
          return 'Create your new password';
        case 'verification_code':
          return 'Enter verification code';
        default:
          return 'Create an Account';
      }
    case 'time':
      if (appointment && appointment.virtual) {
        return 'Choose a time for your virtual consult';
      } else {
        return '';
      }
    case 'insurance':
      return 'Insurance Details';
    case 'confirm':
      if (selectedProduct && selectedProduct.type === 'enterprise_membership') {
        return 'Activate Membership';
      } else {
        return hasEmployer(data) ? 'Activate Kindbody Membership' : 'Confirmation:';
      }
    case 'success':
      if (partnerClinicSelected) {
        return "We're working to schedule your appointment!";
      } else {
        return "You're all set!";
      }
    default:
      return '';
  }
};

export const getStepsMetadata = (state: State, completedSteps: Array<StepType>) => {
  const {
    data: { steps, selectedStep }
  } = state;
  let currentStep = 0;
  let pageTitle = '';

  const stepNames = (Object.keys(steps) as Array<StepType>)
    .map((key: StepType, index: number) => {
      const step = steps[key];

      if (step && step.type === selectedStep) {
        currentStep = index;
        pageTitle = getPageTitle(step.type, state);
      }

      return (step && step.name) || '';
    })
    .filter(name => !completedSteps.includes(name.toLocaleLowerCase() as StepType))
    .filter(Boolean); // skip blank/hidden steps

  return {
    stepNames,
    currentStep,
    pageTitle
  };
};
