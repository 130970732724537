import React from 'react';

import { Membership, themes } from 'kb-shared';
import styled from 'styled-components';

import Button from '../../../components/Button/NewButton';
import { ConfirmDetailsWrapper } from '../steps/styles';
import MembershipDetailBlock from './MembershipDetailBlock';
import { TermsDetails } from './TermsDetails';

const { colors } = themes;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.neutral.primaryNavy};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`;

const Price = styled.div`
  ${themes.fonts.DomaineDisplay};
  font-size: 36px;
  text-align: center;
  color: white;
  padding-bottom: 20px;
`;

const AcessCodeHelper = styled(Price)``;

type Props = {
  loading: boolean;
  membership: Membership | null;
  termsAccepted: boolean;
  discountPrice: number | null;
  hasEmployer?: boolean;
  validatedPromoCode?: boolean;
  onTermsAcceptToggled: () => void;
};

const ConfirmMembershipDetails = (props: Props) => {
  const {
    loading,
    membership,
    termsAccepted,
    discountPrice,
    hasEmployer = false,
    validatedPromoCode = false,
    onTermsAcceptToggled
  } = props;
  if (!membership) return null;
  // special case for free membership promo because 0 is falsey
  const priceString =
    discountPrice === 0
      ? 'Free of charge'
      : `$${(discountPrice || membership.rateCents) / 100} billed yearly`;
  const accessCodeString = validatedPromoCode
    ? 'Membership unlocked'
    : 'Please enter activation code above.';
  return (
    <ConfirmDetailsWrapper>
      <Container>
        <Content>
          <MembershipDetailBlock hasEmployer={hasEmployer} />
          {hasEmployer && <AcessCodeHelper>{accessCodeString}</AcessCodeHelper>}
          {!hasEmployer && <Price>{priceString}</Price>}
          <TermsDetails selected={termsAccepted} onCheckBoxSelected={onTermsAcceptToggled} />
          <Button
            loading={loading}
            disabled={loading || !termsAccepted || (hasEmployer && !validatedPromoCode)}
            type="submit"
            text="Confirm & Book"
          />
        </Content>
      </Container>
    </ConfirmDetailsWrapper>
  );
};

ConfirmMembershipDetails.defaultProps = {
  loading: false,
  membership: null,
  termsAccepted: false,
  discountPrice: null,
  onTermsAcceptToggled: () => null
};

export default ConfirmMembershipDetails;
