import * as React from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';

import { useFeatureFlags } from 'hooks';
import useHomeMarket from 'hooks/useHomeMarket';
import { BookingNavigationTracker } from 'Routes/BookingNavigationTracker/BookingNavigationTracker';

import AppRoute from './Routes/AppRoute';
import { Redirects, Routes } from './Routes/Routes';
import ScrollToTop from './ScrollToTop';

const Router = () => {
  const { vios: isViosPatient } = useHomeMarket();
  const { ppBookingV2 } = useFeatureFlags();

  // During the transition period, we will have both booking v1 and v2 routes
  // in case we need to quickly transition back to v1(bugs, missing features, etc)
  // Booking routes that have v1 and v2 are marked with "bookingVersion" key(/Book and /create-account)
  const bookingFilteredRoutes = Routes.filter(route => {
    // filter out booking v1 or v2 routes based on feature flag
    if (ppBookingV2 && 'bookingVersion' in route) {
      return route.bookingVersion === 'v2';
    } else if (!ppBookingV2 && 'bookingVersion' in route) {
      return route.bookingVersion === 'v1';
    }

    // leave all the other routes as is
    return true;
  });

  return (
    <BrowserRouter>
      <BookingNavigationTracker />
      <ScrollToTop />
      <Switch>
        {Redirects}
        {bookingFilteredRoutes
          .filter(route => !isViosPatient || !route.hiddenFromVios)
          .map(route => {
            const { path, component, layout, page, ...rest } = route;
            return (
              <AppRoute
                exact
                key={path}
                path={path}
                component={component}
                layout={layout}
                page={page}
                {...rest}
              />
            );
          })}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
