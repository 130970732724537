import { useQuery } from '@apollo/client';
import { groupBy as _groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Heading } from 'components/v2/Typography';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { CONTAINER_DEPRECATED, PageInfo } from 'screens/styled_common';
import styled from 'styled-components';
import { analytics } from 'utilities/analytics';

import { AppointmentTypesResultsSection } from './AppointmentTypesResultsSection';
import { KB360_APPOINTMENT_TYPES, Kb360AppointmentTypesResponse } from './graphql/queries';
import { THERAPY_APPOINTMENT_TYPE } from './helpers/constants';
import { filterAppointmentTypes } from './helpers/helpers';
import { SearchResultsCount } from './SearchResultsCount';
import { KB360ServiceType } from './ServiceTypeIcon';
import { ServiceTypeSelect } from './ServiceTypeSelect';

export const Kindbody360Index = () => {
  const [selectedServiceTypes, setSelectedServiceTypes] = useState<KB360ServiceType[]>([]);
  const { data, loading, error } = useQuery<Kb360AppointmentTypesResponse>(KB360_APPOINTMENT_TYPES);

  useEffect(() => {
    analytics.page(analytics.PAGES.KIND360);
  }, []);

  useEffect(() => {
    if (!error) return;
    BugTracker.notify(error, 'Kindbody360');
  }, [error]);

  if (loading || error) return <></>;

  const allKb360AppointmentTypes = (data?.appointmentTypes ?? [])
    .filter(appointmentType => appointmentType.kb360Heading !== null)
    .concat(THERAPY_APPOINTMENT_TYPE);

  const filteredAppointmentTypes = filterAppointmentTypes(
    allKb360AppointmentTypes,
    selectedServiceTypes
  );

  const appointmentTypes = _groupBy(
    filteredAppointmentTypes,
    appointmentType => appointmentType.kb360Heading
  );
  const headings = Object.keys(appointmentTypes).sort();

  const total = allKb360AppointmentTypes.length;
  const matches = filteredAppointmentTypes.length;

  return (
    <CONTAINER_DEPRECATED>
      <Heading>Kindbody360</Heading>
      <PageInfo>
        Because supporting your emotional, mental, &amp; physical health is essential in providing
        the best possible fertility and family-building care. Our “whole person” approach curates
        treatments based on all dimensions of your life.
      </PageInfo>

      <ServiceTypeContainer>
        <ServiceTypeSelect onChange={newServiceTypes => setSelectedServiceTypes(newServiceTypes)} />
      </ServiceTypeContainer>

      <SearchResultsCount total={total} matches={matches} />

      {headings.map(heading => (
        <AppointmentTypesResultsSection
          key={heading}
          heading={heading}
          appointmentTypes={appointmentTypes[heading]}
        />
      ))}
    </CONTAINER_DEPRECATED>
  );
};

const ServiceTypeContainer = styled.div`
  margin-top: 25px;
`;
