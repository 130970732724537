/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import ReactLoading from 'react-loading';

import { themes } from 'kb-shared';
import styled from 'styled-components';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  loading?: boolean;
  color?: string;
  textColor?: string;
}

interface ContainerProps {
  inProgress?: boolean;
  disabled?: boolean;
  color?: string;
}

const Container = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 247px;
  border-radius: 27.5px;
  border-width: 0px;
  opacity: 1;
  transition: background-color 500ms;
  transition: border-color 500ms;
  :hover {
    opacity: 0.8;
  }
  :focus {
    outline: 0;
  }
  text-transform: uppercase;

  ${({ inProgress, color, disabled }: ContainerProps) => {
    if (disabled) {
      return `
        background-color:${themes.colors.neutral.navy};
        border: 1px solid;
        border-color: $night;
        &:hover {
          cursor: not-allowed;
          opacity: 1;
        }
      `;
    } else {
      return `
        background-color: ${
          inProgress
            ? themes.colors.neutral.primaryNavy
            : color
            ? color
            : themes.colors.yellow.primary
        };
      `;
    }
  }}
`;

const Text = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-size: 14px;
  color: ${props => props.color};
`;

const Button = (props: Props) => {
  const { text, disabled, loading, textColor, ...rest } = props;
  const deriveTextColor = disabled ? 'white' : textColor || 'black';
  return (
    <Container {...rest} inProgress={loading ? loading : undefined} disabled={disabled}>
      {loading ? (
        <ReactLoading type="spin" height={20} width={20} />
      ) : (
        <Text color={deriveTextColor}>{text}</Text>
      )}
    </Container>
  );
};

export default Button;
