import React from 'react';

import styled from 'styled-components';

import { YellowButton } from '../../../Buttons';
import { InfoAreaContainer } from './styled-components';
import TileWidget from './TileWidget';

interface Props {
  onPregnantClick: () => void;
}

const NoConfirmedPregnancy: React.FC<Props> = ({ onPregnantClick }) => (
  <TileWidget>
    <Description>
      Once you are pregnant you can track your weeks of pregnancy & due date here.
    </Description>
    <InfoAreaContainer>
      <InfoAreaText>
        If you have a regular menstrual cycle and are late by more than 7 days, please take a
        pregnancy test and let us know if you're pregnant!
      </InfoAreaText>
      <Button aria-describedby="Button to calculate pregnancy" onClick={onPregnantClick}>
        I'm pregnant!
      </Button>
    </InfoAreaContainer>
  </TileWidget>
);

export default NoConfirmedPregnancy;

const Description = styled.p`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
`;

const InfoAreaText = styled.p`
  font-size: 17px;
  text-align: center;
`;

const Button = styled(YellowButton)`
  margin-top: 20px;
`;
