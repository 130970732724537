import React, { Component, MouseEvent } from 'react';

import LeftBracket from 'components/SVG/LeftBracket';
import RightBracket from 'components/SVG/RightBracket';
import { themes } from 'kb-shared';
import styled, { css } from 'styled-components';

import { calendar } from '../../assets/icons';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';

type Props = {
  type?: string;
  title: string;
  buttons: Array<{ text?: string; onClick?: (e: MouseEvent) => void; color?: string }>;
  onClose: (e: MouseEvent) => void;
};

export default class Card extends Component<Props> {
  renderButtons() {
    return this.props.buttons.map((button, index) => {
      return (
        <Button
          tabIndex={0}
          key={`${button.text} + ${index}`}
          onClick={button.onClick}
          color={button.color}
        >
          {button.text}
        </Button>
      );
    });
  }

  render() {
    return (
      <Container
        // @ts-ignore
        type={this.props.type}
      >
        <Header>
          <IconContainer>
            <LeftBracket width={30} height={42} color={themes.colors.yellow.primary} />
            <CalIcon alt="" src={calendar} />
            <RightBracket width={30} height={42} color={themes.colors.yellow.primary} />
            <Title>{this.props.title}</Title>
          </IconContainer>
          <CloseContainer onClick={this.props.onClose}>
            <CloseIcon />
          </CloseContainer>
        </Header>
        <Content>{this.props.children}</Content>
        <ButtonContainer>{this.renderButtons()}</ButtonContainer>
      </Container>
    );
  }
}

//#region Styles
const Container = styled.div`
  max-width: 820px;
  border: 2px solid ${themes.colors.neutral.primaryNavy};
  box-shadow: -4px 4px 0px 0 ${themes.colors.neutral.primaryNavy};
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px 20px;

  ${props =>
    // @ts-ignore
    props.type === 'mobile-no-padding' &&
    css`
      @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
        padding-left: 0;
        padding-right: 0;
      }
    `}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CalIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const CloseContainer = styled.div`
  padding-top: 5px;
  padding-right: 5px;
  cursor: pointer;
`;

const Content = styled.div`
  margin: 20px;
`;

const Title = styled.div`
  ${themes.fonts.DomaineDisplay};
  font-size: 22px;
  text-align: center;
  margin: 0px 10px;
`;

const Button = styled.a`
  ${themes.fonts.FoundersGrotesk};
  cursor: pointer;
  height: 48px;
  box-shadow: 0 2px 8px 0 #ababab;
  border-radius: 24px;
  background-color: ${props => (props.color ? props.color : themes.colors.yellow.lightestYellow)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0px 12px 0px;
  padding: 0px 20px;
  :hover {
    background-color: ${themes.colors.yellow.lighterYellow};
  }
  text-align: center;
  @media screen and (min-width: ${themes.breakpoints.largePhonePortrait}) {
    min-width: 150px;
  }
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    width: 130px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
//#endregion
