import { themes } from 'kb-shared';
import styled from 'styled-components';

const { colors, fonts } = themes;

export const AppointmentBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  margin-bottom: 28px;
`;

export const Content = styled.div`
  padding: 20px;
  display: flex;
  align-items: flex-start;
`;

export const AppointmentName = styled.div`
  ${fonts.DomaineDisplay};
  font-size: 24px;
  color: ${colors.neutral.primaryNavy};
`;

export const AppointmentBlockTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  @media ${({ theme }) => theme.queries.minTablet} {
    flex-direction: row;
  }
`;

export const AppointmentBlockDate = styled.div`
  ${fonts.FoundersGrotesk};
  font-size: 16px;
  color: ${colors.neutral.primaryNavy};
  padding-bottom: 24px;
`;

export const AppointmentBlockLocation = styled.div`
  ${fonts.FoundersGrotesk};
  font-size: 16px;
  color: ${colors.neutral.primaryNavy};
  word-break: break-word;
`;

export const AppointmentBlockLocationBold = styled(AppointmentBlockLocation)`
  font-weight: bold;
`;

export const AppointmentBlockContainer = styled.div`
  margin: 0 0 8px 12px;
  flex: 1;
`;

export const CalendarIconGroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
`;

export const AdditionalInfo = styled.div`
  ${fonts.FoundersGrotesk};
  font-size: 16px;
  margin-top: 12px;
`;
