import React, { Component } from 'react';
import posed from 'react-pose';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import CollapseIcon from '../SVG/Collapse';
import ExpandIcon from '../SVG/Expand';
import { Theme } from './index';

const { colors, fonts } = themes;

const NavigatorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
`;

interface AnimatedItemProps {
  visibleColor: string;
  hiddenColor: string;
}

const AnimatedItem = posed(ItemWrapper)({
  visible: { backgroundColor: (props: AnimatedItemProps) => props.visibleColor, duration: 200 },
  hidden: { backgroundColor: (props: AnimatedItemProps) => props.hiddenColor, duration: 200 }
});

const ItemHeader = styled.a`
  align-items: center;
  display: flex;
  padding: 18px 20px;
  justify-content: space-between;
`;

const ItemLabel = styled.h2`
  ${({ theme }) => theme.typos.sectionHeader};
  ${fonts.FoundersGrotesk};
  font-size: 18px;
  font-weight: bold;
  min-width: 140px;
  padding-right: 10px;
  overflow: hidden;
`;

const AnimatedItemLabel = posed(ItemLabel)({
  visible: { color: (props: AnimatedItemProps) => props.visibleColor, duration: 200 },
  hidden: { color: (props: AnimatedItemProps) => props.hiddenColor, duration: 200 }
});

const ExpandCollapseIcon = styled.div`
  width: 20px;
  height: 20px;
`;

const ContentContainer = styled.div`
  ${({ theme }) => theme.grid.container};
  background-color: ${colors.yellow.lighterYellow};
  overflow: hidden;
`;

const AnimatedContentContainer = posed(ContentContainer)({
  visible: {
    height: 'auto',
    backgroundColor: (props: AnimatedItemProps) => props.visibleColor,
    duration: 200
  },
  hidden: {
    height: 0,
    backgroundColor: (props: AnimatedItemProps) => props.hiddenColor,
    duration: 200
  }
});

type Props<T> = {
  currentIndex: number;
  items: Array<T>;
  getSectionTitle: (index: number, item: T) => string;
  renderContent: (index: number, item: T) => React.ReactNode | null;
  getUniqueKey: (index: number, item: T) => string;
  onSelect: (index: number, item: T) => void;
  theme: Theme;
};

type State = {
  isOpen: boolean;
};

export default class MobileNavigator<T> extends Component<Props<T>, State> {
  constructor(props: Props<T>) {
    super(props);
    this.state = { isOpen: true };
  }

  render() {
    const { currentIndex, items, onSelect, theme } = this.props;
    const { isOpen } = this.state;

    return (
      <NavigatorContainer>
        {items.map((item, index) => {
          const selected = index === currentIndex && isOpen;
          const poseAnimator = selected ? 'visible' : 'hidden';
          const title = this.props.getSectionTitle(index, item);
          const itemKey = this.props.getUniqueKey(index, item);
          return (
            <AnimatedItem
              data-testid={itemKey}
              key={itemKey}
              pose={poseAnimator}
              visibleColor={theme.tab.selectedBG}
              hiddenColor={theme.tab.deselectedBG}
            >
              <ItemHeader
                onClick={e => {
                  e.preventDefault();
                  if (isOpen && selected) {
                    this.setState({ isOpen: false });
                  } else {
                    this.setState({ isOpen: true });
                  }
                  onSelect(index, item);
                }}
              >
                <AnimatedItemLabel
                  pose={poseAnimator}
                  visibleColor={theme.tab.selectedTitle}
                  hiddenColor={theme.tab.deselectedTitle}
                >
                  {title}
                </AnimatedItemLabel>
                <ExpandCollapseIcon>
                  {selected ? (
                    <CollapseIcon color={colors.neutral.primaryNavy} />
                  ) : (
                    <ExpandIcon color={colors.neutral.primaryNavy} />
                  )}
                </ExpandCollapseIcon>
              </ItemHeader>
              <AnimatedContentContainer
                pose={poseAnimator}
                visibleColor={theme.content.selectedBG}
                hiddenColor={theme.content.deselectedBG}
                style={{ pointerEvents: selected ? 'auto' : 'none' }}
              >
                {this.props.renderContent(index, item)}
              </AnimatedContentContainer>
            </AnimatedItem>
          );
        })}
      </NavigatorContainer>
    );
  }
}
