import styled from 'styled-components';

export const BaseCardContainer = styled.label`
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  font-size: 14px;
  border-bottom: 1px solid;
  text-transform: uppercase;
`;

export const CardElementsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CardNumberContainer = styled(BaseCardContainer)`
  width: 230px;
`;

export const CardExpirationContainer = styled(BaseCardContainer)`
  width: 120px;
`;

export const CardCvcContainer = styled(BaseCardContainer)`
  width: 100px;
`;
