import React, { useState } from 'react';

import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { Text, Heading } from 'components/v2/Typography';
import { useContactInfo } from 'hooks';
import { useOnWindowResize } from 'hooks/useOnWindowResize';
import { themes } from 'kb-shared';
import theme from 'kb-shared/theme';
import breakpointsRaw from 'kb-shared/theme/breakpointsRaw';

import ContactModal from '../ContactModal';
import { Container, EmailText } from './Contact.styled';

export const Contact = () => {
  const [modal, setModal] = useState(false);
  const [isSmallOrMediumScreen, setIsSmallOrMediumScreen] = useState<boolean>(true);
  const [textSize, setTextSize] = useState<'xs' | 'sm'>('xs');
  const { email, numberLabel, number } = useContactInfo();

  useOnWindowResize(() => {
    const isSmallOrMedium = document.body.clientWidth <= breakpointsRaw.tabletLandscape;

    setIsSmallOrMediumScreen(isSmallOrMedium);
    setTextSize(isSmallOrMedium ? 'xs' : 'sm');
  }, 300);

  return (
    <Container>
      <div>
        <Text size={textSize} fontStyle="bold" color={theme.colors.neutral.navy}>
          Email
        </Text>
        <a href={`mailto:${email}`}>
          <EmailText>
            {isSmallOrMediumScreen ? (
              <>
                <Text tag="p" size="md" fontStyle="medium" color={theme.colors.neutral.primaryNavy}>
                  {email}
                </Text>
              </>
            ) : (
              <Heading tag="span" styledAs="h3" color={theme.colors.neutral.primaryNavy}>
                {email}
              </Heading>
            )}
          </EmailText>
        </a>
      </div>

      <div>
        <Text size={textSize} fontStyle="bold" color={theme.colors.neutral.navy}>
          Phone
        </Text>
        <a href={`mailto:${number}`}>
          {isSmallOrMediumScreen ? (
            <>
              <Text tag="p" size="md" fontStyle="medium" color={theme.colors.neutral.primaryNavy}>
                {numberLabel}
              </Text>
            </>
          ) : (
            <Heading tag="span" styledAs="h3" color={theme.colors.neutral.primaryNavy}>
              {numberLabel}
            </Heading>
          )}
        </a>
      </div>
      {true ? (
        <>
          <Text size={textSize}>
            For urgent medical emergencies,{' '}
            <LinkButton
              text="click here"
              size={textSize}
              ariaLabel="Button to open a modal with the medical contact"
              onClick={() => setModal(true)}
              color={themes.colors.blue.darkBlue}
            />
          </Text>
          <ContactModal visible={modal} onDismiss={() => setModal(false)} />
        </>
      ) : null}
    </Container>
  );
};
