import { themes } from 'kb-shared';
import styled from 'styled-components';

import { ModalLabel as ModalLabelBase } from '../lib/styled';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ModalLabel = styled(ModalLabelBase)`
  max-width: 200px;
`;

const imageSize = 'height:240px;width:320px;';

export const UploadImageContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 15px 0 0 0;
  justify-content: center;
  cursor: pointer;
  width: 100%;
`;

export const RenderedImage = styled.img`
  object-fit: contain;
  ${imageSize}
`;

export const Description = styled.small`
  ${themes.fonts.FoundersGrotesk};
  max-width: 140px;
  display: inline-block;
  text-align: center;
  margin: 8px 0 0 0;
`;
