import React, { useState, Fragment, ChangeEvent, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { retrieveFile, CATEGORY } from 'api/pf2/files';
import { WhiteSquare } from 'components/WhiteSquare';
import { usePatient } from 'hooks';
import * as redux from 'kb-redux';
import { ReduxState } from 'kb-redux';
import { ProfileImageError } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import {
  isValidProfileImageExtension,
  showErrorProfileImageExtensionToast
} from 'utilities/fileValidation';

import { FileUpload, FileUploadContainer } from '../lib/styled';
import { ProfileImage } from './components/ProfileImage';
import { FileUploadDescription, ImageRow, ImageText } from './ProfileImageUploadButton.styled';
import { ProfileImageUploadButtonProps } from './ProfileImageUploadButton.types';
import ProfilePhotoUploadModal from './ProfilePhotoUploadModal';

const {
  patient: { updateProfileImage, profileImageError }
} = redux;

export const ProfileImageUploadButton = (
  props: ProfileImageUploadButtonProps
): JSX.Element | null => {
  const [croppingPhoto, setCroppingPhoto] = useState<boolean>(false);
  const [currentFileBlob, setCurrentFileBlob] = useState<string>('');
  const dispatch = useDispatch();
  const { isLoggedIn } = usePatient();
  const profileImage = useSelector<ReduxState>(state =>
    state.patient ? state.patient.profileImage : undefined
  ) as string | undefined;

  const profileImageErrorText = useSelector<ReduxState>(
    state => state.patient.profileImageError
  ) as ProfileImageError | null;

  const { size, className, id, onCropStarted, onCropEnded } = props;
  const body = document.body;

  useEffect(() => {
    // usually this is used only when the user is logged in
    // but we had error fixed with KE-11481, where tokenId was not set
    // probably due to some kind of race condition
    // and it was reporting errors to the HB
    if (!isLoggedIn) return;
    getUserImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (croppingPhoto) {
      onCropStarted?.();
    } else {
      onCropEnded?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppingPhoto]);

  const getUserImage = async () => {
    try {
      // @ts-ignore
      const { file } = await retrieveFile(CATEGORY.PROFILE_PHOTO);
      dispatch(updateProfileImage(file));
    } catch (error) {
      BugTracker.notify(error, 'ProfileImageUploadButton.getUserImage');
      dispatch(
        profileImageError({
          type: 'UnableToLoadImage'
        })
      );
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const {
      target: { files }
    } = e;

    if (!files || !files[0]) {
      return;
    }

    if (!isValidProfileImageExtension(files[0].type)) {
      return showErrorProfileImageExtensionToast();
    }

    setCurrentFileBlob(URL.createObjectURL(files[0]));
    setCroppingPhoto(true);
  };

  const onUserImageError = () =>
    dispatch(
      profileImageError({
        type: 'UnableToLoadImage'
      })
    );

  const renderButton = () => {
    const { mode, size, clickableLabelText } = props;

    switch (mode) {
      case 'placeholder':
        return (
          <ImageRow>
            <ProfileImage
              profileImage={profileImage}
              error={profileImageErrorText}
              size={size}
              onError={onUserImageError}
            />
            {clickableLabelText && <ImageText>{clickableLabelText}</ImageText>}
          </ImageRow>
        );
      case 'whiteSquare':
        return (
          <>
            <WhiteSquare />
            <FileUploadDescription>
              JPEG, JPG and PNG file types are allowed. The maximum file size is 2 MB
            </FileUploadDescription>
          </>
        );
      default:
        return null;
    }
  };

  if (!body) return null;

  return (
    <Fragment>
      <FileUploadContainer htmlFor={id} size={size} className={className}>
        <FileUpload
          id={id}
          type="file"
          onChange={handleFileChange}
          accept="image/png, image/jpg, image/jpeg"
          size={size}
        />
        {renderButton()}
      </FileUploadContainer>

      {croppingPhoto &&
        ReactDOM.createPortal(
          <ProfilePhotoUploadModal
            currentFileBlob={currentFileBlob}
            onSuccess={() => {
              getUserImage();
              props.onProfileImageUploaded && props.onProfileImageUploaded();
              setCroppingPhoto(false);
            }}
            onClose={() => setCroppingPhoto(false)}
          />,
          body
        )}
    </Fragment>
  );
};
