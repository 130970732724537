import React from 'react';

import { themes, PartnerClinicDetails } from 'kb-shared';
import styled from 'styled-components';

interface Props {
  partnerClinicDetails: PartnerClinicDetails;
  appointmentName?: string | null;
}

const PartnerLocationConfirmationCard = (props: Props) => {
  const { partnerClinicDetails, appointmentName } = props;

  return (
    <CardContainer>
      <Container>
        <div>
          <CardTitle>Appointment Request:</CardTitle>
          <AppointmentName>{appointmentName}</AppointmentName>

          <LocationWrapper>
            <AppointmentLocation>
              <ClinicName>{partnerClinicDetails.name}</ClinicName>
              <Address>
                {partnerClinicDetails.address}
                <br />
                {partnerClinicDetails.city}, {partnerClinicDetails.state}
              </Address>
            </AppointmentLocation>
          </LocationWrapper>
          <Tag>Partner Clinic</Tag>
        </div>
        <FutureActionsWrapper>
          Once your appointment is confirmed, you will receive a confirmation email with the details
          of your visit. In the mean time, if you have any questions or want to contact us,{' '}
          <b>you can keep us posted on your request through</b>{' '}
          <SecureMessageLink href="/messages">secure message.</SecureMessageLink>
        </FutureActionsWrapper>
      </Container>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px;
  box-shadow: -4px 4px 0 0 ${({ theme }) => theme.colors.neutral.primaryNavy};
  border: solid 2px ${({ theme }) => theme.colors.neutral.primaryNavy};

  @media ${({ theme }) => theme.queries.minDesktop} {
    width: 359px;
  }

  @media ${({ theme }) => theme.queries.tablet} {
    width: 276px;
  }

  @media ${({ theme }) => theme.queries.mobile} {
    width: 288px;
  }
`;

const SecureMessageLink = styled.a`
  cursor: pointer;
  color: ${themes.colors.neutral.primaryNavy};
  ${({ theme }) => theme.fonts.FoundersGrotesk};
  text-decoration: underline;
  font-weight: bold;
`;

const Container = styled.div`
  padding: 20px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
`;

const CardTitle = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
`;

const AppointmentName = styled.span`
  ${themes.fonts.FoundersGrotesk}
  font-size: 14px;
  background-color: ${themes.colors.neutral.lavender};
  padding: 4px 12px;
  text-transform: uppercase;
  color: ${themes.colors.white};
  margin-top: 20px;
`;
// margin-top: ${({ hasStepper }: AppointmentTimeProps) => (hasStepper ? '35px' : '0px')};

const AppointmentLocation = styled.div`
  ${themes.fonts.FoundersGrotesk};
  font-size: 16px;
  display: flex;
  flex-direction: column;
`;

const ClinicName = styled.span`
  ${themes.fonts.DomaineDisplay};
  font-size: 24px;
  margin-bottom: 10px;
`;

const Address = styled.span``;

const LocationWrapper = styled.div`
  display: flex;
  margin: 28px 0;
`;

const FutureActionsWrapper = styled.div`
  ${themes.fonts.FoundersGrotesk};
  align-self: flex-end;
  align-items: center;
  font-size: 16px;
  padding: 5px;
  padding-right: 10px;
`;

const Tag = styled.span`
  ${themes.fonts.FoundersGrotesk}
  font-size: 14px;
  background-color: ${themes.colors.semantic.lightPink};
  padding: 4px 5px;
  text-transform: uppercase;
  color: ${themes.colors.neutral.navy};
  margin-top: 20px;
`;
export default PartnerLocationConfirmationCard;
