import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';

import { Loader } from 'components/Loader/Loader';
import { Button } from 'components/v2/Buttons/Button';
import useHomeMarket from 'hooks/useHomeMarket';
import usePatient from 'hooks/usePatient';
import { PATIENT_APPOINTMENTS } from 'kb-shared/graphql/queries';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { ResponsiveContainer } from 'screens/styled_common';
import { analytics } from 'utilities/analytics';
import { useBreakpoints } from 'utilities/useBreakpoints';
import { getUserDisplayName } from 'utilities/userUtil';

import { CycleVisit } from './CycleVisit/CycleVisit';
import { MY_JOURNEY_QUERY } from './Dashboard.graphql';
import { ViewMemberBenefitButtonContainer, WidgetsContainer } from './Dashboard.styled';
import { JourneyResponse, PatientAppointments } from './Dashboard.types';
import { hasMembershipDocument } from './Dashboard.utils';
import { Header } from './Header/Header';
import { MedicationsWidget } from './MedicationsWidget/MedicationsWidget';
import { ToDoWidget } from './ToDoWidget/ToDoWidget';
import { UnreadMessages } from './UnreadMessages/UnreadMessages';
import { Widgets } from './Widgets/Widgets';

export function Dashboard() {
  const { patient, loading: loadingPatient } = usePatient();
  const { vios: isViosPatient } = useHomeMarket();
  const { isDesktop, isMobile } = useBreakpoints();

  useEffect(() => {
    if (document.body && document.documentElement) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    analytics.page(analytics.PAGES.HOME);
  }, []);

  const {
    data: appointmentsData,
    loading: appointmentsLoading,
    refetch: refetchAppointments
  } = useQuery<PatientAppointments>(PATIENT_APPOINTMENTS, {
    onError: error => BugTracker.notify(error, 'PatientAppointmentsFetch')
  });

  const { data: journeyData, loading: journeyLoading } = useQuery<JourneyResponse>(
    MY_JOURNEY_QUERY,
    {
      onError: error => BugTracker.notify(error, 'PatientJourneyFetch')
    }
  );

  if (patient == null || loadingPatient || journeyLoading || appointmentsLoading)
    return <Loader absoluteCentered />;

  return (
    <ResponsiveContainer>
      {isDesktop && (
        <Header
          name={getUserDisplayName(patient)}
          hasMembershipDocument={hasMembershipDocument(patient)}
          documentUrl={patient?.patientMembership?.membership?.documentUrl}
        />
      )}

      {hasMembershipDocument(patient) && isMobile && (
        <ViewMemberBenefitButtonContainer>
          <Button
            category="secondary"
            label="View Member Benefit"
            onClick={() =>
              window.open(
                patient?.patientMembership?.membership?.documentUrl,
                '_blank',
                'noreferrer noopener'
              )
            }
            size="sm"
            fullWidth
          />
        </ViewMemberBenefitButtonContainer>
      )}

      <UnreadMessages />
      <MedicationsWidget patient={patient} />
      <CycleVisit />

      <ToDoWidget patient={patient} />

      <WidgetsContainer>
        <Widgets
          patient={patient}
          isViosPatient={isViosPatient}
          journeyData={journeyData}
          appointmentsData={appointmentsData}
          refetchAppointments={refetchAppointments}
        />
      </WidgetsContainer>
    </ResponsiveContainer>
  );
}
