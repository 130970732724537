import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from 'components/v2/Buttons/Button';
import { AuthFlowLayout } from 'components/v2/Layouts/AuthFlowLayout/AuthFlowLayout';
import { Text } from 'components/v2/Typography';
import { loginErrorHandled } from 'kb-redux/user.redux';
import { GoogleAuthError } from 'kb-shared';
import { analytics } from 'utilities/analytics';
import { showErrorToast } from 'utilities/notificationUtils';
import { RoutesPath } from 'utilities/pageUrl';
import { pageUrl } from 'utilities/pageUrl';

import { LoginForm } from './LoginForm/LoginForm';
import { ActivateBenefit, ActivateBenefitContainer } from './LoginScreen.styled';

export const LoginScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const [targetRedirect, setTargetRedirect] = useState('');
  const dispatch = useDispatch();

  // TODO: clean up this membership params stuff
  // the additional params from the target are being parsed as if they are their own params
  // they need to be considered one param, so we can redirect to a specific part of the booking flow
  // this is for the employer membership flow currently
  // side: also check ProtectedRoute where `location.pathname === '/book'`
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const target = params.get('target');
    const membershipId = params.get('membership_id');
    const enterpriseMembership = params.get('enterprise_membership');

    if (target)
      setTargetRedirect(
        `${target}${membershipId ? `&membership_id=${membershipId}` : ''}${
          enterpriseMembership ? `&enterprise_membership=${enterpriseMembership}` : ''
        }`
      );

    checkForAutomaticLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const checkForAutomaticLogout = () => {
    const params = new URLSearchParams(location.search);
    const loggedOut = params.get('loggedOut');

    if (loggedOut && !document.hidden) {
      analytics.track(analytics.EVENTS.PATIENT_AUTOMATICALLY_LOGGED_OUT);
    }
  };

  const redirectIfLoggedIn = (isLoggedIn: boolean) => {
    if (!isLoggedIn) return;

    if (targetRedirect) {
      history.push(targetRedirect);
      return;
    }
    history.push('/');
  };

  const onGoogleAuthError = (googleAuthError: GoogleAuthError, googleUserId: string | null) => {
    if (!googleUserId) return;
    if (!googleAuthError) return;

    if (googleAuthError.type === 'UserAlreadyExistsAsEmail') {
      showErrorToast(
        'You already have an account using your email address and password. Please login'
      );
      return;
    }
    if (googleAuthError && googleAuthError.type !== 'UserHasNotSignedUp') return;
    history.push(getCreateAccountPath());
  };

  const getCreateAccountPath = () => {
    if (targetRedirect) {
      return `/create-account?step=create_account&target=${targetRedirect}&previous=login`;
    } else {
      return 'create-account?step=create_account&previous=login';
    }
  };

  const goToCreateAccount = () => {
    history.push(getCreateAccountPath());
  };

  const isAMH = location.search.includes('event=amh');
  if (isAMH) history.replace(`/amh-login${location.search}`);

  return (
    <>
      <AuthFlowLayout
        title="Log In"
        renderMainContent={mobile => {
          return (
            <LoginForm
              mobile={mobile}
              onLogin={() => redirectIfLoggedIn(true)}
              onError={(error, email, password) => {
                if (error.type === 'Unconfirmed') {
                  dispatch(loginErrorHandled());
                  history.push(pageUrl.emailAddressVerification(), { email, password });
                }
              }}
              onGoogleAuthError={onGoogleAuthError}
              onForgotPassword={() => {
                history.push(pageUrl.passwordReset());
              }}
              onSignUpPress={goToCreateAccount}
            />
          );
        }}
      />

      <ActivateBenefitContainer>
        <ActivateBenefit>
          <div>
            <Text tag="p" size="md" fontStyle="medium">
              Does your employer have the Kindbody benefit?
            </Text>
          </div>
          <div>
            <Button
              label="ACTIVATE YOUR BENEFIT"
              category="primary-dark"
              fullWidth={true}
              onClick={() =>
                history.push(
                  `/create-account?step=create_account&target=${RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE}`
                )
              }
            />
          </div>
        </ActivateBenefit>
      </ActivateBenefitContainer>
    </>
  );
};
