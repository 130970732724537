import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import React from 'react';
import { useDispatch } from 'react-redux';

import { loadGoogleAuth } from 'kb-redux/user.redux';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { analytics } from '../utilities/analytics';

type Props = {
  context?: 'signin' | 'signup';
};

export const GoogleAuthButton = (props: Props): JSX.Element => {
  const { context = 'signin' } = props;
  const dispatch = useDispatch();

  const handleGoogleSignIn = (response: CredentialResponse) => {
    if (!response.clientId || !response.credential) return;

    analytics.track(analytics.EVENTS.GOOGLE_SIGN_IN_SUCCEEDED);
    dispatch(loadGoogleAuth(response.credential));
  };

  const handleGoogleSignInError = () => {
    BugTracker.notify('Google Sign In Failed', 'GoogleAuthButton');
    analytics.track(analytics.EVENTS.GOOGLE_SIGN_IN_FAILED);
  };

  const onButtonClicked = () => analytics.track(analytics.EVENTS.GOOGLE_SIGN_IN_STARTED);

  return (
    <GoogleLogin
      onSuccess={response => handleGoogleSignIn(response)}
      onError={handleGoogleSignInError}
      theme="outline"
      shape="rectangular"
      type="standard"
      size="large"
      text="continue_with"
      logo_alignment="center"
      locale="en-US"
      width="320"
      context={context}
      click_listener={onButtonClicked}
    />
  );
};
