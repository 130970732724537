import React from 'react';

import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';

import { EmployerWidgetCTA } from '../EmployerWidgets.types';
import { ActionContainer, ActionTextContainer } from './EmployerWidgetActions.styled';

export const EmployerWidgetActions = ({ actions }: { actions: EmployerWidgetCTA[] }) =>
  actions.map((action, index) => (
    <ActionContainer $last={index === actions.length - 1} key={`cta-${index}`}>
      <ActionTextContainer>
        <Text fontStyle="bold">{action.line1}</Text>
      </ActionTextContainer>
      <Button
        category="secondary"
        label={action.linkText}
        onClick={() => window.open(action.linkUrl, '_blank')}
      />
    </ActionContainer>
  ));
