import React, { FC } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { ReactComponent as ArrowSvg } from '../../../assets/icons/journey-down-arrow.svg';

const { fonts, colors } = themes;

const StepDetail: FC<{}> = ({ children }) => {
  return (
    <DetailWrapper>
      <Arrow className="arrow" />
      {children}
    </DetailWrapper>
  );
};

export default StepDetail;

const DetailWrapper = styled.div`
  background: white;
  padding: 48px 28px 28px 28px;
  ${fonts.FoundersGrotesk};
  color: ${colors.neutral.primaryNavy};
  cursor: default;
  position: relative;
  background: white;
`;

const Arrow = styled(ArrowSvg)`
  position: absolute;
  top: 0;
`;

const Subheader = styled.div`
  margin-bottom: 0;
  font-weight: bold;

  :first-of-type {
    margin: 0;
  }
`;

const Description = styled.p`
  margin: 0;
  margin-bottom: 28px;
`;

export { Subheader, Description };
