import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 14px;
  width: 100%;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TwoColumnContent = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media ${({ theme }) => theme.queries.minTablet} {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-gap: 20px;

    > div,
    > button {
      width: calc(50% - 10px);
    }
  }
`;

export const LinkWrapper = styled.div`
  ${props => props.theme.fonts.Gotham};
  color: ${({ theme }) => theme.colors.blue.darkBlue};
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  text-decoration: underline;
`;

export const RighIconWrapper = styled.div`
  font-weight: 900;
  height: 24px;
  width: 24px;
  text-align: center;
`;
