import styled from 'styled-components';

import TextInput from '../../../components/TextInput/NewTextInput';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  margin: 14px 28px 14px 0;
  width: 100%;
`;

export const TextInputStyled = styled(TextInput)`
  padding-bottom: 0;
  width: 100%;
  max-width: 100%;

  input {
    height: initial;
    padding-top: 8px;
    padding-bottom: 5px;
  }
`;

export const PaymentFormWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 1em;
`;

export const ConfirmDetailsWrapper = styled.div`
  padding-bottom: 30px;
`;
