import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.blue.lightestBlue};
  border: 1px solid ${({ theme }) => theme.colors.blue.darkBlue};
  padding: 16px 40px 32px 32px;
  margin-top: 32px;
`;

export const InfoBoxText = styled.div`
  padding-left: 40px;
  ${({ theme }) => theme.fonts.Gotham};
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
`;

export const InfoBoxIcon = styled.img`
  position: absolute;
  left: 16px;
  top: 16px;
  width: 32px;
  height: 32px;
  margin: 8px;
`;
