import { themes } from 'kb-shared';
import styled from 'styled-components';

export default styled.input`
  font-size: 24px;
  color: black;
  height: 40px;
  width: 420px;
  border: 0px;
  border-bottom: solid 1px black;
  text-align: left;
  ${themes.fonts.DomaineDisplay};
  ::placeholder {
    font-size: 24px;
    text-align: left;
    color: #a9a7b0;
  }
  :focus {
    outline: 0;
    border-bottom: solid 1px ${themes.colors.yellow.primary};
  }
  @media (max-width: 700px) {
    width: 100%;
    min-width: 200px;
  }
`;
