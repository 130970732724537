import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader/Loader';
import { Heading } from 'components/v2/Typography';
import { usePatient } from 'hooks';
import { CONTAINER_DEPRECATED } from 'screens/styled_common';
import { analytics } from 'utilities/analytics';
import { pageUrl } from 'utilities/pageUrl';

import { MY_JOURNEY_QUERY, MyJourneyQueryResponse } from './graphql/queries';
import { buildJourney } from './journeySteps';
import { NoJourneyContainer, NoJourneyMessage, ScheduleButton } from './MyJourney.styled';
import Step from './Step';

export const MyJourney = () => {
  const history = useHistory();

  const { patient } = usePatient();
  const name = patient?.identifier || patient?.firstName;
  const title = name ? `${name}'s Journey` : 'My Journey';

  useEffect(() => {
    analytics.page(analytics.PAGES.JOURNEY);
  }, []);

  const { loading, data } = useQuery<MyJourneyQueryResponse>(MY_JOURNEY_QUERY);

  if (loading || !data) return <Loader absoluteCentered />;

  const { appointments, results, patientTodos, journeyType, cyclePlan, triggerInstruction } = data;

  let noJourneyMessage = 'Your journey type is not yet supported.';
  if (!journeyType) {
    noJourneyMessage = 'Your Kindbody journey has not yet begun.';
  }

  const journey = buildJourney({
    appointments,
    cyclePlan,
    journeyType,
    patientTodos,
    results,
    triggerInstruction
  });

  if (!journey) {
    return (
      <CONTAINER_DEPRECATED>
        <Heading>{title}</Heading>
        <NoJourneyContainer>
          <NoJourneyMessage>{noJourneyMessage}</NoJourneyMessage>
          <ScheduleButton
            label="Schedule an appointment"
            onClick={() => history.push(pageUrl.booking.bookingQuiz())}
          />
        </NoJourneyContainer>
      </CONTAINER_DEPRECATED>
    );
  }

  return (
    <CONTAINER_DEPRECATED>
      <Heading>{title}</Heading>
      {journey.steps.map(step => (
        <Step
          {...step}
          completed={step.isComplete}
          key={step.title}
          journeyType={journeyType}
          showTime={false}
          showDate={false}
        />
      ))}
    </CONTAINER_DEPRECATED>
  );
};

export default MyJourney;
