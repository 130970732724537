import { YellowButton } from 'components/Buttons';
import styled from 'styled-components';

export const CardListItem = styled.li`
  width: 100%;
  padding: 16px 24px;
  margin: 12px 0;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .heading {
    font-size: 24px;
    margin-top: 0.3em;
  }
`;

export const CardContentAmount = styled.div`
  margin-top: 0.5em;
`;

export const PayButton = styled(YellowButton)`
  margin-left: 8px;
  padding: 8px 24px;

  font-size: 14px;
  text-transform: uppercase;
`;

export const LabelHolder = styled.div<{ $forPaid?: boolean }>`
  text-align: center;
  background: ${({ theme, $forPaid }) =>
    $forPaid ? theme.colors.green.lightestGreen : theme.colors.neutral.lavenderLight};
  color: ${({ theme, $forPaid }) =>
    $forPaid ? theme.colors.green.darkestGreen : theme.colors.neutral.primaryNavy};
  padding: 6px 8px;
  border-radius: 4px;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    width: 100%;
  }
`;

export const CardContentButtonRowContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 1.5em;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    flex-direction: row;
    flex-wrap: wrap;

    > div:nth-child(1) {
      padding-right: 1em;
    }

    > div:nth-child(2) {
      padding-left: 1em;
    }
  }
`;

export const CardContentRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  > div {
    margin-top: 1.5em;
    padding-left: 1em;
    border-right: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
    padding-right: 1em;
  }

  > div:nth-child(1) {
    padding-left: 0;
  }

  > div:last-child {
    padding-left: 1em;
    border-right: 0;
    padding-right: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    flex-wrap: wrap;

    > div:nth-child(1) {
      border-right: 0;
      padding-right: 0;
    }

    > div:nth-child(2) {
      padding-left: 0;
    }

    > div:nth-child(3) {
      border-right: 0;
    }

    > div:last-child {
      padding-left: 0;
    }
  }
`;

export const FullWidth = styled.div<{ $hidden?: boolean }>`
  flex-basis: 100%;
  display: ${({ $hidden }) => ($hidden ? 'none' : 'block')};
`;

export const HalfWidth = styled.div`
  flex-basis: 50%;
`;
