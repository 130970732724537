import { themes } from 'kb-shared';
import styled from 'styled-components';

export const FileUploadDescription = styled.small`
  ${themes.fonts.FoundersGrotesk};
  max-width: 140px;
  display: inline-block;
  text-align: center;
  margin: 8px 0 0 0;
`;

export const ImageRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ImageText = styled.div`
  ${({ theme }) => theme.fonts.Gotham};
  color: ${themes.colors.neutral.primaryNavy};
  font-size: 14px;
  margin-left: 20px;
  background: white;
`;
