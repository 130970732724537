import { themes } from 'kb-shared';
import styled from 'styled-components';

const { fonts, colors, breakpoints } = themes;

export const StepContent = styled.div`
  ${fonts.FoundersGrotesk};
  color: ${colors.neutral.primaryNavy};
  cursor: default;
  margin-top: 2px;
  margin-right: 28px;
  margin-bottom: 28px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    margin: 2px 48px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    margin: 2px 96px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  background: white;
  flex-direction: column;
  position: relative;
  margin: 2px 0;
  padding-right: 24px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    flex-direction: row;
  }
`;

export const TimelineWrapper = styled.div`
  position: absolute;
  padding-top: 30px;
  top: 0;
  bottom: 0;
  left: 16px;

  @media screen and (min-width: ${breakpoints.tabletPortrait}) {
    padding-top: 18px;
    margin-left: 54px;
  }

  @media screen and (min-width: ${breakpoints.tabletLandscape}) {
    padding-top: 18px;
    margin-left: 108px;
  }
`;

export const TodoBlock = styled.div`
  margin-left: 70px;
  padding-top: 34px;
  padding-bottom: 28px;

  @media screen and (min-width: ${breakpoints.tabletPortrait}) {
    padding-bottom: 0px;
    padding-top: 18px;
    margin-left: 173px;
    width: 100%;
  }
`;
