import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

export const PREGNANCY_CALCULATOR_QUERY = gql`
  query pregnancyCalculator {
    pregnancyCalculator {
      enabled
      cancelEnabled
      expectedDeliveryDate
      gestationalAgeInDays
    }
  }
`;

const UPDATE_PREGNANCY_ACKNOWLEDGEMENT_MUTATION = gql`
  mutation updatePregnancyAcknowledgement($isPregnant: Boolean!) {
    updatePregnancyAcknowledgement(isPregnant: $isPregnant) {
      isPregnancyAcknowledged
    }
  }
`;
export interface PregnancyCalculatorQueryResponse {
  pregnancyCalculator: {
    enabled: boolean;
    cancelEnabled: boolean;
    expectedDeliveryDate: string | null;
    gestationalAgeInDays: number | null;
  };
}
interface UpdatePregnancyAcknowledgementResponse {
  updatePregnancyAcknowledgement: {
    isPregnancyAcknowledged: boolean;
  };
}

const calculateCurrentState = (
  data: PregnancyCalculatorQueryResponse | undefined,
  loading: boolean
) => {
  if (loading) {
    return 'loading';
  } else if (!data?.pregnancyCalculator.enabled) {
    return 'no-confirmed-pregnancy';
  } else if (data?.pregnancyCalculator.expectedDeliveryDate) {
    return 'lmp-entered';
  }

  return 'no-lmp-entered';
};

export const usePregnancyCalculator = () => {
  const { data, loading } = useQuery<PregnancyCalculatorQueryResponse>(PREGNANCY_CALCULATOR_QUERY);
  const [pregnancyAcknowledgmentMutation] = useMutation<UpdatePregnancyAcknowledgementResponse>(
    UPDATE_PREGNANCY_ACKNOWLEDGEMENT_MUTATION,
    { refetchQueries: [{ query: PREGNANCY_CALCULATOR_QUERY }] }
  );

  const updatePregnancyAcknowledgment = useCallback(
    (isPregnant: boolean) => pregnancyAcknowledgmentMutation({ variables: { isPregnant } }),
    [pregnancyAcknowledgmentMutation]
  );

  const expectedDeliveryDate = data?.pregnancyCalculator.expectedDeliveryDate
    ? new Date(data.pregnancyCalculator.expectedDeliveryDate)
    : undefined;

  const state = calculateCurrentState(data, loading);
  const gestationalAgeInDays = data?.pregnancyCalculator.gestationalAgeInDays ?? undefined;
  const estimatedDueDate = expectedDeliveryDate;
  const isCancelEnabled = !!data?.pregnancyCalculator.cancelEnabled;

  return {
    state,
    gestationalAgeInDays,
    estimatedDueDate,
    isCancelEnabled,
    updatePregnancyAcknowledgment
  };
};
