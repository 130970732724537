import React, { ReactNode } from 'react';

import { InitialModal } from 'screens/InitialModals';
import styled from 'styled-components';

import { NavigationContainer } from './Navigation/NavigationContainer/NavigationContainer';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'main';
  height: 100%;
  background: ${({ theme }) => theme.colors.neutral.lavenderWhite};

  @media ${({ theme }) => theme.queries.minDesktop} {
    grid-template-columns: 80px auto;

    grid-template-areas:
      'navbar main'
      'navbar footer';
  }
`;

const Main = styled.main`
  grid-area: main;
  overflow: hidden;
`;

interface Props {
  children: ReactNode;
}

export const MainMessageLayout = (props: Props) => {
  const { children } = props;

  return (
    <GridContainer>
      <NavigationContainer />
      <Main>{children}</Main>
      <InitialModal />
    </GridContainer>
  );
};
