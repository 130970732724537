import { themes } from 'kb-shared';
import styled from 'styled-components';

import { cardBoxShadow } from '../../styled';

const { colors, breakpoints, fonts } = themes;

export const Label = styled.div`
  ${fonts.FoundersGrotesk};
  color: ${colors.neutral.primaryNavy};
  font-size: 14px;
`;

export const Text = styled.div`
  ${fonts.DomaineDisplay};
  color: ${colors.neutral.primaryNavy};
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;

  @media (min-width: ${breakpoints.tabletLandscape}) {
    font-size: 24px;
  }
`;

export const ContactText = styled.div`
  ${fonts.DomaineDisplay};
  color: ${colors.neutral.primaryNavy};
  font-size: 14px;
  text-decoration: none;
`;

export const Container = styled.div`
  background-color: ${colors.white};
  border-left: 8px solid ${colors.yellow.primary};
  box-shadow: ${cardBoxShadow};
  padding: 12px 24px 0 ${24 - 8}px;

  ${Text} {
    padding-bottom: 12px;
  }
`;

export const ModalButton = styled.button`
  ${fonts.DomaineDisplay};
  color: ${colors.neutral.primaryNavy};
  text-decoration: underline;
`;
