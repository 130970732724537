import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Container = styled.div`
  .circleAndSpacerHolder {
    display: flex;

    .circle {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin: 10px;
      font-size: 16px;
      color: white;

      &.completed {
        background-color: ${themes.colors.yellow.primary};
      }

      &.inprogress {
        border: 10px solid ${themes.colors.yellow.primary};
      }

      &.todo {
        border: 10px solid ${themes.colors.yellow.primary};
      }

      svg {
        left: 8px;
        top: 6px;
        height: 16px;
        width: 16px;
        position: relative;
      }
    }

    .spacer {
      border-radius: 6px;
      width: 56px;
      height: 6px;
      position: relative;
      top: 24px;
      margin-left: 4px;

      @media (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
        width: 28px;
      }

      &.completed,
      &.inprogress {
        background-color: ${themes.colors.yellow.primary};
      }

      &.todo {
        background-color: ${themes.colors.yellow.primary};
      }
    }
  }

  .titleHolder {
    float: right;
    padding-right: 10px;

    .title {
      max-width: 52px;
      height: 18px;
      ${themes.fonts.Gotham};
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      background: white;

      &.todo {
        color: ${themes.colors.neutral.primaryNavy};
      }

      &.completed,
      &.inprogress {
        color: ${themes.colors.neutral.primaryNavy};
      }
    }
  }
`;
